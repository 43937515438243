import React, { useState }from 'react';
import { Button, Col, Collapse, Label, CardBody, Card} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import * as Constant from '../../../../constants/Constant';

const ConfirmationProposerInsPer = ({ respData, fieldValue }) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const [panelOpen, setPanelOpen] = useState(true);
    const [item] = useState("insuredItems")
    const [value] = useState("value");
    const [desc] = useState("desc");

    const InsuredToggle = () => setPanelOpen(!panelOpen);
 
    const loopInsuredPerson = () =>{
        let fields = [];
        for(let i=0; i< fieldValue[item].length; i++){
            fields.push(<Col key={i} xs="12" sm="12" md="12" className="mt-3"><h6>{t('insuredPerson')} {i+1}</h6><br></br><i>{t2('insuredPerson')} {i+1}</i></Col>)

            const insureItem = fieldValue[item][i];
            respData.insuredFields.map((insuredField, insuredIndex) => {
                if(insuredField.key === "name")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.insured.name")}<br></br><i>{t2('trnx.insured.name')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="7">
                        <Label>{insureItem["name"][value]? insureItem["name"][value]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "identityNo")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.insured.identityNo")}<br></br><i>{t2('trnx.insured.identityNo')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{insureItem["identityNo"][value]? insureItem["identityNo"][value]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "dob")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.insured.dob")}<br></br><i>{t2('trnx.insured.dob')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="7">
                        <Label>{insureItem["dob"][value]? format(insureItem["dob"][value], Constant.dateFormat_B): "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "gender")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.insured.gender")}<br></br><i>{t2('trnx.insured.gender')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{insureItem["gender"][desc]? insureItem["gender"][desc]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "email")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.insured.email")}<br></br><i>{t2('trnx.insured.email')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="7">
                        <Label>{insureItem["email"][value]? insureItem["email"][value]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "mobileNo")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.insured.mobileNo")}<br></br><i>{t2('trnx.insured.mobileNo')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{insureItem["mobileNo"][value]? insureItem["mobileNo"][value]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "address1")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.insured.address1")}<br></br><i>{t2('trnx.insured.address1')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="7">
                        <Label>{insureItem["address1"][value]? insureItem["address1"][value]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "address2")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.insured.address2")}<br></br><i>{t2('trnx.insured.address2')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{insureItem["address2"][value]? insureItem["address2"][value]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "address3")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.insured.address3")}<br></br><i>{t2('trnx.insured.address3')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="7">
                        <Label>{insureItem["address3"][value]? insureItem["address3"][value]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "fullAddress")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.insured.fullAddress")}<br></br><i>{t2('trnx.insured.fullAddress')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label className='col-12'>{insureItem["fullAddress"][value]? insureItem["fullAddress"][value]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "province")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.insured.province")}<br></br><i>{t2('trnx.insured.province')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{insureItem["province"][desc]? insureItem["province"][desc]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "city")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.insured.city")}<br></br><i>{t2('trnx.insured.city')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="7">
                        <Label>{insureItem["city"][desc]? insureItem["city"][desc]: "-"}</Label>
                    </Col>
                </Col>)
                if(insuredField.key === "postCode")
                fields.push(
                <Col key={insuredIndex+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.insured.postCode")}<br></br><i>{t2('trnx.insured.postCode')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{insureItem["postCode"][value]? insureItem["postCode"][value]: "-"}</Label>
                    </Col>
                </Col>)
                return fields;
            })
        }
        return fields;
    }

    if(respData.insuredFields.length) return (
        <div>                   
            <div>
            <Button className={"accordion-toggle mt-3 " + (panelOpen ? "collapse-icon" : "expand-icon")} 
                    onClick={e => InsuredToggle(e)}>{t('insuredPersonalInfo')}<br></br><i>{t2('insuredPersonalInfo')}</i>
            </Button>
            <Collapse isOpen={panelOpen}>
            <Card><CardBody><div className="container-flex">
            { fieldValue[item].length !== 0? loopInsuredPerson(): ""} 
            </div></CardBody></Card>
            </Collapse>
            </div>                   
        </div>
    ) 
    else return "";
};

export default ConfirmationProposerInsPer;