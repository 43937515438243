import React, { Component } from "react";
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next'; 

import '../App.css';

class ErrorMessage extends Component {	
	constructor(props) {
		super(props);
		this.onDismiss = this.onDismiss.bind(this);
	}

	onDismiss = () => {
		this.props.clearError();
	}

	render() {
		const { t } = this.props;
		const t2 = (label) => {
			return t('id_translations:'+label) !== label ?
			t('id_translations:'+label) : null; 
		}
		const t3 = (label) => {
			return t('id_riskitems:'+label);
		}
		return (
			<div className="sticky">
				{this.props.errorMsg ?
					(<Alert color={this.props.errorType} isOpen={this.props.isVisible} toggle={this.onDismiss}>
					<div className="mb-3" style={{ textAlign:"left",color: 'black' }}>
						{t(this.props.errorMsg)}
						{this.props.errorDet ?
							Object.values(this.props.errorDet).map( (val) => {

								if (val.indexOf('is required') !== -1) {
									let firstWord = val.split(" ")[0];
									return <ul key={val}><li>{t2(firstWord) ? (t2(firstWord) + ' ' + t('isRequired')) : t3(firstWord) ? (t3(firstWord) + ' ' + t('isRequired')) : val}</li></ul>
								} else if (val.indexOf('greater than') !== -1) {
									let firstWord = val.split(" ")[0];
									let numberLastWord = val.lastIndexOf(" ");
									let lastWord = val.substring(numberLastWord).replace(/[." "]/g, "");
									return <ul key={val}><li>{t2(firstWord) ? (t2(firstWord) + ' ' + t('greaterThan') + ' ' + t2(lastWord) + '.') : t3(firstWord) ? (t3(firstWord) + ' ' + t('greaterThan') + ' ' + t3(lastWord) + '.') : val}</li></ul>
								} else if (val.indexOf('lesser than') !== -1) {
									let firstWord = val.split(" ")[0];
									let numberLastWord = val.lastIndexOf(" ");
									let lastWord = val.substring(numberLastWord).replace(/[." "]/g, "");
									return <ul key={val}><li>{t2(firstWord) ? (t2(firstWord) + ' ' + t('lesserThan') + ' ' + t2(lastWord) + '.') : t3(firstWord) ? (t3(firstWord) + ' ' + t('lesserThan') + ' ' + t3(lastWord) + '.') : val}</li></ul>
								}
								else return <ul key={val}><li>{t2(val) ? t2(val) : t3(val) ? t3(val) : t(val)}</li></ul>
							})
						:''}
					</div>
					</Alert>)
				: ''}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		errorMsg: state.messageReducer.errorMsg,
		errorType: state.messageReducer.errorType,
		isVisible: state.messageReducer.isVisible,
		errorDet: state.messageReducer.errorDet
  }
}

const mapDispatchToState = (dispatch) => {
    return {
		clearError: () => {dispatch({type : 'CLEAR_ERROR'})}
    }
}

export default connect(mapStateToProps, mapDispatchToState)(withTranslation('id_errmsg')(ErrorMessage));