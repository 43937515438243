import React, { useState } from 'react';
import { Button, Label, Input } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import $ from 'jquery';
import CompanyListing from './CompanyListing';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Select from './../../utils/Select';
import * as Constant from './../../constants/Constant';

const Company = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAllowNew = useSelector(state => state.sideMenuReducer.functionItems.companyDetail ? state.sideMenuReducer.functionItems.companyDetail.includes('NEW') : '');
    const [searchCriteria,setSearchCriteria] = useState({initLoad: true, companyNpwp: '', companyName: '', companyStatus: true});
    
    const handleSearch = () => {
        let companyNpwp = searchCriteria.companyNpwp;
        let companyName = searchCriteria.companyName;
        let companyStatus = searchCriteria.companyStatus;
        
        if ($('input[name="companyNpwp"]').val() !== undefined) {
            companyNpwp = $('input[name="companyNpwp"]').val();
        }
        if ($('input[name="companyName"]').val() !== undefined) {
            companyName = $('input[name="companyName"]').val();
        }
        if ($('select[name="companyStatus"]').val() !== undefined) {
            companyStatus = $('select[name="companyStatus"]').val();
        }
        setSearchCriteria({initLoad: false,
            companyNpwp: companyNpwp,
            companyName: companyName,
            companyStatus: companyStatus})
    }

    if (searchCriteria.initLoad) {
        handleSearch();
    }

    const handleNew = () => {
        dispatch(push({pathname: Constant.PATH_COMPANY_DETAILS, state: { action: 'NEW', selected: null }}))
    }
    
        return (
            <div>                
                <Container style={{marginBottom: "30px"}}>
                <Row className="mt-3"><Col><h2>{t('companymaintenance')}</h2></Col></Row>
                <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('searchcompany')}</h4></Col></Row>
                <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>

                <div></div>
                        <Row className="mt-3">
                            <Col xs="10" sm="3" md="3"><Label>{t('companynpwp')}</Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="5" md="5"><Input name="companyNpwp" maxLength={40} /></Col>    
                        </Row>
                        <Row className="mt-3">
                            <Col xs="10" sm="3" md="3"><Label>{t('companyname')}</Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="5" md="5"><Input name="companyName" maxLength={50} /></Col>    
                        </Row>
                        <Row className="mt-3">
                            <Col xs="10" sm="3" md="3"><Label>{t('status')}</Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="5" md="5"><Select name='companyStatus' optionList={Constant.statusList}/></Col>
                         </Row>

                         <Row className="mt-3">
                            <Col xs="12" sm="12" md="12">
                                <center>
                                    <Button name="btnSearch" onClick={() => handleSearch()}>{t('button.search')}</Button>
                                    <> </>
                                    {isAllowNew ? <Button name="btnNew" onClick={() => handleNew()}>{t('button.add')}</Button>
                                    : <></>}
                                </center>
                            </Col>
                        </Row>	
                        
                    </Container>
                
                <CompanyListing searchCriteria={searchCriteria}/>
            </div>
        );
    
}

export default Company;