import * as actions from '../../actions/loginAction';

const initState = {
    errorMsg: '',           //error msg
    infoMsg: '',
    errorType: '',          //something like warning(orange), danger(red), info(blue), success(green)
    isVisible: false,
    errorDet: []        
}

const messageReducer = (state = initState, action) => {
    switch(action.type) {
        case actions.DISPLAY_ERROR: {
            return {
                ...state.messageReducer,
                errorMsg: action.errorMsg,
                errorType: 'danger',
                isVisible: true ,
                errorDet:  action.errorDetails  
            };
        }
        case actions.DISPLAY_INFO: {
            return {
                ...state.messageReducer,
                infoMsg: action.infoMsg,
                errorType: 'info',
                isVisible: true    
            };
        }
        case actions.CLEAR_ERROR: {
            return {
                ...state.messageReducer,
                infoMsg: '',
                errorMsg: '',
                errorType: '',
                isVisible: false,
                errorDet: ''    
            };
        }
        case actions.LOGOUT: {
            return {
                ...state.messageReducer,
                errorMsg: '',
                errorDet: ''
            };
        }    
        default: {
            return state;
        }
    }   

}

export default messageReducer;