import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from '../../../utils/Select';

const QuestionFields = ({ respData, fields, dropdownList, fieldValue, onChangeInputSelect}) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('id_riskitems:'+label); 
    }
    const t3 = (label) => {
        return t('en_riskitems:'+label); 
    }
    const [panelOpen, setPanelOpen] = useState(false);

    const toggle = () => {setPanelOpen(!panelOpen)};

    if (respData.questionFields.length !== 0) return (
        <div>
        <Button className={"accordion-toggle mt-3 " + (panelOpen ? "collapse-icon" : "expand-icon")} 
            onClick={e => toggle(e)}>{t("question")}<br></br><i>{t('en_translations:question')}</i>
        </Button>
        <Collapse isOpen={panelOpen}>
            <Card>
                <CardBody>
                    <div className="container-flex" xs="12" sm="12" md="12">
                    {fields.field.map((f,i) => {
                        return respData.questionFields.map((quesField,i) => {
                            if (quesField.key === f.key) {
                                return (
                                    <Col key={i} xs="12" sm="12" md="12" className="mt-3 container-flex">
                                        <Col xs="10" sm="8" md="8" className="paddingRightZero">
                                            <Label className={quesField.isRequired ? "required" : ""}>
                                                {quesField.axLabel && quesField.axLabel.axLabelId ? quesField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                <br></br>
                                                <i>{quesField.axLabel && quesField.axLabel.axLabelEn ? quesField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                            </Label>
                                        </Col>
                                        <Col xs="2" sm="1" md="1">:</Col>
                                        <Col xs="12" sm="3" md="3">
                                        <div>
                                            <Select name={f.key} optionList={dropdownList(quesField.options)} defaultVal={fieldValue["questionItems"][f.key].value} value={fieldValue["questionItems"][f.key].value} onChange={e => onChangeInputSelect(e, "questionItems", true)} />
                                            <div className="error"><span id={"errMsg_"+f.key}/></div>
                                        </div>
                                        </Col>
                                    </Col>
                                )
                            }else return false;
                        })
                    })}
                    </div>
                </CardBody>
            </Card>
        </Collapse>
        </div>
    )
    else return "";
};

export default QuestionFields;