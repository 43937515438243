import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import copy from "copy-to-clipboard";  

const CopyTooltipItem = props => {
    const { id, url } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { t } = useTranslation();
    
    return (
      <span>
        <Tooltip placement="top" isOpen={tooltipOpen} target={"tooltipmessage-"+id} toggle={()=>setTooltipOpen(!tooltipOpen)}>
           {t('copyLink')}
        </Tooltip>
        <i id={"tooltipmessage-"+id} className="icon-clipboard" >
        <FontAwesomeIcon icon={faCopy} onClick={()=>copy(url)} /></i>
      </span>
    );
};

export default CopyTooltipItem;