import { useSelector } from "react-redux";
import React from 'react';  
import geLogo from '../../images/gel-logo.png';
import * as Constant from '../../constants/Constant';

const Logo = () => {
    const logoSelector = useSelector(state => state.loginReducer.companylogoImgBase64);
    const logoStatusSelector = useSelector(state => state.loginReducer.companylogoImgStatus);

    return (
        <div className="col-md-2" >
            {logoSelector?
                logoStatusSelector === Constant.LOGO_DISPLAY_STATUS.default ?
                    <img src={geLogo} alt="Great Eastern Logo"/>:
                    logoStatusSelector === Constant.LOGO_DISPLAY_STATUS.unhide?
                        <img className="img-fluid" src={`data:image;base64,${logoSelector}`} alt="Initial Logo"/>:
                        '': 
                <img src={geLogo} alt="Great Eastern Logo"/>
            }
        </div>
    );
}

export default Logo;
