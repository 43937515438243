import React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Constant from '../../constants/Constant';
import * as urlConstant from '../../constants/urlConstant';
import {Download } from '../../utils/AxFetch';

const ProductInfo = ({ respData, nextSlideTransitions,channel}) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }

    const dispatch = useDispatch();

    const handleDownloadClick = async() => {
        let url = '';        
        if (channel === Constant.LOGIN_CHANNEL.PARTNER)
            url = urlConstant.URL_B2B_TRNX_DOWNLOAD_PRODUCT_INFO;
        else if (channel ===Constant.LOGIN_CHANNEL.CONSUMER)
            url = urlConstant.URL_B2C_TRNX_DOWNLOAD_PRODUCT_INFO;

        let product = respData.code;
        await Download(urlConstant.URL_BASED+ url + product, Constant.downloadType.GET, null, Constant.pdfdownloadfile, dispatch);
    }

    if (respData) return (
        <div>
        <Row className="mt-3"><Col className="titleH4"><h4>{t('productinfo')}</h4></Col></Row>
        <Row><Col className="titleH4"><h6><i>{t2('productinfo')}</i></h6></Col></Row>
        <Row><Col className="borderBottom"></Col></Row>
        <Row className="mt-3">
            <Col xs="10" sm="3" md="3"><Label>{t("infoproductcode")}<br></br><i>{t2('infoproductcode')}</i></Label></Col>
            <Col xs="2" sm="1" md="1">:</Col>
            <Col xs="12" sm="5" md="5">{respData.code}</Col>    
        </Row>
        <Row className="mt-3">
            <Col xs="10" sm="3" md="3"><Label>{t("productdescription")}<br></br><i>{t2('productdescription')}</i></Label></Col>
            <Col xs="2" sm="1" md="1">:</Col>
            <Col xs="12" sm="5" md="5">{respData.desc}</Col>    
        </Row>
        <Row className="mt-3">
            <Col><center>
                {respData.pdfPath ? (
                <Button onClick={() => handleDownloadClick()}> {t('button.downloadDocument')}</Button>
                ) : null}<> </>
                <Button onClick={() => nextSlideTransitions(true)}> {t('button.next')}</Button></center>
            </Col>
        </Row>
        </div>
    )
    else return "";
};

export default ProductInfo;