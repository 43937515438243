import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Input, Label, Row  } from 'reactstrap';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import * as Constant from './../../constants/Constant';
import * as urlConstant from './../../constants/urlConstant';
import { Get, Post } from '../../utils/AxFetch';
import { useDispatch, useSelector } from 'react-redux';
import MultiselectTwoSides from 'react-multiselect-two-sides';

const AccessControl = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAllowEdit = useSelector(state => state.sideMenuReducer.functionItems.accessControlDetail ? state.sideMenuReducer.functionItems.accessControlDetail.includes('EDIT') : '');
    const [action,setAction] = useState(props.location?.state?.action||"");
    const [idSelected,setIdSelected] = useState(props.location?.state?.idSelected||"");
    const [message,setMessage] = useState("");
    const [functionList, setFunctionList] = useState({options: [], value: []});
    const [isErrReturned, setErrReturned] = useState(false);

    const validateForm = () => {
        let message = '';

        if (!$('input[name="rolename"]').val()) message += t('error.message.input.blank', { 0: t('rolename') })+'\n'

        if (functionList.value.length === 0) message += t('error.message.input.blank', { 0: t('functionlist') + ' ' + t('selected')})+'\n'

        if (message) {
            setMessage(message);
            return false;
        }
        return true;
    };

    const populateField = (data, respFunctionList, respValue) => {
            var lastModifiedName = data.lastModifiedByName;
            var lastModifiedDatetime = data.lastModifiedDatetime;

            var func = data.functions.find(func => (func.selected));
            if(func) {
                lastModifiedName = func.lastModifiedByName;
                lastModifiedDatetime = func.lastModifiedDatetime;
            }
            $('input[name="rolename"]').val(data.name);
            $('#lastUpdateOn').text(lastModifiedDatetime);
            $('#lastUpdateBy').text(lastModifiedName);
    }

    const handleChange = (value) => {
        setFunctionList({options: functionList.options, value: value});
    }

    const handleSave = async dispatch => {
        
        let url = '';
        let funcSelected = '';
        let roleForm = {};

        if (validateForm()) {
            if (action === 'NEW') {
                url = urlConstant.URL_ROLE_CREATE;
                funcSelected = functionList.value.map(valueSelected => {return {funcId: valueSelected}});
                roleForm = {name: $('input[name="rolename"]').val(), functions: funcSelected};
            } else if (action === 'EDIT') {
                url = urlConstant.URL_ROLE_UPDATE;
                funcSelected = functionList.value.map(valueSelected => {return {funcId: valueSelected}});
                roleForm = {id: idSelected, name: $('input[name="rolename"]').val(), functions: funcSelected};
            }

            if(action) {
                let resp = await Post(urlConstant.URL_BASED + url, roleForm, dispatch);

                if(!resp.isError && resp.respData.data) { 
                    if (action === 'NEW') {
                        setMessage(t('message.save.success', { 0: t('role'), 1: t('created') }));
                        setIdSelected(resp.respData.data.id);
                        setAction('EDIT');
                    } else if (action === 'EDIT') {
                        setMessage(t('message.save.success', { 0: t('role'), 1: t('updated') }));
                        populateField(resp.respData.data);
                    }
                }
            }
        }
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        const getFunctionList = async dispatch => {
            let url = '';
            let respFunctionList = [];
            let respValue = [];
    
            if (action === 'NEW') {
                url = urlConstant.URL_ROLE_FUNCTION_LIST;
            } else if (action === 'EDIT') {
                url = urlConstant.URL_ROLE_RETRIEVE + idSelected;
            }
            
            if(action) {
                let resp = await Get(urlConstant.URL_BASED + url , null, dispatch);
                if(!resp.isError && resp.respData.data) { 
                    if (action === 'NEW') {
                        respFunctionList = resp.respData.data.map(func => {
                            return {name: func.name, value: func.funcId}
                        });
                    } else if (action === 'EDIT') {
                        respFunctionList = resp.respData.data.functions.map(func => {
                            return {name: func.name, value: func.funcId}
                        });
            
                        respValue = resp.respData.data.functions.filter(func => func.selected)
                        .map(func => { return func.funcId });
    
                        populateField(resp.respData.data);
                    }
                }else{
                    setErrReturned(resp.isError);
                }
            }
            
            setFunctionList({options: respFunctionList, value: respValue});
        }

        dispatch(getFunctionList);
        window.scrollTo(0, 0);
    }, [dispatch, action, idSelected]);

    return (
        <div>
            <Container>
                { message? <div className="error"><p>{message}</p></div>: null}
                <Row className="mt-3"><Col><h2>{t('accesscontrolmaintenance')}</h2></Col></Row>
                <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('roledetail')}</h4></Col></Row>
                <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>
                <Row className="mt-3">
                        <Col sm={{ size: 3 }}><Label className="required">{t('rolename')} :</Label></Col>
                        <Col sm={{ size: 5 }}><Input type="text" name="rolename" placeholder={t('placeholder.enter', { 0: t('rolename') })} readOnly={action === "EDIT"} maxLength={100}/></Col>
                </Row>
                {action === 'EDIT' ? 
                    <div>
                        <Row className="mt-3">
                            <Col sm={{ size: 3 }}><Label>{t('lastupdatedon')} :</Label></Col>
                            <Col sm={{ size: 5 }}><span id='lastUpdateOn'></span></Col>
                        </Row>
                        <Row className="mt-3">
                            <Col sm={{ size: 3 }}><Label>{t('lastupdatedby')} :</Label></Col>
                            <Col sm={{ size: 5 }}><span id='lastUpdateBy'></span></Col>
                        </Row>
                    </div>
                : ''}
                <Row className="mt-5"><Col style={{color: '#e80b1c'}}><h4>{t('functionlist')}</h4></Col></Row>
                <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>
                <MultiselectTwoSides
                    {...functionList}
                    className="msts_theme_example mt-5"
                    onChange={(value) => handleChange(value)}
                    availableHeader={t('available')}
                    selectedHeader={t('selected')}
                    labelKey="name"
                    placeholder="Filter..."
                    searchable
                />
                <div style={{textAlign: 'center'}}>
                    {(isAllowEdit || action === 'NEW') && !isErrReturned ? <Button className="mt-3" onClick={() => dispatch(handleSave)}>{t('button.save')}</Button>: <></>}{' '}
                    <Button className="mt-3" onClick={() => dispatch(push(Constant.PATH_ROLE_ACCESSCONTROL))}>{t('button.back')}</Button>
                </div>
            </Container>
        </div>
    )
}

export default AccessControl;
