import React, { Component } from 'react';
import { Nav, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { Accordion } from "./Accordion";
import { push } from "connected-react-router";

import './SideDrawer.css';

const SidebarItem = ({ items, goTo }) => {
    return (
        <>  
            <li className={"list-group-item " + (items.level === 1 ? "menu-first-level" : "")}>
                {items.menuItem.length ? (    
                    <Accordion open={0}>
                        <Accordion.Item>
                            <Accordion.Header>
                                <span className="menu-header-multi">{items.label}</span>
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className="list-group">
                                    {items.menuItem.map((subItem, index) => (
                                        <SidebarItem
                                            key={`${subItem.name}${index}`}
                                            items={subItem}
                                            goTo={goTo}
                                        />
                                    ))}
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                ) : (
                    <Accordion>
                        <Accordion.Item>
                            <Accordion.Header>
                                {items.level === 1 ? 
                                    <div className="menu-header-multi text-align-left">
                                        <NavLink href={'/' + items.name} onClick={(e) => goTo(e, '/' + items.name)}>{items.label}</NavLink>
                                    </div>
                                    : 
                                    <div className="menu-last-level text-align-left">
                                        <NavLink href={'/' + items.name} onClick={(e) => goTo(e, '/' + items.name)}>{items.label}</NavLink>
                                    </div>
                                }
                            </Accordion.Header>
                        </Accordion.Item>
                    </Accordion>
                )}
            </li>
        </>
    )
}

class sideDrawer extends Component {
    goTo = (e, route) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.props.goTo(route);
    }

    render() {
        let drawerClassNm = "";
        let menuItem = "";
        if (this.props.show) {
            drawerClassNm = " open";
        }

        if(this.props.menuItems) {
            menuItem = this.props.menuItems.map((sidebarItem, index) => (
                <SidebarItem
                    key={`${sidebarItem.name}${index}`}
                    items={sidebarItem}
                    goTo={this.goTo}
                />
            ))
        }

        return (
            <Nav className={'side-drawer accordion md-accordion ' + drawerClassNm} navbar>
                <ul className="list-group">
                    {menuItem}
                </ul>
            </Nav>
        )
    }
}

const mapStateToProps = (state) => {
	return {
		menuItems : state.sideMenuReducer.sideMenuItems
	}
}

const mapDispatchToState = (dispatch) => {
    return {
      goTo: (route) => {
          dispatch(push(route));
      }
    }
}

export default connect(mapStateToProps, mapDispatchToState)(sideDrawer);