import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PaginationBar from '../../components/PaginationBar';
import * as urlConstant from '../../constants/urlConstant';
import * as Constant from '../../constants/Constant';
import { useDispatch } from 'react-redux';
import { Post } from '../../utils/AxFetch';

const ClaimRegistrationReportListing = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [certRegistrationReportParams, setCertRegistrationReportParams] = useState({data:[], currentPage:1, pageSize:0, pagesCount:0});
    const [pageOnClick,setPageOnClick] = useState(1);

    useEffect(() => {
        if (props.searchCriteria) {
            setPageOnClick(1);
        }
    }, [props.searchCriteria]);

    const onClick = (page) => {  
        setPageOnClick(page)  
    }

    useEffect (() => {
        let searchCriteria = props.searchCriteria;

        const getCertRegistrationReport = async(page) => {
            let resData = '';
            let resCurrentPage = '';
            let resPageSize = '';
            let resPagesCount = '';
            
            let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_REPORT_CLAIM_REGISTERED_LIST + page,
                {
                    rowPerPage: Constant.rowPerPage,
                    pageNum: page,
                    claimDateFrom: searchCriteria.searchEffectiveFromDt,
                    claimDateTo: searchCriteria.searchEffectiveToDt,
                    companyId: searchCriteria.searchCompanyId,
                    productCode: searchCriteria.searchProdCodes,
                    proposerIdentityNo: searchCriteria.searchKTPNo,
                    claimStatus: searchCriteria.searchClaimStatus,
                    policyType: searchCriteria.searchPolicyType 
                }, dispatch);
            
            let dataResp = resp.respData;
            
            if(!resp.isError && dataResp.data) {
                resData = dataResp.data.content;
                resCurrentPage = page;
                resPageSize = dataResp.data.size;
                resPagesCount = dataResp.data.totalPages;
            }
            setCertRegistrationReportParams({data:resData, currentPage: resCurrentPage, pageSize: resPageSize, pagesCount: resPagesCount});
            
        }
        if (searchCriteria && props.isClickSearchButton) getCertRegistrationReport(pageOnClick);
    }, [dispatch, props.searchCriteria, pageOnClick, props.isClickSearchButton]);

    return (
        <div>
            <Table className="mt-3" bordered>
            <thead>
                <tr>
                    <th style={{width: '10%'}}>{t('no.')}</th>
                    <th>{t('claimregistered.result.year')}</th>
                    <th>{t('claimregistered.result.month')}</th>
                    <th>{t('claimregistered.result.product')}</th>
                    <th>{t('claimregistered.search.policytype')}</th>
                    <th>{t('claimregistered.result.claimno')}</th>
                    <th>{t('claimregistered.result.certificate')}</th>
                    <th>{t('claimregistered.result.affinityno')}</th>
                    <th>{t('claimregistered.result.affinityname')}</th>
                    <th>{t('claimregistered.result.insuredname')}</th>
                    <th>{t('claimregistered.result.claimdesc')}</th>
                    <th>{t('claimregistered.result.claimdate')}</th>
                    <th>{t('claimregistered.result.claimamount')}</th>
                    <th>{t('claimregistered.result.settlementamount')}</th>
                    <th>{t('claimregistered.result.claimstatus')}</th>
                    <th>{t('claimregistered.result.claimregistereddt')}</th>
                    <th>{t('claimregistered.result.claimapprovalby')}</th>
                    <th>{t('claimregistered.result.claimapprovaldt')}</th>
                </tr>
            </thead>
            { (certRegistrationReportParams.data.length > 0) ?
            <tbody>
                {certRegistrationReportParams.data.map((item, i) => {
                return(
                    <tr key={i}>
                        <td style={{width: '10%'}}>{i+1}</td>
                        <td>{item.claimYear}</td>
                        <td>{item.claimMonth}</td>
                        <td>{item.productDesc}</td>
                        <td>{item.policyType}</td>
                        <td>{item.claimRefNo}</td>
                        <td>{item.policyNo}</td>
                        <td>{item.agentNo}</td>
                        <td>{item.createdByName}</td>
                        <td>{item.insuredName}</td>
                        <td>{item.claimRemark}</td>
                        <td>{item.incidentDate}</td>
                        <td>{item.claimAmount}</td>
                        <td>{item.settlementAmount}</td>
                        <td>{item.claimStatus}</td>
                        <td>{item.dateRegister}</td>
                        <td>{item.approvedBy}</td>
                        <td>{item.approvedDate}</td>
                    </tr>
                )}
            )}
            </tbody>
            :<tbody><tr><td className="emptyrecord" colSpan="17">{t('norecordfound')}</td></tr></tbody>
            }
            </Table>
            <PaginationBar totalPages={certRegistrationReportParams.pagesCount} currentPage={certRegistrationReportParams.currentPage} handleClick={(page) => onClick(page)} />
        </div>
        )
}
export default ClaimRegistrationReportListing;