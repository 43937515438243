import React, { useState, useEffect } from 'react';
import { Button, Collapse, CardBody, Card, Label, Row, Col } from 'reactstrap';
import * as Constant from './../../../constants/Constant';
import * as urlConstant from './../../../constants/urlConstant';
import $ from 'jquery';
import { Get } from './../../../utils/AxFetch';
import { useTranslation } from 'react-i18next';

const TechnicalDetails = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [dataList,setDataList] = useState({});

  const toggle = () => setIsOpen(!isOpen);

  const getCountList = async dispatch => {
    let premiumCount = "-";
    let createPolicyCount = "-";
    let respPremiumCount = await Get(urlConstant.URL_BASED + urlConstant.URL_TRNX_COUNT + Constant.TRNX_COUNT_GET_PREMIUM, null, dispatch);

    if (!respPremiumCount.isError && respPremiumCount.respData) {
      premiumCount = respPremiumCount.respData.data;
    }

    let respCreatePolicyCount = await Get(urlConstant.URL_BASED + urlConstant.URL_TRNX_COUNT + Constant.TRNX_COUNT_CREATE_POLICY, null, dispatch);

    if (!respCreatePolicyCount.isError && respCreatePolicyCount.respData) {
      createPolicyCount = respCreatePolicyCount.respData.data;
    }

    setDataList({premiumCount:premiumCount,createPolicyCount:createPolicyCount});
  }

  useEffect(() => {

    if (isOpen) {
      $("#technical").removeClass("expand-icon");
      $("#technical").addClass("collapse-icon");
      getCountList();
    } else {
      $("#technical").removeClass("collapse-icon");
      $("#technical").addClass("expand-icon");
    }
  }, [isOpen]);

  return (
    <div className="contentTab">
      <Button id="technical" className="accordion-toggle" onClick={toggle}>{t('technicaldetails')}</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
              <Row>
                <Col xs="10" sm="4" md="4" className="column"><Label>{t('getpremiumcount')}</Label></Col>
                <Col xs="2" sm="1" md="1" className="column text-center">:</Col>
                <Col xs="12" sm="1" md="1" className="column">{dataList.premiumCount}</Col>
                <Col  xs="12" sm="6" md="6"/>
              </Row>
              <Row>
                <Col xs="10" sm="4" md="4" className="column"><Label>{t('createpolicycount')} </Label></Col>
                <Col xs="2" sm="1" md="1" className="column text-center">:</Col>
                <Col xs="12" sm="1" md="1" className="column">{dataList.createPolicyCount}</Col>  
                <Col  xs="12" sm="6" md="6"/>  
              </Row>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

export default TechnicalDetails;