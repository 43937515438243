import React, { useState, useEffect, useCallback } from 'react';
import { Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PaginationBar from '../../components/PaginationBar';
import * as urlConstant from '../../constants/urlConstant';
import * as Constant from '../../constants/Constant';
import { useDispatch } from 'react-redux';
import { Post, Get } from '../../utils/AxFetch';
import { push } from 'connected-react-router';
import TransactionCancel from './TransactionCancel';
import TransactionUpdatePayment from './TransactionUpdatePayment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const TransactionListing = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [transactionListingParams, setTransactionListingParams] = useState({data:[], currentPage:1, pageSize:0, pagesCount:0});
    const [modal, setModal] = useState({isOpen: false, title: '', type: '', param:{}});

    const onClick = (page) => {  
        getTransactions(page);
    }

    const getTransactions = useCallback(async (page) => {
        let searchCriteria = props.searchCriteria;
        let respData = '';
        let respCurrentPage = '';
        let respPageSize = '';
        let respPagesCount = '';
        
        let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_TRNX_ENQUIRY_LIST + page,
            {
                rowPerPage: Constant.rowPerPage,
                pageNum: page,
                trnxDateFrom: searchCriteria.searchEffectiveFromDt,
                trnxDateTo: searchCriteria.searchEffectiveToDt,
                companyId: searchCriteria.searchCompanyId,
                productCode: searchCriteria.searchProdCodes,
                proposerName: searchCriteria.searchProposerName,
                trnxStatus: searchCriteria.searchCIStatus,
                policyNo: searchCriteria.searchCertInsrNo,
                policyType: searchCriteria.searchPolicyType,
                memberAgentCode: searchCriteria.searchMemberAgentCode
            }, dispatch);
        
        let dataResp = resp.respData;
        
        if(!resp.isError && dataResp.data) {
            respData = dataResp.data.content;
            respCurrentPage = page;
            respPageSize = dataResp.data.size;
            respPagesCount = dataResp.data.totalPages;
        }
        setTransactionListingParams({data:respData, currentPage: respCurrentPage, pageSize: respPageSize, pagesCount: respPagesCount});
    }, [props.searchCriteria, dispatch]);

    useEffect (() => {
        if (props.searchCriteria) getTransactions(1);
    },[props.searchCriteria, getTransactions]);

    const handleClose = (isSubmit, message) => {
        setModal({isOpen: false, title: '', type: '', param:{}});
        if(isSubmit) {
            getTransactions(transactionListingParams.currentPage);
            props.setMessage(message);
            window.scrollTo(0, 0);
        }
    }

    const handleView = (certinsno) => {
        let selected = certinsno;
        if(!selected) return;
        dispatch(push({pathname: Constant.PATH_TRANSACTION_DETAILS, state: { action: 'VIEW', selected: selected }}))
    }

    const initUpdatePayment = async (txnid) => {
        let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_UPDATE_PAYMENT_INIT + txnid, null, dispatch);
        
        if(!resp.isError && resp.respData.data) {
            let modalObj = {
                isOpen: true,
                title: 'transaction.updatepayment.detail',
                type: 'UpdatePayment',
                params:{
                    transactionId: txnid,
                    data: [resp.respData.data]
                }
            }
            setModal(modalObj);
       }else{
            window.scrollTo(0, 0);
        }  
    }

    const initCancel = async (txnid, certNo) => {
        let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_TRNX_CANCEL_INIT + txnid, null, dispatch);
        if(!resp.isError && resp.respData.data) {
            let modalObj = {
                isOpen: true,
                title: 'transaction.cancel',
                type: 'Cancel',
                params:{
                    transactionId: txnid,
                    certNo: certNo,
                    data: [resp.respData.data]
                }
            }
            setModal(modalObj);
        }else{
            window.scrollTo(0, 0);
        }
    }
    
    return(
        <div>
            <Table className="mt-3" bordered>
            <thead>
                <tr>
                    <th style={{width: '10%'}}>{t('no.')}</th>
                    <th>{t('certificateinsurance.search.txndate')}</th>
                    <th>{t('transaction.listing.txnid')}</th>
                    <th>{t('productname')}</th>
                    <th>{t('transaction.listing.policytype')}</th>
                    <th>{t('certificateinsuranceno')}</th>
                    <th>{t('effectivedate')}</th>
                    <th>{t('certificateinsurance.result.expirydt')}</th>
                    <th>{t('transaction.listing.proposername')}</th>
                    <th>{t('grossPremium')}</th>
                    <th>{t('transaction.listing.commission')}</th>
                    <th>{t('transaction.listing.nettPremuim')}</th>
                    <th>{t('status')}</th>
                    <th>{t('memberAgentCode')}</th>
                    <th>{t('updatePayment')}</th>
                    <th>{t('cancelCI')}</th>
                </tr>
            </thead>
            { (transactionListingParams.data.length > 0) ?
                <tbody>
                {transactionListingParams.data.map((item, i) => {
                return(
                    <tr key={i}>
                        <td style={{width: '10%'}}>{i+1}</td>
                        <td>{item.issuedDate}</td>
                        <td>{item.id}</td>
                        <td>{item.productDesc}</td>
                        <td>{item.policyType}</td>
                        <td><button className="link-button" onClick={()=>{handleView(item.id)} } >{item.policyNo}</button></td>
                        <td>{item.issuanceStartDate}</td>
                        <td>{item.issuanceEndDate}</td>
                        <td>{item.insuredName}</td>
                        <td>{item.grossPremium}</td>
                        <td>{item.commissionAmt}</td>
                        <td>{item.nettPremium}</td>
                        <td>{item.status}</td>
                        <td>{item.memberAgentCode}</td>
                        <td>{item.status.toUpperCase() === 'PENDING'?
                            <i className="icon-button" name="btnUpdatePayment" onClick={()=>initUpdatePayment(item.id)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </i>
                            : null
                        }</td>
                        <td>{item.status.toUpperCase() === 'CANCELLED'? null:
                            <i className="icon-button" name="btnCancel" onClick={()=>initCancel(item.id, item.policyNo)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </i>
                        }</td>
                    </tr>
                    )}
                )}
                </tbody>
            :<tbody><tr><td className="emptyrecord" colSpan="17">{t('norecordfound')}</td></tr></tbody>
            }
            </Table>
            <PaginationBar totalPages={transactionListingParams.pagesCount} currentPage={transactionListingParams.currentPage} handleClick={(page) => onClick(page)} />
            
            <Modal isOpen={modal.isOpen} >
                <ModalHeader className="modal_header" toggle={() => {handleClose(false, '')}}>{t(modal.title)}</ModalHeader>
                <ModalBody className="report-modal-body" id="bodyId">
                    {modal.type === 'Cancel' ? <TransactionCancel params={modal.params} handleClose={handleClose} /> :
                    modal.type === 'UpdatePayment' ? <TransactionUpdatePayment params={modal.params} handleClose={handleClose} /> :
                    <></>}
                </ModalBody>
            </Modal>
        </div>
    )
}

export default TransactionListing;