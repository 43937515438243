import React, { useState, useEffect, useCallback } from 'react';
import { Label, Input, Button, Container, Row, Col, CustomInput } from 'reactstrap';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import * as Constant from './../../constants/Constant';
import * as urlConstant from './../../constants/urlConstant';
import * as actions from '../../actions/loginAction'
import { Get, Post } from '../../utils/AxFetch';
import * as Crypto from './../../utils/Crypto';

import * as Validate from './../../utils/CommonValidate';
import { useDispatch, useSelector } from 'react-redux';
import Select from './../../utils/Select';

const CompanyDetail = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAllowEdit = useSelector(state => state.sideMenuReducer.functionItems.companyDetail ? state.sideMenuReducer.functionItems.companyDetail.includes('EDIT') : '');
    const loginCompanyId = useSelector(state => state.loginReducer.companyId);
    // const logoSelector = useSelector(state => state.loginReducer.companylogoImgBase64);
    const [action,setAction] = useState(props.location?.state?.action||"");
    const [selected,setSelected] = useState(props.location?.state?.selected||"");
    const [message,setMessage] = useState("");
    const [userType,setUserType] = useState("");
    const [dropdownList,setDropdownList] = useState({userTypeList:[]});
    const [isErrReturned, setErrReturned] = useState(false);
    const [readOnlyFlag, setReadOnlyFlag] = useState(false);
    const [uploadLogoName, setUploadLogoName] = useState('')
    const [uploadLogoBase64, setUploadLogoBase64] = useState('');
    const [disableLogoStatus, setDisableLogoStatus] = useState(true);
    const logoDisplayStatusList = Constant.LOGO_DISPLAY_STATUS;
    const [showMemberAgentField, setShowMemberAgentField] = useState([]);
    const [inputNewMemberAgentCodes, setInputNewMemberAgentCodes] = useState([]);
    const [memberAgentCodeList, setMemberAgentCodeList] = useState({});
    const [removeMemberAgentCodes, setRemoveMemberAgentCodes] = useState([]);

    const validateForm = () => {

        let message = '';
        let systemId = $('input[name="systemId"]').val();
        let uploadLogo = $('input[name="uploadLogo"]').prop('files');

        if (!$('select[name="userTyp"]').val()) message += t('error.message.select.blank', { 0: t('usertype') })+'\n'
        if (!$('input[name="companyNPWP"]').val()) message += t('error.message.input.blank', { 0: t('companynpwp') })+'\n'
        if (!$('input[name="companyName"]').val()) message += t('error.message.input.blank', { 0: t('companyname') })+'\n'
        if (!$('select[name="status"]').val()) message += t('error.message.select.blank', { 0: t('status') })+'\n'
        if (!$('input[name="sourceId"]').val()) message += t('error.message.input.blank', { 0: t('sourceid') })+'\n'
        if (!$('input[name="agentCode"]').val()) message += t('error.message.input.blank', { 0: t('agentCode') })+'\n'
        if (userType !== Constant.USERTYPE_STAFF) {
            if (!systemId) {
                message += t('error.message.input.blank', { 0: t('systemid') })+'\n'
            } else {
                if (systemId.length < 8) {
                    message += t('error.message.min.length', { 0: t('systemid'), 1: 8 })+'\n'
                }
                if (!Validate.validatePasswordBlur(systemId)) { 
                    message += t('error.message.invalid.field', { 0: t('systemid') })+'\n'
                }
            }
        }
        if ( $('input[name="email"]').val() ){
            if (!Validate.validateEmail($('input[name="email"]').val())) {
                message += t('error.message.invalid.field', { 0: t('email') })+'\n'
            }
        }
        if ( uploadLogo.length>0)
        {            
            if(!Validate.validateFileExtensions(uploadLogo[0].name, Constant.imagePathType)){
                message += t('error.message.imgfile.extension')+'\n'
            }

            if (!Validate.validateFileSize(uploadLogo[0].size, Constant.imageSize*Constant.kilobyte)){
                message += t('error.message.file.large')+'\n'
            }
            if (uploadLogo[0].name.length>Constant.fileLength){
                message += t('error.message.filename.long')+'\n'
            }
        }

        if (message) {
            setMessage(message);
            window.scrollTo(0, 0);
            return false;
        } else setMessage('');
        
        return true;
    };

    const userTypeOnChange = () => {
        setUserType($('select[name="userTyp"]').val());
    }

    const uploadLogoOnChange = () => {
        var logo = $('input[name="uploadLogo"]').prop('files');

        if(logo.length > 0){
           const reader = new FileReader();
           reader.onload = (readerEvt) => {
               setUploadLogoBase64(btoa(readerEvt.target.result));
           };
           reader.readAsBinaryString(logo[0]);   
           setUploadLogoName(logo[0].name); 
           setDisableLogoStatus(false); 
        }                  
    }

    const triggerMemberAgentField = () => {
        setShowMemberAgentField(prevState => [...prevState, true])
    }

    const handleNewMemberAgentCodes = (index, value) => {
        setInputNewMemberAgentCodes(prevValues => {
            const newValues = [...prevValues];
            newValues[index] = value;
            return newValues;
        });
    }

    const handleMemberAgentCodeChange = (id, newValue) => {
        setMemberAgentCodeList(prevCodes => {
            return {
                ...prevCodes,
                [id]: newValue
            }
        });
    }

    const removeNewField = (index) => {
        const newInputs = [...inputNewMemberAgentCodes]
        newInputs.splice(index, 1);
        setInputNewMemberAgentCodes(newInputs);

        const newFields = [...showMemberAgentField];
        newFields[index] = false;
        setShowMemberAgentField(newFields);
    }

    const removeExstAgent = (id) => {
        setRemoveMemberAgentCodes(prevValues => {
            return [...prevValues, id]
        });
        setMemberAgentCodeList(prevValues => {
            const exstFields = { ...prevValues };
            delete exstFields[id];
            return exstFields;
        });
    }

    const populateField = useCallback( (data) => {
        $('select[name="userTyp"]').val(data.userTyp);
        $('input[name="companyNPWP"]').val(data.regNo);
        $('input[name="companyName"]').val(data.companyName);
        $('input[name="address1"]').val(data.address1);
        $('input[name="address2"]').val(data.address2);
        $('input[name="address3"]').val(data.address3);
        $('input[name="country"]').val(data.country); 
        $('input[name="postCode"]').val(data.postCode);
        $('select[name="status"]').val(data.active.toString());
        $('input[name="sourceId"]').val(data.sourceId);
        $('input[name="agentCode"]').val(data.agentCode);
        if (data.systemId) $('input[name="systemId"]').val(Crypto.decryptNow(data.systemId));
		$('#lastUpdateOn').text(data.lastModifiedDatetime);
        $('#lastUpdateBy').text(data.lastModifiedByName);

        $('input[name="name"]').val(data.contactPerson);
        $('input[name="officeNo"]').val(data.officeNo);
        $('input[name="contactNo"]').val(data.contactNo);
        $('input[name="email"]').val(data.email);       
        
        if(data.companyLogoName){
            setUploadLogoName(data.companyLogoName);
            setUploadLogoBase64(data.companyLogo);
            setDisableLogoStatus(false); 
            if(data.logoDisplayStatus === logoDisplayStatusList.default)
                $('#logoDisplayStatus1').attr("checked", true);
            else if (data.logoDisplayStatus === logoDisplayStatusList.hide)
                $('#logoDisplayStatus2').attr("checked", true);
            else if (data.logoDisplayStatus === logoDisplayStatusList.unhide)
                $('#logoDisplayStatus3').attr("checked", true);
        }
        setUserType(data.userTyp);

        if (data.exstMemberAgentCodes && Object.keys(data.exstMemberAgentCodes).length > 0) {
            setMemberAgentCodeList(data.exstMemberAgentCodes);
        }
        setInputNewMemberAgentCodes([]);
        setShowMemberAgentField([]);
        setRemoveMemberAgentCodes([]);
    }, [logoDisplayStatusList])

    let companyId = '';
    if (selected) companyId = selected;

    const handleSave = async dispatch => {      
        let url = '';
        let postObj = {};
        let selectId = null;
        let sysId = '';

        if (validateForm()) {
            if (action === 'NEW') {
                url = urlConstant.URL_COMPANY_CREATE;
            } else if (action === 'EDIT') {
                url = urlConstant.URL_COMPANY_UPDATE;
                selectId = selected;
            }

			if (userType !== Constant.USERTYPE_STAFF) sysId = Crypto.encryptNow($('input[name="systemId"]').val());
			
            postObj = {
                userTyp: $('select[name="userTyp"]').val(),
                regNo: $('input[name="companyNPWP"]').val(),
                companyName: $('input[name="companyName"]').val(),
                address1: $('input[name="address1"]').val(),
                address2: $('input[name="address2"]').val(),
                address3: $('input[name="address3"]').val(),
                country: $('input[name="country"]').val(),
                postCode: $('input[name="postCode"]').val(),
                active: $('select[name="status"]').val(),
                sourceId: $('input[name="sourceId"]').val(),
                agentCode: $('input[name="agentCode"]').val(),
                newMemberAgentCodes: inputNewMemberAgentCodes,
                exstMemberAgentCodes: memberAgentCodeList,
                removeAgentCodes: removeMemberAgentCodes,
                systemId: sysId,
                contactPerson: $('input[name="name"]').val(),
                officeNo: $('input[name="officeNo"]').val(),
                contactNo: $('input[name="contactNo"]').val(),
                email: $('input[name="email"]').val(),
                id: selectId,
                logoDisplayStatus: $('input[name="logoDisplayStatus"]:checked').val(),
                companyLogo: uploadLogoBase64,
                companyLogoName: uploadLogoName
            };

            if(action) {
                let resp = await Post(urlConstant.URL_BASED + url, postObj, dispatch);

                if(!resp.isError && resp.respData.data) { 
                    if (action === 'NEW') {
                        setReadOnlyFlag(true);
                        setMessage(t('message.save.success', { 0: t('company'), 1: t('created') }));
                        setSelected(resp.respData.data.id);
                        setAction('EDIT');
                    } else if (action === 'EDIT') {
                        setMessage(t('message.save.success', { 0: t('company'), 1: t('updated') }));
                    }

                    if(resp.respData.data.companyLogo && loginCompanyId === resp.respData.data.id){                        
                        // const currentImgLogoUrl = logoSelector;
                        // const localImgLogoUrl = window.URL.createObjectURL(logo);
                        
                        dispatch({type: actions.DISPLAY_LOGO, 
                            // companylogoImgBase64: localImgLogoUrl,
                            companylogoImgBase64: resp.respData.data.companyLogo,
                            companylogoImgStatus: resp.respData.data.logoDisplayStatus
                        });
                        // URL.revokeObjectURL(currentImgLogoUrl);  
                    }  
                    populateField(resp.respData.data);
                }                
                window.scrollTo(0, 0);
            }            
        }
    }

    useEffect(() => {
        const dropdownLists = async dispatch => {
            let userType = [];
            let userTypeResp = await Get(urlConstant.URL_BASED + urlConstant.URL_LOOKUP_USERTYPE, null, dispatch);
            if(!userTypeResp.isError && userTypeResp.respData.data) {
                userType = userTypeResp.respData.data.map(company => {
                    return {shortCode: company.shortCode, value: company.value}
                });
            }else{
                setErrReturned(userTypeResp.isError);
                return;
            }
            
            setDropdownList({userTypeList: userType});
            
            if (action === 'EDIT') {
                dispatch(retrieveCompany);
                setReadOnlyFlag(true);
            }
        }
    
        const retrieveCompany = async dispatch => {
            let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_COMPANY_RETRIEVE + companyId, null, dispatch);
            if(!resp.isError && resp.respData.data) {
                populateField(resp.respData.data);
            }else{
                setErrReturned(resp.isError);
            }
        }

        if (dropdownList.userTypeList.length === 0) dispatch(dropdownLists);
        window.scrollTo(0, 0);
    }, [action, companyId, dropdownList, dispatch, populateField])

    if (action) {
        return (
            <div>
                <Container>
                    { message? <div className="error"><p>{message}</p></div>: null}
                    <Row className="mt-3"><Col><h2>{t('companymaintenance')}</h2></Col></Row>
                    <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('companydetail')}</h4></Col></Row>
                    <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label className="required">{t('usertype')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Select name='userTyp' optionList={dropdownList.userTypeList} onChange={userTypeOnChange}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label className="required">{t('companynpwp')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="companyNPWP" placeholder={t('placeholder.enter', { 0: t('companynpwp') })} maxLength={40}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label className="required">{t('companyname')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="companyName" placeholder={t('placeholder.enter', { 0: t('companyname') })} maxLength={50} readOnly={readOnlyFlag} /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label>{t('addressline1')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="address1" placeholder={t('placeholder.enter', { 0: t('addressline1') })} maxLength={50}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label>{t('addressline2')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="address2" placeholder={t('placeholder.enter', { 0: t('addressline2') })} maxLength={50}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label>{t('addressline3')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="address3" placeholder={t('placeholder.enter', { 0: t('addressline3') })} maxLength={50}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label>{t('country')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="country" placeholder={t('placeholder.enter', { 0: t('country') })} maxLength={30}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label>{t('postalcode')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="tel" name="postCode" placeholder={t('placeholder.enter', { 0: t('postalcode') })} onKeyPress={(event) => Validate.numeric(event)} maxLength={5}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label className="required">{t('status')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Select name='status' optionList={Constant.statusList} defaultVal={true}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label className="required">{t('sourceid')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="sourceId" placeholder={t('placeholder.enter', { 0: t('sourceid') })} maxLength={20}/></Col>
                    </Row>
                    {
                        userType !== Constant.USERTYPE_STAFF ?
                        <Row className="mt-3">
                            <Col sm={{ size: 3}}><Label className="required">{t('systemid')}</Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="7" md="5"><Input type="text" name="systemId" id="systemId" placeholder={t('placeholder.enter', { 0: t('systemid') })} 
                                onKeyPress={e => Validate.validatePasswordKeyPress(e)} maxLength={15}/></Col>
                        </Row>
                        : ""
                    }
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label className="required">{t('agentCode')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="agentCode" placeholder={t('placeholder.enter', { 0: t('agentCode') })} maxLength={40}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3 }}><Label>{t('memberAgentCode')}</Label></Col>
                        <Col xs="2" sm="1" md="1">
                            {/* <FontAwesomeIcon onClick={triggerMemberAgentField} className='mt-3 ml-3' icon={faPlusCircle} /> */}
                            <button class="btn btn-secondary" onClick={triggerMemberAgentField}>+</button>
                        </Col>
                        <Col xs="12" sm="7" md="5">
                            {Object.entries(memberAgentCodeList).map(([id, memberAgentCode]) => (
                                <div key={id} className="d-flex align-items-center mt-3">
                                    <Input type="text" name="memberAgentCode" value={memberAgentCode} maxLength={48} onChange={(e) => handleMemberAgentCodeChange(id, e.target.value)} />
                                    <button className="btn btn-secondary" onClick={() => removeExstAgent(id)}>x</button>
                                </div>
                            ))}
                            {showMemberAgentField.map((showInput, index) => (
                                showInput && (
                                    <div key={index} className="d-flex align-items-center mt-3">
                                        <Input key={index} type="text" name="memberAgentCode" placeholder={t('placeholder.enter', { 0: `${t('memberAgentCode')}` })} maxLength={48} onChange={(e) => handleNewMemberAgentCodes(index, e.target.value)} />
                                        <button className="btn btn-secondary" onClick={() => removeNewField(index)}>x</button>
                                    </div>
                                )
                            ))}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size:3}}><Label>{t('uploadlogo')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5">
                            <CustomInput type="file" name="uploadLogo" id="uploadLogo" accept={Constant.imageExtension} label={uploadLogoName? uploadLogoName:"Choose File"}
                            onChange={uploadLogoOnChange}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size:3}}><Label>{t('logodisplay')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="2" md="2" >
                            <Label>
                                <input disabled={disableLogoStatus} type="radio" name="logoDisplayStatus" id="logoDisplayStatus1" value={logoDisplayStatusList.default}/>
                                {' '}{t('logodisplay.status.default')}
                            </Label>
                        </Col>
                        <Col xs="12" sm="2" md="2" >
                            <Label>
                                <input disabled={disableLogoStatus} type="radio" name="logoDisplayStatus" id="logoDisplayStatus2" value={logoDisplayStatusList.hide}/>
                                {' '}{t('logodisplay.status.hide')}
                            </Label>
                        </Col>
                        <Col xs="12" sm="2" md="2" >
                            <Label>
                                <input disabled={disableLogoStatus} type="radio" name="logoDisplayStatus" id="logoDisplayStatus3" value={logoDisplayStatusList.unhide}/>
                                {' '}{t('logodisplay.status.unhide')}
                            </Label>
                        </Col>
                    </Row>
                    {action === 'EDIT' ? 
                        <div>
                            <Row className="mt-3">
                                <Col sm={{ size: 3}}><Label>{t('lastupdateon')}</Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="7" md="5"><span id='lastUpdateOn'></span></Col>
                            </Row>
                            <Row className="mt-3">
                                <Col sm={{ size: 3}}><Label>{t('lastupdateby')}</Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="7" md="5"><span id='lastUpdateBy'></span></Col>
                            </Row>
                        </div>
                    : ''}

                    <Row className="mt-4"><Col style={{color: '#e80b1c'}}><h4>{t('contactperson')}</h4></Col></Row>
                    <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label>{t('fullname')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="name" placeholder={t('placeholder.enter', { 0: t('fullname') })} maxLength={50} onKeyPress={(event) => Validate.alphanumeric(event)}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label>{t('officeno')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="tel" name="officeNo" placeholder={t('placeholder.enter', { 0: t('officeno') })} onKeyPress={(event) => Validate.numeric(event)} maxLength={20}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label>{t('mobileno')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="tel" pattern="[0-9]*" name="contactNo" placeholder={t('placeholder.enter', { 0: t('mobileno') })} onKeyPress={(event) => Validate.numeric(event)} maxLength={20}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={{ size: 3}}><Label>{t('email')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="email" name="email" placeholder={t('placeholder.enter', { 0: t('email') })} maxLength={100}/></Col>
                    </Row>
                </Container>
                <div style={{textAlign: 'center'}}>
                    { (isAllowEdit || action === 'NEW') && !isErrReturned ?<Button className="mt-3" onClick={() => dispatch(handleSave) }>{t('button.save')}</Button> : <></>}{' '}     
                    <Button className="mt-3" onClick={() => dispatch(push('/company'))}>{t('button.back')}</Button>     
                </div>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}

export default CompanyDetail;
