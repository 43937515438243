import React, { useState }from 'react';
import { Col, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ConfirmationProposerInfo = ({ respData, field, fieldValue }) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const [item] = useState("infoItems");
    const [value] = useState("value");

    if(respData.infoFields.length) return (
        <div>           
            { field.field.map((infoField, infoIndex) =>
                respData.infoFields.map((respField, respIndex) =>
                    (respField.key === infoField.key)? 
                        <div key={respIndex}>
                            <Col className="container-flex mt-3" xs="12" sm="6" md="6">
                                <Col xs="10" sm="5" md="5"><Label>{t('trnx.info.'+infoField.key)}<br></br><i>{t2("trnx.info."+infoField.key)}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                            </Col>
                            {[...Array(fieldValue[item][infoField.key][value].length)].map((_, indexDesc) =>    
                                <Col key={indexDesc} xs="12" sm="12" md="12">
                                    <Col><Label>
                                        {indexDesc+1}. {fieldValue[item][infoField.key][value][indexDesc]}
                                    </Label></Col>
                                </Col>
                            )}
                        </div>
                        : ''
                )
            )}      
        </div>
    )
    else return "";
};

export default ConfirmationProposerInfo;