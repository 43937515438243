import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {Label, Button, Row, Col, Container} from 'reactstrap';
import DatePicker from './../../components/DatePicker';
import { useTranslation } from 'react-i18next';
import { Post } from '../../utils/AxFetch';
import * as urlConstant from './../../constants/urlConstant';
import $ from 'jquery';

const HostSubmission = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const [submitDate, setSubmitDate] = useState(new Date());

    const handleSubmit = async dispatch => {
        let message = '';
        let isValid = true;
        let submissionDate = $('input[name="submissionDate"]').val();
        
        if(!submissionDate || submissionDate === '') {
            message = t('error.message.input.blank', { 0: t('hostsubmission.date') });
            isValid = false;
        }

        if(isValid) {
            let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_BATCH_HOSTSUBMISSION,    
                { date: submissionDate}, dispatch);
            
            if(!resp.isError && resp.respData.data) {
                message = resp.respData.data;
            }
        }

        setMessage(message);
    }

    return (
        <div>
            <Container>
                { message? <div className="error"><p>{message}</p></div>:null}
                <Row className="mt-3"><Col><h2>{t('hostsubmission.title')}</h2></Col></Row>
                <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('hostsubmission.title')}</h4></Col></Row>
                <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('hostsubmission.date')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="5" md="5"><DatePicker name="submissionDate"
                        className="form-control datepicker-icon" dateFormat="MM/yyyy" selected={submitDate}
                        showMonthYearPicker maxDate={new Date()} onChange={value => setSubmitDate(value)} />
                      </Col>       
                  </Row>

                  <Row className="mt-3">
                    <Col xs="12" sm="12" md="12">
                        <center>
                            <Button name="btnSubmit" onClick={() => dispatch(handleSubmit)}>{t('button.submit')}</Button>
                        </center>
                    </Col>
                </Row>
            </Container> 
        </div>
      );
}

export default HostSubmission;