import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import * as Constant from '../../constants/Constant';

const Acknowledgement = (props) => {
    const { t } = useTranslation();
    const t2 = (label, params) => { 
        if (params) {
            return t('en_translations:'+label, {0:params[0], 1:params[1]}) 
        } else {
            return t('en_translations:'+label) 
        }
    };
    const dispatch = useDispatch();
    const respData = props.location.state;

    const ok = () => {
        dispatch(push({pathname: Constant.PATH_CERTIFICATE_INSURANCE_B2B, search: '?code='+respData.productCode}))
        window.location.reload();
    }

    if(respData)
    return (
        <div>
        <Row className="mt-3"><Col className="titleH4"><h4>{t('acknowledgement')}</h4></Col></Row>
        <Row><Col className="titleH4"><h6><i>{t2('acknowledgement')}</i></h6></Col></Row>
        <Row><Col className="borderBottom"></Col></Row>
        <Card className="bg-light mt-3">
            <CardBody>
                <Col xs="12" sm="12" md="12" className="mb-3" >{t('dear')}&nbsp;
                <b>
                    {   respData.proposer ?
                            respData.proposer.gender ?
                                respData.proposer.gender === "GEN0000001" ?
                                t('mr') : t('ms')
                            : t('mr/ms')
                        : ""
                    } {respData.proposer ? respData.proposer.name: ""}
                </b>
                </Col>
                <Col xs="12" sm="12" md="12" className="mb-3" >{
                    respData.policyType == "Master Policy"?
                        t('acknowledgement.mss', {0:respData.policyNo, 1:respData.proposer? respData.proposer.email:""})
                    : 
                        t('acknowledgement.mss.individual', {0:respData.coverNoteNo, 1:respData.proposer? respData.proposer.email:""})}
                </Col>
                <Col xs="12" sm="12" md="12" className="mb-3" >{t('acknowledgement.mss.thanks')}</Col>
                <Col xs="12" sm="12" md="12" >{t('acknowledgement.mss.regards')}</Col>
                <Col xs="12" sm="12" md="12">{t('acknowledgement.mss.signature')}</Col>
                <br></br><br></br>
                <Col xs="12" sm="12" md="12" className="mb-3" >{t2('dear')}&nbsp;
                <b>
                    {   respData.proposer ?
                            respData.proposer.gender ?
                                respData.proposer.gender === "GEN0000001" ?
                                t2('mr') : t2('ms')
                            : t2('mr/ms')
                        : ""
                    } {respData.proposer ? respData.proposer.name: ""}
                </b>
                </Col>
                <Col xs="12" sm="12" md="12" className="mb-3" >{
                    respData.policyType == "Master Policy"? 
                        t2('acknowledgement.mss', {0:respData.policyNo, 1:respData.proposer? respData.proposer.email:""})
                    : 
                        t2('acknowledgement.mss.individual', {0:respData.coverNoteNo, 1:respData.proposer? respData.proposer.email:""})}
                </Col>
                <Col xs="12" sm="12" md="12" className="mb-3" >{t2('acknowledgement.mss.thanks')}</Col>
                <Col xs="12" sm="12" md="12" >{t2('acknowledgement.mss.regards')}</Col>
                <Col xs="12" sm="12" md="12">{t2('acknowledgement.mss.signature')}</Col>
            </CardBody>
        </Card>

        <Row className="mt-3">
            <Col><center><Button onClick={ok}>{t('ok')}</Button></center></Col>
        </Row>      
        </div>
    ) 
    else return "";
};

export default Acknowledgement;