import React, { useState, useEffect } from 'react';
import { Button, Label, Input } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import * as urlConstant from './../../constants/urlConstant';
import { Get } from '../../utils/AxFetch';
import Select from './../../utils/Select';
import { useDispatch } from 'react-redux';
import DatePicker from './../../components/DatePicker';
import * as Validate from './../../utils/CommonValidate';
import ClaimListing from './ClaimListing';

const Claim = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchCriteria,setSearchCriteria] = useState('');
    const [dropdownList,setDropdownList] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [message,setMessage] = useState("");
    const [policyType, setPolicyType] = useState({policyTypeList:[]});

    const validateDt = () => {
        let message = '';
        
        if ( !(Validate.validateDate( $('input[name="effFrom"]').val(), $('input[name="effTo"]').val())) ) {
            message += t('error.message.greaterthan.enddate') +'\n'
        }
        setMessage(message);
        
        if (message) {
            window.scrollTo(0, 0);
            return false;
        }
        return true;
    }
    
    const handleSearch = () => {
        let certInsuranceNo = '';
        let claimId = '';
        let effFrom = '';
        let effTo = '';
        let proposerName = '';
        let status = '';
        let policyType = '';

        if ($('input[name="certInsuranceNo"]').val() !== undefined) {
            certInsuranceNo = $('input[name="certInsuranceNo"]').val();
        }
        if ($('input[name="claimId"]').val() !== undefined) {
            claimId = $('input[name="claimId"]').val();
        }
        if ($('input[name="effFrom"]').val() !== undefined) {
            effFrom = $('input[name="effFrom"]').val();
        }
        if ($('input[name="effTo"]').val() !== undefined) {
            effTo = $('input[name="effTo"]').val();
        }
        if ($('input[name="proposerName"]').val() !== undefined) {
            proposerName = $('input[name="proposerName"]').val();
        }
        if ($('select[name="status"]').val() !== undefined) {
            status = $('select[name="status"]').val();
        }
        if ($('select[name="policyType"]').val() !== undefined) {
            policyType = $('select[name="policyType"]').val();
        }

        setSearchCriteria({
            certInsuranceNo: certInsuranceNo,
            claimId: claimId,
            effFrom: effFrom,
            effTo: effTo,
            proposerName: proposerName,
            status: status,
            policyType: policyType
        })
    }

    useEffect(() => {
        const dropdownLists = async dispatch => {
            let statusList = [];
            let statusListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_CLAIM_APPROVAL_STATUS_LIST, null, dispatch);
            if(!statusListResp.isError && statusListResp.respData.data) {
                statusList = statusListResp.respData.data.map(status => {
                    return {shortCode: status.code, value: status.desc}
                });
                setDropdownList({statusList: statusList});
            }
        }

        if (!dropdownList) {
            dispatch(dropdownLists);
            
        } else {
            $('select[name="status"]').val('PEND_APPVL'); //initial load status
            handleSearch(); 
        }
        dispatch(getPolicyTypeList);
    }, [dispatch, dropdownList]);

    const getPolicyTypeList = async dispatch => {
        let polTypeList = [];
        let polTypeListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_COMMON_POLICY_TYPE_LIST,null,dispatch);
    
        if(!polTypeListResp.isError && polTypeListResp.respData.data){
            polTypeList = polTypeListResp.respData.data.map(policyType => {
                return {shortCode: policyType.code, value:policyType.desc}
            });
        }
        setPolicyType({policyTypeList: polTypeList});
    }

    return (
        <div>
            <Container>  
            { message? <div className="error"><p>{message}</p></div>: null}
                <Row className="mt-3"><Col><h2>{t('searchclaim')}</h2></Col></Row>
                <div></div>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('certificateinsuranceno')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Input name='certInsuranceNo' maxLength={60}/></Col> 
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('claimid')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Input name="claimId" maxLength={6} onKeyPress={(event) => Validate.numeric(event)} /></Col>    
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('claimregisterdate')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col sm="2" md="2"><DatePicker className="form-control datepicker-icon" selected={startDate} onChange={date => setStartDate(date)} name="effFrom"/></Col>
                    <Col sm="1" md="1" className="text-center"><Label>{t('effectivedateto')}</Label></Col>
                    <Col sm="2" md="2" ><DatePicker className="form-control datepicker-icon" selected={endDate} onChange={date => setEndDate(date)} name="effTo"/></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('proposername')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Input name="proposerName" maxLength={100} /></Col>  
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('status')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select name='status' optionList={dropdownList.statusList} defaultVal="PEND_APPVL"/></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('policytype')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select name='policyType' optionList={policyType.policyTypeList}/></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="12" sm="12" md="12">
                        <center>
                        <Button name="btnSearch" onClick={ () => {if (validateDt()) handleSearch()}}>{t('button.search')}</Button>
                        </center>
                    </Col>
                </Row>	

            </Container>
            
            <ClaimListing searchCriteria={searchCriteria}/>
            
        </div>
    )
}

export default Claim;