import React, { Component } from "react";
import {Label, Input, Button, Row, Col, Container} from 'reactstrap';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import loginAction from '../../actions/loginAction';
import "../../containers/common/Login.css";
import companyLogo from '../../images/gel-logo.png';
import { withTranslation } from 'react-i18next';
import * as Validate from './../../utils/CommonValidate';

const initialState = {
  username: "",
  email: "",
  usernameError: "",
  emailError: ""
}

const entries = performance.getEntriesByType("navigation").map( nav => nav.type );

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }


  hideForgot = () => {
    // this.setState({ showForgot: false });
    this.props.backToLogin()
  }

  componentDidMount = () => {
    if (this.isPageRefreshed()) {
      this.props.clearError();
    }
  }

  isPageRefreshed() {
    return window.performance && entries.includes("reload");
  }

  validateForget() {
    let usernameError = "";
    let emailError = "";

    if (!this.state.username) {
      usernameError = "Username cannot be blank";
    }
    if (!this.state.email) {
      emailError = "Email cannot be blank";
    }else if (!Validate.validateEmail(this.state.email)) {
      emailError = "Email is Invalid";
    }

    if (usernameError || emailError) {
      this.setState({ usernameError, emailError})
      return false;
    }

    return true;
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  forgetSubmit = event => {
    event.preventDefault();
    const isValid = this.validateForget();
    if (isValid) {
      this.props.handleForgot(this.state.username, this.state.email);
      // clear form
      this.setState(initialState);
    }
  }

  render() {
    const { t } = this.props
    return (
      <Container>
        <div className="auth-wrapper Login">
          <div className="auth-top"><img src={companyLogo} className="company-logo mx-auto d-block" alt="username" />
          <p className="text-center">{new Intl.DateTimeFormat('default', {weekday: 'long', year: 'numeric', month: 'long',day: '2-digit'}).format(today)}</p>
          </div>

          <div className="auth-inner">
              
              <div><p>{t('forgetpassword')}</p></div>
              
              <Row className="mt-12">
                <Col sm={{ size: 4 }}><Label>{t('username')} :</Label></Col>
                <Col sm={{ size: 8 }}><Input type="text" name="username" id="username" maxLength={20} value={this.state.username} className="form-control" placeholder={t('placeholder.enter', { 0: t('username') })} onChange={this.handleChange}/></Col>
              </Row>

              <Row className="mt-12">
                <Col sm={{ size: 8, offset: 4 }}><div className="error">{this.state.usernameError}</div></Col>
              </Row>

              <Row className="mt-12">
                <Col sm={{ size: 4 }}><Label>{t('emailaddress')} :</Label></Col>
                <Col sm={{ size: 8 }}><Input type="email" name="email" id="email" value={this.state.email} className="form-control" placeholder={t('placeholder.enter', { 0: t('emailaddress') })} onChange={this.handleChange}/>
                </Col>
              </Row>
              
              <Row className="mt-12">
                <Col sm={{ size: 8, offset: 4 }}><div className="error">{this.state.emailError}</div></Col>
              </Row>
              
              <Row className="mt-12">
                  <Col sm={{ size: 8, offset: 4 }}>
                    <Button type="submit" color="secondary" className="btn--wide" onClick={this.forgetSubmit}>OK</Button>{' '}
                    <Button color="secondary" onClick={this.hideForgot}>Back</Button>
                  
                  </Col>       
              </Row>
            
          </div>
        </div>
        </Container> 
    );

  }
}

const today = Date.now();

const mapDispatchToState = (dispatch) => {
  return {
    handleForgot: (username, email) => {
      dispatch(loginAction.forgotHandler(username, email));
    },
    backToLogin: () => dispatch(push('/login')),
    clearError: () => {dispatch({type : 'CLEAR_ERROR'})}
  }
}

export default connect(null, mapDispatchToState) (withTranslation()(ForgetPassword));
