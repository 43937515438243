import React, { useState }from 'react';
import { Button, Col, Collapse, Label, CardBody, Card} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import * as Constant from '../../../../constants/Constant';
import ConfirmationProposerInfoField from './ConfirmationProposerInfoField';

const ConfirmationProposerRisk = ({ respData, field, fieldValue }) => {
    const { t } = useTranslation();
    const t2 = (label) => { return t('id_riskitems:'+label) }
    const t3 = (label) => { return t('en_riskitems:'+label) }
    const [panelOpen, setPanelOpen] = useState(true);
    const [item] = useState("riskItems");
    const [value] = useState("value");
    const [desc] = useState("desc");

    const RiskToggle = () => setPanelOpen(!panelOpen);

    if (respData.riskFields) return (
        <div>       
            <Button className={"accordion-toggle mt-3 " + (panelOpen ? "collapse-icon" : "expand-icon")} 
                    onClick={e => RiskToggle(e)}>{t('generalInfo')}<br></br><i>{t('en_translations:generalInfo')}</i>
            </Button>
            <Collapse isOpen={panelOpen}>
            <Card><CardBody>
            <div className="container-flex">    
            { field.field.map( (riskField) => 
                respData.riskFields.map((respField, respIdex) =>
                respField.key === riskField.key && riskField.key === "houseType" && fieldValue[item]["propertyType"][value] === "HOUSE" ?
                <Col key={respIdex} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5">
                        <Label>
                            {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(riskField.key) ? t2(riskField.key) : riskField.key}
                            <br></br>
                            <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(riskField.key) ? t3(riskField.key) : riskField.key}</i>
                        </Label>
                    </Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>
                            {
                            fieldValue[item][riskField.key][value] && fieldValue[item][riskField.key][desc]? fieldValue[item][riskField.key][desc]
                            : fieldValue[item][riskField.key][value]? fieldValue[item][riskField.key][value]
                            : "-"}
                        </Label>
                    </Col>
                </Col>
                : respField.key === riskField.key && riskField.key === "noOfFloors" && fieldValue[item]["propertyType"][value] === "APARTMENT" ?
                <Col key={respIdex} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5">
                        <Label>
                            {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(riskField.key) ? t2(riskField.key) : riskField.key}
                            <br></br>
                            <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(riskField.key) ? t3(riskField.key) : riskField.key}</i>
                        </Label>
                    </Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>
                            {fieldValue[item][riskField.key][value] == 0 ? "0" :
                            fieldValue[item][riskField.key][value]?
                            fieldValue[item][riskField.key][value]
                            : "-"}
                        </Label>
                    </Col>
                </Col>
                : respField.key === riskField.key ?
                <Col key={respIdex} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5">
                        <Label>
                            {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(riskField.key) ? t2(riskField.key) : riskField.key}
                            <br></br>
                            <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(riskField.key) ? t3(riskField.key) : riskField.key}</i>
                        </Label>
                    </Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label className='col-12'>
                            {fieldValue[item][riskField.key][value] instanceof Date?
                            format(fieldValue[item][riskField.key][value], Constant.dateFormat_B) :
                            fieldValue[item][riskField.key][value] !== "" && respField.isCurrency ?
                            parseInt(fieldValue[item][riskField.key][value]).toLocaleString(Constant.defaultLocale):
                            fieldValue[item][riskField.key][value] && fieldValue[item][riskField.key][desc]?
                            fieldValue[item][riskField.key][desc]:
                            fieldValue[item][riskField.key][value]?
                            fieldValue[item][riskField.key][value]
                            : "-"}
                        </Label>
                    </Col>
                </Col>
                : ""
            ))} 
            </div>
            <ConfirmationProposerInfoField respData={respData} field={field} fieldValue={fieldValue}/>
            </CardBody></Card>
            </Collapse>              
        </div>
    )
    else return "";
};

export default ConfirmationProposerRisk;