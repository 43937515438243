import React, { useState, useEffect } from 'react';
import { Button, Label, Input } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import $ from 'jquery';
import AccountListing from './AccountListing';
import { useTranslation } from 'react-i18next';
import * as urlConstant from './../../constants/urlConstant';
import { Get } from '../../utils/AxFetch';
import Select from './../../utils/Select';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import * as Constant from './../../constants/Constant';
import * as Validate from './../../utils/CommonValidate';

const Account = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAllowNew = useSelector(state => state.sideMenuReducer.functionItems.accountDetail ? state.sideMenuReducer.functionItems.accountDetail.includes('NEW') : '');
    const [searchCriteria,setSearchCriteria] = useState('');
    const [dropdownList,setDropdownList] = useState({activeCompanyList:[]});
    const [isErrReturned, setErrReturned] = useState(false);
    
    const handleSearch = () => {
        let accCompanyID = '';
        let accLoginId = '';
        let accName = '';
        let accStatus = '';

        if ($('select[name="companyId"]').val() !== undefined) {
            accCompanyID = $('select[name="companyId"]').val();
        }
        if ($('input[name="loginId"]').val() !== undefined) {
            accLoginId = $('input[name="loginId"]').val();
        }
        if ($('input[name="accName"]').val() !== undefined) {
            accName = $('input[name="accName"]').val();
        }
        if ($('select[name="accStatus"]').val() !== undefined) {
            accStatus = $('select[name="accStatus"]').val();
        }
        setSearchCriteria({
            accCompanyID: accCompanyID,
            accLoginId: accLoginId,
            accName: accName,
            accStatus: accStatus
        })
    }

    useEffect(() => {
        const dropdownLists = async dispatch => {
            let activeCompany = [];
            let activeCompanyResp = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_ACTIVECOMPANY, null, dispatch);
    
            if(!activeCompanyResp.isError && activeCompanyResp.respData.data) {
                activeCompany = activeCompanyResp.respData.data.map(company => {
                    return {shortCode: company.id, value: company.companyName}
                });
                setDropdownList({activeCompanyList: activeCompany});
                handleSearch();
            } else {
                setErrReturned(activeCompanyResp.isError);
                return;
            }
        }
        dispatch(dropdownLists);
    }, [dispatch]);

    const handleNew = () => {
        dispatch(push({pathname: Constant.PATH_ACCOUNT_DETAILS, state: { action: 'NEW', selected: null }}))
    }

    return (
        <div>
            <Container>  
                <Row className="mt-3"><Col><h2>{t('accountmaintenance')}</h2></Col></Row>
                <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('searchaccount')}</h4></Col></Row>
                <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>

                <div></div>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('company')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select name='companyId' optionList={dropdownList.activeCompanyList}/></Col> 
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('accountloginid')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Input name="loginId" maxLength={20} /></Col>    
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('accountname')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Input name="accName" maxLength={50} onKeyPress={(event) => Validate.alphanumeric(event)}/></Col>  
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('status')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select name='accStatus' optionList={Constant.statusList}/></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="12" sm="12" md="12">
                        <center>
                            {!isErrReturned ? <Button name="btnSearch" onClick={ () => handleSearch()}>{t('button.search')}</Button>
                            : <Button name="btnSearch">{t('button.search')}</Button>}
                            <> </>
                            {isAllowNew ? <Button name="btnNew" onClick={() => handleNew()}>{t('button.add')}</Button>
                            : <></>}
                        </center>
                    </Col>
                </Row>	

            </Container>
            
            <AccountListing searchCriteria={searchCriteria}/>
            
        </div>
    )
}

export default Account;