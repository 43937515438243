import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Table } from 'reactstrap';
import PaginationBar from '../../components/PaginationBar';
import * as Constant from './../../constants/Constant';
import * as urlConstant from './../../constants/urlConstant';
import { Post } from '../../utils/AxFetch';
import { useTranslation } from 'react-i18next';

const ClaimListing = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [claimListParams,setClaimListParams] = useState({data:''});
    const [pageOnClick,setPageOnClick] = useState(1);
    
    useEffect(() => {
        if (props.searchCriteria) {
            setPageOnClick(1);
        }
    }, [props.searchCriteria]);

    const handleView = (id) => {
        if(!id) return;
        dispatch(push({pathname: Constant.PATH_CLAIM_APPROVAL_DETAILS, state: { claimId: id }}))
    }

    const onClick = (page) => {  
        setPageOnClick(page)  
    }

    useEffect(() => {
        let searchCriteria = props.searchCriteria;
        const getClaimList = async(page) => {
            let resData = '';
            let resCurrentPage = '';
            let resPageSize = '';
            let resPagesCount = '';
            let resp = "";
    
            resp = await Post(urlConstant.URL_BASED + urlConstant.URL_CLAIM_APPROVAL_LIST + page,    
                {
                    rowPerPage: Constant.rowPerPage,
                    pageNum: page,
                    claimRefNo: searchCriteria.claimId,
                    policyNo: searchCriteria.certInsuranceNo,
                    claimDateFrom: searchCriteria.effFrom,
                    claimDateTo: searchCriteria.effTo,
                    proposerName: searchCriteria.proposerName,
                    claimStatus: searchCriteria.status,
                    policyType: searchCriteria.policyType
                }, dispatch);
            
            let dataResp = resp.respData;
            
            if(!resp.isError && dataResp.data) {
                resData = dataResp.data.content;
                resCurrentPage = page;
                resPageSize = dataResp.data.size;
                resPagesCount = dataResp.data.totalPages;
            }
            setClaimListParams({data:resData, currentPage: resCurrentPage, pageSize: resPageSize, pagesCount: resPagesCount});
        }
        if (searchCriteria) getClaimList(pageOnClick);
}, [dispatch, props.searchCriteria, pageOnClick]);
    return (
        <div>
            <Table className="mt-3" bordered>
                <thead>
                    <tr>
                        <th style={{width: '10%'}}>{t('no.')}</th>
                        <th>{t('certificateno')}</th>
                        <th>{t('claimid')}</th>
                        <th>{t('claimregisterdate')}</th>
                        <th>{t('policytype')}</th>
                        <th>{t('status')}</th>
                    </tr>
                </thead>
                {(claimListParams.data && claimListParams.data.length > 0) ?
                <tbody>
                    {claimListParams.data.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td style={{width: '10%'}}>{i+1}</td>
                                <td>{item.policyNo}</td>
                                <td><button className="link-button" onClick={() => handleView(item.id)}>{item.claimRefNo}</button></td>
                                <td>{item.createdDatetime}</td>
                                <td>{item.policyType}</td>
                                <td>{item.claimStatus}</td>
                            </tr>
                        )}
                    )}
                </tbody>
                :   <tbody>
                        <tr><td className="emptyrecord" colSpan={5}>{t('norecordfound')}</td></tr>
                    </tbody>
                }
            </Table>
            <PaginationBar totalPages={claimListParams.pagesCount} currentPage={claimListParams.currentPage} handleClick={(page) => onClick(page)}  />
        </div>
    );
}

export default ClaimListing;