//import storage from 'redux-persist/lib/storage' // Local Storage
import storage from 'redux-persist/lib/storage/session' // Session Storage
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: hardSet,
    whitelist: ['loginReducer', 'sideMenuReducer', 'loadingReducer', 'messageReducer']
  }

export default persistConfig;