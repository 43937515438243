import React, { useEffect, useState } from "react";

const NyalaChildPage = (props) => {
  useEffect(() => {
    console.log("props", props);
  }, []);

  useEffect(() => {
    const scriptUrls = [
        "/nyala/scripts/jquery-3.6.4.min.js",
        "/nyala/scripts/bootstrap.bundle.min.js",
        "/nyala/scripts/page.js",
      // Add more script URLs as needed
    ];

    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = url;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () =>
          reject(new Error(`Failed to load script: ${url}`));
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        await Promise.all(scriptUrls.map((url) => loadScript(url)));
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    loadScripts();

    if (typeof $ === 'undefined') {
      console.error('jQuery is not loaded in NyalaChildPage.js');
      } else {
      // Your existing code
      console.log('jQuery is loaded');
      // Attach createCard to window if not already attached
      window.createCard = function(data) {
        console.log('createCard function loaded');
        // Function implementation...
      };
      }

    // Cleanup function
    return () => {
      scriptUrls.forEach((url) => {
        const script = document.querySelector(`script[src="${url}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: props.html }} />;
};

export default NyalaChildPage;
