import React, { useEffect, useState } from 'react';
import ReferralChildPage from './ReferralPageScreen';

const ReferralPage = () => {

	const [jqueryLoaded, setJqueryLoaded] = useState(false);

	useEffect(() => {
	  const scriptUrls = [
		"/referral/scripts/jquery-3.6.4.min.js",
		"/referral/scripts/popper.min.js",
		"/referral/scripts/bootstrap.min.js",
		// Add more script URLs as needed
	  ];
  
	  const loadScript = (url) => {
		return new Promise((resolve, reject) => {
		  const script = document.createElement("script");
		  script.src = url;
		  script.defer = true;
		  script.onload = () => resolve();
		  script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
		  document.body.appendChild(script);
		});
	  };
  
	  const loadScripts = async () => {
		try {
		  await Promise.all(scriptUrls.map(url => loadScript(url)));
		  setJqueryLoaded(true)
	 
		} catch (error) {
		  console.error("Error loading scripts:", error);
		}
	  };
  
	  loadScripts();
  
	  // Cleanup function
	  return () => {
		scriptUrls.forEach(url => {
		  const script = document.querySelector(`script[src="${url}"]`);
		  if (script) {
			document.body.removeChild(script);
		  }
		});
	  };
	}, []);

  const htmlContent = `
  <!doctype html>
  <html lang="en">

  <head>
	  <title>Great Eastern General Insurance Indonesia</title>
	  <link rel="icon" type="image/x-icon" href="/referral/Resources/favicon.ico">
	  <meta charset="utf-8">
	  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  
	  <!-- Bootstrap CSS v5.2.1 -->
	  <link rel="stylesheet" href="/referral/css/bootstrap.min.css">
	  <link rel="stylesheet" href="/referral/css/style.css">
	  <link rel="stylesheet" href="/referral/css/Public_Sans/PublicSans-Regular.ttf">
	  <link rel="stylesheet" href="/referral/css/Public_Sans/PublicSans-SemiBold.ttf">
  </head>
  
  <body>
	  <header>
		  <nav class="container blur mr-3 ml-3">
			  <img class="logo-header" src="/referral/Resources/GE_Master_Logo_FC-HighRes.png">
			  <!--div class="flag-wrapper">
				  <img class="flag-img flag-img-margin" src="/referral/Resources/01-Main_Page/indonesia.svg" onclick="switchLanguage('ID');">
				  <img class="flag-img" src="/referral/Resources/01-Main_Page/english.svg" onclick="switchLanguage('EN');">
			  </div-->
		  </nav>
	  </header>
  
	  <main>
		  <section class="container blur">
			  <div class="row">
				  <div class="col-lg-4 col-md-6 col-sm-12 card-spacing">
					  <div class="card">
						  <div class="card-body">
							  <img class="card-img" src="/referral/Resources/01-Main_Page/icon_Asuransi_Semua_Risiko_Industri.jpg">
							  <h5 class="card-title">
								  <div class="ID">Asuransi Semua Risiko Industri</div>
								  <div class="EN">xxxxx</div>
							  </h5>
							  <p class="card-text ID" style="padding-bottom: 60px">Merupakan produk Asuransi yang memberikan perlindungan untuk bangunan, isi bangunan, termasuk peralatan elektronik dan furniture di properti Anda.</p>
							  <p class="card-text EN">xxxxx</p>
							  <a onclick="modalAllRisk()" href="#" class="stretched-link"></a>
						  </div>
					  </div>
				  </div>
				  <div class="col-lg-4 col-md-6 col-sm-12 card-spacing">
					  <div class="card">
						  <div class="card-body">
							  <img class="card-img" src="/referral/Resources/01-Main_Page/icon_Asuransi_Kecelakaan_Diri.jpg">
							  <h5 class="card-title">
								  <div class="ID">Asuransi Kecelakaan Diri</div>
								  <div class="EN">xxxxx</div>
							  </h5>
							  <p class="card-text ID">Merupakan produk Asuransi yang memberikan perlindungan menyeluruh terhadap kecelakaan dengan memberikan kompensasi keuangan kepada anda, keluarga atau karyawan anda yang mengalami cacat tetap atau kematian, termasuk biaya pengobatan yang disebabkan oleh kecelakaan dan perluasan jaminan lainnya.</p>
							  <p class="card-text EN">xxxxx</p>
							  <a onclick="modalAccident()" href="#" class="stretched-link"></a>
						  </div>
					  </div>
				  </div>
				  <div class="col-lg-4 col-md-6 col-sm-12 card-spacing">
					  <div class="card">
						  <div class="card-body">
							  <img class="card-img" src="/referral/Resources/01-Main_Page/icon_GALP.jpg">
							  <h5 class="card-title">
								  <div class="ID">Great Accident & Life Protection</div>
								  <div class="EN">xxxxx</div>
							  </h5>
							  <p class="card-text ID">Merupakan Produk Asuransi Bersama antara PT. Great Eastern General Insurance Indonesia dengan PT. Great Eastern Life Indonesia yang memiliki jaminan yang komprehensif dalam hal meninggal dunia akibat kecelakaan atau bukan kecelakaan, cacat tetap, biaya pengobatan dan biaya-biaya atau santunan lainnya akibat dari kecelakaan.</p>
							  <p class="card-text EN">xxxxx</p>
							  <a onclick="modalGALP()" href="#" class="stretched-link"></a>
						  </div>
					  </div>
				  </div>
		  
				  <div class="col-lg-4 col-md-6 col-sm-12 card-spacing">
					  <div class="card">
						  <div class="card-body">
							  <img class="card-img" src="/referral/Resources/01-Main_Page/icon_Asuransi_Mikro_Sepeda_Motor.jpg">
							  <h5 class="card-title">
								  <div class="ID">Asuransi Mikro Sepeda Motor</div>
								  <div class="EN">xxxxx</div>
							  </h5>
							  <p class="card-text ID">Merupakan produk Asuransi mikro yang memberikan santunan kepada sepeda motor milik Peserta asuransi apabila terjadi kerugian/musibah yang dijamin di dalam Polis.</p>
							  <p class="card-text EN">xxxxx</p>
							  <a onclick="modalMotor()" href="#" class="stretched-link"></a>
						  </div>
					  </div>
				  </div>
				  <div class="col-lg-4 col-md-6 col-sm-12 card-spacing">
					  <div class="card">
						  <div class="card-body">
							  <img class="card-img" src="/referral/Resources/01-Main_Page/icon_Asuransi_Kendaraan_Bermotor.jpg">
							  <h5 class="card-title">
								  <div class="ID">Asuransi Kendaraan Bermotor</div>
								  <div class="EN">xxxxx</div>
							  </h5>
							  <p class="card-text ID">Merupakan produk Asuransi yang memberikan perlindungan kepada kendaraan bermotor milik Anda apabila terjadi kerugian/musibah yang dijamin di dalam Polis.</p>
							  <p class="card-text EN">xxxxx</p>
							  <a onclick="modalMV()" href="#" class="stretched-link"></a>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
	  </main>
  
	  
	  <footer>
		  <hr>
		  <div class="container blur">
			  <div class="row">
				  <div class="footer-div">
					  <a href="https://www.greateasterngeneral.com/id">
						  <img src="/referral/Resources/GE_Master_Logo_FC-HighRes.png" class="logo-footer">
					  </a>
					  <div class="icon-block">
						  <a href="https://www.facebook.com/greateasterngeneral.id">
							  <img src="/referral/Resources/01-Main_Page/facebook-icon.png">
						  </a>
						  <a href="https://www.instagram.com/greateasterngeneral.id">
							  <img src="/referral/Resources/01-Main_Page/instagram_icon.png">
						  </a>
					  </div>
				  </div>
			  </div>
			  
			  <div class="footer-div">
				  <div class="d-flex flex-column">
					  <p class="gegi-contact ID">Hubungi Kami :</p><p class="gegi-contact EN">Contact Us :</p>
					  <p class="gegi-subtitle ID">Kantor Pusat :</p><p class="gegi-subtitle EN">Head Office :</p>
					  <p class="gegi-item-red">Midplaza 2, 23rd floor</p>
					  <p class="gegi-item-red">Jalan Jenderal Sudirman Kav. 10-11</p>
					  <p class="gegi-item-red">Jakarta 10220, Indonesia</p>
					  <p class="gegi-subtitle ID">Email Layanan Nasabah :</p><p class="gegi-subtitle EN">Customer Care Email :</p>
					  <a class="gegi-item-red" href="mailto:wecare-id@greateasterngeneral.com">Email: wecare-id@greateasterngeneral.com</a>
				  </div>
			  </div>
			  
			  <div class="footer-div">
				  <div class="d-flex flex-column">
					  <p class="gegi-subtitle ID">Akun Resmi WhatsApp :</p><p class="gegi-subtitle EN">Official WhatsApp Account :</p>
					  <a class="gegi-item-red" href="https://wa.me/+6281915723737">WhatsApp: +62 819 1572 3737</a>
					  <p class="gegi-subtitle ID">Nomor Telepon* :</p><p class="gegi-subtitle EN">Phone Number* :</p>
					  <a class="gegi-item-red" href="Tel:+62215723737">+62 21 5723737</a>
					  <p class="gegi-item-notes ID">*Beroperasi pada hari kerja Senin-Jum'at pukul 09:00 - 17:00 WIB.</p>
					  <p class="gegi-item-notes EN">*Operation on working days Mon-Fri 9 AM - 5 PM Jakarta Local Time.</p>
				  </div>
			  </div>
			  
			  <div class="footer-div">
				  <div class="d-flex flex-column">
					  <p class="gegi-contact ID">Ketentuan Layanan 24 Jam</p><p class="gegi-contact EN">24 hours customer care</p>
					  <p class="gegi-service-desc ID">Silakan sampaikan pertanyaan dan pengaduan ke E-mail Layanan Nasabah atau Akun Resmi Whatsapp kami. Pertanyaan dan pengaduan akan kami respon di hari Senin - Jumat, pukul 09.00 - 17.00 WIB.</p>
					  <p class="gegi-service-desc EN">Please inquiry your questions and complains to Customer Care E-Mail Address or our Official WhatsApp Account. We will response your inquiry on Mon-Fri, at 9 AM - 5 PM Jakarta Local Time.</p>
				  </div>
			  </div>
			  
			  <div class="footer-div">
				  <div class="d-flex flex-column">
					  <br><p class="ID">PT. Great Eastern General Insurance Indonesia berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK)</p>
					  <br><p class="EN">PT. Great Eastern General Insurance Indonesia licensed and supervised by Otoritas Jasa Keuangan (OJK)</p>
				  </div>
			  </div>
		  </div>
	  </footer>
  
	  <div class="modal fade modalClass" id="modalContactCenter" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"</button>
				  </div>
				  <div class="modal-body">
					  <div class="container" style="text-align: center; margin-bottom: 30px;">
						  <div class="modalContactCenter-logo">
							  <img src="/referral/Resources/GE_Master_Logo_FC-HighRes.png" class="logo-header">
						  </div>
					  </div>
					  <div class="container ID" style="text-align: center; margin-bottom: 30px;">
						  <div>
							  <p><h4>Lengkapi Informasi Berikut</h4></p>
						  </div>
					  </div>
					  <div class="container EN" style="text-align: center; margin-bottom: 30px; display: none;">
						  <div>
							  <p><h4>Complete the following information</h4></p>
						  </div>
					  </div>
					  <div class="container">
						  <div class="row">
							  <form class="need-validation" id="formContactCenter" onSubmit="waRedirect();">
								  <div class="form-group row">
									  <div class="col-4">&nbsp;</div>
									  <div class="col-2">
										  <label for="txtName" class="col-form-label ID">Nama*</label>
										  <label for="txtName" class="col-form-label EN" style="display: none;">Name*</label>
									  </div>	
									  <div class="col-3">
										  <input type="text" class="form-control" id="txtName" placeholder="" required>
									  </div>
								  </div>
								  <div class="form-group row">
									  <div class="col-4">&nbsp;</div>
									  <div class="col-2">
										  <label for="txtNoPonsel" class="col-form-label ID">Nomor Ponsel*</label>
										  <label for="txtNoPonsel" class="col-form-label EN" style="display: none;">Mobile Phone Number*</label>
									  </div>
									  <div class="col-1">
										  <select class="form-select" id="listCountry" style="width: 125%">
											  <option value="+62" selected>+62</option>
										  </select>
									  </div>
									  <div class="col-2">
										  <input min="0" type="number" class="form-control" id="txtNoPonsel" placeholder="" required>
									  </div>
								  </div>
								  <div class="form-group row">
									  <div class="col-4">&nbsp;</div>
									  <div class="col-2">
										  <label for="txtEmail" class="col-form-label ID">Alamat Email*</label>
										  <label for="txtEmail" class="col-form-label EN" style="display: none;">Email Address*</label>
									  </div>
									  <div class="col-3"><input type="email" class="form-control" id="txtEmail" placeholder="" required></div>
								  </div>
								  <div class="form-group row">
									  <div class="col-4">&nbsp;</div>
									  <div class="col-2">
										  <label for="txtDate" class="col-form-label ID">Tanggal*</label>
										  <label for="txtDate" class="col-form-label EN" style="display: none;">Date*</label>
									  </div>
									  <div class="col-3"><input type="date" class="form-control" id="txtDate" placeholder="" required></div>
								  </div>
								  <div class="form-group row">
									  <div class="col-4">&nbsp;</div>
									  <div class="col-2">
										  <label for="txtClock" class="col-form-label ID">Jam*</label>
										  <label for="txtClock" class="col-form-label EN" style="display: none;">Time*</label>
									  </div>
									  <div class="col-3">
										  <select class="form-select" id="listClock">
											  <option value="08:00 - 10:00">08:00 - 10:00</option>
											  <option value="10:00 - 12:00">10:00 - 12:00</option>
											  <option value="13:00 - 15:00">13:00 - 15:00</option>
										  </select>
									  </div>
								  </div>
								  <br>
								  <div class="form-group row" align="center">
									  <div class="col-12">
										  <p id="productId" value="" hidden="true"></p>
										  <button id="btnSubmit" type="submit" class="btn btn-danger">
											  <div class="ID">Hubungi Kami</div>
											  <div class="EN" style="display: none;">Contact Us</div>
										  </button>
									  </div>
								  </div>
							  </form>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  
  
	  <div class="modal fade modalClass" id="modalAllRisk" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				  </div>
				  <div class="modal-body modal-AllRisk">
					  <div class="container">
						  <div class="row">
							  <div class="col-lg-6 col-md-12 col-sm-12">
								  <h1 class="mt-2 mb-4" id="productAllRisk" value="Asuransi Semua Risiko Industri">
									  <div class="ID">Asuransi Semua Risiko Industri</div>
									  <div class="EN">xxxxx</div>
								  </h1>
								  <div class="modal-div-gap">
									  <p class="ID">Merupakan produk Asuransi yang memberikan perlindungan untuk bangunan, isi bangunan, termasuk peralatan elektronik dan furniture di properti Anda.</p>
									  <p class="EN">xxxxx</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Keunggulan Produk:</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Memiliki perlindungan yang lengkap.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Proses pembelian polis yang mudah.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Jaminan perluasan otomatis ( tanpa premi tambahan ):</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tanggung gugat hukum pribadi, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Uang dalam penyimpanan, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya akomodasi sementara, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Pembersihan puing, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya arsitek, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya persiapan klaim, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya pemadaman kebakaran, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Okupasi Bangunan:</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Rumah Tinggal</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Toko</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Restoran</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Apartemen</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Dan jenis usaha lain-lain.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="button-unduh-wrapper">
									  <a href="/referral/Documents/Brosur/Asuransi_Semua_Risiko_Industri-Brosur-AX_Referal.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
									  <a href="/referral/Documents/RIPLAY%20Umum/Industrial_All_Risk_(IAR)-GEGI_RIPLAY_Umum_2024_IND-AX_Referal.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
								  </div>
								  <br>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <p class="title semibold ID">Syarat & Ketentuan</p>
									  <p class="title semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Konstruksi bangunan kelas 1 ( konstruksi beton atau baja ).</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tidak berada di area banjir.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Nilai pertanggungan maksimum IDR 5 Milyar, apabila nilai pertanggungan diatas IDR 5 Milyar, harap menghubungi kontak GEGI.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <div class="d-flex">
										  <div class="ID"><p>Untuk mendapatkan penjelasan produk secara langsung, silahkan klik Hubungi Kami.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="btn-beli-wrapper">
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(1);"><div class="ID">Hubungi Kami</div><div class="EN">xxxxx</div></button>
									  <!--button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(1);"><div class="ID">Beli sekarang</div><div class="EN">xxxxx</div></button-->
								  </div>
							  </div>
							  <div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								  <img class="modal-content-img" src="/referral/Resources/02-Detail/Asuransi_Semua_Risiko_Industri.jpg" loading="lazy">
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  
	  <div class="modal fade modalClass" id="modalAccident" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				  </div>
				  <div class="modal-body modal-accident">
					  <div class="container">
						  <div class="row">
							  <div class="col-lg-6 col-md-12 col-sm-12">
								  <h1 class="mt-2 mb-4" id="productAccident" value="Asuransi Kecelakaan Diri">
									  <div class="ID">Asuransi Kecelakaan Diri</div>
									  <div class="EN">xxxxx</div>
								  </h1>
								  <div class="modal-div-gap">
									  <p class="ID">Asuransi kecelakaan diri memberikan perlindungan menyeluruh terhadap kecelakaan dengan memberikan kompensasi keuangan kepada anda, keluarga atau karyawan anda yang mengalami cacat tetap sebagian, cacat tetap keseluruhan atau kematian, termasuk biaya pengobatan yang disebabkan oleh kecelakaan dan perluasan jaminan lainnya sesuai kebutuhan Anda.</p>
									  <p class="EN">xxxxx</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Keunggulan Produk:</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Memiliki perlindungan yang lengkap.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Proses pembelian polis yang mudah.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Berlaku 24 jam di seluruh dunia.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Manfaat Asuransi:</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Meninggal Dunia.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Cacat Tetap Sebagian dan Keseluruhan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Biaya Perawatan dan Pengobatan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Biaya Pengobatan Alternatif (Sinshe).</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Biaya Ambulance.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Biaya Pengurusan Sertifikat Kematian.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Harian Rawat Inap Rumah Sakit akibat Kecelakaan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Penggantian Ganda Kecelakaan Transportasi Umum.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Jaminan Terorisme.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Barang Pribadi yang Rusak Akibat Kecelakaan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Santunan Pembayaran Cicilan Rumah dan Kendaraan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Klasifikasi Tertanggung:</p>
									  <p class="semibold EN">xxxxx</p>
									  <p class="semibold ID">Kelas 1:</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div class="ID"><p>Pekerjaan dalam ruangan kantor, seperti karyawan, akuntan, arsitek, auditor, dokter, pengacara, sekretaris, guru, ibu rumah tangga, dan sebagainya (list lengkap dapat dilihat pada dokumen List Kelas Pekerjaan)</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <p class="semibold ID">Kelas 2:</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div class="ID"><p>Pekerjaan dinas luar atau lapangan, seperti insinyur sipil, manajer proyek, tenaga sales, supir, pedagang eceran, dan sebagainya (list lengkap dapat dilihat pada dokumen List Kelas Pekerjaan)</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="button-unduh-wrapper">
									  <a href="/referral/Documents/Brosur/Asuransi_Kecelakaan_Diri-Brosur-AX_Referal.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
									  <a href="/referral/Documents/RIPLAY%20Umum/Kecelakaan_Diri_(PA)-GEGI_RIPLAY_Umum_2024_IND-AX_Referal.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
									  <a href="/referral/Documents/List_Pekerjaan-Kelas_1_2-20240215.xlsx" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">List Kelas Pekerjaan</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
								  </div>
								  <br>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <p class="title semibold ID">Syarat & Ketentuan</p>
									  <p class="title semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Usia minimum 1 tahun dan usia maksimum 65 tahun saat penutupan asuransi.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tertanggung tidak memiliki pekerjaan sebagai Pilot, Crew Pesawat atau Kapal, Angkatan Bersejata, Bodyguard, Polisi, Stuntman, Pekerja Tambang Minyak, Penyelam, Jockey.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tidak ada pemeriksaan kesehatan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <div class="d-flex">
										  <div class="ID"><p>Untuk mendapatkan penjelasan produk secara langsung, silahkan klik Hubungi Kami.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  
								  <div class="row">
									  <div class="col-5">
										  <label for="categoryPA" class="col-form-label ID">Pilih kelas pekerjaan anda:</label>
										  <label for="categoryPA" class="col-form-label EN">xxxxx</label>
									  </div>
									  <div class="col-3">
										  <select class="form-select" id="categoryPA" name="categoryPA">
											  <option value="">...</option>
											  <option value="1">Kelas 1</option>
											  <option value="2">Kelas 2</option>
										  </select>
									  </div>
								  </div>
								  <br>
								  <div class="btn-beli-wrapper">
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(2);"><div class="ID">Hubungi Kami</div><div class="EN">xxxxx</div></button>
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(2);"><div class="ID">Beli sekarang</div><div class="EN">xxxxx</div></button>
								  </div>
							  </div>
							  <div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								  <img class="modal-content-img" src="/referral/Resources/02-Detail/Asuransi_Kecelakaan_Diri.jpg" loading="lazy">
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
	  
	  <div class="modal fade modalClass" id="modalGALP" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				  </div>
				  <div class="modal-body modal-galp">
					  <div class="container">
						  <div class="row">
							  <div class="col-lg-6 col-md-12 col-sm-12">
								  <h1 class="mt-2 mb-4" id="productGALP" value="Great Accident and Life Protection">
									  <div class="ID">Great Accident and Life Protection</div>
									  <div class="EN">xxxxx</div>
								  </h1>
								  <div class="modal-div-gap">
									  <p class="ID">Great Accident & Life Protection merupakan Produk Asuransi Bersama antara PT. Great Eastern General Insurance Indonesia dengan PT. Great Eastern Life Indonesia yang memiliki jaminan yang komprehensif untuk memenuhi kebutuhan Anda. Produk ini memiliki jaminan meninggal dunia akibat kecelakaan atau bukan kecelakaan, cacat tetap / sebagian, biaya pengobatan dan biaya-biaya atau santunan lainnya akibat kecelakaan.</p>
									  <p class="EN">xxxxx</p>
								  </div>
								  <div class="modal-div-gap">
									<p class="semibold ID">Keunggulan Produk:</p>
									<p class="semibold EN">xxxxx</p>
								</div>
								<div class="modal-div-gap">
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Memiliki perlindungan yang lengkap.</p></div>
										<div class="EN"><p>xxxxx</p></div>
									</div>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Proses pembelian polis yang mudah.</p></div>
										<div class="EN"><p>xxxxx</p></div>
									</div>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Tidak memerlukan pemeriksaan kesehatan.</p></div>
										<div class="EN"><p>xxxxx</p></div>
									</div>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Cashless untuk biaya pengobatan akibat kecelakaan di Rumah Sakit rekanan.</p></div>
										<div class="EN"><p>xxxxx</p></div>
									</div>
								</div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Manfaat Asuransi:</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div class="ID"><p>Menjamin meninggal dunia akibat kecelakaan atau bukan kecelakaan, cacat tetap / sebagian, biaya pengobatan dan biaya-biaya atau santunan lainnya.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap">
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan A – Meninggal dunia akibat Kecelakaan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan B – Cacat Tetap akibat Kecelakaan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan C – Biaya Pengobatan akibat Kecelakaan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan D – Biaya-biaya / santunan akibat Kecelakaan. seperti santunan beasiswa untuk anak, santunan angsuran pembiayaan kendaraan / rumah, pengobatan alternatif Sinshe, dan lain-lain.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan E – Meninggal dunia akibat Bukan Kecelakaan (Sakit).</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="button-unduh-wrapper">
									  <a href="/referral/Documents/Brosur/Great_Accident_Life_Protection-Brosur-AX_Referal.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
									  <a href="/referral/Documents/RIPLAY%20Umum/Great_Accident_Life_Protection-GEGI_RIPLAY_Umum_2024_IND-AX_Referal.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
								  </div>
								  <br>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <p class="title semibold ID">Syarat & Ketentuan</p>
									  <p class="title semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Usia minimum 1 tahun dan usia maksimum 65 tahun saat penutupan asuransi.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tertanggung dalam keadaan kesehatan yang baik, dan saat ini tidak menjalani perawatan medis / kesehatan dan tidak sedang dalam perawatan Covid-19 dan telah sembuh dari Covid 19 lebih dari 3 bulan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <div class="d-flex">
										  <div class="ID"><p>Untuk mendapatkan penjelasan produk secara langsung, silahkan klik Hubungi Kami.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="btn-beli-wrapper">
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(3);"><div class="ID">Hubungi Kami</div><div class="EN">xxxxx</div></button>
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(3);"><div class="ID">Beli sekarang</div><div class="EN">xxxxx</div></button>
								  </div>
							  </div>
							  <div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								  <img class="modal-content-img" src="/referral/Resources/02-Detail/Great_Accident_and_Life_Protection.jpg" loading="lazy">
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
	  
	  <div class="modal fade modalClass" id="modalMotor" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				  </div>
				  <div class="modal-body modal-motor">
					  <div class="container">
						  <div class="row">
							  <div class="col-lg-6 col-md-12 col-sm-12">
								  <h1 class="mt-2 mb-4" id="productMotor" value="Asuransi Mikro Sepeda Motor">
									  <div class="ID">Asuransi Mikro Sepeda Motor</div>
									  <div class="EN">xxxxx</div>
								  </h1>
								  <div class="modal-div-gap">
									  <p class="ID">Merupakan produk Asuransi mikro yang memberikan santunan kepada sepeda motor milik Peserta asuransi apabila terjadi kerugian/musibah yang dijamin di dalam Polis.</p>
									  <p class="EN">xxxxx</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Keunggulan Produk:</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Memiliki perlindungan yang lengkap.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Harga yang kompetitif di market.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Proses pembelian polis yang mudah.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Produk ini memiliki 2 (dua) paket, yaitu:</p>
									  <p class="semibold EN">xxxxx</p>
									  <p class="semibold ID">1. Paket Standar</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Manfaat Kerusakan Total Sepeda Motor Maksimal Rp. 2.500.000.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Manfaat Santunan Meninggal Dunia atau Cacat Tetap Keseluruhan akibat kecelakaan Maksimal Rp. 2.500.000.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Manfaat Biaya Pengobatan akibat kecelakaan Maksimal Rp. 500.000.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Maksimal nilai manfaat yang dibayarkan jika terdapat lebih dari 1 (satu) manfaat klaim bersamaan adalah Maksimal Rp. 5.000.000 per polis.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">2. Paket Super</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Manfaat Kerusakan Total Sepeda Motor Maksimal Rp. 5.000.000.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Manfaat Santunan Meninggal Dunia atau Cacat Tetap Keseluruhan akibat kecelakaan Maksimal Rp. 5.000.000.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Manfaat Biaya Pengobatan akibat kecelakaan Maksimal Rp. 1.000.000.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Maksimal nilai manfaat yang dibayarkan jika terdapat lebih dari 1 (satu) manfaat klaim bersamaan adalah Maksimal Rp. 10.000.000 per polis.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  
								  <div class="button-unduh-wrapper">
									  <a href="/referral/Documents/Brosur/Asuransi_Mikro_Sepeda_Motor-Brosur-AX_Referal.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
									  <a href="/referral/Documents/RIPLAY%20Umum/Asuransi_Mikro_Motor-GEGI_RIPLAY_Umum_2024_IND-AX_Referal.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
								  </div>
								  <br>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <p class="title semibold ID">Syarat & Ketentuan</p>
									  <p class="title semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Batas usia peserta adalah 17 s/d 70 tahun saat pembelian asuransi.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Maksimal usia sepeda motor saat pembelian asuransi adalah 15 tahun sejak tahun pembuatan kendaraan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Asuransi ini hanya berlaku untuk kejadian/insiden yang terjadi di wilayah Indonesia.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>1 (satu) plat nomor hanya dapat diasuransikan oleh 1 (satu) sertifikat asuransi selama periode asuransi yang sama.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Masa tunggu berlaku selama 14 (empat belas) hari kalender sejak tanggal pembelian asuransi, kecuali untuk jaminan kecelakaan diri maka masa tunggu berlaku selama 3(tiga) hari kalender.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Klaim hanya dapat dilakukan 1 (satu) kali selama periode asuransi untuk semua bagian jaminan sepeda motor dan kecelakaan diri. Jika klaim sudah dibayarkan maka jaminan asuransi akan otomatis berakhir.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Untuk klaim pencurian harus segera dilaporkan ke polisi dalam 1 x 24 jam sejak kejadian terjadi, jika tidak maka klaim tidak dapat dibayarkan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <div class="d-flex">
										  <div class="ID"><p>Untuk mendapatkan penjelasan produk secara langsung, silahkan klik Hubungi Kami.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="row">
									  <div class="col-5">
										  <label for="categoryMV" class="col-form-label ID">Pilih paket pilihan anda:</label>
										  <label for="categoryMV" class="col-form-label EN">xxxxx</label>
									  </div>
									  <div class="col-3">
										  <select class="form-select" id="categoryMV" name="categoryMV">
											  <option value="">...</option>
											  <option value="Standar">Paket Standar</option>
											  <option value="Super">Paket Super</option>
										  </select>
									  </div>
								  </div>
								  <br>
								  <div class="btn-beli-wrapper">
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(4);"><div class="ID">Hubungi Kami</div><div class="EN">xxxxx</div></button>
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(4);"><div class="ID">Beli sekarang</div><div class="EN">xxxxx</div></button>
								  </div>
							  </div>
							  <div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								  <img class="modal-content-img" src="/referral/Resources/02-Detail/Asuransi_Mikro_Sepeda_Motor.jpg" loading="lazy">
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
	  
	  <div class="modal fade modalClass" id="modalMV" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				  </div>
				  <div class="modal-body modal-mv">
					  <div class="container">
						  <div class="row">
							  <div class="col-lg-6 col-md-12 col-sm-12">
								  <h1 class="mt-2 mb-4" id="productMV" value="Asuransi Kendaraan Bermotor">
									  <div class="ID">Asuransi Kendaraan Bermotor</div>
									  <div class="EN">xxxxx</div>
								  </h1>
								  <div class="modal-div-gap">
									  <p class="ID">Merupakan produk Asuransi yang memberikan perlindungan kepada kendaraan bermotor milik Anda apabila terjadi kerugian/musibah yang dijamin di dalam Polis.</p>
									  <p class="EN">xxxxx</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Keunggulan Produk:</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Memiliki perlindungan yang lengkap.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Proses pembelian polis yang mudah.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Manfaat Asuransi Dasar:</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan Comprehensive (All Risk).</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan Kerusakan Total (Total Loss Only/TLO).</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Manfaat Asuransi Perluasan (dengan tambahan premi):</p>
									  <p class="semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tanggung Jawab Hukum terhadap Pihak Ketiga.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan Kecelakaan Diri.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan Huru-Hara.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan Terorisme.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan Gempa Bumi, Tsunami dan/ atau Letusan Gunung Berapi.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Jaminan Angin Topan, Badai, Hujan es, Banjir dan/atau Tanah Longsor.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
  
								  <div class="button-unduh-wrapper">
									  <a href="/referral/Documents/Brosur/Asuransi_Kendaraan_Bermotor-Brosur-AX_Referal.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
									  <a href="/referral/Documents/RIPLAY%20Umum/Kendaraan_Bermotor-GEGI_RIPLAY_Umum_2024_IND-AX_Referal.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											  <span class="EN"><img src="/referral/Resources/02-Detail/product-summary-icon.svg">xxxxx</span>
										  </button>
									  </a>
								  </div>
								  <br>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <p class="title semibold ID">Syarat & Ketentuan</p>
									  <p class="title semibold EN">xxxxx</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Batas usia peserta adalah 17 s/d 70 tahun saat pembelian asuransi.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Maksimal usia kendaraan bermotor saat pembelian asuransi adalah 15 tahun sejak tahun pembuatan kendaraan.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Asuransi ini hanya berlaku untuk kejadian/insiden yang terjadi di wilayah Indonesia.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>1 (satu) plat nomor hanya dapat diasuransikan oleh 1 (satu) sertifikat asuransi selama periode asuransi yang sama.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <div class="d-flex">
										  <div class="ID"><p>Untuk mendapatkan penjelasan produk secara langsung, silahkan klik Hubungi Kami.</p></div>
										  <div class="EN"><p>xxxxx</p></div>
									  </div>
								  </div>
								  <div class="btn-beli-wrapper">
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(5);"><div class="ID">Hubungi Kami</div><div class="EN">xxxxx</div></button>
									  <!--button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(5);"><div class="ID">Beli sekarang</div><div class="EN">xxxxx</div></button-->
								  </div>
							  </div>
							  <div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								  <img class="modal-content-img" src="/referral/Resources/02-Detail/Asuransi_Kendaraan_Bermotor.jpg" loading="lazy">
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  
	  <script src="scripts/jquery-3.6.4.min.js"></script>
	  <script src="scripts/popper.min.js"></script>
	  <script src="scripts/bootstrap.min.js"></script>
	  <script src="scripts/page.js"></script>
  </body>
  
  </html>`
  return jqueryLoaded ? (
    <ReferralChildPage html={htmlContent}/>
  ) : (
	null
);
};

export default ReferralPage;
