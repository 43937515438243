import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PaginationBar from './../../../components/PaginationBar';
import * as Constant from './../../../constants/Constant';
import * as urlConstant from './../../../constants/urlConstant';
import { Post } from './../../../utils/AxFetch';
import { useDispatch } from 'react-redux';

const ClaimsDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pageOnClick,setPageOnClick] = useState(1);
  const [claimAppListPagination,setClaimAppListPagination] = useState({data:[], currentPage:1, pagesCount:0});
  const [prodCode, setProdCode] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [companyId, setCompanyId] = useState('');

  const onClick = (page) => { setPageOnClick(page); };

  useEffect(() => {
    if (!props.modalIsOpen && pageOnClick !== 1) {
      setPageOnClick('');
    } else if (props.modalIsOpen && !pageOnClick) {
      setPageOnClick(1);
    }
  }, [props.modalIsOpen, pageOnClick]);

  useEffect(() => {
    setProdCode(props.productCode);
    setFromDate(props.fromDate);
    setToDate(props.toDate);
    setCompanyId(props.companyId);
  }, [props.fromDate, props.toDate, props.productCode, props.companyId]);

  useEffect(() => {
    const getClaimAppDet = async (page) => {
      let resData = '';
      let resCurrentPage = '';
      let resPagesCount = '';
      let urlParams = {};
  
      if (fromDate) {
        if (toDate){ //year to date filter
          urlParams = {
            productCode: prodCode,
            fromDate: fromDate,
            toDate: toDate,
            companyId: companyId,
            rowPerPage: Constant.rowPerPage,
            pageNum: page
          }
        }else{ //monthly filter
          urlParams = {
            productCode: prodCode,
            fromDate: fromDate,
            companyId: companyId,
            rowPerPage: Constant.rowPerPage,
            pageNum: page
          }
        }
      }else{ //init
        urlParams = {
          productCode: prodCode,
          companyId: companyId,
          rowPerPage: Constant.rowPerPage,
          pageNum: page
        }
      }

      let claimAppDetResp = await Post(urlConstant.URL_BASED + urlConstant.URL_TRNX_CLAIM_APP_DET + page, urlParams, dispatch);
      let dataResp = claimAppDetResp.respData;
                
      if(!claimAppDetResp.isError && dataResp.data) {
        resData = dataResp.data.content;
        resCurrentPage = page;
        resPagesCount = dataResp.data.totalPages;
      }
      
      setClaimAppListPagination({data:resData, currentPage: resCurrentPage, pagesCount: resPagesCount});
    }
    
    if (prodCode && pageOnClick) getClaimAppDet(pageOnClick);
  }, [dispatch, pageOnClick, fromDate, toDate, prodCode, companyId])

  return (
    <div>
      <Modal isOpen={props.modalIsOpen} className="modal-max">
        <ModalHeader className={'modal_header'} toggle={props.handleClose}>
          {t('claimsdetails')}
        </ModalHeader>
        <ModalBody className={'report-modal-body'}>
        <Table>
          <thead>
            <tr>
              <th>{t('no.')}</th>
              <th>{t('claimsid')}</th>
              <th>{t('certificateinsuranceno')}</th>
              <th>{t('proposername')}</th>
              <th>{t('ktp')}</th>
              <th>{t('claimsregistereddate')}</th>
              <th>{t('dateofloss')}</th>
              <th>{t('claimsdescription')}</th>
              <th>{t('claimsamount')}</th>
              <th>{t('settlementamount')}</th>
              <th>{t('claimsstatus')}</th>
            </tr>
          </thead>
          <tbody>
            {
              claimAppListPagination.data.map((data, i) => {
                return [
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{data.claimRefNo}</td>
                    <td>{data.policyNo}</td>
                    <td>{data.proposer.name}</td>
                    <td>{data.proposer.identityNo}</td>
                    <td>{data.claimRegDate}</td>
                    <td>{data.incidentDate}</td>
                    <td>{data.claimRemark}</td>
                    <td>{data.claimAmount}</td>
                    <td>{data.settlementAmount}</td>
                    <td>{data.status}</td>
                  </tr>
                ]
              }) 
            }
          </tbody>
        </Table>
        </ModalBody>
        <PaginationBar totalPages={claimAppListPagination.pagesCount} currentPage={claimAppListPagination.currentPage} handleClick={(page) => onClick(page)}  />
      </Modal>
    </div>
  );
}

export default ClaimsDetails;