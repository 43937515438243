import React, { useState, useEffect } from 'react';
import { Button, Collapse, CardBody, Card, Label, Row, Col } from 'reactstrap';
import { Get } from './../../../utils/AxFetch';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as urlConstant from './../../../constants/urlConstant';
import CertificateInsuranceDetails from './CertificateInsuranceDetails';
import ClaimsDetails from './ClaimsDetails';

const StatisticDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [obj,setObj] = useState({type: '', status:''});
  const [dataList,setDataList] = useState({});
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selProdCode, setSelProdCode] = useState('');
  const [companyId, setcompanyId] = useState('');

  const certInsStatus = {
    issue: 'ISSUED',
    cancel: 'CANCELLED'
  }
  
  const handleClose = () => {
    setModalIsOpen(false);
  }
  
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    setFromDate(props.selectedFromDt);
    setToDate(props.selectedEndDt);
    setcompanyId(props.selectedCompanyId)
  }, [props.selectedFromDt, props.selectedEndDt, props.selectedCompanyId]);

  useEffect(() => {
    const getCountList = async() => {
      let certIssuedCount = '';
      let claimListCount = '';
      let certCancelCount = '';
      let urlParams = {};

      if (fromDate){
        if (toDate) { //year to date filter
          urlParams = {
            fromDate: fromDate,
            toDate: toDate,
            companyId: companyId
          }
        }else{ //monthly filter
          urlParams = {
            fromDate: fromDate,
            companyId: companyId
          }
        }

      }else{ //init
        urlParams = {
          companyId: companyId
        }
      }

      let certIssuedResp = await Get(urlConstant.URL_BASED + urlConstant.URL_CREATE_TRNX_COUNT, { params: {...urlParams, status: certInsStatus.issue }}, dispatch);
      if (!certIssuedResp.isError) {
        if (certIssuedResp.respData.data.length !== 0) {
          certIssuedCount = certIssuedResp.respData.data
        }
      }

      let claimListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_CLAIM_APP_COUNT, { params: urlParams }, dispatch);
      if (!claimListResp.isError) {
        if (claimListResp.respData.data.length !== 0) {
          claimListCount = claimListResp.respData.data
        }
      }

      let certCancelResp = await Get(urlConstant.URL_BASED + urlConstant.URL_CREATE_TRNX_COUNT, { params: {...urlParams, status: certInsStatus.cancel }}, dispatch);
      if (!certCancelResp.isError) {
        if (certCancelResp.respData.data.length !== 0) {
          certCancelCount = certCancelResp.respData.data
        }
      }

      setDataList({certIssuedCount: certIssuedCount, claimListCount: claimListCount, certCancelCount: certCancelCount });
    }

    if (isOpen) {
      $("#statistic").removeClass("expand-icon");
      $("#statistic").addClass("collapse-icon");
      dispatch(getCountList);
    } else {
      $("#statistic").removeClass("collapse-icon");
      $("#statistic").addClass("expand-icon");
    }
  }, [dispatch, isOpen, fromDate, toDate, companyId, certInsStatus.issue, certInsStatus.cancel]);

  const certificateIssueInsurance = (prodCode) => {
    setSelProdCode(prodCode);
    setModalIsOpen(true);
    setObj({type:'CII', status: certInsStatus.issue});
  }
  
  const claims = (prodCode) => {
    setSelProdCode(prodCode);
    setModalIsOpen(true);
    setObj({type:'CA'});
  }

  const certificateCancelInsurance = (prodCode) => {
    setSelProdCode(prodCode);
    setModalIsOpen(true);
    setObj({type:'CII', status: certInsStatus.cancel});
  }

  return (
    <div className="contentTab">
      <Button id="statistic" className="accordion-toggle" onClick={toggle}>{t('statisticdetails')}</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <div className="box"> 
                <div className="box-row"> 
                    <div className="box-cell box1"> 
                      { dataList.certIssuedCount ? 
                        dataList.certIssuedCount.map((certIssued, i) => {
                          return [
                            <Row key={i}>
                              <Col xs="10" sm="8" md="8" className="column">{t('certissuedfor')}&nbsp;<Label><strong>{certIssued.prodName}</strong></Label></Col>
                              <Col xs="2" sm="2" md="2" className="column text-center">:</Col>
                              <Col xs="12" sm="2" md="2" className="column"><button className="link-button" onClick={()=> certificateIssueInsurance(certIssued.prodCode)}>{certIssued.count}</button></Col>
                            </Row>  
                        ]
                        }) : t('nodata')
                      }
                    </div>
                    <div className="box-cell box2"> 
                      { dataList.claimListCount ? 
                        dataList.claimListCount.map((claim, i) => {
                          return [
                            <Row key={i}>
                              <Col xs="10" sm="8" md="8" className="column">{t('claimapprovedfor')}&nbsp;<Label><strong>{claim.prodName}</strong></Label></Col>
                              <Col xs="2" sm="2" md="2" className="column text-center">:</Col>
                              <Col xs="12" sm="2" md="2" className="column"><button className="link-button" onClick={()=> claims(claim.prodCode)}>{claim.count}</button></Col>
                            </Row>
                          ]
                        }) : t('nodata')
                      }
                    </div> 
                </div>
                <div className="box-row"> 
                    <div className="box-cell box1"> 
                      { dataList.certCancelCount ? 
                        dataList.certCancelCount.map((cancel, i) => {
                          return [
                            <Row key={i}>
                              <Col xs="10" sm="8" md="8" className="column">{t('certcancelledfor')}&nbsp;<Label><strong>{cancel.prodName}</strong></Label></Col>
                              <Col xs="2" sm="2" md="2" className="column text-center">:</Col>
                              <Col xs="12" sm="2" md="2" className="column"><button className="link-button" onClick={()=> certificateCancelInsurance(cancel.prodCode)}>{cancel.count}</button></Col>
                            </Row>  
                        ]
                        }) : t('nodata')
                      }
                    </div>                    
                </div> 
            </div>
          </CardBody>
        </Card>
      </Collapse>
      {obj.type === 'CII' ? <CertificateInsuranceDetails insuranceStatus={obj.status} modalIsOpen={modalIsOpen} handleClose={handleClose} productCode={selProdCode} fromDate={fromDate} toDate={toDate} companyId={companyId}/> :
      obj.type === 'CA' ? <ClaimsDetails modalIsOpen={modalIsOpen} handleClose={handleClose} productCode={selProdCode} fromDate={fromDate} toDate={toDate} companyId={companyId}/> :
      <></>}
    </div>
  );
}

export default StatisticDetails;