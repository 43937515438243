import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Post, Get } from '../../../utils/AxFetch';
import * as Constant from './../../../constants/Constant';
import * as urlConstant from '../../../constants/urlConstant';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import PremiumPlanDefault from './PremiumPlan_Default';
import PremiumPlanMobile from './PremiumPlan_Mobile';

const PremiumPlan = ({ respData, premiumResp, setPremiumResp, premiumValue, setPremiumValue, nextSlideTransitions, errorMessage, setErrorMessage, getRespPremiumPlan, channel, setTempPromoCode, tempPromoCode, isPlanSelectionHide:propIsPlanSelectionHide, setIsPlanSelectionHide:propSetIsPlanSelectionHide }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [recalculateFlag, setRecalculateFlag] = useState(true);
    const [showSumInsured, setShowSumInsured] = useState(false);
    const [showDuration, setShowDuration] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 720;
    const [isPlanSelectionHide, setIsPlanSelectionHide] = useState(propIsPlanSelectionHide || false);

    useEffect(()=>{
        // Update local state if the prop changes
        if (propIsPlanSelectionHide !== undefined) {
            setIsPlanSelectionHide(propIsPlanSelectionHide);
        }
    }, [propIsPlanSelectionHide]);

    useEffect(() => {

        if (premiumResp.plans || window.innerWidth !== width) {
            premiumResp.plans.forEach(plan => {
                plan.coverages.forEach(coverage => {
                    if (coverage.value) setShowSumInsured(true);
                });
                plan.premiums.forEach(premium => {
                    if (premium.duration) setShowDuration(true);
                });

                if (plan.premiums.length > 1) {
                    for (var premium of plan.premiums) {
                        if (premium.duration === parseInt($('#duration_'+plan.code).val())) {
                            if (!$('#basicPremiumText_'+plan.code).text()) {
                                $('#basicPremiumText_'+plan.code).text("Rp. "+parseInt(premium.basicPremium).toLocaleString(Constant.defaultLocale));
                            }
                            if (!$('#grossPremiumText_'+plan.code).text()) {
                                $('#grossPremiumText_'+plan.code).text("Rp. "+parseInt(premium.grossPremium).toLocaleString(Constant.defaultLocale));
                            }
                        }
                    }
                } else {
                    if (!$('#basicPremiumText_'+plan.code).text()) {
                        $('#basicPremiumText_'+plan.code).text("Rp. "+parseInt(plan.premiums[0].basicPremium).toLocaleString(Constant.defaultLocale));
                    }
                    if (!$('#grossPremiumText_'+plan.code).text()) {
                        $('#grossPremiumText_'+plan.code).text("Rp. "+parseInt(plan.premiums[0].grossPremium).toLocaleString(Constant.defaultLocale));
                    }
                }
                const handleWindowResize = () => setWidth(window.innerWidth)
                window.addEventListener("resize", handleWindowResize);
            });
        }

        if (premiumValue.planSelected.code && !$('input[name="planSelected"]:checked').val()) {
            $('#planSelected_'+premiumValue.planSelected.code).attr('checked', 'checked');
        }
    }, [premiumResp, setPremiumResp, premiumValue, showSumInsured, showDuration, width])

    const handlePlanSelectionHide = (value) => {
        if(propSetIsPlanSelectionHide !== undefined){
            propSetIsPlanSelectionHide(value);
        }else{
            setIsPlanSelectionHide(value);
        }
    }

    const validatePlanSelection = () => {
        let errMessage = '';

        if (!recalculateFlag ) errMessage += t('warning.message.recalculate')+'\n'
        if(!premiumValue.planSelected.code) errMessage += t('error.message.plan.noselected')+'\n'

        if (errMessage) {setErrorMessage(errMessage);window.scroll(0, 0);}
        
        if (!errMessage) {
            if (errorMessage) setErrorMessage("");
            return true;
        } else return false;
    }

    const handlePlan = () => {
        if (validatePlanSelection()) {
            let resp = {...premiumResp};
            let value = {...premiumValue};
            for (var respPlan of resp.plans) {
                if (respPlan.code === value.planSelected.code) {
                    for (var respPremium of respPlan.premiums) {
                        if(respPremium.duration === parseInt($('#duration_'+value.planSelected.code).val())) {
                            value.planSelected.premium.grossPremium = respPremium.grossPremium;
                            value.planSelected.premium.basicPremium = respPremium.basicPremium;
                            value.planSelected.premium.additionalBenefitPremium = respPremium.additionalBenefitPremium;
                            value.planSelected.premium.duration = respPremium.duration;
                            value.planSelected.premium.startDate = respPremium.startDate;
                            value.planSelected.premium.endDate = respPremium.endDate;
                            setPremiumValue(value);
                            break;
                        }
                    }
                    break;
                }
            }
            nextSlideTransitions(true);
            window.scrollTo(0, 0);
        }
    }

    const clearPlanSelected = () => {
        let value = {...premiumValue};
        value.planSelected.code = "";
        value.planSelected.name = "";
        value.planSelected.coverage.value = "";
        value.planSelected.coverage.desc = "";
        value.planSelected.premium.basicPremium = "";
        value.planSelected.premium.additionalBenefitPremium = "";
        value.planSelected.premium.duration = "";
        value.planSelected.premium.text = "";
        value.planSelected.premium.grossPremium = "";
        setPremiumValue(value);
    }

    const selectBasicPremium = (e, plan) => {
        let resp = {...premiumResp};
        for (var respPlan of resp.plans) {
            if (respPlan.code === plan.code) {
                for (var respPremium of respPlan.premiums) {
                    if(respPremium.duration === parseInt(e.target.value)) {
                        $('#basicPremiumText_'+plan.code).text("Rp. "+parseInt(respPremium.basicPremium).toLocaleString(Constant.defaultLocale));
                        $('#grossPremiumText_'+plan.code).text("Rp. "+parseInt(respPremium.grossPremium).toLocaleString(Constant.defaultLocale));
                        break;
                    }
                }
                break;
            }
        }
    }

    const additionalBenefitChecked = (e, additionalBenefit, i) => {
        if (e.target && additionalBenefit) {
            let value = {...premiumValue};
            value.additionalBenefitsChecked[i].checked = e.target.checked;
            setPremiumValue(value);
            setRecalculateFlag(false);
            $('input[type=radio]:checked').prop('checked', false);
            handlePlanSelectionHide(true);
            clearPlanSelected();
        }
    }

    const getRecalculate = async(isPromoCodeEmpty) => {
        let value = {...premiumValue};
        let additionalBenefitChecked =[];
        let url = "";

        value.additionalBenefitsChecked.forEach((additionalBenefit) => {

            if (additionalBenefit.checked) {
                additionalBenefitChecked.push({code: additionalBenefit.code});
            }
        });

        if (channel === Constant.LOGIN_CHANNEL.PARTNER) {
            url = urlConstant.URL_B2B_PREMIUM_PLAN
        } else if (channel === Constant.LOGIN_CHANNEL.CONSUMER) {
            url = urlConstant.URL_B2C_PREMIUM_PLAN
        }

        let resp = await Post(urlConstant.URL_BASED + url, {
            productCode : premiumValue.getPremiumParams.productCode,
            riskItems : premiumValue.getPremiumParams.riskItems,
            infoItems : premiumValue.getPremiumParams.infoItems,
            insuredPersons : premiumValue.getPremiumParams.insuredPersons,
            adultInsuredPersons : premiumValue.getPremiumParams.adultInsuredPersons,
            childrenInsuredPersons : premiumValue.getPremiumParams.childrenInsuredPersons,
            additionalBenefits: additionalBenefitChecked ? additionalBenefitChecked : null,
            proposerFields : premiumValue.getPremiumParams.proposerFields,
            questionItems : premiumValue.getPremiumParams.questionItems,
            promoCode : isPromoCodeEmpty ? "" : tempPromoCode
        }, dispatch);

        if (!resp.isError && resp.respData.data) {
            handlePlanSelectionHide(false);
            if (errorMessage) setErrorMessage("");
            setRecalculateFlag(true);
            getRespPremiumPlan(resp.respData.data, premiumValue.getPremiumParams, false);

            resp.respData.data.plans.forEach(plan => {
                if (plan.premiums.length > 1) {
                    plan.premiums.forEach(premium => {
                        if (premium.duration === parseInt($('#duration_'+plan.code).val())) {
                            let value = {...premiumValue};
                            value.planSelected.premium.grossPremium = premium.grossPremium;
                            setPremiumValue(value);
                            $('#basicPremiumText_'+plan.code).text("Rp. "+parseInt(premium.basicPremium).toLocaleString(Constant.defaultLocale));
                            $('#grossPremiumText_'+plan.code).text("Rp. "+parseInt(premium.grossPremium).toLocaleString(Constant.defaultLocale));
                        }
                    })
                } else if (plan.premiums.length === 1) {
                    $('#basicPremiumText_'+plan.code).text("Rp. "+parseInt(plan.premiums[0].basicPremium).toLocaleString(Constant.defaultLocale));
                    $('#grossPremiumText_'+plan.code).text("Rp. "+parseInt(plan.premiums[0].grossPremium).toLocaleString(Constant.defaultLocale));
                }
            })
        }
    }

    const planOnClick = (plan, i) => {

        if (!recalculateFlag) {
            setErrorMessage(t('warning.message.recalculate'));
            $('html, body').animate({scrollTop:0}, 200);
        } else {
            let value = {...premiumValue};
        
            value.planSelected.code = plan.code;
            value.planSelected.name = plan.name;
            value.planSelected.coverage.value = plan.coverages[0] ? plan.coverages[0].value : "";
            value.planSelected.coverage.desc = plan.coverages[0] ? plan.coverages[0].desc : "";

            if (plan.premiums.length === 1) {
                value.planSelected.premium.basicPremium = plan.premiums[0].basicPremium;
                value.planSelected.premium.additionalBenefitPremium = plan.premiums[0].additionalBenefitPremium;
                value.planSelected.premium.grossPremium = plan.premiums[0].grossPremium;
                value.planSelected.premium.adminFee = plan.premiums[0].adminFee;
                value.planSelected.premium.stampDuty = plan.premiums[0].stampDuty;
                value.planSelected.premium.discountAmount = plan.premiums[0].discountAmount;
                value.planSelected.premium.discountPercentage = plan.premiums[0].discountPercentage;
                value.planSelected.premium.grandTotal = plan.premiums[0].grandTotal;
                value.planSelected.premium.duration = plan.premiums[0].duration;
                value.planSelected.premium.startDate = plan.premiums[0].startDate;
                value.planSelected.premium.endDate = plan.premiums[0].endDate;
                value.planSelected.premium.promoCode = plan.premiums[0].promoCode;
                value.planSelected.premium.promoDiscountAmount = plan.premiums[0].promoDiscountAmount;



            } else if (plan.premiums.length) {
                if ($('#duration_'+plan.code).val()) {
                    for (const premium of plan.premiums) {
                        if (premium.duration === parseInt($('#duration_'+plan.code).val())) {    
                            value.planSelected.premium.basicPremium = premium.basicPremium;
                            value.planSelected.premium.additionalBenefitPremium =    premium.additionalBenefitPremium;
                            value.planSelected.premium.duration = premium.duration;
                            value.planSelected.premium.startDate = premium.startDate;
                            value.planSelected.premium.endDate = premium.endDate;
                            value.planSelected.premium.text = premium.text;
                            value.planSelected.premium.grossPremium = premium.grossPremium;
                            value.planSelected.premium.adminFee = premium.adminFee;
                            value.planSelected.premium.stampDuty = premium.stampDuty;
                            value.planSelected.premium.discountAmount = premium.discountAmount;
                            value.planSelected.premium.discountPercentage = premium.discountPercentage;
                            value.planSelected.premium.promoCode = premium.promoCode;
                            value.planSelected.premium.promoDiscountAmount = premium.promoDiscountAmount;
                            break;
                        }
                    }
                }
            }
            setPremiumValue(value);
        }
    }

    const setPromoCodeInputField = async(item, value) => {
        let currentFieldValue = {...premiumValue};
        currentFieldValue["promoCode"] = value;
        setPremiumValue(currentFieldValue);
    }

    const getPromoCode = async() => {
 
        // When empty promo code input, clear plan selection to avoid value cache, and call recalculate function to recalculate grant total
        if(!premiumValue["promoCode"]) {
            setTempPromoCode("");
            getRecalculate(true);
            clearPlanSelected();
            // To disabled mobile radio button
            $('input[type=radio]:checked').prop('checked', false);
            return;
        }

        let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_COMMON_PROMO_CODE, {promoteCode: premiumValue["promoCode"]}, dispatch);
        if(resp.isError) {
            // Empty the Promo Code input
            premiumValue["promoCode"]= "";
            setTempPromoCode("");
        } else{
            // Pop up Success Message when promo code is valid
            if(resp.respData.data) {
                dispatch({ type: 'DISPLAY_INFO', infoMsg: 'Selamat! Kode promosi yang anda masukan telah aktif. Silahkan tekan tombol recalculate dan pilih plan yang anda inginkan.' });
            }
            if (errorMessage) setErrorMessage("");
            setTempPromoCode(resp.respData.data)
        }

        setPremiumValue(premiumValue)
        handlePlanSelectionHide(true);
        setRecalculateFlag(false);
        clearPlanSelected();
    }

    if (width > breakpoint) return (<PremiumPlanDefault 
        respData={respData}
        premiumResp={premiumResp} 
        premiumValue={premiumValue} 
        showSumInsured={showSumInsured}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        nextSlideTransitions={nextSlideTransitions}
        handlePlan={handlePlan}
        selectBasicPremium={(e, plan)=>selectBasicPremium(e, plan)}
        additionalBenefitChecked={(e, additionalBenefit, i) => additionalBenefitChecked(e, additionalBenefit, i)}
        getRecalculate={getRecalculate}
        planOnClick={(plan, i) => planOnClick(plan, i)}
        channel={channel}
        getPromoCode={(item) => getPromoCode(item)}
        setPromoCodeInputField={(item, value) => setPromoCodeInputField(item, value)}
        recalculateFlag={(recalculateFlag)}
        isPlanSelectionHide={isPlanSelectionHide}
    />)
    else return (<PremiumPlanMobile 
        respData={respData}
        premiumResp={premiumResp} 
        premiumValue={premiumValue} 
        showSumInsured={showSumInsured}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        nextSlideTransitions={nextSlideTransitions}
        handlePlan={handlePlan}
        selectBasicPremium={(e, plan)=>selectBasicPremium(e, plan)}
        additionalBenefitChecked={(e, additionalBenefit, i) => additionalBenefitChecked(e, additionalBenefit, i)}
        getRecalculate={getRecalculate}
        planOnClick={(plan, i) => planOnClick(plan, i)}
        channel={channel}
        getPromoCode={(item) => getPromoCode(item)}
        setPromoCodeInputField={(item, value) => setPromoCodeInputField(item, value)}
        recalculateFlag={(recalculateFlag)}
        isPlanSelectionHide={isPlanSelectionHide}
    />)
    
};

export default PremiumPlan;