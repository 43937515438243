import React, { Component } from "react";
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next'; 

import '../App.css';

class InfoMessage extends Component {	
	constructor(props) {
		super(props);
		this.onDismiss = this.onDismiss.bind(this);
	}

	onDismiss = () => {
		this.props.clearInfo();
	}

	render() {
		const { t } = this.props;	
		return (
			<div className="sticky">
				{this.props.infoMsg?
					(<Alert color={this.props.errorType} isOpen={this.props.isVisible} toggle={this.onDismiss}>
					<div className="mb-3" style={{ textAlign:"center",color: 'black' }}>
						{t(this.props.infoMsg)}
					</div>
					</Alert>)
				: ''}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		infoMsg: state.messageReducer.infoMsg,
		errorType: state.messageReducer.errorType,
		isVisible: state.messageReducer.isVisible
  }
}

const mapDispatchToState = (dispatch) => {
    return {
		clearInfo: () => {dispatch({type : 'CLEAR_ERROR'})}
    }
}

export default connect(mapStateToProps, mapDispatchToState)(withTranslation('infomsg')(InfoMessage));