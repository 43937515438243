import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { Post, Get } from '../../../utils/AxFetch';
import * as Constant from '../../../constants/Constant';
import * as urlConstant from '../../../constants/urlConstant';
import { format } from 'date-fns';
import { Card, CardBody, Col, Label, Row, Button } from 'reactstrap';
import ocbcLogo from './../../../images/ocbc.jpg';
import creditCardLogo from './../../../images/creditCard.jpg';
import bankTransferLogo from './../../../images/bankTransfer.jpg';
import otherPaymentLogo from './../../../images/otherPayment.jpg';
import './../../../App.css';

const PaymentSelection = ({
    respData,
    premiumResp,
    premiumValue,
    fields,
    fieldValue,
    nextSlideTransitions,
    encryptedKey,
    tempPromoCode
}) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:' + label);
    }
    const dispatch = useDispatch();
    const [trnxCreated, setTrnxCreated] = useState();
    const [memberAgentCode, setMemberAgentCode] = useState(respData.memberAgentCode)

    const formatProductPageUrl = () => {
        let modifiedKey = Constant.PATH_CERTIFICATE_INSURANCE_B2C + "?key=" + encryptedKey.replaceAll("+", "%2B");
        return modifiedKey;
      }

    const createTrnx = async (paymentType) => {
        let proposer, riskItems, infoItems, plan, proposerFields = {}
        let questionFields = {};
        let insuredItems = [];
        let infoProposer = {};
        let adultInsuredItems = [];
        let childrenInsuredItems = [];

        Object.entries(fieldValue["proposerItems"]).forEach(([key, value]) => {
            if (value.value instanceof Date) {
                let date = format(value.value, Constant.dateFormat_B);
                infoProposer = Object.assign({ [key]: date }, infoProposer);
            } else infoProposer = Object.assign({ [key]: value.value }, infoProposer);
            infoProposer = Object.assign({ identityTyp: "KTP" }, infoProposer)
        });
        proposer = infoProposer;

        if (respData.infoFields.length) {
            let info = {};
            respData.infoFields.forEach(infoField => {
                if (fieldValue["infoItems"][infoField.key]) {
                    info = Object.assign({ [infoField.key]: fieldValue["infoItems"][infoField.key].value }, info);
                }
            });
            infoItems = info;
        }
        if (respData.riskFields.length) {
            let risk = {}
            respData.riskFields.map((riskField) => {
                if (fieldValue["riskItems"][riskField.key]) {
                    if (fieldValue["riskItems"][riskField.key].value instanceof Date) {
                        let date = format(fieldValue["riskItems"][riskField.key].value, Constant.dateFormat_B);
                        return risk = Object.assign( { [riskField.key]: date }, risk );
                    } else return risk = Object.assign( { [riskField.key]: fieldValue["riskItems"][riskField.key].value }, risk );
                } else return true;
            })
            riskItems = risk;
        }

        if (respData.proposerFields.length) {
            let proposer = {}
            respData.proposerFields.map((proposerField) => {
                if (fieldValue["dynamicProposerFields"][proposerField.key]) {
                    return proposer = Object.assign({ [proposerField.key]: fieldValue["dynamicProposerFields"][proposerField.key].value }, proposer);
                } else return true;
            })
            proposerFields = proposer;
        }

        if (respData.insuredFields.length) {
            let insuredArray = [];
            fieldValue["insuredItems"].forEach((insuredItem) => {
                let insured = {};
                for (let key in insuredItem) {
                    if (insuredItem[key].value instanceof Date) {
                        let date = format(insuredItem[key].value, Constant.dateFormat_B);
                        insured = Object.assign({ [key]: date }, insured);
                    } else insured = Object.assign({ [key]: insuredItem[key].value }, insured);
                }
                insuredArray.push(insured);
            });
            insuredItems = insuredArray
        }

        if (respData.insuredFields.length) {
            let insuredArray =[];
            fieldValue["adultInsuredItems"].forEach((insuredItem) => {
                let insured ={};
                let allFieldsEmpty = true

                // Do check if all the input is empty, do not push into array
                for (let key in insuredItem) {
                    if(insuredItem[key].value) allFieldsEmpty = false
                }
                if(!allFieldsEmpty) {
                    for (let key in insuredItem) {
                        if (insuredItem[key].value instanceof Date) {
                            let date = format(insuredItem[key].value, Constant.dateFormat_B);
                            insured = Object.assign( { [key]: date }, insured );
                        } else insured = Object.assign( { [key]: insuredItem[key].value }, insured );
                    }
                    insuredArray.push(insured);
                }
            });
            adultInsuredItems = insuredArray
        }

        if (respData.insuredFields.length) {
            let insuredArray =[];
            fieldValue["childrenInsuredItems"].forEach((insuredItem) => {
                let insured ={};
                let allFieldsEmpty = true

                // Do check if all the input is empty, do not push into array
                for (let key in insuredItem) {
                    if(insuredItem[key].value) allFieldsEmpty = false
                }
                if(!allFieldsEmpty) {
                    for (let key in insuredItem) {
                        if (insuredItem[key].value instanceof Date) {
                            let date = format(insuredItem[key].value, Constant.dateFormat_B);
                            insured = Object.assign( { [key]: date }, insured );
                        } else insured = Object.assign( { [key]: insuredItem[key].value }, insured );
                    }
                    insuredArray.push(insured);
                }
            });
            childrenInsuredItems = insuredArray
        }

        if (respData.questionFields.length) {
            let question = {}
            respData.questionFields.map(questionField => {
                if (fieldValue["questionItems"][questionField.key]) {
                    return question = Object.assign({ [questionField.key]: fieldValue["questionItems"][questionField.key].value }, question);
                } else return true;
            })
            questionFields = question;
        }

        let planAdditionalBenefit = [];

        if (premiumValue.additionalBenefitsChecked.length !== 0) {
            premiumValue.additionalBenefitsChecked.map(additionalBenefit => {
                if (additionalBenefit.checked === true)
                    planAdditionalBenefit.push({ code: additionalBenefit.code })
                return true
            })
        }
        plan = { planCode: premiumValue.planSelected.code, additionalBenefits: planAdditionalBenefit }

        if (premiumValue.planSelected.premium.duration) {
            plan = { ...plan, duration: premiumValue.planSelected.premium.duration }
        }

        let url = urlConstant.URL_B2C_TRNX_CREATE;
        if (!trnxCreated) {
            let resp = await Post(urlConstant.URL_BASED + url,
                {
                    productCode: respData.code,
                    proposer: proposer,
                    riskItems: riskItems,
                    infoItems: infoItems,
                    insuredPersons: insuredItems,
                    plan: plan,
                    proposerFields: proposerFields,
                    questionFields: questionFields,
                    disclaimerFields: respData.disclaimerFields,
                    selectedPayment: paymentType,
                    memberAgentCode : memberAgentCode,
                    adultInsuredPersons : adultInsuredItems,
                    childrenInsuredPersons : childrenInsuredItems,
                    promoCode: tempPromoCode
                }, dispatch);

            if (!resp.isError && resp.respData.data) {
                setTrnxCreated(resp.respData.data);
                createPayment(resp.respData.data, paymentType);
            }
        } else {
            createPayment(trnxCreated, paymentType);
        }
    }

    const createPayment = async (trans, paymentType) => {
        let createPaymentResp = await Get(urlConstant.URL_BASED + urlConstant.URL_CREATE_PAYMENT_INT + trans.id + "/" + paymentType, null, dispatch);
        if (!createPaymentResp.isError && createPaymentResp.respData.data && paymentType !== "OCBC") {
            dispatch(push({ pathname: Constant.PATH_PAYMENT_SUBMISSION, state: { paymentResp: createPaymentResp.respData.data } }));
        } else if (!createPaymentResp.isError && createPaymentResp.respData.data && paymentType === "OCBC") {
            let modifiedKey = formatProductPageUrl();
            let paymentResp = {
                encryptedKey: modifiedKey,
                trnxId: trans.id,
                paymentID: createPaymentResp.respData.data.paymentID,
            }
            dispatch(push({ pathname: Constant.PATH_PAYMENT_OCBC, state: { paymentResp: paymentResp } }))
        }
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <div className='d-flex flex-column'>

                    <h4 className='text-center font-weight-bolder'>
                        Silakan pilih metode pembayaran untuk menyelesaikan pembayaran anda
                    </h4>


                    <p className='text-center titleH4 font-weight-bolder'>
                        Please select a payment method for your order
                    </p>
                </div>
                <div>
                    <p className='font-weight-normal mb-0'>Rincian Pesanan</p>
                    <p className='titleH4 mt-0'>Detail Orders</p>
                </div>
                <Card>
                    <CardBody>
                        <div className='container-flex font-weight-normal'>
                            {/* First Column */}
                            <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                                {/* First Row */}
                                <Col xs="10" sm="4" md="4">
                                    <Label>{"Nama Produk"}
                                        <br /><i className='text-danger'> {"Product Name"}</i>
                                    </Label>
                                </Col>
                                <Col xs="12" sm="7" md="7">
                                    <Label>{respData ? respData.code : "-"}</Label><br /><br />
                                </Col>

                                {/* Second Row */}
                                <Col xs="10" sm="4" md="4">
                                    <Label>{"Nama Tertanggung"}
                                        <br /><i className='text-danger'> {"Insured Name"}</i>
                                    </Label>
                                </Col>
                                <Col xs="12" sm="7" md="7">
                                    <Label>{fieldValue ? fieldValue.proposerItems.name.value : "-"}</Label><br /><br />
                                </Col>

                                <Col xs="10" sm="4" md="4">
                                    <Label>{"Periode Pertanggungan"}
                                        <br /><i className='text-danger'> {"Insured Period"}</i>
                                    </Label>
                                </Col>
                                <Col xs="12" sm="7" md="7">
                                    <Label>
                                        {premiumValue.planSelected.premium.duration
                                            ? premiumValue.planSelected.premium.duration+" Bulan"
                                            : "-"}
                                    </Label><br /><br />
                                </Col>

                                <Col xs="10" sm="4" md="4">
                                    <Label>{"Issuance Start Date"}
                                        <br /><i className='text-danger'> {"Issuance Start Date"}</i>
                                    </Label>
                                </Col>
                                <Col xs="12" sm="7" md="7">
                                    <Label>
                                        {premiumValue.planSelected.premium.startDate
                                            ? premiumValue.planSelected.premium.startDate
                                            : "-"}
                                    </Label><br /><br />
                                </Col>
                            </Col>

                            {/* Second Column */}
                            <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                                {/* First Row */}
                                <Col xs="10" sm="4" md="4">
                                    <Label>{"Premi Dasar"}
                                        <br /><i className='text-danger'>{"Basic Premium"}</i>
                                    </Label>
                                </Col>
                                <Col xs="12" sm="7" md="7">
                                    <Label>{premiumValue ? Constant.defaultCurrency+" "+parseInt(premiumValue.planSelected.premium.basicPremium).toLocaleString(Constant.defaultLocale) : "-"}</Label><br /><br />
                                </Col>

                                {/* Second Row - Conditionally Render */}
                                {premiumValue && premiumValue.planSelected.premium.discountAmount !== 0 && (
                                    <>
                                        <Col xs="10" sm="4" md="4">
                                            <Label>{"Diskon"}
                                                <br /><i className='text-danger'>{"Discount"}</i>
                                            </Label>
                                        </Col>
                                        <Col xs="12" sm="7" md="7">
                                            <Label>{premiumValue ? Constant.defaultCurrency+" "+parseInt(premiumValue.planSelected.premium.discountAmount).toLocaleString(Constant.defaultLocale) : "-"}</Label><br /><br />
                                        </Col>
                                    </>
                                )}

                                {/* Third Row - Conditionally Render */}
                                {premiumValue && premiumValue.planSelected.premium.adminFee !== 0 && (
                                    <>
                                        <Col xs="10" sm="4" md="4">
                                            <Label>{"Biaya Admin"}
                                                <br /><i className='text-danger'>{"Admin Fee"}</i>
                                            </Label>
                                        </Col>
                                        <Col xs="12" sm="7" md="7">
                                            <Label>{premiumValue ? Constant.defaultCurrency+" "+parseInt(premiumValue.planSelected.premium.adminFee).toLocaleString(Constant.defaultLocale) : "-"}</Label><br /><br />
                                        </Col>
                                    </>
                                )}

                                {/* Fourth Row - Conditionally Render */}
                                {premiumValue &&  premiumValue.planSelected.premium.stampDuty !== 0 && (
                                    <>
                                        <Col xs="10" sm="4" md="4">
                                            <Label>{"Biaya Meterai"}
                                                <br /><i className='text-danger'>{"Stamp Duty Fee"}</i>
                                            </Label>
                                        </Col>
                                        <Col xs="12" sm="7" md="7">
                                            <Label>{premiumValue ? Constant.defaultCurrency+" "+parseInt(premiumValue.planSelected.premium.stampDuty).toLocaleString(Constant.defaultLocale) : "-"}</Label><br /><br />
                                        </Col>
                                    </>
                                )}

                                <Col xs="10" sm="4" md="4">
                                    <Label>{"Total Premi"}
                                        <br /><i className='text-danger'>{"Total Premium"}</i>
                                    </Label>
                                </Col>
                                <Col xs="12" sm="7" md="7">
                                    <Label>{premiumValue ? Constant.defaultCurrency+" "+parseInt(premiumValue.planSelected.premium.grandTotal).toLocaleString(Constant.defaultLocale) : "-"}</Label><br /><br />
                                </Col>

                                <Col xs="10" sm="4" md="4">
                                    <Label>{"Issuance End Date"}
                                        <br /><i className='text-danger'> {"Issuance End Date"}</i>
                                    </Label>
                                </Col>
                                <Col xs="12" sm="7" md="7">
                                    <Label>
                                        {premiumValue.planSelected.premium.endDate
                                            ? premiumValue.planSelected.premium.endDate
                                            : "-"}
                                    </Label><br /><br />
                                </Col>
                            </Col>
                        </div>
                    </CardBody>
                </Card>

            </div>
            <div className='card-footer bg-white align-self-center custom-background-color'>
                <div className="card-deck">
                    {respData.paymentMethods.map((method, key) =>
                        method.key === "OCBC" ?
                            <div key={key} className="hover-overlay card custom-card" onClick={() => createTrnx(method.key)}>
                                <img className="image-overlay img-fluid h-auto" src={ocbcLogo} alt="OCBC Logo" />
                                <div className="card-body custom-background-color">
                                    <h5 className="card-title font-weight-normal text-center">OCBC</h5>
                                    {/* Other content specific to OCBC payment */}
                                </div>
                            </div>
                            : method.key === "DOKU" ?
                                <>
                                    <div className="hover-overlay card custom-card" onClick={() => createTrnx(method.key)}>
                                        <img className="image-overlay img-fluid h-auto" src={creditCardLogo} alt="Credit Card Logo" />
                                        <div className="card-body custom-background-color">
                                            <h5 className="card-title font-weight-normal text-center">Credit Card</h5>
                                            {/* Other content specific to Credit Card payment */}
                                        </div>
                                    </div>
                                    <div className="hover-overlay card custom-card" onClick={() => createTrnx(method.key)}>
                                        <img className="image-overlay img-fluid h-auto" src={bankTransferLogo} alt="Bank Transfer Logo" />
                                        <div className="card-body custom-background-color">
                                            <h5 className="card-title font-weight-normal text-center">Bank Transfer</h5>
                                            {/* Other content specific to Bank Transfer payment */}
                                        </div>
                                    </div>
                                    <div className="hover-overlay card custom-card" onClick={() => createTrnx(method.key)}>
                                        <img className="image-overlay img-fluid h-auto" src={otherPaymentLogo} alt="Other Payment Logo" />
                                        <div className="card-body custom-background-color">
                                            <h5 className="card-title font-weight-normal text-center">Others</h5>
                                            <p className='text-danger text-center'>Others Payment Method</p>
                                            {/* Other content specific to Others payment */}
                                        </div>
                                    </div>
                                </>
                                :
                                ""
                    )}
                </div>
            </div>
            <Row className="mt-3 mb-3">
                <Col>
                    <center>
                        <Button onClick={() => { nextSlideTransitions(false); window.scrollTo(0, 0); }}>{t('button.previous')}</Button><> </>
                    </center>
                </Col>
            </Row>
        </div>
    )
}

export default PaymentSelection;