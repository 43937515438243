import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Checkbox = ({data, changeCheck, errorMessage}) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }

    const validateError = () => {
        if(data.isRequired && !data.checked && errorMessage) {
            return true;
        }
        return false;
    }

    return (
        <div className="form-check mb-3 d-flex flex-row">
            <input onChange={() => changeCheck()} className="form-check-input mt-2 checkbox" type="checkbox" checked={data.checked} />
            {
                data.isRequired === true ?
                    <div className='pl-2'>
                        <label className="form-check-label required label_id" dangerouslySetInnerHTML={{__html:data.axLabel.axLabelId}} />
                        <br/> 
                        <label className="form-check-label required label_en" dangerouslySetInnerHTML={{__html:data.axLabel.axLabelEn}} />
                        {validateError() ? <p className='error' id={"Mandatory"+data.key}>{t('error.message.disclaimer.unchecked', {0:data.key})}</p> : ""}
                    </div>
                    
                :
                <div className='pl-2'>
                    <label className="form-check-label" dangerouslySetInnerHTML={{__html:data.axLabel.axLabelId}} />
                    <br/>
                    <label className="form-check-label" dangerouslySetInnerHTML={{__html:data.axLabel.axLabelEn}} />
                </div>
            }
        </div>
    )
}

export default Checkbox;