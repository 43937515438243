import React, { useEffect, useCallback, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Get } from '../utils/AxFetch';
import * as urlConstant from './../constants/urlConstant';
import * as Constant from '../constants/Constant';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const reviewVaPage = (props) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({});
  const getPaymentDetails = useCallback(async () => {
    let paymentID = props.location.state.paymentResp.paymentID;
    let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_GET_PAYMENT_INT + paymentID, null, dispatch);
    if (!resp.isError && resp.respData.data) {
      setResponse({
        vaNumber: resp.respData.data.vaNumber,
        expiredDateTime: resp.respData.data.expiredDateTime,
        transactionId: resp.respData.data.transactionId,
        totalPremium: resp.respData.data.totalPremium,
      })
    }
  }, [dispatch]
  )

  const getProductPageUrl = () => {
    let encryptedKey = props.location.state.paymentResp.encryptedKey;
    dispatch(push(encryptedKey))
  }

  const buttonStyle = {
    width: '60vw', // Set the width to 60% of the viewport width
  };

  useEffect(() => { { getPaymentDetails() } }, []);

  return (
    <Container className="mt-5 pt-5 ">
      {/* Content Row */}
      <Row className="text-center">
        <Col xs={12} className="text-success">
          <h6>Nombor Virtual Account</h6>
          <h1 className="mb-5">{response.vaNumber? response.vaNumber:"-"}</h1>
        </Col>
      </Row>

      <Row className="text-center">
        <Col xs={12}>
          <table className="table">
            <tbody>
              <tr>
                <td>Bank</td>
                <td className="text-danger"> <h4>OCBC NISP</h4></td>
              </tr>
              <tr>
                <td>Jumlah Tagihan</td>
                <td className="text-danger"><h4> {Constant.generalRupiahCurrecy}{response.totalPremium? response.totalPremium:"-"}</h4></td>
              </tr>
              <tr>
                <td>Expired date time</td>
                <td>{response.expiredDateTime? response.expiredDateTime:"-"}</td>
              </tr>
              <tr>
                <td>ID Transaksi</td>
                <td>{response.transactionId? response.transactionId:"-"}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      <Row className="text-center mx-auto">
        <Col xs={12}>
          <div>
            <button onClick={() => getProductPageUrl()} type="button" className="btn btn-danger" style={buttonStyle}>
              Close the Page
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default reviewVaPage;
