import React, { useEffect, useState } from "react";
import OcbcChildPage from './OcbcPageScreen'

const OcbcPage = () => {
  const [jqueryLoaded, setJqueryLoaded] = useState(false);

  useEffect(() => {
    const scriptUrls = [
      "/ocbc/scripts/jquery-3.6.4.min.js",
      "/ocbc/scripts/popper.min.js",
      "/ocbc/scripts/bootstrap.min.js",
      // Add more script URLs as needed
    ];

    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = url;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        await Promise.all(scriptUrls.map(url => loadScript(url)));
		setJqueryLoaded(true)
   
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    loadScripts();

    // Cleanup function
    return () => {
      scriptUrls.forEach(url => {
        const script = document.querySelector(`script[src="${url}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  const htmlContent = `
  <!doctype html>
  <html lang="en">
  
  <head>
	  <title>Great Eastern General Insurance Indonesia</title>
	  <link rel="icon" type="image/x-icon" href="/ocbc/Resources/favicon.ico">
	  <meta charset="utf-8">
	  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  
	  <!-- Bootstrap CSS v5.2.1 -->
	  <link rel="stylesheet" href="/ocbc/css/bootstrap.min.css">
	  <link rel="stylesheet" href="/ocbc/css/style.css">
	  <link rel="stylesheet" href="/ocbc/css/Public_Sans/PublicSans-Regular.ttf">
	  <link rel="stylesheet" href="/ocbc/css/Public_Sans/PublicSans-SemiBold.ttf">
  </head>
  
  <body>
	  <header>
		  <nav class="container blur mr-3 ml-3">
			  <img class="logo-header" src="/ocbc/Resources/GE_Master_Logo_FC-HighRes.png">
			  <!--div class="flag-wrapper">
				  <img class="flag-img flag-img-margin" src="/ocbc/Resources/01-Main_Page/indonesia.svg" onclick="switchLanguage('ID');">
				  <img class="flag-img" src="/ocbc/Resources/01-Main_Page/english.svg" onclick="switchLanguage('EN');">
			  </div-->
		  </nav>
	  </header>
  
	  <main>
		  <section class="container blur">
			  <div class="row">
				  <div class="col-lg-4 col-md-6 col-sm-12 card-spacing">
					  <div class="card">
						  <div class="card-body">
							  <img class="card-img" src="/ocbc/Resources/01-Main_Page/home-icon.png">
							  <h5 class="card-title">
								  <div class="ID">Rumah tinggal</div>
								  <div class="EN">Dwelling House / Landed House</div>
							  </h5>
							  <p class="card-text ID">Merupakan produk Asuransi yang memberikan perlindungan untuk bangunan, isi bangunan. Termasuk peralatan elektronik dan furniture di rumah tinggal anda.</p>
							  <p class="card-text EN" style="padding-bottom: 20px">It is an insurance product that provides protection for building, contents. Including electronic equipment and furniture in your home.</p>
							  <a onclick="modalRumah()" href="#" class="stretched-link"></a>
						  </div>
					  </div>
				  </div>
				  <div class="col-lg-4 col-md-6 col-sm-12 card-spacing">
					  <div class="card">
						  <div class="card-body">
							  <img class="card-img" src="/ocbc/Resources/01-Main_Page/apartment-icon.png">
							  <h5 class="card-title">
								  <div class="ID">Apartemen</div>
								  <div class="EN">Apartment</div>
							  </h5>
							  <p class="card-text ID">Merupakan produk Asuransi yang memberikan perlindungan untuk isi dan perabot apartemen, termasuk peralatan elektronik dan furniture di apartemen anda.</p>
							  <p class="card-text EN">It is an insurance product that provides protection for the contents and furniture of your apartment, including electronic equipment and furniture in your apartment.</p>
							  <a onclick="modalApartemen()" href="#" class="stretched-link"></a>
						  </div>
					  </div>
				  </div>
				  <div class="col-lg-4 col-md-6 col-sm-12 card-spacing">
					  <div class="card">
						  <div class="card-body">
							  <img class="card-img" src="/ocbc/Resources/01-Main_Page/shop-icon.png">
							  <h5 class="card-title">
								  <div class="ID">Toko</div>
								  <div class="EN">Shop</div>
							  </h5>
							  <p class="card-text ID" style="padding-bottom: 20px">Merupakan produk Asuransi yang memberikan perlindungan untuk bangunan termasuk isi bangunan/stok toko anda.</p>
							  <p class="card-text EN" style="padding-bottom: 20px">It is an insurance product that provides protection for buildings including the contents of your building/shop stock.</p>
							  <a onclick="modalToko()" href="#" class="stretched-link"></a>
						  </div>
					  </div>
				  </div>
		  
				  <div class="col-lg-4 col-md-6 col-sm-12 card-spacing">
					  <div class="card">
						  <div class="card-body">
							  <img class="card-img" src="/ocbc/Resources/01-Main_Page/restaurant-icon.png">
							  <h5 class="card-title">
								  <div class="ID">Restoran</div>
								  <div class="EN">Restaurant</div>
							  </h5>
							  <p class="card-text ID">Merupakan produk Asuransi yang memberikan perlindungan untuk bangunan, isi bangunan, termasuk peralatan elektronik dan furnitur di restoran anda.</p>
							  <p class="card-text EN">It is an insurance product that provides protection for buildings, building contents, including electronic equipment and furniture in your restaurant.</p>
							  <a onclick="modalRestoran()" href="#" class="stretched-link"></a>
						  </div>
					  </div>
				  </div>
				  <div class="col-lg-4 col-md-6 col-sm-12 card-spacing">
					  <div class="card">
						  <div class="card-body">
							  <img class="card-img" src="/ocbc/Resources/01-Main_Page/office-icon.png">
							  <h5 class="card-title">
								  <div class="ID">Kantor</div>
								  <div class="EN">Office</div>
							  </h5>
							  <p class="card-text ID">Merupakan produk Asuransi yang memberikan perlindungan untuk bangunan, isi bangunan, termasuk peralatan elektronik dan furnitur di gedung perkantoran anda.</p>
							  <p class="card-text EN">It is an insurance product that provides protection for buildings, building contents, including electronic equipment and furniture in your office building.</p>
							  <a onclick="modalKantor()" href="#" class="stretched-link"></a>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
	  </main>
  
	  
	  <footer>
		  <hr>
		  <div class="container blur">
			  <div class="row">
				  <div class="footer-div">
					  <a href="https://www.greateasterngeneral.com/id">
						  <img src="/ocbc/Resources/GE_Master_Logo_FC-HighRes.png" class="logo-footer">
					  </a>
					  <div class="icon-block">
						  <a href="https://www.facebook.com/greateasterngeneral.id">
							  <img src="/ocbc/Resources/01-Main_Page/facebook-icon.png">
						  </a>
						  <a href="https://www.instagram.com/greateasterngeneral.id">
							  <img src="/ocbc/Resources/01-Main_Page/instagram_icon.png">
						  </a>
					  </div>
				  </div>
			  </div>
			  
			  <div class="footer-div">
				  <div class="d-flex flex-column">
					  <p class="gegi-contact ID">Hubungi Kami :</p><p class="gegi-contact EN">Contact Us :</p>
					  <p class="gegi-subtitle ID">Kantor Pusat :</p><p class="gegi-subtitle EN">Head Office :</p>
					  <p class="gegi-item-red">Midplaza 2, 23rd floor</p>
					  <p class="gegi-item-red">Jalan Jenderal Sudirman Kav. 10-11</p>
					  <p class="gegi-item-red">Jakarta 10220, Indonesia</p>
					  <p class="gegi-subtitle ID">Email Layanan Nasabah :</p><p class="gegi-subtitle EN">Customer Care Email :</p>
					  <a class="gegi-item-red" href="mailto:wecare-id@greateasterngeneral.com">Email: wecare-id@greateasterngeneral.com</a>
				  </div>
			  </div>
			  
			  <div class="footer-div">
				  <div class="d-flex flex-column">
					  <p class="gegi-subtitle ID">Akun Resmi WhatsApp :</p><p class="gegi-subtitle EN">Official WhatsApp Account :</p>
					  <a class="gegi-item-red" href="https://wa.me/+6281915723737">WhatsApp: +62 819 1572 3737</a>
					  <p class="gegi-subtitle ID">Nomor Telepon* :</p><p class="gegi-subtitle EN">Phone Number* :</p>
					  <a class="gegi-item-red" href="Tel:+62215723737">+62 21 5723737</a>
					  <p class="gegi-item-notes ID">*Beroperasi pada hari kerja Senin-Jum'at pukul 09:00 - 17:00 WIB.</p>
					  <p class="gegi-item-notes EN">*Operation on working days Mon-Fri 9 AM - 5 PM Jakarta Local Time.</p>
				  </div>
			  </div>
			  
			  <div class="footer-div">
				  <div class="d-flex flex-column">
					  <p class="gegi-contact ID">Ketentuan Layanan 24 Jam</p><p class="gegi-contact EN">24 hours customer care</p>
					  <p class="gegi-service-desc ID">Silakan sampaikan pertanyaan dan pengaduan ke E-mail Layanan Nasabah atau Akun Resmi Whatsapp kami. Pertanyaan dan pengaduan akan kami respon di hari Senin - Jumat, pukul 09.00 - 17.00 WIB.</p>
					  <p class="gegi-service-desc EN">Please inquiry your questions and complains to Customer Care E-Mail Address or our Official WhatsApp Account. We will response your inquiry on Mon-Fri, at 9 AM - 5 PM Jakarta Local Time.</p>
				  </div>
			  </div>
			  
			  <div class="footer-div">
				  <div class="d-flex flex-column">
					  <br><p class="ID">PT. Great Eastern General Insurance Indonesia berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK)</p>
					  <br><p class="EN">PT. Great Eastern General Insurance Indonesia licensed and supervised by Otoritas Jasa Keuangan (OJK)</p>
				  </div>
			  </div>
		  </div>
	  </footer>
  
	  <div class="modal fade" id="modalDisclaimer" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-body">
					  <div class="container" style="text-align: center; margin-bottom: 30px;">
						  <div class="modalDisclaimer-logo">
							  <img src="/ocbc/Resources/GE_Master_Logo_FC-HighRes.png" class="logo-header">
						  </div>
						  
  
						  <div class="modalDisclaimer-txt">
							  <p class="p1">Anda telah keluar dari halaman website Bank OCBC dan saat ini berada di halaman website PT. Great Eastern General Insurance Indonesia.</p>
							  <p class="p2">Segala hal yang ada di dalam website ini tidak menjadi tanggung jawab pihak Bank OCBC</p>
						  </div>
						  
						  <!--div class="modalDisclaimer-txt">
							  <p class="p1"><i>You have left the OCBC Bank website page and are currently at PT. Great Eastern General Insurance Indonesia website page.</i></p>
							  <p class="p2"><i>Everything on this website is not responsibility of OCBC Bank.</i></p>
						  </div-->
						  
						  <div class="modalDisclaimer-txt">
							  <p class="p2">Klik Setuju, untuk melanjutkan.</p>
							  <!--p class="p1"><i>Click Agree, to continue.</i></p-->
						  </div>
  
						  <p></p>
						  <button type="button" class="btn btn-danger btn-border" data-bs-dismiss="modal" aria-label="Close">Setuju<br><!--i>Agree</i--></button>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  
	  <div class="modal fade modalClass" id="modalContactCenter" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"</button>
				  </div>
				  <div class="modal-body">
					  <div class="container" style="text-align: center; margin-bottom: 30px;">
						  <div class="modalContactCenter-logo">
							  <img src="/ocbc/Resources/GE_Master_Logo_FC-HighRes.png" class="logo-header">
						  </div>
					  </div>
					  <div class="container ID" style="text-align: center; margin-bottom: 30px;">
						  <div>
							  <p><h4>Lengkapi Informasi Berikut</h4></p>
						  </div>
					  </div>
					  <div class="container EN" style="text-align: center; margin-bottom: 30px; display: none;">
						  <div>
							  <p><h4>Complete the following information</h4></p>
						  </div>
					  </div>
					  <div class="container">
						  <div class="row">
							  <form class="need-validation" id="formContactCenter" onSubmit="waRedirect();">
								  <div class="form-group row">
									  <div class="col-4">&nbsp;</div>
									  <div class="col-2">
										  <label for="txtName" class="col-form-label ID">Nama*</label>
										  <label for="txtName" class="col-form-label EN" style="display: none;">Name*</label>
									  </div>	
									  <div class="col-3">
										  <input type="text" class="form-control" id="txtName" placeholder="" required>
									  </div>
								  </div>
								  <div class="form-group row">
									  <div class="col-4">&nbsp;</div>
									  <div class="col-2">
										  <label for="txtNoPonsel" class="col-form-label ID">Nomor Ponsel*</label>
										  <label for="txtNoPonsel" class="col-form-label EN" style="display: none;">Mobile Phone Number*</label>
									  </div>
									  <div class="col-1">
										  <select class="form-select" id="listCountry" style="width: 125%">
											  <option value="+62" selected>+62</option>
										  </select>
									  </div>
									  <div class="col-2">
										  <input min="0" type="number" class="form-control" id="txtNoPonsel" placeholder="" required>
									  </div>
								  </div>
								  <div class="form-group row">
									  <div class="col-4">&nbsp;</div>
									  <div class="col-2">
										  <label for="txtEmail" class="col-form-label ID">Alamat Email*</label>
										  <label for="txtEmail" class="col-form-label EN" style="display: none;">Email Address*</label>
									  </div>
									  <div class="col-3"><input type="email" class="form-control" id="txtEmail" placeholder="" required></div>
								  </div>
								  <div class="form-group row">
									  <div class="col-4">&nbsp;</div>
									  <div class="col-2">
										  <label for="txtDate" class="col-form-label ID">Tanggal*</label>
										  <label for="txtDate" class="col-form-label EN" style="display: none;">Date*</label>
									  </div>
									  <div class="col-3"><input type="date" class="form-control" id="txtDate" placeholder="" required></div>
								  </div>
								  <div class="form-group row">
									  <div class="col-4">&nbsp;</div>
									  <div class="col-2">
										  <label for="txtClock" class="col-form-label ID">Jam*</label>
										  <label for="txtClock" class="col-form-label EN" style="display: none;">Time*</label>
									  </div>
									  <div class="col-3">
										  <select class="form-select" id="listClock">
											  <option value="08:00 - 10:00">08:00 - 10:00</option>
											  <option value="10:00 - 12:00">10:00 - 12:00</option>
											  <option value="13:00 - 15:00">13:00 - 15:00</option>
										  </select>
									  </div>
								  </div>
								  <br>
								  <div class="form-group row" align="center">
									  <div class="col-12">
										  <p id="productId" value="" hidden="true"></p>
										  <button id="btnSubmit" type="submit" class="btn btn-danger">
											  <div class="ID">Hubungi Kami</div>
											  <div class="EN" style="display: none;">Contact Us</div>
										  </button>
									  </div>
								  </div>
							  </form>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  
  
	  <div class="modal fade modalClass" id="modalRumah" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				  </div>
				  <div class="modal-body modal-rumah">
					  <div class="container">
						  <div class="row">
							  <div class="col-lg-6 col-md-12 col-sm-12">
								  <h1 class="mt-2 mb-4" id="productRumah" value="Rumah Tinggal">
									  <div class="ID">Rumah Tinggal</div>
									  <div class="EN">Dwelling House / Landed House</div>
								  </h1>
								  <div class="modal-div-gap">
									  <p class="ID">Merupakan produk Asuransi yang memberikan perlindungan untuk bangunan, isi bangunan, termasuk peralatan dan elektronik di rumah tinggal Anda.</p>
									  <p class="EN">It is an insurance product that provides protection for building, contents. Including electronic equipment and furniture in your home.</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="ID">Perlindungan ini tidak berlaku untuk kos-kosan.</p>
									  <p class="EN">This protection does not apply to boarding houses.</p>
								  </div>
								  <div class="modal-div-gap ID">
									  <p class="semibold ID">Manfaat Asuransi</p>
									  <p class="semibold EN">Insurance Benefits</p>
									  <p class="ID">Menjamin kerusakan harta benda yang diasuransikan yang terjadi akibat sebab apapun kecuali yang disebutkan dalam pengecualian polis selama masa asuransi dan polis masih berlaku.</p>
									  <p class="EN">Covers damage to insured property that occurs due to any cause except those stated in the policy exceptions during the insurance period and the policy is still in force.</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Jaminan perluasan otomatis ( tanpa premi tambahan ):</p>
									  <p class="semibold EN">Automatic additional coverage (no additional premium):</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tanggung gugat hukum pribadi, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Personal legal liability, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Uang dalam penyimpanan, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Money in safe, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya akomodasi sementara, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Temporary accommodation costs, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Pembersihan puing, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Removal debris, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya arsitek, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Architect's fee, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya persiapan klaim, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Claim preparation costs, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya pemadaman kebakaran, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Fire extinguishing costs, maximum IDR 10 million.</p></div>
									  </div>
								  </div>
								  <div class="button-unduh-wrapper">
									  <a href="/ocbc/Documents/Brosur-Asuransi_Semua_Resiko_Industri_ID-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											  <span class="EN"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Product Brochure</span>
										  </button>
									  </a>
									  <a href="/ocbc/Documents/RIPLAY_Umum-IAR-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											  <span class="EN"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Download general RIPLAY</span>
										  </button>
									  </a>
								  </div>
								  <br>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <p class="title semibold ID">Risiko yang bisa diasuransikan</p>
									  <p class="title semibold EN">Terms and conditions</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Konstruksi bangunan kelas 1 ( konstruksi beton atau baja ).</p></div>
										  <div class="EN"><p>Class 1 building construction (concrete or steel construction).</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tidak berada di area banjir.</p></div>
										  <div class="EN"><p>Not in a flood area.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Nilai pertanggungan maksimum IDR 5 Milyar, apabila nilai pertanggungan diatas IDR 5 Milyar, harap menghubungi kontak GEGI.</p></div>
										  <div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <div class="d-flex">
										  <div class="ID"><p>Untuk mendapatkan penjelasan produk secara langsung, silahkan klik Hubungi Kami.</p></div>
										  <div class="EN"><p>To get a direct product explanation, please click Contact Us.</p></div>
									  </div>
								  </div>
								  <div class="btn-beli-wrapper">
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(1);"><div class="ID">Hubungi Kami</div><div class="EN">Contact Us</div></button>
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(1);"><div class="ID">Beli sekarang</div><div class="EN">Buy Now</div></button>
								  </div>
							  </div>
							  <div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								  <img class="modal-content-img" src="/ocbc/Resources/02-Detail/Home-Insurance.jpg" loading="lazy">
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  
	  <div class="modal fade modalClass" id="modalApartemen" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"</button>
				  </div>
				  <div class="modal-body modal-apartment">
					  <div class="container">
						  <div class="row">
							  <div class="col-lg-6 col-md-12 col-sm-12">
								  <h1 class="mt-2 mb-4" id="productApartemen" value="Apartemen">
									  <div class="ID">Apartemen</div>
									  <div class="EN">Apartment</div>
								  </h1>
								  <div class="modal-div-gap">
									  <p class="ID">Merupakan produk Asuransi yang memberikan perlindungan untuk isi dan perabot apartemen, termasuk peralatan elektronik dan furniture di apartemen Anda.</p>
									  <p class="EN">It is an insurance product that provides protection for buildings, contents, including equipment and electronics in your home.</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Manfaat Asuransi</p>
									  <p class="semibold EN">Insurance Benefits</p>
									  <p class="ID">Menjamin kerusakan harta benda yang diasuransikan yang terjadi akibat sebab apapun kecuali yang disebutkan dalam pengecualian polis selama masa asuransi dan polis masih berlaku.</p>
									  <p class="EN">Covers damage to the insured property arising from any cause except those mentioned in the policy exclusions during the insurance period and the policy is still in force.</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Jaminan perluasan otomatis ( tanpa premi tambahan ):</p>
									  <p class="semibold EN">Automatic additional coverage (without additional premium):</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tanggung gugat hukum pribadi, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Personal legal liability, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Uang dalam penyimpanan, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Money in safe, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya akomodasi sementara, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Temporary accommodation expenses, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Pembersihan puing, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Removal debris, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya arsitek, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Architect fees, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya persiapan klaim, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Claim preparation fee, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya pemadaman kebakaran, maksimum IDR 10juta.</p></div>
										  <div class="EN"><p>Fire extinguishing costs, maximum IDR 10 million.</p></div>
									  </div>
								  </div>
								  <div class="button-unduh-wrapper">
									  <a href="/ocbc/Documents/Brosur-Asuransi_Semua_Resiko_Industri_ID-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											  <span class="EN"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Product Brochure</span>
										  </button>
									  </a>
									  <a href="/ocbc/Documents/RIPLAY_Umum-IAR-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											  <span class="EN"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Download general RIPLAY</span>
										  </button>
									  </a>
								  </div>
								  <br>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <p class="title semibold ID">Risiko yang bisa diasuransikan</p>
									  <p class="title semibold EN">Terms and conditions</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Nilai pertanggungan maksimum IDR 5 Milyar, apabila nilai pertanggungan diatas IDR 5 Milyar, harap menghubungi kontak GEGI.</p></div>
										  <div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <div class="d-flex">
										  <div class="ID"><p>Untuk mendapatkan penjelasan produk secara langsung, silahkan klik Hubungi Kami.</p></div>
										  <div class="EN"><p>To get a direct product explanation, please click Contact Us.</p></div>
									  </div>
								  </div>
								  <div class="btn-beli-wrapper">
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(2);"><div class="ID">Hubungi Kami</div><div class="EN">Contact Us</div></button>
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(2);"><div class="ID">Beli sekarang</div><div class="EN">Buy Now</div></button>
								  </div>
							  </div>
							  <div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								  <img class="modal-content-img" src="/ocbc/Resources/02-Detail/Apartment-Insurance.jpg" loading="lazy">
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  
	  <div class="modal fade modalClass" id="modalToko" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"button>
				  </div>
				  <div class="modal-body modal-apartment">
					  <div class="container">
						  <div class="row">
							  <div class="col-lg-6 col-md-12 col-sm-12">
								  <h1 class="mt-2 mb-4" id="productToko" value="Toko">
									  <div class="ID">Toko</div>
									  <div class="EN">Shop</div>
								  </h1>
								  <div class="modal-div-gap">
									  <p class="ID">Merupakan produk Asuransi yang memberikan perlindungan untuk bangunan termasuk isi bangunan/stok toko anda.</p>
									  <p class="EN">It is an insurance product that provides protection for buildings including the contents of your building/store stock.</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Manfaat Asuransi</p>
									  <p class="semibold EN">Insurance Benefits</p>
									  <p class="ID">Menjamin kerusakan harta benda yang diasuransikan yang terjadi akibat sebab apapun kecuali yang disebutkan dalam pengecualian polis selama masa asuransi dan polis masih berlaku.</p>
									  <p class="EN">Covers damage to insured property that occurs due to any cause except those stated in the policy exceptions during the insurance period and the policy is still in force.</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Jaminan perluasan otomatis ( tanpa premi tambahan ):</p>
									  <p class="semibold EN">Automatic additional coverage (without additional premium):</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Uang dalam penyimpanan, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Money in safe, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Pembersihan puing, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Removal debris, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya arsitek, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Architect fees, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya persiapan klaim, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Claim preparation fee, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya pemadaman kebakaran, maksimum IDR 10juta.</p></div>
										  <div class="EN"><p>Fire extinguishing costs, maximum IDR 10 million.</p></div>
									  </div>
								  </div>
								  <div class="button-unduh-wrapper">
									  <a href="/ocbc/Documents/Brosur-Asuransi_Semua_Resiko_Industri_ID-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											  <span class="EN"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Product Brochure</span>
										  </button>
									  </a>
									  <a href="/ocbc/Documents/RIPLAY_Umum-IAR-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											  <span class="EN"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Download general RIPLAY</span>
										  </button>
									  </a>
								  </div>
								  <br>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <p class="title semibold ID">Risiko yang bisa diasuransikan</p>
									  <p class="title semibold EN">Terms and conditions</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Konstruksi bangunan kelas 1 ( konstruksi beton atau baja ).</p></div>
										  <div class="EN"><p>Class 1 building construction (concrete or steel construction).</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tidak berada di area banjir.</p></div>
										  <div class="EN"><p>Not in a flood area.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Bukan yang berada di dalam Mall, bukan toko grosir, bukan toko yang berada di dalam pasar tradisional.</p></div>
										  <div class="EN"><p>Not inside Malls, not grocery stores, not shops in traditional markets.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Nilai pertanggungan maksimum IDR 5 Milyar, apabila nilai pertanggungan diatas IDR 5 Milyar, harap menghubungi kontak GEGI.</p></div>
										  <div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <div class="d-flex">
										  <div class="ID"><p>Untuk mendapatkan penjelasan produk secara langsung, silahkan klik Hubungi Kami.</p></div>
										  <div class="EN"><p>To get a direct product explanation, please click Contact Us.</p></div>
									  </div>
								  </div>
								  <div class="btn-beli-wrapper">
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(3);"><div class="ID">Hubungi Kami</div><div class="EN">Contact Us</div></button>
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(3);"><div class="ID">Beli sekarang</div><div class="EN">Buy Now</div></button>
								  </div>
							  </div>
							  <div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								  <img class="modal-content-img" src="/ocbc/Resources/02-Detail/Shop-Insurance.jpg" loading="lazy">
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  
	  <div class="modal fade modalClass" id="modalRestoran" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"button>
				  </div>
				  <div class="modal-body modal-apartment">
					  <div class="container">
						  <div class="row">
							  <div class="col-lg-6 col-md-12 col-sm-12">
								  <h1 class="mt-2 mb-4" id="productRestoran" value="Restoran">
									  <div class="ID">Restoran</div>
									  <div class="EN">Restaurant</div>
								  </h1>
								  <div class="modal-div-gap">
									  <p></p>
									  <p class="ID">Merupakan produk Asuransi yang memberikan perlindungan untuk bangunan, isi bangunan, termasuk peralatan elektronik dan furnitur di restoran anda.</p>
									  <p class="EN">It is an insurance product that provides protection for buildings, contents, including electronic equipment and furniture in your restaurant.</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Manfaat Asuransi</p>
									  <p class="semibold EN">Insurance Benefits</p>
									  <p class="ID">Menjamin kerusakan harta benda yang diasuransikan yang terjadi akibat sebab apapun kecuali yang disebutkan dalam pengecualian polis selama masa asuransi dan polis masih berlaku.</p>
									  <p class="EN">Covers damage to insured property that occurs due to any cause except those stated in the policy exceptions during the insurance period and the policy is still in force.</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Jaminan perluasan otomatis ( tanpa premi tambahan ):</p>
									  <p class="semibold EN">Automatic additional coverage (without additional premium):</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Uang dalam penyimpanan, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Money in safe, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Pembersihan puing, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Removal debris, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya arsitek, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Architect fees, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya persiapan klaim, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Claim preparation fee, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya pemadaman kebakaran, maksimum IDR 10juta.</p></div>
										  <div class="EN"><p>Fire extinguishing costs, maximum IDR 10 million.</p></div>
									  </div>
								  </div>
								  <div class="button-unduh-wrapper">
									  <a href="/ocbc/Documents/Brosur-Asuransi_Semua_Resiko_Industri_ID-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											  <span class="EN"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Product Brochure</span>
										  </button>
									  </a>
									  <a href="/ocbc/Documents/RIPLAY_Umum-IAR-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											  <span class="EN"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Download general RIPLAY</span>
										  </button>
									  </a>
								  </div>
								  <br>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <p class="title semibold ID">Risiko yang bisa diasuransikan</p>
									  <p class="title semibold EN">Terms and conditions</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Konstruksi bangunan kelas 1 ( konstruksi beton atau baja ).</p></div>
										  <div class="EN"><p>Class 1 building construction (concrete or steel construction).</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tidak berada di area banjir.</p></div>
										  <div class="EN"><p>Not in a flood area.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Nilai pertanggungan maksimum IDR 5 Milyar, apabila nilai pertanggungan diatas IDR 5 Milyar, harap menghubungi kontak GEGI.</p></div>
										  <div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <div class="d-flex">
										  <div class="ID"><p>Untuk mendapatkan penjelasan produk secara langsung, silahkan klik Hubungi Kami.</p></div>
										  <div class="EN"><p>To get a direct product explanation, please click Contact Us.</p></div>
									  </div>
								  </div>
								  <div class="btn-beli-wrapper">
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(4);"><div class="ID">Hubungi Kami</div><div class="EN">Contact Us</div></button>
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(4);"><div class="ID">Beli sekarang</div><div class="EN">Buy Now</div></button>
								  </div>
							  </div>
							  <div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								  <img class="modal-content-img" src="/ocbc/Resources/02-Detail/Restaurant-Insurance.jpg" loading="lazy">
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  
	  <div class="modal fade modalClass" id="modalKantor" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			  <div class="modal-content">
				  <div class="modal-header">
					  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				  </div>
				  <div class="modal-body modal-apartment">
					  <div class="container">
						  <div class="row">
							  <div class="col-lg-6 col-md-12 col-sm-12">
								  <h1 class="mt-2 mb-4" id="productKantor" value="Kantor">
									  <div class="ID">Kantor</div>
									  <div class="EN">Office</div>
								  </h1>
								  <div class="modal-div-gap">
									  <p class="ID">Merupakan produk Asuransi yang memberikan perlindungan untuk bangunan, isi bangunan, termasuk peralatan elektronik dan furnitur di gedung perkantoran anda.</p>
									  <p class="EN">It is an insurance product that provides protection for buildings, contents, including electronic equipment and furniture in your office building.</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Manfaat Asuransi</p>
									  <p class="semibold EN">Insurance Benefits</p>
									  <p class="ID">Menjamin kerusakan harta benda yang diasuransikan yang terjadi akibat sebab apapun kecuali yang disebutkan dalam pengecualian polis selama masa asuransi dan polis masih berlaku.</p>
									  <p class="EN">Covers damage to insured property that occurs due to any cause except those stated in the policy exceptions during the insurance period and the policy is still in force.</p>
								  </div>
								  <div class="modal-div-gap">
									  <p class="semibold ID">Jaminan perluasan otomatis ( tanpa premi tambahan ):</p>
									  <p class="semibold EN">Automatic additional coverage (without additional premium):</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Uang dalam penyimpanan, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Money in safe, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Pembersihan puing, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Removal debris, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya arsitek, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Architect fees, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya persiapan klaim, maksimum IDR 10 juta.</p></div>
										  <div class="EN"><p>Claim preparation fee, maximum IDR 10 million.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Biaya pemadaman kebakaran, maksimum IDR 10juta.</p></div>
										  <div class="EN"><p>Fire extinguishing costs, maximum IDR 10 million.</p></div>
									  </div>
								  </div>
								  <div class="button-unduh-wrapper">
									  <a href="/ocbc/Documents/Brosur-Asuransi_Semua_Resiko_Industri_ID-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											  <span class="EN"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Product Brochure</span>
										  </button>
									  </a>
									  <a href="/ocbc/Documents/RIPLAY_Umum-IAR-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										  <button>
											  <span class="ID"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											  <span class="EN"><img src="/ocbc/Resources/02-Detail/product-summary-icon.svg">Download general RIPLAY</span>
										  </button>
									  </a>
								  </div>
								  <br>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <p class="title semibold ID">Risiko yang bisa diasuransikan</p>
									  <p class="title semibold EN">Terms and conditions</p>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Konstruksi bangunan kelas 1 ( konstruksi beton atau baja ).</p></div>
										  <div class="EN"><p>Class 1 building construction (concrete or steel construction).</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Tidak berada di area banjir.</p></div>
										  <div class="EN"><p>Not in a flood area.</p></div>
									  </div>
									  <div class="d-flex">
										  <div><p>-&nbsp;</p></div>
										  <div class="ID"><p>Nilai pertanggungan maksimum IDR 5 Milyar, apabila nilai pertanggungan diatas IDR 5 Milyar, harap menghubungi kontak GEGI.</p></div>
										  <div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									  </div>
								  </div>
								  <div class="modal-div-gap terms-div" style="padding: 15px;">
									  <div class="d-flex">
										  <div class="ID"><p>Untuk mendapatkan penjelasan produk secara langsung, silahkan klik Hubungi Kami.</p></div>
										  <div class="EN"><p>To get a direct product explanation, please click Contact Us.</p></div>
									  </div>
								  </div>
								  <div class="btn-beli-wrapper">
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(5);"><div class="ID">Hubungi Kami</div><div class="EN">Contact Us</div></button>
									  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(5);"><div class="ID">Beli sekarang</div><div class="EN">Buy Now</div></button>
								  </div>
							  </div>
							  <div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								  <img class="modal-content-img" src="/ocbc/Resources/02-Detail/Office-Insurance.jpg" loading="lazy">
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  
	  <script src="scripts/jquery-3.6.4.min.js"></script>
	  <script src="scripts/popper.min.js"></script>
	  <script src="scripts/bootstrap.min.js"></script>
	  <script src="scripts/page.js"></script>
  </body>
  
  </html>`;

  return jqueryLoaded ? (
    <OcbcChildPage html={htmlContent}/>
  ) : (
	null
);
};

export default OcbcPage;
