import React from 'react';

const MockPaymentRedirect = () => {
  return  (
      <div>
        <form action="http://localhost:8080/affinityExchange/rws/payment/int/redirectpayment" method="POST" >
          <label>AMOUNT: </label><input className="ml-2" name="AMOUNT" defaultValue="25000"/><br/>
          <label>TRANSIDMERCHANT: </label><input className="ml-2" name="TRANSIDMERCHANT" defaultValue="40b1b17ac4ef4be3b7afa936cd3ab8"/><br/>
          <label>WORDS: </label><input className="ml-2" name="WORDS" defaultValue="bc4873840023bf27899a55dbfe548e8b83a046c0"/><br/>
          <label>STATUSCODE: </label><input className="ml-2" name="STATUSCODE" defaultValue="0000"/><br/>
          <label>PAYMENTCHANNEL: </label><input className="ml-2" name="PAYMENTCHANNEL" defaultValue="04"/><br/>
          <label>SESSIONID: </label><input className="ml-2" name="SESSIONID" defaultValue="7f8d3d3204b34b0cb503f76d62f447"/><br/>
          <label>PAYMENTCODE: </label><input className="ml-2" name="PAYMENTCODE" defaultValue="PAYC123456789"/><br/>
          <label>CURRENCY: </label><input className="ml-2" name="CURRENCY" defaultValue="458"/><br/>
          <label>PURCHASECURRENCY: </label><input className="ml-2" name="PURCHASECURRENCY" defaultValue="458"/><br/>
          <input type="submit" value="Submit"/><br/>
        </form>
      </div>
    )
}

export default MockPaymentRedirect;