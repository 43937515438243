import React, { useState }from 'react';
import { Button, Col, Row, Collapse, Label, CardBody, Card} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ConfirmationProduct = ({ respData }) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const [panelOpen, setPanelOpen] = useState(true);

    const ProductToggle = () => setPanelOpen(!panelOpen);
   
    if (respData) return (
        <div>
            <Row className="mt-3"><Col><h5>{t('productinfo')}</h5></Col></Row>
            <Row><Col><h6><i>{t2('productinfo')}</i></h6></Col></Row>
            <Button className={"accordion-toggle mt-3 " + (panelOpen ? "collapse-icon" : "expand-icon")} 
                    onClick={e => ProductToggle(e)}>{t('productinfo')}<br></br><i>{t2('productinfo')}</i>
            </Button>
            <Collapse isOpen={panelOpen}>
            <Card><CardBody><div className="container-flex">
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("infoproductcode")}<br></br><i>{t2('infoproductcode')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{respData.code}</Label>
                    </Col>
                </Col>
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("productdescription")}<br></br><i>{t2('productdescription')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{respData.desc}</Label>
                    </Col>
                </Col>
            </div></CardBody></Card>
            </Collapse>
        </div>
    )
    else return "";
};

export default ConfirmationProduct;