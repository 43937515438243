import React from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ConfirmationProposerInfo from './ConfirmationProposerInfo';
import ConfirmationProposerInsPer from './ConfirmationProposerInsPer';
import ConfirmationProposerRisk from './ConfirmationProposerRisk';
import ConfirmationProposerInsPerAdultChildren from './ConfirmationProposerInsPerAdultChildren';

const ConfirmationProposer = ({ respData, field, fieldValue, adultInsuredItems, childrenInsuredItems}) => {
    const { t } = useTranslation();

    return (
        <div>       
            <Row className="mt-3"><Col><h5>{t('proposal')}</h5></Col></Row>
            <Row><Col><h6><i>{t('en_translations:proposal')}</i></h6></Col></Row>
            <ConfirmationProposerInfo respData={respData} field={field} fieldValue={fieldValue}/>
            <ConfirmationProposerRisk respData={respData} field={field} fieldValue={fieldValue}/>
            {fieldValue.insuredItems && fieldValue.insuredItems.length > 0 && (
                <ConfirmationProposerInsPer respData={respData} fieldValue={fieldValue} />
            )}
            {fieldValue.adultInsuredItems && fieldValue.adultInsuredItems.length >0 && (
                <ConfirmationProposerInsPerAdultChildren respData={respData} fieldValue={fieldValue} type={"adultInsuredItems"} label={"adult"} noOfInsured={adultInsuredItems}/>
            )}

            {fieldValue.childrenInsuredItems && fieldValue.childrenInsuredItems.length >0 && (
                <ConfirmationProposerInsPerAdultChildren respData={respData} fieldValue={fieldValue} type={"childrenInsuredItems"} label={"child"} noOfInsured={childrenInsuredItems}/>
            )}
        </div>
    )
};

export default ConfirmationProposer;