import React, { Component } from "react";
import {Label, Input, Button, Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import loginAction from '../../actions/loginAction';
import "../../containers/common/Login.css";
import companyLogo from '../../images/gel-logo.png';
import * as Constant from './../../constants/Constant';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import * as Validate from './../../utils/CommonValidate';

const initialState = {
  username: "",
  password: "",
  usernameError: "",
  passwordError: "",
  isOtp: false,
  isModalOpen: false,
  otpno: "",
  otpnoError: ""
}

class Login extends Component {  
  constructor(props) {
    super(props);
    this.props.initLogin();
    this.state = initialState;
  }

  validateForm() {
    let usernameError = "";
    let passwordError = "";

    if (!this.state.username) {
      usernameError = "Username cannot be blank";
    }

    if (!this.state.password) {
      passwordError = "Password cannot be blank";
    }

    if (usernameError || passwordError) {
      this.setState({ usernameError, passwordError})
      return false;
    }
    return true;
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const isValid = this.validateForm();
    if (isValid) {
      this.props.handleLogin(this.state.username, this.state.password);
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.isOtp !== this.props.isOtp) {
      this.setState({isOtp: nextProps.isOtp, isModalOpen:nextProps.isOtp});
    }
  }
  
  goToForgot = () => {
    this.props.goToForgot()
  }

  handleCloseNow = () => {
    this.setState({isModalOpen: false});
    $(':input').val('');
  }


  validateOTPForm() {
    let otpnoError = "";

    if (!this.state.otpno) {
      otpnoError = "Verification Code cannot be blank";
    }
    
    if (otpnoError) {
      this.setState({ otpnoError })
      return false;
    }    
    return true;
  };
  

  handleOtpSubmit = event => {
    const isOtpFormValid = this.validateOTPForm();
    if (isOtpFormValid) {
      this.props.handleLoginWithOtp(this.state.username, this.state.password, this.state.otpno);
    }
  }

  handleCancel = event => {
    this.props.goTologout();
    this.props.initLogin();    
    // clear form
    this.setState(initialState);
  }


  render() {
    const { t } = this.props
    const t2 = (label) => {
      return this.props.t('en_translations:'+label);
    }

    return (
      <Container>
      <div className="auth-wrapper Login">
          <div className="auth-top"><img src={companyLogo} className="company-logo mx-auto d-block" alt="username" />
          <p className="text-center">{new Intl.DateTimeFormat('default', {weekday: 'long', year: 'numeric', month: 'long',day: '2-digit'}).format(today)}</p>
          </div>

          { this.state.isOtp?
          <div className="auth-inner">
            
              <Row className="mt-12">
                <Col sm={{ size: 4 }}><Label>{t('verificationcode')} :<br></br><i>{t2('verificationcode')}</i></Label></Col>
                <Col sm={{ size: 8 }}><Input name="otpno" id="otpno" onKeyPress={(event) => Validate.number(event)} maxLength={6} value={this.state.otpno} placeholder={t('placeholder.enter', { 0: t('verificationcode') })} onChange={this.handleChange}/>
                </Col>
              </Row>

              <Row className="mt-12">
                <Col sm={{ size: 8, offset: 4 }}><div className="error">{this.state.otpnoError}</div></Col>
              </Row>

              <Row className="mt-12">
                <Col sm={{ size: 8, offset: 4 }}>
                  <Button color="secondary" onClick={this.handleOtpSubmit}>{t('button.next')}</Button>{' '}
                  <Button color="secondary" onClick={this.handleCancel}>{t('button.cancel')}</Button>
                </Col>       
              </Row>
           </div>  
          :
          <div className="auth-inner">
              
              <Row>
                <Col xs="12" sm="12" md="12"><Label>{t('loginpage')}<br></br><i>{t2('loginpage')}</i></Label></Col>
              </Row>
              
              <Row className="mt-3">
                <Col sm={{ size: 4 }}><Label>{t('username')} :<br></br><i>{t2('username')}</i></Label></Col>
                <Col sm={{ size: 8 }}><Input type="text" name="username" id="username" maxLength={20} value={this.state.username} className="form-control" placeholder={t('placeholder.enter', { 0: t('username') })} onChange={this.handleChange}/></Col>
              </Row>
              
              <Row className="mt-2">
                <Col sm={{ size: 8, offset: 4 }}><div className="error">{this.state.usernameError}</div></Col>
              </Row>
              
              <Row className="mt-12">
                <Col sm={{ size: 4 }}><Label>{t('password')} :<br></br><i>{t2('password')}</i></Label></Col>
                <Col sm={{ size: 8 }}><Input type="password" name="password" id="password" value={this.state.password} placeholder={t('placeholder.enter', { 0: t('password') })} minLength={8} maxLength={15} onChange={this.handleChange}/>
                </Col>
              </Row>
              
              <Row className="mt-12">
                <Col sm={{ size: 8, offset: 4 }}><div className="error">{this.state.passwordError}</div></Col>
              </Row>

              <Row className="mt-12">
                  <Col sm={{ size: 7, offset: 4 }}>
                    <Button color="secondary" onClick={this.goToForgot}>{t('button.forgotPassword')}</Button>{' '}
                    <Button type="submit" color="secondary" className="btn--wide" onClick={this.handleSubmit}>{t('button.login')}</Button>
                  </Col>       
              </Row>
            
          </div>
          }

            <Modal isOpen={this.state.isModalOpen} className="modal-dialog">
              <ModalHeader className="modal_header">
                OTP Verification
              </ModalHeader>
              <ModalBody className="report-modal-body">
                Kode OTP sudah dikirimkan ke alamat Email Anda yang terdaftar.<br></br>
                <i>OTP Code has been sent to your registered email account</i>
              </ModalBody>
              <ModalFooter><Button className="mt-3 mx-auto" onClick={this.handleCloseNow}>OK</Button></ModalFooter>
            </Modal>

        </div>
        </Container>

    );

  }
}

const today = Date.now();

const mapDispatchToState = (dispatch) => {
  return {
    handleLogin: (username, password) => {
      dispatch(loginAction.loginHandler(username, password));
    },
    handleLoginWithOtp: (username, password, otpno) => {
      dispatch(loginAction.loginOtpHandler(username, password, otpno));
    },
    goToForgot: () => dispatch(push('/forgetpassword')),
    initLogin: () => dispatch(push(Constant.PATH_LOGIN)),
    clearError: () => {dispatch({type : 'CLEAR_ERROR'})},
    goTologout: () => dispatch(loginAction.logoutHandler)
  }
}

const mapStateToProps = (state) => {
  return {
    isOtp: state.loginReducer.isOtp
  }
}

export default connect(mapStateToProps, mapDispatchToState) (withTranslation(['id_translations', 'en_translations'])(Login));
