import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import * as urlConstant from './../../constants/urlConstant';
import { Get } from '../../utils/AxFetch';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StatisticDetails from './dashboard/StatisticDetails';
import TechnicalDetails from './dashboard/TechnicalDetails';
import Select from '../../utils/Select';
import { format, eachMonthOfInterval, subMonths, endOfMonth, startOfMonth } from 'date-fns';
import { Col, Row } from 'reactstrap';
import * as Constant from './../../constants/Constant';
import $ from 'jquery';

import {
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Line,
    Bar,
    ComposedChart,
    ResponsiveContainer
} from "recharts";

const HomePage = (props) => {
  const { t } = useTranslation();
  const t2 = (label) => { return t('en_translations:'+label) };
  const dispatch = useDispatch();
  const [dataList,setDataList] = useState({items:[]});
  const [activeTab, setActiveTab] = useState('1');

  const [drpdwnListComp,setDropdownList] = useState({activeCompanyList:[]});
  const [drpdwnListMonth, setDrpdwnListMonth] = useState({monthList:[]});
  let selectedOption = '';
  let selectedDateOpt = '';
  const [selectedStartDtOpt, setSelectedStartDtOpt] = useState('');
  const [selectedEndDt, setSelectedEndDt] = useState('');

  const companyId = useSelector(state => state.loginReducer.companyId);
  const companyName = useSelector(state => state.loginReducer.companyName);  
  const isStaff = useSelector(state => state.loginReducer.isStaff);
  const [handleChangeInd, setHandleChangeInd] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyId);
  
  const todayDate = Date();
  let formattedTodayDate = format(new Date(todayDate), Constant.dateFormat_A);
  let startOfMonths = Constant.startOfMonth;
  let thisYear =format(new Date(todayDate), Constant.dateFormat_year);
  let endOfMonths = endOfMonth(new Date(todayDate));
  let formattedEndOfMonths = format(new Date(endOfMonths), Constant.dateFormat_B);
  let defaultCurrentMonth = format(startOfMonth(new Date(todayDate)), Constant.dateFormat_B);
  let defaultFilter = Constant.month;

  const toggleTab = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  
  useEffect(() => {
    const getChartList = async dispatch => {
      let respChartList = [];
      let urlParams = {};
      
      if (selectedStartDtOpt){
        if (selectedEndDt){ //year to date filter
          urlParams = {
            fromDate: selectedStartDtOpt,
            toDate: selectedEndDt,
            companyId: selectedCompanyId
          }
        }else{ //monthly filter
          urlParams = {
            fromDate: selectedStartDtOpt,
            companyId: selectedCompanyId
          }
        }
      } else { //init
        urlParams = {
          companyId: selectedCompanyId
        }
      }

      let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_CREATE_TRNX_COUNT, { params: urlParams }, dispatch);

      if (resp.respData && resp.respData.data) {
        respChartList = resp.respData.data.map(item => {
          return {name: item.prodName, "PolicyCount": item.count, "GrossPremium": item.totalPremium}
        });
      }
      setHandleChangeInd(false);
      setDataList({items: respChartList});
    }

    const dropdownListComp = async dispatch => {
      let activeCompanyData = [];
      let attachedCompany = [{companyId, companyName}];
      
      if (isStaff){
        let activeCompanyResp = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_ACTIVECOMPANY, null, dispatch);
        if(!activeCompanyResp.isError && activeCompanyResp.respData.data) {
            activeCompanyData = activeCompanyResp.respData.data.map(company => {
              return {shortCode: company.id, value: company.companyName}
            });
          }
      }else{
        activeCompanyData = attachedCompany.map( company => {
          return {shortCode: company.companyId, value: company.companyName}
        });
      }
      
      setDropdownList({activeCompanyList: activeCompanyData});
    }

    const dropdownListMonth = async dispatch => {
      let monthListData = [];
      let prevMonths = subMonths(new Date(formattedTodayDate), 11);
      let formattedPrevStartDt = format(new Date(prevMonths), Constant.dateFormat_A);
      let monthsInterval = eachMonthOfInterval({
        start: new Date(formattedPrevStartDt),
        end: new Date(formattedTodayDate)
      })
      
      monthListData = monthsInterval.map((data) => {    
        return {shortCode: format(new Date(data), Constant.dateFormat_B), value: format(new Date(data), Constant.dateFormat_month) }
      });
      setDrpdwnListMonth({monthList:monthListData});
    }
  
    dispatch(getChartList);
    dispatch(dropdownListComp);
    dispatch(dropdownListMonth);
  }, [dispatch, selectedStartDtOpt, selectedEndDt, isStaff, companyName, companyId, handleChangeInd, selectedCompanyId, formattedTodayDate]);

  const handleChange = event => {
    setSelectedCompanyId(event.target.value);
    setHandleChangeInd(true);
  }
  
  //ddl monthly or year to date
  const onChangeValue = event => {
    selectedOption = event.target.value;
    if (selectedOption === Constant.month) { //monthly filter
      $('select[name="monthly"]').show();
      setSelectedStartDtOpt(defaultCurrentMonth);
    } else if (selectedOption === Constant.year) { //year to date filter
      $('select[name="monthly"]').hide();
      setSelectedStartDtOpt(startOfMonths + thisYear);
      setSelectedEndDt(formattedEndOfMonths);
    } else {
      $('select[name="monthly"]').hide();
      setSelectedStartDtOpt('');
      setSelectedEndDt('');
    }  
  }

  //select month from ddl monthly
  const selectedDate = event => {
    selectedDateOpt = event.target.value;
    setSelectedStartDtOpt(selectedDateOpt);
    setSelectedEndDt(''); //reset end date. Backend not need end date to query monthly data 
  }

  return (
    <div>
      <div className="chartDiv">
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart 
            width={900}
            height={400}
            margin={{
              top: 20, right: 20, bottom: 20, left: 20,
            }}
            data={dataList.items} >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" tickFormatter={amount => {return amount.toLocaleString(Constant.defaultLocale);}}/>
              <YAxis yAxisId="right" orientation='right' allowDecimals={false} />
              <Tooltip formatter={amount => {return amount.toLocaleString(Constant.defaultLocale);}} />
              <Legend />
              <Bar name={t('GWP')} yAxisId="left" dataKey="GrossPremium" barSize={30} fill="#413ea0" />
              <Line name={t('policycount')} yAxisId="right" type="monotone"  dataKey="PolicyCount" stroke="#ff7300" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div>
        <Row className="mt-3">
          <Col xs="3" sm="3" md="3">{t('dashboardwordingasat')}<br></br><i>{t2('dashboardwordingasat')}</i></Col>
          <Col xs="2" sm="2" md="2"><Select name='intervalFilter' optionList={Constant.dashboardList} defaultVal={defaultFilter} onChange={onChangeValue}/></Col>
          <Col xs="2" sm="2" md="2"><Select name='monthly' optionList={drpdwnListMonth.monthList} defaultVal={defaultCurrentMonth} onChange={selectedDate}/></Col>
          <Col xs="1" sm="1" md="auto">{t('dashboardfor')}<br></br><i>{t2('dashboardfor')}</i></Col>
          <Col xs="3" sm="3" md="3"><Select name='companyRegNo' optionList={drpdwnListComp.activeCompanyList} defaultVal={selectedCompanyId} onChange={handleChange}/></Col>
        </Row>
        <br/>
      </div>
      
      <Nav tabs>
        <NavItem>
          <NavLink className={activeTab === '1' ? 'activeTab' : '' } onClick={() => { toggleTab('1'); }}>{t('statisticdetails')}</NavLink>
        </NavItem>
        <NavItem>
        <NavLink className={ activeTab === '2' ? 'activeTab' : '' } onClick={() => { toggleTab('2'); }}>{t('technicaldetails')}</NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1"><StatisticDetails selectedFromDt={ selectedStartDtOpt } selectedEndDt={ selectedEndDt } selectedCompanyId={ selectedCompanyId } /></TabPane>
        <TabPane tabId="2"><TechnicalDetails selectedFromDt={ selectedStartDtOpt } selectedEndDt={ selectedEndDt } selectedCompanyId={ selectedCompanyId } /></TabPane>
      </TabContent>
    </div>
  ) 
}

export default HomePage;