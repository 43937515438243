import React from 'react';
import { Card, CardBody, Col, Row, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import companyLogo from './../../images/gel-logo.png';

const Acknowledgement = (props) => {
    const { t } = useTranslation();
    const t2 = (label, params) => { 
        if (params) {
            return t('en_translations:'+label, {0:params[0], 1:params[1]}) 
        } else {
            return t('en_translations:'+label) 
        }
    };
    const respData = props.location.state;

    if(respData)
    return (
        <div>
        <div className="auth-top claim">
            <img src={companyLogo} className="company-logo mx-auto d-block" alt="username" />
        </div>
        <Container className="pt-3 pb-5">
            <Row className="mt-3"><Col className="titleH4"><h4>{t('acknowledgement')}</h4></Col></Row>
            <Row><Col className="titleH4"><h6><i>{t2('acknowledgement')}</i></h6></Col></Row>
            <Row><Col className="borderBottom"></Col></Row>
            <Card className="bg-light mt-3">
                <CardBody>
                    <Col xs="12" sm="12" md="12" className="mb-3" >{t('dear')}&nbsp;
                    <b>
                        {   respData.proposer ?
                                respData.proposer.gender ?
                                    respData.proposer.gender === "GEN0000001" ?
                                    t('mr') : t('ms')
                                : ""
                            : ""
                        } {respData.proposer ? respData.proposer.name: ""}
                    </b>
                    </Col>
                    <Col xs="12" sm="12" md="12" className="mb-3" >{t('acknowledgement.mss', {0:respData.policyNo, 1:respData.proposer? respData.proposer.email:""})}</Col>
                    <Col xs="12" sm="12" md="12" className="mb-3" >{t('acknowledgement.mss.thanks')}</Col>
                    <Col xs="12" sm="12" md="12" >{t('acknowledgement.mss.regards')}</Col>
                    <Col xs="12" sm="12" md="12">{t('acknowledgement.mss.signature')}</Col>
                    <br></br><br></br>
                    <Col xs="12" sm="12" md="12" className="mb-3" >{t2('dear')}&nbsp;
                    <b>
                        {   respData.proposer ?
                                respData.proposer.gender ?
                                    respData.proposer.gender === "GEN0000001" ?
                                    t2('mr') : t2('ms')
                                : ""
                            : ""
                        } {respData.proposer ? respData.proposer.name: ""}
                    </b>
                    </Col>
                    <Col xs="12" sm="12" md="12" className="mb-3" >{t2('acknowledgement.mss', {0:respData.policyNo, 1:respData.proposer? respData.proposer.email:""})}</Col>
                    <Col xs="12" sm="12" md="12" className="mb-3" >{t2('acknowledgement.mss.thanks')}</Col>
                    <Col xs="12" sm="12" md="12" >{t2('acknowledgement.mss.regards')}</Col>
                    <Col xs="12" sm="12" md="12">{t2('acknowledgement.mss.signature')}</Col>
                </CardBody>
            </Card> 
        </Container>   
        </div>
    ) 
    else return "";
};

export default Acknowledgement;