import React, { useEffect, useCallback, useState} from 'react';
import { useDispatch } from 'react-redux';
import { Get } from '../../utils/AxFetch';
import { Button, Card, CardBody, Col, Row, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import * as urlConstant from '../../constants/urlConstant';
import * as Constant from '../../constants/Constant';
import companyLogo from './../../images/gel-logo.png';

const PaymentInfo = (props) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const dispatch = useDispatch();
    const params = new URLSearchParams(props.location.search);
    const paymentId = params.get('paymentId');
    const [paymentDetails, setPaymentDetails] = useState("");

    const getPaymentDetails = useCallback( async (payId) => {
        let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_GET_PAYMENT_INT + payId, null, dispatch);
        if(!resp.isError && resp.respData.data){
            setPaymentDetails(resp.respData.data)
        }
        }, [dispatch]
    )

    useEffect(() => {
        getPaymentDetails(paymentId)
    }, [getPaymentDetails, paymentId])

    const ok = () => {
        let respData = {
            productCode: paymentDetails.productCode,
            createdByName: paymentDetails.createdByName,
            policyNo: paymentDetails.policyNo,
            proposer: {
                name: paymentDetails.proposerName, 
                email: paymentDetails.proposerEmail
            }
        }
        dispatch(push({pathname: Constant.PATH_ACKNOWLEDGEMENT, state: respData}));
    }

    const repay = async (transId) => {
        let createPaymentResp = await Get(urlConstant.URL_BASED + urlConstant.URL_CREATE_PAYMENT_INT + transId, null, dispatch);                
        if(!createPaymentResp.isError && createPaymentResp.respData.data){
            dispatch(push({pathname: Constant.PATH_PAYMENT_SUBMISSION, state: {paymentResp: createPaymentResp.respData.data}}));
        }       
    }

    return  (
        <div>
            <div className="auth-top claim">
                <img src={companyLogo} className="company-logo mx-auto d-block" alt="username" />
            </div>
           
            <Container className="pt-3 pb-5">
                <Row className="mt-3"><Col className="titleH4"><h4>{t('paymentinfo')}</h4></Col></Row>
                <Row><Col><h5><i>{t2('paymentinfo')}</i></h5></Col></Row>
                <Row><Col className="borderBottom"></Col></Row>

                { paymentDetails?
                <div>
                <Row>
                    <Col>
                    <h5>{t('paymentstatus')}: {' '}
                        {paymentDetails.status === Constant.PAYMENT_STATUS.SUCCESS? "Sukses":
                        paymentDetails.status === Constant.PAYMENT_STATUS.FAILED? "Gagal":
                        paymentDetails.status === Constant.PAYMENT_STATUS.PROCESSING? "Pemprosesan":
                    "" }
                    {/* {paymentDetails.status !== Constant.PAYMENT_STATUS.SUCCESS && paymentDetails.paymentResponseCode?
                        <span className="error"> {' '}(Kod Maklum:  {paymentDetails.paymentResponseCode}) </span> : null
                    } */}
                    </h5></Col>
                </Row>
            
                <Row>
                    <Col><h5>
                    {t2('paymentstatus')}: {' '} {paymentDetails.status}
                    {/* {paymentDetails.status !== Constant.PAYMENT_STATUS.SUCCESS && paymentDetails.paymentResponseCode?
                        <span className="error"> {' '}({t('responseCode')}: {paymentDetails.paymentResponseCode}) </span> : null
                    } */}
                    </h5></Col>
                </Row>

                <Card className="bg-light mt-4">
                    <CardBody>
                        <Container>
                        <Row><Col><h5>{t('paymentdetails')} {' '}<small><i>/{t2('paymentdetails')}</i></small></h5></Col></Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('refno')} <br/> <small><i>{t2('refno')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{paymentDetails.refNo1}</Col>
                        </Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('paymentMethod')} <br/> <small><i>{t2('paymentMethod')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{paymentDetails.paymentChannelDesc}</Col>
                        </Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('amount')} <br/> <small><i>{t2('amount')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{Constant.defaultCurrency}{' '}{paymentDetails.amountFormatted}</Col>
                        </Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('paymentdatetime')} <br/> <small><i>{t2('paymentdatetime')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{paymentDetails.paymentDateTime}</Col>
                            {/* {format(new Date(paymentDetails.paymentDateTime), Constant.dateFormat_A)} */}
                        </Row>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="bg-light mt-2">
                    <CardBody>
                        <Container>
                        <Row><Col><h5>{t('infodetails')} {' '}<small><i>/{t2('infodetails')}</i></small></h5></Col></Row>
                        {
                            paymentDetails.policyType ?
                                <>
                                    <Row>
                                        {
                                            paymentDetails.policyType === 'MASTERPOLICY' ?
                                                <Col xs="10" sm="3" md="3">{t('infocertificateno')}<br /> <small><i>{t2('infocertificateno')}</i></small></Col> :
                                                <Col xs="10" sm="3" md="3">{t('covernoteno')}<br /> <small><i>{t2('covernoteno')}</i></small></Col>
                                        }
                                        <Col xs="1" sm="1" md="1">:</Col>
                                        <Col xs="12" sm="8" md="8">{paymentDetails.policyNo}</Col>
                                    </Row>
                                </>

                                :
                                <>-</>
                        }


                        <Row>
                            <Col xs="10" sm="3" md="3">{t('infoproductcode')} <br/> <small><i>{t2('infoproductcode')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{paymentDetails.productCode}</Col>
                        </Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('infoproductname')} <br/> <small><i>{t2('infoproductname')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{paymentDetails.productDesc}</Col>
                        </Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('infosuminsured')} <br/> <small><i>{t2('infosuminsured')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{Constant.defaultCurrency}{' '}{paymentDetails.sumInsured}</Col>
                        </Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">Total Premi <br/> <small><i>{t('totalPremium')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{Constant.defaultCurrency}{' '}{paymentDetails.totalPremium}</Col>
                        </Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('planname')} <br/> <small><i>{t2('planname')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{paymentDetails.planName}</Col>
                        </Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('infoproposername')} <br/> <small><i>{t2('infoproposername')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{paymentDetails.proposerName}</Col>
                        </Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('proposeremail')} <br/> <small><i>{t2('proposeremail')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{paymentDetails.proposerEmail}</Col>
                        </Row>                    
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('issuancestartdate')} <br/> <small><i>{t2('issuancestartdate')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{paymentDetails.issuanceStartDate}</Col>
                        </Row>
                        <Row>
                            <Col xs="10" sm="3" md="3">{t('issuanceenddate')} <br/> <small><i>{t2('issuanceenddate')}</i></small></Col>
                            <Col xs="1" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{paymentDetails.issuanceEndDate}</Col>
                        </Row>
                        </Container>
                    </CardBody>
                </Card>
                <Row className="mt-3">
                    {paymentDetails.status===Constant.PAYMENT_STATUS.SUCCESS?
                        <Col><center><Button onClick={ok}>{t('button.next')}</Button></center></Col>
                    :paymentDetails.status===Constant.PAYMENT_STATUS.FAILED?                        
                        <Col><center><Button onClick={()=>repay(paymentDetails.transactionId)}>{t('button.resubmit.payment')}</Button></center></Col>
                    :null
                    }
                </Row>
                </div> : null}
            </Container>
        </div>
    )
}

export default PaymentInfo;