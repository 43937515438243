import React, {useState} from 'react';
import { Input } from 'reactstrap';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import * as urlConstant from '../constants/urlConstant';
import { Get } from '../utils/AxFetch';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const AutoComplete = (props) => {
    const { t } = useTranslation();
    const [fieldValue] = useState(props.fieldValue?props.fieldValue:"" );
    const [item] = useState(props.item?props.item:"" );
    const [options, setOptions] = useState(props.optionList?props.optionList:[]);
    const [inputValue, setInputValue] = useState(props.defaultVal || "");
    const cityStatus = props.cityStatus ?? false;
    const dispatch = useDispatch();
    let attributes = {...props};

    delete attributes.optionList;
    delete attributes.defaultVal;
    delete attributes.fieldValue;
    delete attributes.setFieldValue;
    delete attributes.item;
    delete attributes.respData;
    delete attributes.setRespData;
    delete attributes.onDisableCityDropdown; 
    delete attributes.disabled; 
    delete attributes.cityStatus;
    
    if(props.defaultVal) {
        attributes.defaultValue = props.defaultVal;
    }

    const onClick = (e) => {
        if (e.target) {
            let currentFieldValue = {...fieldValue};
            
            let id = $("#options_"+attributes.name+ " option[value='" + e.target.value + "']").attr('data-id');
            for (var key in currentFieldValue) {
                if (key === item) {
                    if (id !== undefined) {
                        currentFieldValue[item][e.target.name].desc = e.target.value
                        currentFieldValue[item][e.target.name].value = id
                        props.setFieldValue(currentFieldValue);
                    } else if (e.target.value === '') {
                        currentFieldValue[item][e.target.name].desc = ''
                        currentFieldValue[item][e.target.name].value = ''
                        props.setFieldValue(currentFieldValue);
                    }
                    
                    if(e.target.name === "travelDestCategory") {
                        props.destCategoryOnChange();
                    }
                    if(e.target.name === "travelDestCountry") {
                        props.destCountryOnChange();
                    }
                    if(e.target.name === "tripType") {
                        props.tripTypeOnChange();
                    }
                    if(e.target.name === "durationType") {
                        props.durationTypeOnchange(e);
                        currentFieldValue[item]["travelDestCategory"]={desc: '',value:''}
                        props.setFieldValue(currentFieldValue);
                    }
                    if(e.target.name === "noOfInsured") {
                        props.noOfInsuredOnChange(e);
                    }
                    if(e.target.name === "province") {
                        currentFieldValue[item]["city"]={desc:'',value:''};
                        currentFieldValue[item]["postCode"]={desc: '',value:''};
                        props.setFieldValue(currentFieldValue);
                    }
                    if(e.target.name === "city") {
                        currentFieldValue[item]["postCode"]={desc: '',value:''};
                        props.setFieldValue(currentFieldValue);
                    }
                    if(e.target.name === "riskCity") {
                        currentFieldValue[item]["riskPostCode"]={desc: '',value:''}
                        props.setFieldValue(currentFieldValue);
                    }
                    setInputValue(e.target.value);
                }
            }
        }
    }

    const clicked = (e) => {
        if (inputValue) {
            // Clear the input value if it is not empty
            setInputValue('');
            let currentFieldValue = { ...fieldValue };
            if (currentFieldValue[item] && currentFieldValue[item][e.target.name]) {
                currentFieldValue[item][e.target.name]={desc:'', value:''};
                props.setFieldValue(currentFieldValue);
            }
            setOptions(props.optionList);
        }
        //Reset dropdown list for postcode
        if(e.target.name==="province"){
            getPostalCode(e,"ALL",true,false);
        }
        if(e.target.name==="riskProvince"){
            getPostalCode(e,"ALL",false,true);
        }
    };
    
    useEffect(() => {
        setOptions(props.optionList);
        setInputValue(props.defaultVal || "");
    }, [props.optionList, props.defaultVal])

    const getCities = (e, id) => {
        if(e.target.name === "province" || e.target.name === "riskProvince") {
            let provinceShortCode = id || "ALL";
            Get(
                urlConstant.URL_BASED+urlConstant.URL_TRNX_PROVINCE_CITY+`${provinceShortCode}/city`,
                {},
                dispatch
            ).then(res => {
                if(!res.isError) {
                    if(e.target.name === "province") {
                        const data = res.respData.data.commonLookupList;
                        let newRespData = structuredClone(props.respData);
                        newRespData.lookupParams["CITY"] = data;
                        props.setRespData(newRespData);
                    } else {
                        const cities = res.respData.data.commonLookupList;
                        let newRespData = structuredClone(props.respData);
                        newRespData.riskFields.map(fields => {
                            if("riskCity" === fields.key)  {
                                fields.options = cities;
                            }
                        })
                        props.setRespData(newRespData);
                    }
                }
            });
        }
    }

    const getPostalCode = (e, id, showAllPostCode=false, showAllRiskPostCode=false) => {
        e.persist();
        if(e.target.name === "city" || e.target.name === "riskCity" || showAllPostCode || showAllRiskPostCode) {
            let cityShortCode = id || "ALL";
            Get(
                urlConstant.URL_BASED+urlConstant.URL_TRNX_CITY_POSTALCODE+`${cityShortCode}/postCode`,
                {},
                dispatch
            ).then(res => {
                if(!res.isError) {
                    if(e.target.name === "city" || showAllPostCode) {
                        const data = res.respData.data.commonLookupList;
                        let newRespData = structuredClone(props.respData);
                        newRespData.lookupParams["POSTAL_CODE"] = data;
                        props.setRespData(newRespData);
                    } else{
                        const data = res.respData.data.commonLookupList;
                        let newRespData = structuredClone(props.respData);
                        newRespData.riskFields.map(fields => {
                            if("riskPostCode" === fields.key)  {
                                fields.options = data;
                            }
                        })
                        props.setRespData(newRespData);
                    }
                }
            });
        }
    }

    const getCityProvinceByPostcode = (e,id) => {
        if(e.target.name==="postCode" || e.target.name==="riskPostCode"){
            let postcodeShortCode = id;
            Get(
                urlConstant.URL_BASED+urlConstant.URL_TRNX_POSTCODE_CITY_PROVINCE+`${postcodeShortCode}/cityProvince`,
                {},
                dispatch
            ).then(res => {
                if(!res.isError) {
                    const city = res.respData.data.commonLookupList;
                    const province = res.respData.data.provinceLookupList;

                    let currentFieldValue = { ...fieldValue };
                    if(e.target.name === "postCode") {
                        currentFieldValue[item]["city"] = { desc: city[0].value, value: city[0].shortCode };
                        currentFieldValue[item]["province"] = { desc: province[0].value, value: province[0].shortCode };
                        props.setFieldValue(currentFieldValue);
                    } else {
                        currentFieldValue[item]["riskCity"] = { desc: city[0].value, value: city[0].shortCode };
                        currentFieldValue[item]["riskProvince"] = { desc: province[0].value, value: province[0].shortCode };
                        props.setFieldValue(currentFieldValue);
                    }
                }
            });
        }
    }

    const getDesCategory = (e, id) => {
        if(e.target.name === "durationType"){
            let durationShortCode = id || "ALL";
            Get(
                urlConstant.URL_BASED+urlConstant.URL_TRNX_DURATION_TYPE_DEST_CATEGORY+`${durationShortCode}/category`,
                {},
                dispatch
            ).then(res => {
                if(!res.isError) {
                    const data = res.respData.data.commonLookupList;
                    let newRespData = structuredClone(props.respData);
                    newRespData.riskFields.map(fields => {
                        if("travelDestCategory" === fields.key)  {
                            fields.options = data;
                        }
                    })
                    props.setRespData(newRespData);
                }
            });
        }
    }

    const onBlur = (e) => {
        let id = $("#options_"+attributes.name+ " option[value='" + e.target.value + "']").attr('data-id');
        let currentFieldValue = { ...fieldValue };

        if (e.target.value !== '' && (e.target.defaultValue !== undefined || e.target.defaultValue !== '')) {
            $("#input_"+attributes.name).val(e.target.defaultValue);
            e.persist();
            if (props.onDisableCityDropdown) {
                props.onDisableCityDropdown(false); 
            }
            if((e.target.name==="postCode" || e.target.name==="riskPostCode") && cityStatus){
                getCityProvinceByPostcode(e,id);
            }
        } else {
            $("#input_"+attributes.name).val('');
            e.persist();
            

            if (e.target.name === "province") {
                currentFieldValue[item]["city"]={desc:'',value:''};
                currentFieldValue[item]["postCode"]={desc:'',value:''};
            }
            if (e.target.name === "riskProvince") {
                currentFieldValue[item]["riskCity"]={desc:'',value:''};
                currentFieldValue[item]["riskPostCode"]={desc:'',value:''};
            }
            if(e.target.name === "city") {
                currentFieldValue[item]["postCode"]={desc:'',value:''};
            }
            if(e.target.name === "riskCity") {
                currentFieldValue[item]["riskPostCode"]={desc:'',value:''};
            }
            if(e.target.name === "durationType") {
                currentFieldValue[item]["travelDestCategory"]={desc: '',value:''}
            }
            props.setFieldValue(currentFieldValue);
            setInputValue('');
            if (props.onDisableCityDropdown) {
                props.onDisableCityDropdown(true);
            }
        }

        if(e.target.name === "riskProvince") {
            $("#input_riskCity").val("");
            $("#input_riskPostCode").val("");
        }
        if(e.target.name === "province") {
            $("#input_city").val("");
            $("#input_postCode").val("");
        }
        if(e.target.name === "city") {
            $("#input_postCode").val("");
        }
        if(e.target.name === "riskCity") {
            $("#input_riskPostCode").val("");
        }
        if(e.target.name === "durationType") {
            $("#input_travelDestCategory").val("");
        }

        getCities(e, id);
        getPostalCode(e,id,false,false); //When city is selected get filter postcode
        getDesCategory(e,id);
    }

    if (props.fieldValue) {
        return(
            <div>
            <Input
                type="text"
                id={"input_"+attributes.name} 
                {...attributes}
                list={"options_"+attributes.name}
                placeholder={t("placeholder.selectsearch")}
                onChange={(e)=>onClick(e)}
                onBlur={(e)=>onBlur(e)}
                onClick={(e)=>clicked(e)}
                value={inputValue}
                disabled={props.disabled}
            />
                <datalist id={"options_"+attributes.name}>
                    {options.map(item => {
                                return(<option key={item.shortCode} value={item.value} data-id={item.shortCode}></option>)
                            }
                        )
                    }
                </datalist>
            </div>
        );
    } else return null;
}

export default AutoComplete;