import React, { useEffect } from "react";
import { Route, Switch } from 'react-router';
import loginpage from './common/Login';
import ForgetPassword from './common/ForgetPassword';
import ResetPassword from './common/ResetPassword';
import claimRegistration from './common/ClaimRegistration';
import individualClaimRegistration from './common/IndividualClaimRegistration';
import claimSubmissionForm from './common/ClaimSubmissionForm';
import claimReuploadSubmissionForm from './common/ClaimReuploadSubmissionForm';
import certificateInsurance from './transaction/CertificateInsuranceB2C';
import paymentInfo from "./transaction/PaymentInfoB2C";
import acknowledgement from "./transaction/AcknowledgementB2C";
import paymentSubmission from "./transaction/PaymentSubmissionB2C";
import mockPaymentRedirect from "../mock/MockPaymentRedirect";
import sessionTimeOut from "./common/SessionTimeOut";
import reviewVaPage from "./ReviewVaPage";
import ocbcPage from "../containers/static/OcbcPage";
import referralPage from "../containers/static/ReferralPage";
import NyalaPage from "../containers/static/NyalaPage";

const loginRoute = () => {
    return (
    <>
        <Switch>
            <Route path={["/forgetpassword"]} component={ForgetPassword}/>
            <Route path={["/resetpassword"]} component={ResetPassword}/>
            <Route path={["/claimRegistration"]} component={claimRegistration} />
            <Route path={["/individualClaimRegistration"]} component={individualClaimRegistration} />
            <Route path={["/claimSubmissionForm"]} component={claimSubmissionForm} />
            <Route path={["/claimreupload"]} component={claimReuploadSubmissionForm} />
            <Route path={["/consumerPOS"]} component={certificateInsurance} />
            <Route path={["/paymentinfo"]} component={paymentInfo}/>
            <Route path={["/acknowledgement"]} component={acknowledgement}/>
            <Route path={["/paymentSubmission"]} component={paymentSubmission}/>
            <Route path={["/mockPaymentRedirect"]} component={mockPaymentRedirect}/>
            <Route path={["/sessionTimeOut"]} component={sessionTimeOut}/>
			<Route path={["/paymentDetails"]} component={reviewVaPage}/>
            <Route path={["/ocbc"]} component={ocbcPage}/>
            <Route path={["/referral"]} component={referralPage}/>
            <Route path={["/nyala"]} component={NyalaPage}/>
            <Route component={loginpage} />
        </Switch>
    </>
    )
}

export default loginRoute;