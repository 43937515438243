import * as Constant from './../constants/Constant';

const passwordRegex = /^[a-zA-Z\d~!@#$%^&*()_+-={}[\]/<>,.;?':|]*$/gm;
const identityRegex = /^[a-zA-Z0-9-]*$/gm;

export const identityKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(new RegExp(identityRegex).test(keyValue))) event.preventDefault();
}

export const validateIdentity = (value) => {
    if (!(new RegExp(identityRegex).test(value))) {
        return false
    } else return true;
}

export const alphanumeric = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(/[A-Za-z\d ]/.test(keyValue))) event.preventDefault();
}

export const alphanumericUnderscore = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(/[A-Za-z_\d ]/.test(keyValue))) event.preventDefault();
}

export const alphabet = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(/[A-Za-z ]/.test(keyValue))) event.preventDefault();
}

export const numeric = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(/[\d+-]/.test(keyValue))) event.preventDefault();
}

export const validateEmail = (email) => {
    if (/^\w+([.!#$%&‘*+–/=?^`.{|}~]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/i.test(email)) {
        return true;
    } else return false;
}

export const validateMobileNoLength = (mobileno) => {
    if(mobileno.length>=10 && mobileno.length<=12){
        return true;
    }else false;
}

export const validateAlphaApostrophe = (value) =>{
    if(/^[a-zA-Z]+(?:['\s][a-zA-Z]+)*$/.test(value)){
        return true;
    }else return false;
}

export const validatePasswordKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(new RegExp(passwordRegex).test(keyValue))) event.preventDefault();
}

export const validatePasswordBlur = (value) => {
    if (!(new RegExp(passwordRegex).test(value))) {
        return false
    } else return true;
}

export const validateDate = (fromDt, toDt) => {
    let fromDateParts = fromDt.split("/");
    let toDateParts = toDt.split("/");

    if (new Date(toDateParts[2], toDateParts[1] - 1, toDateParts[0])
        >= new Date(fromDateParts[2], fromDateParts[1] - 1, fromDateParts[0])) {
        return true;
    }else return false;
}

export const number = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(/[\d]/.test(keyValue))) event.preventDefault();
}

export const validateFileSize = (fsize, validfsize) => {
    if (fsize > ( validfsize )) {
        return false;
    } else return true;
}

export const validateFileExtensions = (filePath, validPathType) => {
    let allowedExtensions;

    if(validPathType === Constant.imagePathType)
        allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    else if(validPathType === Constant.excelPathType)
        allowedExtensions = /(\.xls|\.xlsx)$/i;
    else
        allowedExtensions =  /(\.jpg|\.jpeg|\.png|\.gif|\.pdf|\.doc|\.docx)$/i;

    if (!allowedExtensions.exec(filePath)) {
        return false;
    } else return true;
}