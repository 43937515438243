import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label } from 'reactstrap';
import * as Constant from '../../../../constants/Constant';

const ConfirmationProposerDynamicInfo = ({ respData, field, fieldValue }) => {
    const { t } = useTranslation();
    const t2 = (label) => { return t('id_riskitems:'+label) }
    const t3 = (label) => { return t('en_riskitems:'+label) }
    const [item] = useState("dynamicProposerFields");
    const [value] = useState("value");
    const [desc] = useState("desc");

    if (respData.proposerFields) return (
        <div>       
            <div className="container-flex">
            { field.field.map( (proposerField) => 
                respData.proposerFields.map((respField, respIdex) =>
                respField.key === proposerField.key ?
                <Col key={respIdex} xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5">
                        <Label>
                            {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(proposerField.key) ? t2(proposerField.key) : proposerField.key}
                            <br></br>
                            <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t2(proposerField.key) ? t2(proposerField.key) : proposerField.key}</i>
                        </Label>
                    </Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>
                            {fieldValue[item][proposerField.key][value] !== "" && respField.isCurrency ?
                            parseInt(fieldValue[item][proposerField.key][value]).toLocaleString(Constant.defaultLocale):
                            fieldValue[item][proposerField.key][value]?
                            fieldValue[item][proposerField.key][value]
                            : "-"}
                        </Label>
                    </Col>
                </Col>: ""
            ))} 
            </div>            
        </div>
    )
    else return "";
};

export default ConfirmationProposerDynamicInfo;