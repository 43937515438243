import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = (props) => {
    let attributes = {...props};
    
    return (
        <DatePicker 
            {...attributes}
            dateFormat={props.dateFormat? props.dateFormat:"dd/MM/yyyy"}
            showMonthDropdown 
            showYearDropdown 
            dropdownMode="scroll"
        />
    )
}

export default Datepicker;