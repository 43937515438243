import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Table } from 'reactstrap';
import PaginationBar from '../../components/PaginationBar';
import * as Constant from './../../constants/Constant';
import * as urlConstant from './../../constants/urlConstant';
import { Post } from '../../utils/AxFetch';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';

const CompanyListing = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchCriteria, setSearchCriteria] = useState('');
    const [companyListParams,setCompanyListParams] = useState({data:[], currentPage:1, pageSize:0, pagesCount:0});
    const [pageOnClick,setPageOnClick] = useState(1);

    useEffect(() => {
        if ($('input[name="companyNpwp"]').val() === '' &&
        $('input[name="companyName"]').val() === '' &&
        $('select[name="companyStatus"]').val() === '') {
            setSearchCriteria('');
        } else {
            setSearchCriteria(props.searchCriteria);
        }
        setPageOnClick(1);
    }, [props.searchCriteria]);

    const handleEdit = (id) => {

        let selected = {};
        selected = id;

        if(!selected) return;
        dispatch(push({pathname: Constant.PATH_COMPANY_DETAILS, state: { action: 'EDIT', selected: selected }}))
    }

    const onClick = (page) => {  
        setPageOnClick(page)  
    }

    useEffect(() => {
        const getCompanyList = async(page) => {
            let active = '';
            if(searchCriteria.companyStatus === 'false') {
                active = false;
            } else if(searchCriteria.companyStatus === 'true') {
                active = true;
            }

            let resData = '';
            let resCurrentPage = '';
            let resPageSize = '';
            let resPagesCount = '';
            let resp = "";
    
            resp = await Post(urlConstant.URL_BASED + urlConstant.URL_COMPANY_LIST + page,
                {
                    rowPerPage: Constant.rowPerPage,
                    pageNum: page,
                    name: searchCriteria.companyName,
                    regNo: searchCriteria.companyNpwp,
                    active: active
                }, dispatch);
    
            let dataResp = resp.respData;
    
            if(!resp.isError && dataResp.data) { 
                resData = dataResp.data.content;
                resCurrentPage = page;
                resPageSize = dataResp.data.size;
                resPagesCount = dataResp.data.totalPages;
            }
            setCompanyListParams({data:resData, currentPage: resCurrentPage, pageSize: resPageSize, pagesCount: resPagesCount});
        }
        getCompanyList(pageOnClick);
    }, [dispatch, searchCriteria, pageOnClick]);
        return (
            <div>
                <Table bordered>
                    <thead>
                        <tr>
                            <th style={{width: '10%'}}>{t('no.')}</th>
                            <th>{t('companynpwp')}</th>
                            <th>{t('companyname')}</th>
                            <th>{t('lastupdateon')}</th>
                            <th>{t('lastupdateby')}</th>
                            <th>{t('status')}</th>
                        </tr>
                    </thead>
                    {(companyListParams.data && companyListParams.data.length > 0) ?
                        <tbody>
                            {companyListParams.data.map((item, i) => {
                                let status = 'Inactive';
                                if(item.active) status = 'Active';
                            return (
                                <tr key={i}>
                                    <td style={{width: '10%'}}>{i+1}</td>
                                    <td>{item.regNo}</td>
                                    <td><button className="link-button" onClick={() => handleEdit(item.id)}>{item.companyName}</button></td>
                                    <td>{item.lastModifiedDatetime}</td>
                                    <td>{item.lastModifiedByName}</td>
                                    <td>{status}</td>
                                </tr>
                            )}
                            )}
                        </tbody>
                    :   <tbody>
                            <tr><td className="emptyrecord" colSpan={6}>{t('norecordfound')}</td></tr>
                        </tbody>
                    }
                </Table>
                <PaginationBar totalPages={companyListParams.pagesCount} currentPage={companyListParams.currentPage} handleClick={(page) => onClick(page)}  />
            </div>
        );
}
export default CompanyListing;