import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PaginationBar from '../../components/PaginationBar';
import * as urlConstant from '../../constants/urlConstant';
import * as Constant from '../../constants/Constant';
import { useDispatch } from 'react-redux';
import { Post } from '../../utils/AxFetch';

const CertificateIssuedReportListing = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [certIssuedReportParams, setCertIssuedReportParams] = useState({data:[], currentPage:1, pageSize:0, pagesCount:0});
    const [pageOnClick,setPageOnClick] = useState(1);
    
    useEffect(() => {
        if (props.searchCriteria) {
            setPageOnClick(1);
        }
    }, [props.searchCriteria]);

    const onClick = (page) => {  
        setPageOnClick(page)  
    }

    useEffect (() => {
        let searchCriteria = props.searchCriteria;
        const getCertIssuedReport = async(page) => {
            let resData = '';
            let resCurrentPage = '';
            let resPageSize = '';
            let resPagesCount = '';
            
            let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_REPORT_CERTIFICATE_ISSUED_LIST + page,
                {
                    rowPerPage: Constant.rowPerPage,
                    pageNum: page,
                    trnxDateFrom: searchCriteria.searchEffectiveFromDt,
                    trnxDateTo: searchCriteria.searchEffectiveToDt,
                    companyId: searchCriteria.searchCompanyId,
                    productCode: searchCriteria.searchProdCodes,
                    proposerIdentityNo: searchCriteria.searchKTPNo,
                    policyType: searchCriteria.searchPolicyType,
                    memberAgentCode: searchCriteria.searchMemberAgentCode
                }, dispatch);
            
            let dataResp = resp.respData;
                        
            if(!resp.isError && dataResp.data) {
                resData = dataResp.data.content;
                resCurrentPage = page;
                resPageSize = dataResp.data.size;
                resPagesCount = dataResp.data.totalPages;
             }
            setCertIssuedReportParams({data:resData, currentPage: resCurrentPage, pageSize: resPageSize, pagesCount: resPagesCount});
        }
        if (searchCriteria && props.isClickSearchButton) getCertIssuedReport(pageOnClick);
    }, [dispatch, props.searchCriteria, pageOnClick, props.isClickSearchButton]);
    
    return (
        <div>
            <Table className="mt-3" bordered responsive>
            <thead>
                <tr>
                    <th style={{width: '10%'}}>{t('no.')}</th>
                    <th>{t('certificateinsurance.result.year')}</th>
                    <th>{t('certificateinsurance.result.month')}</th>
                    <th>{t('certificateinsurance.result.product')}</th>
                    <th>{t('certificateinsurance.result.policytype')}</th>
                    <th>{t('certificateinsurance.result.certificate')}</th>
                    <th>{t('certificateinsurance.result.effectivedt')}</th>
                    <th>{t('certificateinsurance.result.expirydt')}</th>
                    <th>{t('certificateinsurance.result.txncode')}</th>
                    <th>{t('certificateinsurance.result.currency')}</th>
                    <th>{t('certificateinsurance.result.affinitytype')}</th>
                    <th>{t('certificateinsurance.result.affinityno')}</th>
                    <th>{t('certificateinsurance.result.agentname')}</th>
                    <th>{t('certificateinsurance.result.memberagentcode')}</th>
                    <th>{t('certificateinsurance.result.insuredname')}</th>
                    <th>{t('certificateinsurance.result.transno')}</th>
                    {/* <th>{t('certificateinsurance.result.accexchg')}</th> */}
                    <th>{t('certificateinsurance.result.grosspremium')}</th>
                    <th>{t('certificateinsurance.result.commissionamt')}</th>
                    <th>{t('certificateinsurance.result.nettpremium')}</th>
                    <th>{t('certificateinsurance.result.issueddt')}</th>
                </tr>
            </thead>
            { (certIssuedReportParams.data.length > 0) ?
            <tbody>
            {certIssuedReportParams.data.map((item, i) => {
                return(
                    <tr key={i}>
                        <td style={{width: '10%'}}>{i+1}</td>
                        <td>{item.transactionYear}</td>
                        <td>{item.transactionMonth}</td>
                        <td>{item.productDesc}</td>
                        <td>{item.policyType}</td>
                        <td>{item.policyNo}</td>
                        <td>{item.issuanceStartDate}</td>
                        <td>{item.issuanceEndDate}</td>
                        <td>{item.transactionCode}</td>
                        <td>{item.oriCurrency}</td>
                        <td>{item.agentType}</td>
                        <td>{item.agentNo}</td>
                        <td>{item.createdByName}</td>
                        <td>{item.memberAgentCode}</td>
                        <td>{item.insuredName}</td>
                        <td>{item.trnxNo}</td>
                        {/* <td>{item.accExchg}</td> */}
                        <td>{item.grossPremium}</td>
                        <td>{item.commissionAmt}</td>
                        <td>{item.nettPremium}</td>
                        <td>{item.issuedDate}</td>
                    </tr>
                )}
            )}
            </tbody>
            : <tbody><tr><td className="emptyrecord" colSpan="20">{t('norecordfound')}</td></tr></tbody>
            }    
            </Table>
            <PaginationBar totalPages={certIssuedReportParams.pagesCount} currentPage={certIssuedReportParams.currentPage} handleClick={(page) => onClick(page)} />
        </div>
    )
}
export default CertificateIssuedReportListing;