import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Label, Input } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import Select from '../../../utils/Select';
import * as Constant from '../../../constants/Constant';
import * as urlConstant from '../../../constants/urlConstant';
import * as Validate from '../../../utils/CommonValidate';
import CertificateBatchUploadList from './CertificateBatchUploadList';
import { FileGateway, Get, Download } from '../../../utils/AxFetch';
import Disclaimers from '../../../components/Disclaimers';

const CertificateBatchUpload = () => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const dispatch = useDispatch();
    const companyIdSelector = useSelector(state => state.loginReducer.companyId);
    const [message, setMessage] = useState('')
    const [searchCriteria, setSearchCriteria] = useState('');
    const [dropdownList,setDropdownList] = useState({productList:[]});
    const [uploadedFile, setUploadedFile] = useState('');
    const [disclaimerFields, setDisclaimerFields] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
        disclaimerFields.map((disclaimer) => {
            disclaimer.checked = false;
        })
    }
    
    const handleSearchProduct = () => {
        let product = $('select[name="productId"]').val();
        setSearchCriteria({ product: product });
    }

    const handleDowload = async() => {
        setMessage('');
        let product = '';
        if ($('select[name="productId"]').val()) {
            product = $('select[name="productId"]').val();
            await Download(urlConstant.URL_BASED+urlConstant.URL_TRNX_DOWNLOAD_TEMPLATE+product, 
                Constant.downloadType.GET, null, Constant.cibatchuploadfile, dispatch);
        }else{
            setMessage(t('error.message.input.blank', {0: t('batch.certificateinsurance.product')}));
        }
    }

    const handleUpload = async() => {
        setMessage('');
        let errorMessage = '';
        let productCode = $('select[name="productId"]').val();

        if(!productCode) {
            errorMessage += t('error.message.input.blank', {0: t('batch.certificateinsurance.product')})+'\n'
        }

        if(!uploadedFile){
            errorMessage += t('error.message.input.blank', {0: t('batch.certificateinsurance.file')})+'\n';
        }

        if(errorMessage){
            setMessage(errorMessage);
            window.scrollTo(0, 0);
        }else{
            let isRespFile = false;

            let resp = await FileGateway(productCode, uploadedFile, Constant.FG_CH_fileUpload_trnx, dispatch, disclaimerFields)
            if (!resp.isError) {
                isRespFile = true;
            } else {
                if (resp.respData === Constant.FG_SCAN_VIRUS_FAILED) {
                    setMessage(Constant.FG_SCAN_VIRUS_FAILED);
                }
                window.scrollTo(0, 0);
                isRespFile = false;
            }
            
            if(isRespFile){
                $('input[name="fileNameId"]').val("");
                $('#fileUploadId').val('');
                setUploadedFile('');
                setSearchCriteria({ product: productCode });
            }
        }
    }

    const validateUploadFile = (file) => {
        let errorMessage = '';
        
        if(!Validate.validateFileExtensions(file.name, Constant.excelPathType))
            errorMessage += t('error.message.excelfile.extension')+'\n'
        if(file.name.length > Constant.fileLength)
            errorMessage += t('error.message.filename.long')+'\n'
        if (!Validate.validateFileSize(file, Constant.fileSize*Constant.megabyte)) 
            errorMessage = t('error.message.file.large');
        return errorMessage;       
    }

    const handleUploadChange = e =>{
        setMessage('');
        let errorMessage = '';
        let file = e.target.files;

        if(file.length>0){   
            errorMessage += validateUploadFile(file[0]);      
            if(errorMessage){
                $('input[name="fileNameId"]').val("");
                setUploadedFile('');
                setMessage(errorMessage);
                window.scrollTo(0, 0);
            } else{
                $('input[name="fileNameId"]').val(file[0].name);
                setUploadedFile(file[0]);
            }      
        }
    }

    useEffect(() => {
        const dropdownLists = async dispatch => {
            let activeProduct = [];
            let activeProductResp = await Get(urlConstant.URL_BASED + urlConstant.URL_REPORT_COMPANY_PRODUCT_LIST + companyIdSelector, null, dispatch)
            if(!activeProductResp.isError && activeProductResp.respData.data){
                activeProduct = activeProductResp.respData.data.map( product => {
                    return {shortCode: product.code, value: product.desc}
                });
                setDropdownList({productList: activeProduct});
            }
        }
        dispatch(dropdownLists);
    }, [dispatch, companyIdSelector]);

    return (
        <div>
            <Container>
                { message? <div className="error"><p>{message}</p></div>: null}
                <Row className="mt-3"><Col><h2>{t('batch.certificateinsurance')}</h2></Col></Row>
                <Row><Col><h4><i>{t2('batch.certificateinsurance')}</i></h4></Col></Row>
                <Row className="mt-3"><Col className="titleH4"><h4>{t('batch.certificateinsurance.by')}</h4></Col></Row>
                <Row><Col className="borderBottom"></Col></Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('batch.certificateinsurance.product')}<br></br><i>{t2('batch.certificateinsurance.product')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select name='productId' optionList={dropdownList.productList} onChange={handleSearchProduct}/></Col> 
                    <Col className="mt-2 mt-sm-0 mt-md-0" xs="12" sm="3" md="3"><Button onClick={handleDowload} name="btnUpload">{t('batch.certificateinsurance.dowloadTemplate')}</Button></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('batch.certificateinsurance.file')}<br></br><i>{t2('batch.certificateinsurance.file')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="10" sm="5" md="5">
                    <div onClick={ () => {document.getElementById('fileUploadId').click()} }>
                        <Input name="fileNameId" disabled={true}/>
                    </div>
                    </Col>    
                    <Col xs="2" sm="3" md="3">
                        <Label htmlFor="fileUploadId"><i className="fa fa-folder-open fa-lg"/></Label>
                        <Input type="file" id="fileUploadId" style={{ display: 'none' }} onClick={(event)=> {event.target.value = null}} onChange={ e => handleUploadChange(e)} accept={Constant.excelExtension} />
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="12" sm="12" md="12">
                        <center>
                            <Button onClick={disclaimerFields.length === 0 ? handleUpload : toggle} name="btnUpload">{t('button.uploadFile')}</Button>
                        </center>
                    </Col>
                </Row>	

            </Container>
            
            <CertificateBatchUploadList searchCriteria={searchCriteria} setDisclaimerFields={setDisclaimerFields} />
            <Disclaimers isOpen={isOpen} toggle={toggle} disclaimerFields={disclaimerFields} handleUpload={handleUpload} setDisclaimerFields={setDisclaimerFields} />
            
        </div>
    )
}

export default CertificateBatchUpload;