import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {Label, Input, Button, Row, Col, Container} from 'reactstrap';
import companyLogo from '../../images/gel-logo.png';
import { useTranslation } from 'react-i18next';
import loginAction from '../../actions/loginAction';
import * as Validate from './../../utils/CommonValidate';
import "../../containers/common/Login.css";

const ResetPassword = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [key, setKey] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const today = Date.now();

  useEffect(() => {
    var params = new URLSearchParams(props.location.search);
    var paramKey = params.get('key');
    if (paramKey) {
      setKey(paramKey);
    }
  }, [props])

  const validateReset = () => {
    var isValid = true;
    setNewPasswordError("");
    setConfirmPasswordError("");
    if (!newPassword) {
      setNewPasswordError("New Password cannot be blank");
      isValid = false;
    }else if (newPassword.length < 8) {
      setNewPasswordError("The password provided is not long enough min is 8");
      isValid = false;
    }else if (newPassword.length > 15) {
      setNewPasswordError("The password provided is too long max is 15");
      isValid = false;
    }else if (!Validate.validatePasswordBlur(newPassword)) {
      setNewPasswordError("Invalid new password");
      isValid = false;
    }

    if (!confirmPassword) {
      setConfirmPasswordError("Confirm New Password cannot be blank");
      isValid = false;
    }else if (confirmPassword.length < 8) {
      setConfirmPasswordError("The confirm password provided is not long enough min is 8");
      isValid = false;
    }else if (confirmPassword.length > 15) {
      setConfirmPasswordError("The confirm password provided is too long max is 15");
      isValid = false;
    }else if (!Validate.validatePasswordBlur(confirmPassword)) {
      setConfirmPasswordError("Invalid confirm password");
      isValid = false;
    }

    if (isValid && newPassword !== confirmPassword) {
      setConfirmPasswordError("New Password and Confirm New Password is not match. Please key-in again to create new password");
      isValid = false;
    }

    return isValid;
  };

  const onBlurValidate = event => {
    if (event.target.value) {
      if (event.target.id === 'newpassword') {
        if (!Validate.validatePasswordBlur(event.target.value)) { 
            setNewPasswordError("Invalid new password");
        } else {
            setNewPasswordError("");
        }
      } else if (event.target.id === 'confirmpassword') {
          if (!Validate.validatePasswordBlur(event.target.value)) { 
            setConfirmPasswordError("Invalid confirm password");
        } else {
          setConfirmPasswordError("");
        }
      }
    } else {
      if (event.target.id === 'newpassword') {
        setNewPasswordError("");
      } else if (event.target.id === 'confirmpassword') {
        setConfirmPasswordError("");
      }
    }
  }

  const resetSubmit = async event => {
    event.preventDefault();
    if (validateReset()) {
      dispatch(loginAction.resetHandler(key, newPassword));
    }
  }

  return (
    <Container>
      <div className="auth-wrapper Login">
        <div className="auth-top"><img src={companyLogo} className="company-logo mx-auto d-block" alt="username" />
        <p className="text-center">{new Intl.DateTimeFormat('default', {weekday: 'long', year: 'numeric', month: 'long',day: '2-digit'}).format(today)}</p>
        </div>

        <div className="auth-inner">
            <div><p>{t('resetpassword')}</p></div>
            <Row className="mt-12">
              <Col sm={{ size: 4 }}><Label>{t('newpassword')} :</Label></Col>
              <Col sm={{ size: 8 }}>
              <Input type="password" name="newpassword" id="newpassword" className="form-control" placeholder={t('placeholder.enter', { 0: t('newpassword') })} 
              onChange={e => setNewPassword(e.target.value)} onKeyPress={e => Validate.validatePasswordKeyPress(e)} minLength={8} maxLength={15} onBlur={e => onBlurValidate(e)}/></Col>
            </Row>
            <Row className="mt-12">
                <Col sm={{ size: 8, offset: 4 }}><div className="error">{newPasswordError}</div></Col>
            </Row>
            <Row className="mt-12">
              <Col sm={{ size: 4 }}><Label>{t('confirmnewpassword')} :</Label></Col>
              <Col sm={{ size: 8 }}>
                <Input type="password" name="confirmpassword" id="confirmpassword" className="form-control" placeholder={t('placeholder.enter', { 0: t('confirmnewpassword') })} 
                onChange={e => setConfirmPassword(e.target.value)} onKeyPress={e => Validate.validatePasswordKeyPress(e)} minLength={8} maxLength={15} onBlur={e => onBlurValidate(e)}/>
              </Col>
            </Row>
            <Row className="mt-12">
                <Col sm={{ size: 8, offset: 4 }}><div className="error">{confirmPasswordError}</div></Col>
            </Row>
            <Row className="mt-12">
                <Col sm={{ size: 8, offset: 4 }}>
                  <Button type="submit" color="secondary" className="btn--wide" onClick={resetSubmit}>OK</Button>
                </Col>       
            </Row>
          
        </div>
      </div>
      </Container> 
  );
}

export default ResetPassword;
