import React from "react";
import { Route, Switch } from 'react-router';
import company from './company/Company';
import companyProduct from './company/CompanyProduct';
import companyDetail from './company/CompanyDetail';
import account from './account/Account';
import accountDetail from './account/AccountDetail';
import homepage from './common/Home';
import accessControl from './role/AccessControl';
import accessControlDetail from './role/AccessControlDetail';
import claim from './claim/Claim';
import claimApproval from './claim/ClaimApproval';
import certificateIssuedReport from './report/CertificateIssuedReport';
import claimRegistrationReport from './report/ClaimRegistrationReport';
import hostSubmission from './batch/HostSubmission';
import certificateBatchUpload from "./transaction/certificateBatchUpload/CertificateBatchUpload";
import transactionEnquiry from './enquiry/TransactionEnquiry';
import transactionDetail from './enquiry/TransactionDetail'; 
import certificateInsurance from './transaction/CertificateInsuranceB2B';
import acknowledgement from "./transaction/AcknowledgementB2B";

const appRoute = () => {
    return (
    <>
        <Switch>
            <Route exact path={[".", "/", "/home"]} component={homepage} />
            <Route path="/company" component={company} />
            <Route path="/companyProd" component={companyProduct} />
            <Route path="/companyDetail" component={companyDetail} />
            <Route path="/account" component={account} />
            <Route path="/accountDetail" component={accountDetail} />
            <Route path="/accessControl" component={accessControl} />
            <Route path="/accessControlDetail" component={accessControlDetail} />
            <Route path="/claimAppr" component={claim} />
            <Route path="/claimApprDetail" component={claimApproval} />
            <Route path="/certIssuedReport" component={certificateIssuedReport} />
            <Route path="/claimRegisterReport" component={claimRegistrationReport} />
            <Route path="/hostSubmission" component={hostSubmission} />
            <Route path="/certificateBatchUpload" component={certificateBatchUpload} />
            <Route path="/transactionEnquiry" component={transactionEnquiry} />
            <Route path="/transactionDetail" component={transactionDetail} />
            <Route path="/partnerPOS" component={certificateInsurance} />
            <Route path="/acknowledgement" component={acknowledgement}/>
        </Switch>
    </>
    )
}

export default appRoute;