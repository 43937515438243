import * as urlConstant from './../constants/urlConstant';
import * as Crypto from './../utils/Crypto';
import { Get, Post } from './../utils/AxFetch';
import { push } from 'connected-react-router';
// import { base64StringToBlob } from 'blob-util';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const LOGIN_PROCESS = 'LOGIN_PROCESS';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const DISPLAY_INFO = 'DISPLAY_INFO';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const POPULATE_MENU = "POPULATE_MENU";
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_PRODUCT_VERSION = 'UPDATE_PRODUCT_VERSION';
export const DISPLAY_LOGO = "DISPLAY_LOGO";


const loginHandler = (username, password) => async dispatch =>{
	let passwordEncrypt = Crypto.encryptNow(password);
		
    let loginRes = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_LOGIN,

    //this is for kong gateway testing pupose
    // let loginRes = await AxFetch("http://192.168.0.26:8000/axTest/login" ,
    // let loginRes = await AxFetch("http://192.168.0.26:8000/login" ,
    {params: {loginId: username, password: passwordEncrypt}}, dispatch);

    if (!loginRes.isError) {
        
        if (loginRes.respData.data.token && !loginRes.respData.data.isOtp) {
            dispatch({ type: LOGIN_SUCCESS, loginRespData: loginRes.respData.data });

            let sideMenuRes = await Get(urlConstant.URL_BASED + urlConstant.URL_SIDEMENU,
            loginRes.respData.data, dispatch);

            let companyResp = await Get(urlConstant.URL_BASED + urlConstant.URL_COMPANY_LOGO_RETRIVE + loginRes.respData.data.loginId, null, dispatch);
            if(!companyResp.isError && companyResp.respData.data) {
            // const blob = base64StringToBlob(resp.respData.data.companylogo, 'image');
            // const urlObject = window.URL.createObjectURL(blob);

            dispatch({type: DISPLAY_LOGO, 
                // companylogoImgBase64: urlObject, 
                companylogoImgBase64: companyResp.respData.data.companyLogo,
                companylogoImgStatus: companyResp.respData.data.logoDisplayStatus }); 
            }

            if (!sideMenuRes.isError) {
                dispatch({ type: POPULATE_MENU, sideMenuRespData: sideMenuRes.respData.data });
                dispatch(push('/'));
                dispatch({type : 'CLEAR_ERROR'});
            }   

        }else{
            dispatch({ type: LOGIN_PROCESS, loginRespData: loginRes.respData.data });
        }   
    }
}


const loginOtpHandler = (username, password, otpno) => async dispatch => {
	let passwordEncrypt = Crypto.encryptNow(password);
	
    //let loginOtpRes = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_OTP,
    //{params: {loginId: username, password: passwordEncrypt, otp: otpno}}, dispatch);
    
    console.log(password);
    console.log(passwordEncrypt);

    //test
    let postObj = {loginId: username, password: passwordEncrypt, otp: otpno};
    let loginOtpRes = await Post(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_OTP, 
        postObj, dispatch);

    
    if (!loginOtpRes.isError && loginOtpRes.respData.data.token){
        
        dispatch({ type: LOGIN_SUCCESS, loginRespData: loginOtpRes.respData.data });

        let sideMenuRes = await Get(urlConstant.URL_BASED + urlConstant.URL_SIDEMENU,
        loginOtpRes.respData.data, dispatch);

        let companyResp = await Get(urlConstant.URL_BASED + urlConstant.URL_COMPANY_LOGO_RETRIVE + loginOtpRes.respData.data.loginId, null, dispatch);
        if(!companyResp.isError && companyResp.respData.data) {
            dispatch({type: DISPLAY_LOGO, 
                // companylogoImgBase64: urlObject, 
                companylogoImgBase64: companyResp.respData.data.companyLogo,
                companylogoImgStatus: companyResp.respData.data.logoDisplayStatus });
        }

        if (!sideMenuRes.isError) {
            dispatch({ type: POPULATE_MENU, sideMenuRespData: sideMenuRes.respData.data });
            dispatch(push('/'));
            dispatch({type : 'CLEAR_ERROR'});
        }  

    }
}

const logoutHandler = async dispatch => {
    let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_LOGOUT, null, dispatch);
    if(!resp.isError){
        dispatch({ type : LOGOUT });
        dispatch(push({pathname: '/logout'}));
    }
}

const resetHandler = (key, newpassword) => async dispatch =>{
	let passEncrypt = Crypto.encryptNow(newpassword);
	
    let resetRes = await Get(urlConstant.URL_BASED + urlConstant.URL_RESET_PASSWORD,
        {
            params: {
                key: key,
                password: passEncrypt
            }
        }, dispatch);
        if(!resetRes.isError && resetRes.respData.data) { 
        dispatch({ type: DISPLAY_INFO, infoMsg: 'Your password has been changed successfully. Please use your new password to login.' });
        dispatch(push({pathname: '/login'}));
    }
}

const forgotHandler = (username, email) => async dispatch =>{
    let forgetRes = await Get(urlConstant.URL_BASED + urlConstant.URL_FORGOT_PASSWORD,
        {
            params: {
                loginId: username, 
                email: email
        }
    }, dispatch);
    if(forgetRes.respData && forgetRes.respData.data) { 
        dispatch({ type: DISPLAY_INFO, infoMsg: 'Please check your email to change password!' });
    }

}


// Assign the object to a variable before exporting it as default
const exportedAction = {
    loginHandler,
    loginOtpHandler,
    logoutHandler,
    forgotHandler,
    resetHandler
};

export default exportedAction;