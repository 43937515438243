import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Label, Input, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from './../../components/DatePicker';
import SelectUtil from '../../utils/Select';
import { useDispatch, useSelector } from 'react-redux';
import { Get,Download } from '../../utils/AxFetch';
import * as urlConstant from '../../constants/urlConstant';
import * as Constant from '../../constants/Constant';
import ClaimRegistrationReportListing from './ClaimRegistrationReportListing';
import * as Validate from './../../utils/CommonValidate';
import $ from 'jquery';
import Select from "react-select";

const ClaimRegistrationReport = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const companyId = useSelector(state => state.loginReducer.companyId);
    const companyName = useSelector(state => state.loginReducer.companyName);
    const isStaff = useSelector(state => state.loginReducer.isStaff);
    
    const [drpdwnListComp,setDropdownList] = useState({activeCompanyList:[]});
    const [productListData,setProductListData] = useState({compProdListData:[]});
    const [searchCriteria,setSearchCriteria] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedOptions, setSelectedOptions] = useState(productListData.compProdListData);    
    const [message,setMessage] = useState("");
    const [isClickSearchButton, setIsClickSearchButton] = useState(false);
    const [claimStatusData, setClaimStatusData] = useState({claimStatusList:[]});
    const [policyType, setPolicyType] = useState({policyTypeList:[]});
    let selectCompany = '';

    const validateDt = () => {
        let message = '';
               
        if (!($('input[name="effFrom"]').val() && $('input[name="effTo"]').val())) {
            message += t('error.message.select.blank', { 0: t('certificateinsurance.search.txndate') }) +'\n'
        }
        
        if ( !(Validate.validateDate( $('input[name="effFrom"]').val(), $('input[name="effTo"]').val())) ) {
            message += t('error.message.greaterthan.enddate') +'\n'
        } 
        
        if (!Validate.validateIdentity($('input[name="ktpno"]').val())){
            message += t('error.message.invalid.field', {0: t('claimregistered.search.ktpno')}) +'\n'
        } 
        
        if (message) {
            setMessage(message);
            return false;
        }
        return true;
    }

    const getClaimStatus = async dispatch => {
        let claimStatLst = [];

        let claimStatusListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_CLAIM_APPROVAL_STATUS_LIST, null, dispatch);
       
        if (!claimStatusListResp.isError && claimStatusListResp.respData.data) {
            claimStatLst = claimStatusListResp.respData.data.map( claimstatus => {
                return {shortCode:claimstatus.code, value:claimstatus.desc}
            });
        }
        setClaimStatusData({claimStatusList: claimStatLst});
    }

    const getPolicyTypeList = async dispatch => {
        let list = [];

        let polcyTypeListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_COMMON_POLICY_TYPE_LIST, null, dispatch);

        if(!polcyTypeListResp.isError && polcyTypeListResp.respData.data) {
            list = polcyTypeListResp.respData.data.map(policyType => {
                return {
                    shortCode: policyType.code, value: policyType.desc
                }
            });
        }

        setPolicyType({policyTypeList: list});
    }

    useEffect(() => {
        const dropdownListComp = async dispatch => {
            let activeCompany = [];
            let attachedCompany = [];
            
            let activeCompanyResp = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_ACTIVECOMPANY, null, dispatch);
            
            if (isStaff){
                if(!activeCompanyResp.isError && activeCompanyResp.respData.data) {
                    activeCompany = activeCompanyResp.respData.data.map(company => {
                        return {shortCode: company.id, value: company.companyName}
                    });
                }
                setDropdownList({activeCompanyList: activeCompany});
            }else{
                if(!activeCompanyResp.isError && activeCompanyResp.respData.data) {
                    let activeCompData = activeCompanyResp.respData.data;
                    attachedCompany = activeCompData.filter(comp => comp.id === companyId ).map(filteredComp => {
                        return {shortCode: filteredComp.id, value: companyName}
                    });
                }
                setDropdownList({activeCompanyList: attachedCompany});
            }                
        }
        dispatch(dropdownListComp);
        dispatch(getClaimStatus);
        dispatch(getPolicyTypeList);
        }, [dispatch, companyId, companyName, isStaff]);
    
    const getProductList = async dispatch => {
        let prodList = [];
           
        let compProductListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_REPORT_COMPANY_PRODUCT_LIST + selectCompany, null, dispatch);
            
        if (!compProductListResp.isError && compProductListResp.respData.data) {
            prodList = compProductListResp.respData.data.map( product => {
                return {value: product.code, label: product.desc} //to accomodate react-select
            }); 
        }
        setProductListData({compProdListData: prodList});
    }
    
    const handleChangeCompany = event => {
        setSelectedOptions([]);
        selectCompany = event.target.value;
        if (selectCompany) dispatch(getProductList);
    }

    const handleMultiChangeProduct = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    }

    const handleSearchCriteria = () => {
        let searchEffectiveFromDt = '';
        let searchEffectiveToDt = '';
        let searchCompanyId = '';
        let searchKTPNo = '';
        let searchClaimStatus = '';
        let searchPolicyType = '';
        if (validateDt()) {
            if ($('select[name="companyId"]').val() !== undefined) {
                searchCompanyId = $('select[name="companyId"]').val();
            }
            if ($('input[name="ktpno"]').val() !== undefined) {
                searchKTPNo = $('input[name="ktpno"]').val();
            }
            if ($('input[name="effFrom"]').val() !== undefined) {
                searchEffectiveFromDt = $('input[name="effFrom"]').val();
            }
            if ($('input[name="effTo"]').val() !== undefined) {
                searchEffectiveToDt = $('input[name="effTo"]').val();
            }
            if ($('select[name="claimstatus"]').val() !== undefined) {
                searchClaimStatus = $('select[name="claimstatus"]').val();
            }
            if ($('select[name="policyType"]').val() !== undefined) {
                searchPolicyType = $('select[name="policyType"]').val();
            }
            let prodCodeList =[];
            $("input[name='prodCode']").each(function() {
                if (this.value) prodCodeList.push(this.value);
            });

            setSearchCriteria({
                searchCompanyId: searchCompanyId,
                searchProdCodes: prodCodeList,
                searchKTPNo: searchKTPNo,
                searchEffectiveFromDt: searchEffectiveFromDt,
                searchEffectiveToDt: searchEffectiveToDt,
                searchClaimStatus: searchClaimStatus,
                searchPolicyType: searchPolicyType
            })
        }
    }

    useEffect(() => {
        const getClaimRegisteredReportDwnld = async() => {
            let outputfilename = '';
            outputfilename = Constant.claimregistrreportfile;

            if (searchCriteria){
                await Download(urlConstant.URL_BASED + urlConstant.URL_REPORT_CLAIM_REGISTERED_DWNLD, 
                    Constant.downloadType.POST,
                    {
                        claimDateFrom: searchCriteria.searchEffectiveFromDt,
                        claimDateTo: searchCriteria.searchEffectiveToDt,
                        companyId: searchCriteria.searchCompanyId,
                        productCode: searchCriteria.searchProdCodes,
                        proposerIdentityNo: searchCriteria.searchKTPNo,
                        claimStatus: searchCriteria.searchClaimStatus,
                        policyType: searchCriteria.searchPolicyType
                    }, outputfilename, dispatch);
            }
        }
        if (searchCriteria && !isClickSearchButton) dispatch(getClaimRegisteredReportDwnld);
    }, [dispatch, searchCriteria, isClickSearchButton]);
    
    return(
        <div>
            <Container style={{marginBottom: "30px"}}>
                { message? <div className="error"><p>{message}</p></div>: null}
                <Row className="mt-3"><Col><h2>{t('claimregistered.report')}</h2></Col></Row>
                <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('Claim Registered Report Search')}</h4></Col></Row>
                <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('claimregistered.search.date')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col sm={{ size: 2 }} md="2"><DatePicker className="form-control datepicker-icon"  selected={startDate} onChange={date => setStartDate(date)} name="effFrom"/></Col>
                    <Col sm={{ size: 1 }} md="1" className="text-center"><Label>{t('effectivedateto')}</Label></Col>
                    <Col sm={{ size: 2 }} md="2" ><DatePicker className="form-control datepicker-icon" selected={endDate} onChange={date => setEndDate(date)} name="effTo"/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('company')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><SelectUtil name='companyId' optionList={drpdwnListComp.activeCompanyList} onChange={handleChangeCompany}/></Col>    
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('productname')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select 
                        id='prodCode' 
                        name='prodCode'
                        placeholder={t('placeholder.select')} 
                        options={productListData.compProdListData}
                        value={selectedOptions} 
                        onChange={handleMultiChangeProduct} 
                        isMulti
                    /></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('claimregistered.search.ktpno')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Input type="text" name="ktpno" onKeyPress={(event) => Validate.identityKeyPress(event)} maxLength={16}/></Col>    
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('claimregistered.search.claimstatus')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><SelectUtil name="claimstatus" optionList={claimStatusData.claimStatusList} /></Col>    
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('claimregistered.search.policytype')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><SelectUtil name="policyType" optionList={policyType.policyTypeList} /></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="12" sm="12" md="12">
                        <center>
                            <Button name="btnSearch" onClick={ () => {handleSearchCriteria(); setIsClickSearchButton(true)} } >{t('button.search')}</Button>
                            <> </>
                            <Button name="btnDownload" onClick={ () => {handleSearchCriteria(); setIsClickSearchButton(false)} } >{t('button.download')}</Button>
                        </center>
                    </Col>
                </Row>
            </Container>

            <ClaimRegistrationReportListing searchCriteria={searchCriteria} isClickSearchButton={isClickSearchButton}/>
        </div>
    )
}
export default ClaimRegistrationReport;