import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from './../../../utils/Select';
import * as Validate from './../../../utils/CommonValidate';
import DatePicker from './../../../components/DatePicker';
import AutoComplete from './../../../utils/AutoComplete';
import ProposerItems from './ProposerItems';

const ProposerInfo = ({ respData, fieldValue, setFieldValue, onChangeInputSelect, onChangeDate, fields, onChangeCurrency,onChangeDobInputPopulateAge,isCityDisabled,handleDisableCityDropdown, setRespData, onClickOnSelectDropdown }) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }

    const [panelOpen, setPanelOpen] = useState(true);
    const [item] = useState("proposerItems");
    // const [isCityDisabled, setIsCityDisabled] = useState(true);

    const toggle = () => {setPanelOpen(!panelOpen)};

    // const handleDisableCityDropdown = (isDisabled) => {
    //     setIsCityDisabled(isDisabled);
    // };
    
    return (
        <div>
        <Button className={"accordion-toggle mt-3 " + (panelOpen ? "collapse-icon" : "expand-icon")} 
            onClick={e => toggle(e)}>{t("proposerInformation")}<br></br><i>{t2('proposerInformation')}</i>
        </Button>
        <Collapse isOpen={panelOpen}>
            <Card>
                <CardBody>
                    <div className="container-flex">
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_name" className="required">{t("trnx.proposer.name")}<br></br><i>{t2('trnx.proposer.name')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                <Input name="name" maxLength={100} 
                                    value={fieldValue[item]["name"].value} 
                                    onChange={e => onChangeInputSelect(e, "proposerItems", false)} 
                                />
                                <div className="error"><span id="errMsg_proposer_name"/></div>
                            </Col>
                        </Col>
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_identityNo" className="required">{t("trnx.proposer.identityNo")}<br></br><i>{t2('trnx.proposer.identityNo')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                <Input name="identityNo" maxLength={50} 
                                    value={fieldValue[item]["identityNo"].value} 
                                    onChange={e => onChangeInputSelect(e, "proposerItems", false)} 
                                />
                                <div className="error"><span id="errMsg_proposer_identityNo"/></div>
                            </Col>
                        </Col>
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_dob">{t("trnx.proposer.dob")}<br></br><i>{t2('trnx.proposer.dob')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                <DatePicker className="form-control datepicker-icon" 
                                    placeholderText={t('placeholder.dateFormat')} 
                                    selected={fieldValue[item]["dob"].value} 
                                    onChange={value => {onChangeDate(value, "proposerItems", "dob"); onChangeDobInputPopulateAge(value, "riskItems", "riskAge");}} 
                                    name="dob" 
                                />
                                <div className="error"><span id="errMsg_proposer_dob"/></div>
                            </Col>
                        </Col> 
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_gender">{t("trnx.proposer.gender")}<br></br><i>{t2('trnx.proposer.gender')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                <Select name="gender" 
                                    defaultVal={fieldValue[item]["gender"].value} 
                                    onMouseDown={e => onClickOnSelectDropdown(e,"proposerItems")}
                                    onChange={e => onChangeInputSelect(e, "proposerItems",true)}
                                    optionList={respData.lookupParams ? respData.lookupParams["GENDER"] : ""}
                                />
                                <div className="error"><span id="errMsg_proposer_gender"/></div>
                            </Col>
                        </Col>
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_email" className="required">{t("trnx.proposer.email")}<br></br><i>{t2('trnx.proposer.email')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                <Input name="email" maxLength={100} 
                                    value={fieldValue[item]["email"].value} 
                                    onChange={e => onChangeInputSelect(e, "proposerItems", false)} 
                                />
                                <div className="error"><span id="errMsg_proposer_email"/></div>
                            </Col>
                        </Col>
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_mobileNo" className="required">{t("trnx.proposer.mobileNo")}<br></br><i>{t2('trnx.proposer.mobileNo')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                <Input name="mobileNo" maxLength={12} 
                                    value={fieldValue[item]["mobileNo"].value} 
                                    onChange={e => onChangeInputSelect(e, "proposerItems", false)} 
                                    onKeyPress={(event) => Validate.number(event)}
                                />
                                <div className="error"><span id="errMsg_proposer_mobileNo"/></div>
                            </Col>
                        </Col>
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_address">{t("trnx.proposer.address")}<br></br><i>{t2('trnx.proposer.address')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                <Input
                                    rows={3}
                                    type='textarea' 
                                    name="fullAddress" maxLength={150} 
                                    value={fieldValue[item]["fullAddress"].value} 
                                    onChange={e => onChangeInputSelect(e, "proposerItems", false)} 
                                />
                                <div className="error"><span id="errMsg_proposer_fullAddress"/></div>
                            </Col>
                        </Col>
                        <Col sm="6" md="6" />
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_province">{t("trnx.proposer.province")}<br></br><i>{t2('trnx.proposer.province')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                <AutoComplete
                                    name="province" 
                                    defaultVal={fieldValue[item]["province"].desc} 
                                    fieldValue={fieldValue}
                                    setFieldValue={e=>setFieldValue(e)}
                                    item="proposerItems"
                                    respData={respData}
                                    setRespData={setRespData}
                                    optionList={respData.lookupParams ? respData.lookupParams["PROVINCE"] : ""}
                                    onDisableCityDropdown={handleDisableCityDropdown}
                                />
                                <div className="error"><span id="errMsg_proposer_province"/></div>
                            </Col>
                        </Col>
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_city">{t("trnx.proposer.city")}<br></br><i>{t2('trnx.proposer.city')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                <AutoComplete
                                    name="city" 
                                    defaultVal={fieldValue[item]["city"].desc} 
                                    fieldValue={fieldValue}
                                    setFieldValue={e=>setFieldValue(e)}
                                    item="proposerItems"
                                    setRespData={setRespData}
                                    respData={respData}
                                    disabled={isCityDisabled}
                                    optionList={respData.lookupParams ? respData.lookupParams["CITY"] : ""}
                                />
                                <div className="error"><span id="errMsg_proposer_city"/></div>
                            </Col>
                        </Col>
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_postCode">{t("trnx.proposer.postCode")}<br></br><i>{t2('trnx.proposer.postCode')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                 <AutoComplete
                                    name="postCode" 
                                    defaultVal={fieldValue[item]["postCode"].desc} 
                                    fieldValue={fieldValue}
                                    setFieldValue={e=>setFieldValue(e)}
                                    item="proposerItems"
                                    setRespData={setRespData}
                                    respData={respData}
                                    cityStatus={isCityDisabled}
                                    optionList={respData.lookupParams ? respData.lookupParams["POSTAL_CODE"] : ""}
                                />
                                <div className="error"><span id="errMsg_proposer_postCode"/></div>
                            </Col>
                        </Col>
                        <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label id="label_nationality" className="required">{t("trnx.proposer.nationality")}<br></br><i>{t2('trnx.proposer.nationality')}</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="6" md="6">
                                <AutoComplete
                                    name="nationality" 
                                    defaultVal={fieldValue[item]["nationality"].desc} 
                                    fieldValue={fieldValue}
                                    setFieldValue={e=>setFieldValue(e)}
                                    item="proposerItems"
                                    setRespData={setRespData}
                                    respData={respData}
                                    optionList={respData.lookupParams ? respData.lookupParams["NATIONALITY"] : ""}
                                />
                                <div className="error"><span id="errMsg_proposer_nationality"/></div>
                            </Col>
                        </Col>
                    </div>
                    <ProposerItems 
                            respData={respData} 
                            fields={fields} 
                            fieldValue={fieldValue} 
                            onChangeInputSelect={onChangeInputSelect}
                        />
                </CardBody>
            </Card>
        </Collapse>
        </div>
    )
};

export default ProposerInfo;