import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor, history } from './store'
import { ConnectedRouter } from 'connected-react-router'
import Loading from './utils/Loading'
import axios from 'axios';
import * as Constant from './constants/Constant';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = store.getState().loginReducer.token;

    config.headers.sourceId = Constant.sourdeId;
    if(token && token !== '') {
        config.headers.token = Constant.tokenPrefix + token;
    }

    return config;
});

ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={<Loading/>} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </PersistGate>
        </Provider>, 
        document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
