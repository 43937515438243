import React, { useState, useEffect } from 'react';
import { Button, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { push } from "connected-react-router";
import { Get } from './../../utils/AxFetch';
import * as Constant from "./../../constants/Constant";
import * as urlConstant from './../../constants/urlConstant';
import * as Validate from './../../utils/CommonValidate';
import companyLogo from './../../images/gel-logo.png';
import $ from 'jquery';
import * as actions from './../../actions/loginAction';

const ClaimRegistration = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [fieldInput, setFieldInput] = useState('');
    const dispatch = useDispatch();

    const handleNext = async() => {
        if (validateForm()) {
            let mobileNo = $('input[name="mobileno"]').val();
            let policyNo = $('input[name="certificateinsuranceno"]').val();
            let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_CLAIM_REGISTRATION,    
                {
                    params: {
                        mobileNo: mobileNo, 
                        policyNo: policyNo
                    }
                }, dispatch);
            if (!resp.isError) {
                setModalIsOpen(true);
                setFieldInput({mobileNo: mobileNo, policyNo: policyNo})
            } else {
                $(':input').val('');
            }
        }
    }

    const validateForm = () => {
        let mobilenoError = "";
        let certificateinsurancenoError = "";

        if (!$('input[name="mobileno"]').val()) mobilenoError = "Mobile No cannot be blank";

        if (!$('input[name="certificateinsuranceno"]').val()) certificateinsurancenoError = "Certificate Insurance No cannot be blank";

        setErrorMsg({ mobilenoError: mobilenoError, certificateinsurancenoError: certificateinsurancenoError});
        if (mobilenoError || certificateinsurancenoError) {
            return false;
        }
        return true;
    };

    const handleClose = () => {
        setModalIsOpen(false);
        setEmailSent(true);
        $(':input').val('');
    }

    const handleVerification = async() => {

        if (!$('input[name="verificationcode"]').val()) {
            setErrorMsg({ verificationcodeError: 'Verification Code cannot be blank'});
        } else {
            if (errorMsg.verificationcodeError) {
                setErrorMsg({ verificationcodeError: ''});
            }
            let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_CLAIM_VERIFYCLAIMOTP,    
                {
                    params: {
                        otp: $('input[name="verificationcode"]').val(),
                        policyNo: fieldInput.policyNo
                    }
                }, dispatch);
            if (!resp.isError) {
                if (resp.respData.data) {
                    dispatch({ type: actions.UPDATE_TOKEN, token: resp.respData.data.token });
                    dispatch(push({pathname: Constant.PATH_CLAIM_SUBMISSION, state: { data: resp.respData.data }}))
                }
            }
        }
    }

    const handleCancel = () => setEmailSent(false);

    useEffect(() => {
        dispatch({ type: actions.UPDATE_TOKEN, token: '' });
    }, [dispatch])

    return (
        <div>
            <div className="auth-top claim"><img src={companyLogo} className="company-logo mx-auto d-block" alt="username" /></div>
            <Container className="auth-inner claim">
                <Row className="mt-3"><Col><h2>Pendaftaran Klaim</h2></Col></Row>
                <Row><Col><h4><i>Claim Registration</i></h4></Col></Row><br></br>
                    {!emailSent ?
                    <div>
                    <Row className="mt-3">
                        <Col xs="10" sm="3" md="3"><Label>Nomor Handphone<br></br><i>Mobile No.</i></Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="5" md="5"><Input name="mobileno" onKeyPress={(event) => Validate.numeric(event)} maxLength={20} /></Col>    
                    </Row>
                    {errorMsg.mobilenoError ?
                        <Row>
                            <Col sm={{ size: 8, offset: 4 }}><div className="error">{errorMsg.mobilenoError}</div></Col>
                        </Row>
                        : <></>
                    }
                    <Row className="mt-3">
                        <Col xs="10" sm="3" md="3"><Label>Nomor Sertifikat Asuransi<br></br><i>Certificate Insurance No.</i></Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="5" md="5"><Input name="certificateinsuranceno" maxLength={60} /></Col>    
                    </Row>
                    {errorMsg.certificateinsurancenoError ?
                        <Row>
                            <Col sm={{ size: 8, offset: 4 }}><div className="error">{errorMsg.certificateinsurancenoError}</div></Col>
                        </Row>
                        : <></>
                    }
                    <div className="text-center">
                        <Button className="mt-3" onClick={handleNext}>Next</Button>
                    </div>
                    <Row className="mt-5">
                        <Col xs="12" sm="12" md="12">Kami telah menerima pemberitahuan klaim. Mohon untuk melengkapi dokumen yang dipersyaratkan. Pengiriman dokumen bukan sebagai kepastian bahwa klaim akan dibayar. Kami akan melakukan pengecekan terlebih dahulu.</Col> 
                    </Row>
                    </div>
                    :
                    <div>
                    <Row className="mt-3">
                        <Col xs="10" sm="3" md="3"><Label>Kode Verifikasi<br></br><i>Verification Code</i></Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="5" md="5"><Input name="verificationcode" onKeyPress={(event) => Validate.number(event)} maxLength={6} /></Col>    
                    </Row>
                    {errorMsg.verificationcodeError ?
                        <Row>
                            <Col sm={{ size: 8, offset: 4 }}><div className="error">{errorMsg.verificationcodeError}</div></Col>
                        </Row>
                        : <></>
                    }
                    <div className="text-center">
                        <Button className="mt-3" onClick={handleVerification}>Next</Button>{' '}
                        <Button className="mt-3" onClick={handleCancel}>Cancel</Button>
                    </div>
                    </div>
                    }
            </Container>
            <Modal isOpen={modalIsOpen} className="modal-dialog">
                <ModalHeader className="modal_header">
                Pesan<i> / Message</i>
                </ModalHeader>
                <ModalBody className="report-modal-body">
                "Kode Verifikasi" sudah dikirimkan ke email Anda. Mohon diperiksa.<br></br>
                <i>"Verification Code" has been sent to your registered email account. Please check.</i>
                </ModalBody>
                <ModalFooter><Button className="mt-3 mx-auto" onClick={handleClose}>OK</Button></ModalFooter>
            </Modal>
    </div>
    )
}

export default ClaimRegistration;