import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Table } from 'reactstrap';
import PaginationBar from '../../components/PaginationBar';
import * as Constant from './../../constants/Constant';
import * as urlConstant from './../../constants/urlConstant';
import { Post } from '../../utils/AxFetch';
import { useTranslation } from 'react-i18next';

const AccountListing = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [accountListParams,setAccountListParams] = useState({data:[], currentPage:1, pageSize:0, pagesCount:0});
    const [pageOnClick,setPageOnClick] = useState(1);
    
    useEffect(() => {
        if (props.searchCriteria) {
            setPageOnClick(1);
        }
    }, [props.searchCriteria]);

    const handleEdit = (id) => {
        let selected = {};
        // selected.id = $(obj.currentTarget).parent().find("td:eq(5)").text();
        selected = id;

        if(!selected) return;
        dispatch(push({pathname: Constant.PATH_ACCOUNT_DETAILS, state: { action: 'EDIT', selected: selected }}))
    }

    const onClick = (page) => {  
        setPageOnClick(page)  
    }

    useEffect(() => {
        let searchCriteria = props.searchCriteria;
        const getAccountList = async(page) => {
            let active = '';
            if(searchCriteria.accStatus === 'false') {
                active = false;
            } else if(searchCriteria.accStatus === 'true') {
                active = true;
            }
    
            let resData = '';
            let resCurrentPage = '';
            let resPageSize = '';
            let resPagesCount = '';
            let resp = "";

            resp = await Post(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_LIST + page,    
                {
                    rowPerPage: Constant.rowPerPage,
                    pageNum: page,
                    name: searchCriteria.accName,
                    company: searchCriteria.accCompanyID,
                    loginId: searchCriteria.accLoginId,
                    active: active
                }, dispatch);
            
            let dataResp = resp.respData;
            
            if(!resp.isError && dataResp.data) {
                resData = dataResp.data.content;
                resCurrentPage = page;
                resPageSize = dataResp.data.size;
                resPagesCount = dataResp.data.totalPages;
            }
            setAccountListParams({data:resData, currentPage: resCurrentPage, pageSize: resPageSize, pagesCount: resPagesCount});
        }
        if (searchCriteria) getAccountList(pageOnClick);
}, [dispatch, props.searchCriteria, pageOnClick]);
    return (
        <div>
            <Table className="mt-3" bordered>
                <thead>
                    <tr>
                        <th style={{width: '10%'}}>{t('no.')}</th>
                        <th>{t('company')}</th>
                        <th>{t('gegiaccountno')}</th>
                        <th>{t('accountname')}</th>
                        <th>{t('effectivedate')}</th>
                        <th>{t('status')}</th>
                    </tr>
                </thead>
                {(accountListParams.data && accountListParams.data.length > 0) ?
                <tbody>
                    {accountListParams.data.map((item, i) => {
                        let status = 'Inactive';
                        if(item.active) status = 'Active';

                        return (
                            <tr key={i}>
                                <td style={{width: '10%'}}>{i+1}</td>
                                <td>{item.companyName}</td>
                                <td><button className="link-button" onClick={() => handleEdit(item.id)}>{item.loginId}</button></td>
                                <td>{item.name}</td>
                                {item.effectiveStartDate !== null ?
                                    <td>{item.effectiveStartDate} to {item.effectiveEndDate}</td> : <td></td>}
                                <td>{status}</td>
                            </tr>
                        )}
                        )}
                </tbody>
                : <tbody>
                    <tr><td className="emptyrecord" colSpan={6}>{t('norecordfound')}</td></tr>
                </tbody>
            }
            </Table>
            <PaginationBar totalPages={accountListParams.pagesCount} currentPage={accountListParams.currentPage} handleClick={(page) => onClick(page)}  />
        </div>
    );
}

export default AccountListing;