import React, { Fragment, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import PaginationBar from './../../../components/PaginationBar';
import Endorsement from './Endorsement';
import * as Constant from './../../../constants/Constant';
import * as urlConstant from './../../../constants/urlConstant';
import { Post } from './../../../utils/AxFetch';
import { useDispatch } from 'react-redux';

const CertificateInsuranceDetails = (props) => {
  const { t } = useTranslation();
  const [collapseDetail, setCollapseDetail] = useState({isExpand:false, id:''});
  const dispatch = useDispatch();
  const [pageOnClick,setPageOnClick] = useState(1);
  const [certInsuranceListPagination,setCertInsuranceListPagination] = useState({data:[], currentPage:1, pageSize:0, pagesCount:0});
  const [prodCode, setProdCode] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [insuranceStatus, setInsuranceStatus] = useState('');

  const onClick = (page) => { setPageOnClick(page) };
  
  const expandCollapseDetails = (id) => {
    if ( $("#" + $.escapeSelector(id)).hasClass("expandTable") ) {
      $("#" + $.escapeSelector(id)).addClass("collapseTable");
      $("#" + $.escapeSelector(id)).removeClass("expandTable");
      setCollapseDetail({isExpand:true, id:id});
    } else if ($("#" + $.escapeSelector(id)).hasClass("collapseTable")) {
      $("#" + $.escapeSelector(id)).addClass("expandTable");
      $("#" + $.escapeSelector(id)).removeClass("collapseTable");
      setCollapseDetail({isExpand:false, id:id});
    }
  }

  useEffect(() => {
    if (!props.modalIsOpen && pageOnClick !== 1) {
      setPageOnClick('');
    } else if (props.modalIsOpen && !pageOnClick) {
      setPageOnClick(1);
    }
  }, [props.modalIsOpen, pageOnClick]);

  useEffect(() => {
    setProdCode(props.productCode);
    setFromDate(props.fromDate);
    setToDate(props.toDate);
    setCompanyId(props.companyId);
    setInsuranceStatus(props.insuranceStatus);
  }, [props.fromDate, props.toDate, props.productCode, props.companyId, props.insuranceStatus]);

useEffect(() => {
  const getCertInsuranceDet = async (page) => {
    let resData = '';
    let resCurrentPage = '';
    let resPageSize = '';
    let resPagesCount = '';
    let urlParams = {};

    if (fromDate) {
      if (toDate){ //year to date filter
        urlParams = {
          productCode: prodCode,
          fromDate: fromDate,
          toDate: toDate,
          companyId: companyId,
          rowPerPage: Constant.rowPerPage,
          pageNum: page,
          status: insuranceStatus
        }
      }else{ //monthly filter
        urlParams = {
          productCode: prodCode,
          fromDate: fromDate,
          companyId: companyId,
          rowPerPage: Constant.rowPerPage,
          pageNum: page,
          status: insuranceStatus
        }
      }
    }else{ //init
      urlParams = {
        productCode: prodCode,
        companyId: companyId,
        rowPerPage: Constant.rowPerPage,
        pageNum: page,
        status: insuranceStatus
      }
    }

    let certInsDetResp = await Post(urlConstant.URL_BASED + urlConstant.URL_TRNX_STATISTIC_DET + page, urlParams, dispatch);
    let dataResp = certInsDetResp.respData;
          
    if(!certInsDetResp.isError && dataResp.data) {
      resData = dataResp.data.content;
      resCurrentPage = page;
      resPageSize = dataResp.data.size;
      resPagesCount = dataResp.data.totalPages;
    }
    setCertInsuranceListPagination({data:resData, currentPage: resCurrentPage, pageSize: resPageSize, pagesCount: resPagesCount});
  }
  
  if (prodCode && pageOnClick) getCertInsuranceDet(pageOnClick);

  if (collapseDetail.isExpand && collapseDetail.id) {
    $("#" + $.escapeSelector(collapseDetail.id) + "_table").addClass("showTable");
    $("#" + $.escapeSelector(collapseDetail.id) + "_table").removeClass("hideTable");
  } else if (!collapseDetail.isExpand && collapseDetail.id) {
    $("#" + $.escapeSelector(collapseDetail.id) + "_table").addClass("hideTable");
    $("#" + $.escapeSelector(collapseDetail.id) + "_table").removeClass("showTable");
  }
}, [dispatch, collapseDetail, pageOnClick, fromDate, toDate, prodCode, companyId, insuranceStatus])

  return (
    <div>
      <Modal isOpen={props.modalIsOpen} className="modal-max" >
        <ModalHeader className="modal_header" toggle={props.handleClose}>
          {t('certificateinsurancedetails')}
        </ModalHeader>
        <ModalBody className="report-modal-body">
          <Table id="certificateInsuranceDetailsTable">
          <thead>
            <Fragment key="certificateInsuranceDetailsFragment">
              <tr>
                <th>{t('no.')}</th>
                <th>{t('certificateinsuranceno')}</th>
                <th>{t('proposername')}</th>
                <th>{t('ktp')}</th>
                <th>{t('certificatestartdate')}</th>
                <th>{t('certificateenddate')}</th>
                <th>{t('product')}</th>
                <th>{t('suminsured')}</th>
                <th>{t('grosspremium')}</th>
                <th>{t('commission')}</th>
                <th>{t('netpremium')}</th>
              </tr>
            </Fragment>
          </thead>
          <tbody>
            {certInsuranceListPagination.data.map((ci, i) => {
              return [
                <Fragment key="certificateInsuranceDetailsInfoFragment">
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{ci.policyNo} {ci.endorsement.length !== 0 ? <span id={ci.policyNo} className="expandTable" onClick={() => expandCollapseDetails(ci.policyNo)}></span> : null}</td>
                    <td>{ci.proposer.name}</td>
                    <td>{ci.proposer.identityNo}</td>
                    <td>{ci.issuanceStartDate}</td>
                    <td>{ci.issuanceEndDate}</td>
                    <td>{ci.productDesc}</td>
                    <td>{ci.sumInsured}</td>
                    <td>{ci.grossPremium}</td>
                    <td>{ci.commissionRate}</td>
                    <td>{ci.nettPremium}</td>
                  </tr>
                  {ci.endorsement.length !== 0 ? 
                    <tr id={ci.policyNo + "_table"} key={ci.policyNo + "_table"} className="hideTable">
                      <td/>
                      <td key={ci.policyNo +"_endorsement"} colSpan="10">
                        <Endorsement items={ci.endorsement} prodCode={ci.productCode}/>
                      </td>
                    </tr>
                  : null}
                  </Fragment>
                 ]
              })
            }
          </tbody>
          </Table>
        </ModalBody>
        <PaginationBar totalPages={certInsuranceListPagination.pagesCount} currentPage={certInsuranceListPagination.currentPage} handleClick={(page) => onClick(page)}  />
      </Modal>
    </div>
  );
}

export default CertificateInsuranceDetails;