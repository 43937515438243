import * as actions from '../../actions/loginAction';

const initState = {
    sideMenuItems: [],
    functionItems: {}
}

const sideMenuReducer = (state = initState, action) => {
  switch(action.type) {
    case actions.POPULATE_MENU: {
      return {
        ...state.sideMenuReducer,
        sideMenuItems: action.sideMenuRespData.menuItem,
        functionItems: action.sideMenuRespData.functionItem
      }
    }
    case actions.LOGOUT: {
      return initState;
    }
    default: {
      return state;
    }
  }
}

export default sideMenuReducer;