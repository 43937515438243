import React, { Component } from 'react';
import {Spinner} from 'reactstrap';

export default class Loading extends Component {

    render() {
        return (
            <div className="loading">
                <Spinner className="center_spinner" animation="border"/>
                <p className="center">Loading</p>
            </div>
        );
    }
}
