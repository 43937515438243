import React, { Component } from "react";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import memoize from "memoize-one";

class PaginationBar extends Component {
    filterPages = memoize((visiblePages, totalPages) => {
        return visiblePages.filter(page => page <= totalPages);
    });

    getVisiblePages = (page, total) => {
        if (total < 7) {
            return this.filterPages([1, 2, 3, 4, 5, 6], total);
        } else {
            if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                return [1, page - 1, page, page + 1, total];
            } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                return [1, total - 3, total - 2, total - 1, total];
            } else {
                return [1, 2, 3, 4, 5, total];
            }
        }
    };

    handleClick = (e, page) => {
        e.preventDefault();
        this.setState({
            visiblePages: this.getVisiblePages(page, this.props.totalPages)
        });
        this.props.handleClick(page);
    }

    render() {
        const visiblePages = this.getVisiblePages(this.props.currentPage , this.props.totalPages);
        return (
            <div className="pagination-wrapper">
            <Pagination aria-label="Product sample">
                <PaginationItem disabled={this.props.currentPage <= 1}>
                    <PaginationLink
                        onClick={e => this.handleClick(e, this.props.currentPage - 1)}
                        previous
                        href="#"
                    />
                </PaginationItem>
                {visiblePages.map((page, index, array) => {
                    let extra = null;
                    if(array[index - 1] + 1 < page) {
                        extra = <PaginationItem><PaginationLink>...</PaginationLink></PaginationItem>;
                    }

                    return (
                        <React.Fragment key={index}>
                             {extra}
                            <PaginationItem active={(page) === this.props.currentPage}>
                                <PaginationLink onClick={e => this.handleClick(e, page)} href="#">
                                    {page}
                                </PaginationLink>
                            </PaginationItem>
                        </React.Fragment>
                    )
                })}
                <PaginationItem disabled={this.props.currentPage >= this.props.totalPages}>
                    <PaginationLink
                        onClick={e => this.handleClick(e, this.props.currentPage + 1)}
                        next
                        href="#"
                    />
                </PaginationItem>
            </Pagination>
        </div>
        )
    }
}

export default PaginationBar;