import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Label, Input, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from './../../components/DatePicker';
import { Get, Download } from '../../utils/AxFetch';
import * as urlConstant from '../../constants/urlConstant';
import * as Constant from '../../constants/Constant';
import { useDispatch, useSelector } from 'react-redux';
import SelectUtil from '../../utils/Select'; //renamed for testing
import CertificateIssuedReportListing from './CertificateIssuedReportListing';
import * as Validate from './../../utils/CommonValidate';
import $ from 'jquery';
import Select from "react-select";

const CertificateIssuedReport = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const companyId = useSelector(state => state.loginReducer.companyId);
    const companyName = useSelector(state => state.loginReducer.companyName);
    const isStaff = useSelector(state => state.loginReducer.isStaff);

    const [drpdwnListComp,setDropdownList] = useState({activeCompanyList:[]});
    const [productListData,setProductListData] = useState({compProdListData:[]});
    const [searchCriteria,setSearchCriteria] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedOptions, setSelectedOptions] = useState(productListData.compProdListData);
    const [message,setMessage] = useState("");
    const [isClickSearchButton, setIsClickSearchButton] = useState(false);
    const [policyType, setPolicyType] = useState({policyTypeList:[]});
    const [memberAgentListData, setMemberAgentListData] = useState({memberAgentCodeList:[]});
    let selectCompany = '';

    const validateDt = () => {
        let message = '';
        
        if (!($('input[name="effFrom"]').val() && $('input[name="effTo"]').val())) {
            message += t('error.message.select.blank', { 0: t('certificateinsurance.search.txndate') }) +'\n'
        }
        
        if ( !(Validate.validateDate( $('input[name="effFrom"]').val(), $('input[name="effTo"]').val())) ) {
            message += t('error.message.greaterthan.enddate') +'\n'
        } 
        
        if (!Validate.validateIdentity($('input[name="ktpno"]').val())){
            message += t('error.message.invalid.field', {0: t('certificateinsurance.search.ktpno')}) +'\n'
        }
        
        if (message) {
            setMessage(message);
            return false;
        }
        return true;
    }
    
    useEffect(() => {
        const dropdownListComp = async dispatch => {
            let activeCompany = [];
            let attachedCompany = [];
            
            let activeCompanyResp = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_ACTIVECOMPANY, null, dispatch);   
            
            if (isStaff) {
                if(!activeCompanyResp.isError && activeCompanyResp.respData.data) {
                    activeCompany = activeCompanyResp.respData.data.map(company => {
                        return {shortCode: company.id, value: company.companyName}
                    });
                }
                setDropdownList({activeCompanyList: activeCompany});
            }else{
                if(!activeCompanyResp.isError && activeCompanyResp.respData.data) {
                    let activeCompData = activeCompanyResp.respData.data;
                    attachedCompany = activeCompData.filter(comp => comp.id === companyId ).map(filteredComp => {
                        return {shortCode: filteredComp.id, value: companyName}
                    });
                }
                setDropdownList({activeCompanyList: attachedCompany});
            }
        }
    dispatch(dropdownListComp);
    dispatch(getPolicyTypeList);
    }, [dispatch, companyId, companyName, isStaff]);

    const getProductList = async dispatch => {
        let prodList = [];        
        let compProductListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_REPORT_COMPANY_PRODUCT_LIST + selectCompany, null,dispatch);

        if(!compProductListResp.isError && compProductListResp.respData.data) {
            prodList = compProductListResp.respData.data.map( product => {
                return {value: product.code, label: product.desc} //to accomodate react-select
            });
        }
        setProductListData({compProdListData: prodList});
    }
    
    const getPolicyTypeList = async dispatch => {
        let polTypeList = [];
        let polTypeListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_COMMON_POLICY_TYPE_LIST,null,dispatch);
    
        if(!polTypeListResp.isError && polTypeListResp.respData.data){
            polTypeList = polTypeListResp.respData.data.map(policyType => {
                return {shortCode: policyType.code, value:policyType.desc}
            });
        }
        setPolicyType({policyTypeList: polTypeList});
    }

    const getMemberAgentCodeList = async (dispatch, companyName) => {
        let memAgentList = [];
        let memAgentListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_MEMBER_AGENT_CODE_LIST,
            {params:{companyName:companyName}}, dispatch);

        if(!memAgentListResp.isError && memAgentListResp.respData.data){
            memAgentList = Object.entries(memAgentListResp.respData.data).map(([key,value]) => {
                return { shortCode: key, value: value };
            });
        }
        setMemberAgentListData({ memberAgentCodeList: memAgentList });
    }

    const handleChangeCompany = event => {
        setSelectedOptions([]);
        selectCompany = event.target.value;
        const companyName = drpdwnListComp.activeCompanyList[event.target.selectedIndex-1].value;   
        getMemberAgentCodeList(dispatch, companyName);
        if (selectCompany) dispatch(getProductList);
    }

    const handleMultiChangeProduct = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    }

    const handleSearchCriteria = () => {
        let searchEffectiveFromDt = '';
        let searchEffectiveToDt = '';
        let searchCompanyId = '';
        let searchKTPNo = '';
        let searchPolicyType = '';
        let searchMemberAgentCode = '';    
        if (validateDt()) {
            if ($('select[name="companyId"]').val() !== undefined) {
                searchCompanyId = $('select[name="companyId"]').val();
            }
            if ($('input[name="ktpno"]').val() !== undefined) {
                searchKTPNo = $('input[name="ktpno"]').val();
            }
            if ($('input[name="effFrom"]').val() !== undefined) {
                searchEffectiveFromDt = $('input[name="effFrom"]').val();
            }
            if ($('input[name="effTo"]').val() !== undefined) {
                searchEffectiveToDt = $('input[name="effTo"]').val();
            }
            let prodCodeList =[];
                $("input[name='prodCode']").each(function() {
                    if (this.value) prodCodeList.push(this.value);
                });
            if ($('select[name="policyType"]').val() !== undefined) {
                searchPolicyType = $('select[name="policyType"]').val();
            }
            if ($('select[name="memberAgentCode"]').val() !== undefined) {
                searchMemberAgentCode = $('select[name="memberAgentCode"]').val();
            }
            setSearchCriteria({
                searchCompanyId: searchCompanyId,
                searchProdCodes: prodCodeList,
                searchKTPNo: searchKTPNo,
                searchEffectiveFromDt: searchEffectiveFromDt,
                searchEffectiveToDt: searchEffectiveToDt,
                searchPolicyType: searchPolicyType,
                searchMemberAgentCode: searchMemberAgentCode
            })
        }     
    }
    
    useEffect(() => {
        const getCertIssuedReportDwnld = async() => {
            let outputfilename = '';
            outputfilename = Constant.certissuedreportfile;
            if (searchCriteria){
                await Download(urlConstant.URL_BASED + urlConstant.URL_REPORT_CERTIFICATE_ISSUED_DWNLD,
                    Constant.downloadType.POST, 
                    {
                        trnxDateFrom: searchCriteria.searchEffectiveFromDt,
                        trnxDateTo: searchCriteria.searchEffectiveToDt,
                        companyId: searchCriteria.searchCompanyId,
                        productCode: searchCriteria.searchProdCodes,
                        proposerIdentityNo: searchCriteria.searchKTPNo,
                        policyType: searchCriteria.searchPolicyType,
                        memberAgentCode: searchCriteria.searchMemberAgentCode
                    }, outputfilename, dispatch);
            }   
        }
        if (searchCriteria && !isClickSearchButton) dispatch(getCertIssuedReportDwnld);
    }, [dispatch, searchCriteria, isClickSearchButton]);

    return(
        <div>
            <Container style={{marginBottom: "30px"}}>
                { message? <div className="error"><p>{message}</p></div>: null}
                <Row className="mt-3"><Col><h2>{t('certificateinsurance.report')}</h2></Col></Row>
                <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('certificateinsurance.search')}</h4></Col></Row>
                <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('certificateinsurance.search.txndate')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col sm={{ size: 2 }} md="2"><DatePicker className="form-control datepicker-icon"  selected={startDate} onChange={date => setStartDate(date)} name="effFrom"/></Col>
                    <Col sm={{ size: 1 }} md="1" className="text-center"><Label>{t('effectivedateto')}</Label></Col>
                    <Col sm={{ size: 2 }} md="2" ><DatePicker className="form-control datepicker-icon" selected={endDate} onChange={date => setEndDate(date)} name="effTo"/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('company')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><SelectUtil name='companyId' optionList={drpdwnListComp.activeCompanyList} onChange={handleChangeCompany}/></Col>    
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('productname')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select 
                        id='prodCode' 
                        name='prodCode'
                        placeholder={t('placeholder.select')} 
                        options={productListData.compProdListData}
                        value={selectedOptions} 
                        onChange={handleMultiChangeProduct} 
                        isMulti
                    /></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('certificateinsurance.search.ktpno')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Input type="text" name="ktpno" onKeyPress={(event) => Validate.validateIdentity(event)} maxLength={16}/></Col>    
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('certificateinsurance.search.policytype')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><SelectUtil name="policyType" optionList={policyType.policyTypeList} /></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('certificateinsurance.search.memberAgentCode')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><SelectUtil name="memberAgentCode" optionList={memberAgentListData.memberAgentCodeList} /></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="12" sm="12" md="12">
                        <center>
                            <Button id="btnSearch" name="btnSearch" onClick={ () => {handleSearchCriteria(); setIsClickSearchButton(true)} } >{t('button.search')}</Button>
                            <> </>
                            <Button id="btnDownload" name="btnDownload" onClick={() => {handleSearchCriteria(); setIsClickSearchButton(false)} } >{t('button.download')}</Button>
                        </center>
                    </Col>
                </Row>
            </Container>

            <CertificateIssuedReportListing searchCriteria={searchCriteria} isClickSearchButton={isClickSearchButton}/>
        </div> 
    )
}
export default CertificateIssuedReport;