import React, { useEffect, useState } from "react";
import NyalaChildPage from "./NyalaPageScreen";


const NyalaPage = () => {
  const [jqueryLoaded, setJqueryLoaded] = useState(false);

  useEffect(() => {
    const scriptUrls = [
     	"/nyala/scripts/jquery-3.6.4.min.js",
        "/nyala/scripts/bootstrap.bundle.min.js",
      // Add more script URLs as needed
    ];

    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = url;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        await Promise.all(scriptUrls.map(url => loadScript(url)));
		setJqueryLoaded(true)
   
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    loadScripts();


if (typeof $ === 'undefined') {
	console.error('jQuery is not loaded in NyalaPage.js');
  } else {
	// Your existing code
	console.log('jQuery is loaded');
	// Attach createCard to window if not already attached
	window.createCard = function(data) {
	  console.log('createCard function loaded');
	  // Function implementation...
	};
  }

    // Cleanup function
    return () => {
      scriptUrls.forEach(url => {
        const script = document.querySelector(`script[src="${url}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  const htmlContent = `
  <!doctype html>
<html lang="en">

<head>
    <title>Great Eastern General Insurance Indonesia</title>
    <link rel="icon" type="image/x-icon" href="nyala/Resources/favicon.ico">
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <!-- Fonts -->
    <link href="nyala/CSS/public_sans.css" rel="stylesheet">

    <!-- CSS -->
    <link rel="stylesheet" href="nyala/CSS/bootstrap.min.css">
    <link rel="stylesheet" href="nyala/CSS/style.css">

    <!-- JS -->
    <script src="nyala/scripts/bootstrap.bundle.min.js"></script> 
    <script src="nyala/scripts/jquery-3.6.4.min.js"></script>
    <script src="nyala/scripts/page.js"></script> 
</head>



<body>
	<header>
		<nav class="container blur mx-6">
			<img class="logo-header" src="nyala/Resources/GE_Master_Logo_FC-HighRes.png" alt="Logo">
			<!--div class="flag-wrapper">
    			<button class="btn btn-outline-secondary" onclick="switchLanguage('ID')" id="ID">
        			<img class="flag-img" src="nyala/Resources/01-Main_Page/indonesia.svg" alt="Indonesia">ID
    			</button>
    			<button class="btn btn-outline-secondary" onclick="switchLanguage('EN')" id="EN">
        			<img class="flag-img" src="nyala/Resources/01-Main_Page/english.svg" alt="English">EN
   		 		</button>
			</div-->
		</nav>
	</header>


	<main>
		<section class="container blur">
			<div class="row" id="cardContainer"></div>
		</section>
	</main>
	
	 <script>
	// 	const cardData = [
	// 		{
	// 			icon: "nyala/Resources/01-Main_Page/PA-icon.png",
	// 			titleID: "Aktivasi Asuransi Kecelakaan Diri GRATIS!!<br>",
	// 			titleEN: "",
	// 			descriptionID: "Jaminan Meninggal Dunia dan/atau Cacat Tetap Total akibat kecelakaan Rp. 10.000.000<br>Biaya Pengobatan tidak dijamin.<br>Periode asuransi selama 30 hari.",
	// 			descriptionEN: "",
	// 			modalFunction: "modalRumah"
	// 		},
	// 		{
	// 			icon: "nyala/Resources/01-Main_Page/PA-icon.png",
	// 			titleID: "Bayar Rp. 20.000 dan dapatkan perlindungan yang lebih LENGKAP!!",
	// 			titleEN: "",
	// 			descriptionID: "Jaminan Meninggal Dunia dan/atau Cacat Tetap akibat kecelakaan hingga Rp. 50.000.000<br>Biaya Pengobatan akibat kecelakaan hingga Rp. 5.000.000<br>Periode asuransi selama 1 tahun.",
	// 			descriptionEN: "",
	// 			modalFunction: "modalApartemen",
	// 			highlight: true
	// 		}
	// 	];
	// 	//cardData.forEach(createCard);

	// 	cardData.forEach((data, index) => {
	// 		createCard(data, index); 
	// 	});
	</script>

</body>

<footer class="sticky-footer">
	<hr>
	<div class="container blur">
		<div class="row">
			<div class="col-6">
				<div class="row">
					<div class="col-12">
						<a href="https://www.greateasterngeneral.com/id">
							<img src="nyala/Resources/GE_Master_Logo_FC-HighRes.png" class="logo-footer" alt="Great Eastern General Insurance Logo">
						</a>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="icon-block">
							<a href="https://www.facebook.com/greateasterngeneral.id">
								<img src="nyala/Resources/01-Main_Page/facebook-icon.png" alt="Facebook">
							</a>
							<a href="https://www.instagram.com/greateasterngeneral.id">
								<img src="nyala/Resources/01-Main_Page/instagram_icon.png" alt="Instagram">
							</a>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<p class="gegi-contact ID">Ketentuan Layanan 24 Jam</p>
						<p class="gegi-contact EN">24 hours customer care</p>
						<p class="gegi-service-desc ID">Silakan sampaikan pertanyaan dan pengaduan ke E-mail Layanan Nasabah atau Akun Resmi Whatsapp kami. Pertanyaan dan pengaduan akan kami respon di hari Senin - Jumat, pukul 09.00 - 17.00 WIB.</p>
						<p class="gegi-service-desc EN">Please inquiry your questions and complains to Customer Care E-Mail Address or our Official WhatsApp Account. We will response your inquiry on Mon-Fri, at 9 AM - 5 PM Jakarta Local Time.</p>
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="row">
					<div class="col-6">
						<p class="gegi-subtitle ID">Nomor Telepon* :</p>
						<p class="gegi-subtitle EN">Phone Number* :</p>
						<a class="gegi-item-red" href="Tel:+62215723737">+62 21 5723737</a>
						<p class="gegi-item-notes ID">*Beroperasi pada hari kerja Senin-Jum'at pukul 09:00 - 17:00 WIB.</p>
						<p class="gegi-item-notes EN">*Operation on working days Mon-Fri 9 AM - 5 PM Jakarta Local Time.</p>
					</div>
					<div class="col-6">
						<p class="gegi-subtitle ID">Akun Resmi WhatsApp :</p>
						<p class="gegi-subtitle EN">Official WhatsApp Account :</p>
						<a class="gegi-item-red" href="https://wa.me/+6281915723737">WhatsApp: +62 819 1572 3737</a>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<p class="gegi-contact ID">Kontak Asuransi Great Eastern General Insurance Indonesia :</p>
						<p class="gegi-contact EN">Great Eastern General Insurance Contact :</p>
					</div>
				</div>
				<div class="row">
					<div class="col-6">
						<p class="gegi-subtitle ID">Kantor Pusat :</p>
						<p class="gegi-subtitle EN">Head Office :</p>
						<p class="gegi-item-red">Midplaza 2, 23rd floor</p>
						<p class="gegi-item-red">Jalan Jenderal Sudirman Kav. 10-11</p>
						<p class="gegi-item-red">Jakarta 10220, Indonesia</p>
					</div>
					<div class="col-6">
						<p class="gegi-subtitle ID">Email Layanan Nasabah :</p>
						<p class="gegi-subtitle EN">Customer Care Email :</p>
						<a class="gegi-item-red" href="mailto:wecare-id@greateasterngeneral.com">wecare-id@greateasterngeneral.com</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<hr>
	<div class="container blur">
		<div class="row">
			<div class="col-12 text-center mb-3">
				<p class="ID">PT. Great Eastern General Insurance Indonesia berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK)</p>
				<p class="EN">PT. Great Eastern General Insurance Indonesia licensed and supervised by Otoritas Jasa Keuangan (OJK)</p>
			</div>
		</div>
	</div>
</footer>

<div class="modal fade modalClass" id="modalRumah" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body modal-rumah">
					<div class="container">
						<div class="row">
							<div class="col-lg-6 col-md-12 col-sm-12">
								<h1 class="mt-2 mb-4" id="productRumah" value="Rumah Tinggal">
									<div class="ID">Asuransi Kecelakaan Diri (FREE)</div>
									<div class="EN">Dwelling House / Landed House</div>
								</h1>
								<div class="modal-div-gap">
									<p class="ID">Asuransi yang memberikan perlindungan menyeluruh dengan memberikan kompensasi keuangan kepada Anda dan keluarga Anda apabila Anda mengalami cacat tetap keseluruhan dan/atau kematian yang disebabkan oleh kecelakaan sebesar Rp. 10,000,000.</p>
									<p class="EN">It is an insurance product that provides protection for building, contents. Including electronic equipment and furniture in your home.</p>
								</div>
								<div class="modal-div-gap terms-div" style="padding: 15px;">
									<p class="title ID"><b>Manfaat Asuransi:</b></p>
									<p class="title EN"><b>Insurance Benefit:</b></p>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Meninggal Dunia dan/atau Cacat Tetap Total akibat Kecelakaan Rp. 10.000.000</p></div>
										<div class="EN"><p>Class 1 building construction (concrete or steel construction).</p></div>
									</div>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Periode Asuransi: 30 Hari.</p></div>
										<div class="EN"><p>Not in a flood area.</p></div>
									</div>
								</div>
								<div class="button-unduh-wrapper">
									<!--a href="nyala/Documents/Brosur-Asuransi_Semua_Resiko_Industri_ID-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										<button>
											<span class="ID"><img src="nyala/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											<span class="EN"><img src="nyala/Resources/02-Detail/product-summary-icon.svg">Product Brochure</span>
										</button>
									</a-->
									<a href="nyala/Documents/Kecelakaan_Diri_(PA-Free)_OCBC-GEGI_RIPLAY_Umum.pdf" target="_blank" rel="noopener noreferrer">
										<button>
											<span class="ID"><img src="nyala/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											<span class="EN"><img src="nyala/Resources/02-Detail/product-summary-icon.svg">Download general RIPLAY</span>
										</button>
									</a>
								</div>
								<div class="btn-beli-wrapper">
									<!--button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(1);"><div class="ID">Hubungi Kami</div><div class="EN">Contact Us</div></button-->
									<button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#registrationModal" aria-label="Close">
										<div class="ID">Beli sekarang</div>
										<div class="EN">Buy Now</div>
									</button>
								</div>
							</div>
							<div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								<img class="modal-content-img" src="nyala/Resources/02-Detail/Asuransi_Kecelakaan_Diri.jpg" loading="lazy">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade modalClass" id="modalApartemen" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body modal-apartment">
					<div class="container">
						<div class="row">
							<div class="col-lg-6 col-md-12 col-sm-12">
								<h1 class="mt-2 mb-4" id="productApartemen" value="Apartemen">
									<div class="ID">Asuransi Kecelakaan Diri (UPGRADE)</div>
									<div class="EN">Apartment</div>
								</h1>
								<div class="modal-div-gap">
									<p class="ID">Asuransi yang memberikan perlindungan menyeluruh dengan memberikan kompensasi keuangan kepada Anda dan keluarga Anda apabila Anda mengalami cacat tetap sebagian, cacat tetap keseluruhan atau kematian, termasuk biaya pengobatan yang disebabkan oleh kecelakaan.</p>
									<p class="EN">It is an insurance product that provides protection for buildings, contents, including equipment and electronics in your home.</p>
								</div>
								<div class="modal-div-gap terms-div" style="padding: 15px;">
									<p class="title ID"><b>Manfaat Asuransi:</b></p>
									<p class="title EN"><b></b>Insurance Benefit:</p>
									<br>
									<p class="title ID"><b>Plan A:</b></p>
									<p class="title EN"><b>Plan A:</b></p>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Meninggal Dunia dan/atau Cacat Tetap Total akibat Kecelakaan Rp. 10.000.000</p></div>
										<div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									</div>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Biaya Pengobatan akibat Kecelakaan Rp. 1.000.000</p></div>
										<div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									</div>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Premi Asuransi Rp. 20.000</p></div>
										<div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									</div>
								</div>
								<div class="modal-div-gap terms-div" style="padding: 15px;">
									<p class="title ID"><b>Plan B:</b></p>
									<p class="title EN"><b>Plan B:</b></p>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Meninggal Dunia dan/atau Cacat Tetap Total akibat Kecelakaan Rp. 25.000.000</p></div>
										<div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									</div>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Biaya Pengobatan akibat Kecelakaan Rp. 2.500.000</p></div>
										<div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									</div>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Premi Asuransi Rp. 50.000</p></div>
										<div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									</div>
								</div>
								<div class="modal-div-gap terms-div" style="padding: 15px;">
									<p class="title ID"><b>Plan C:</b></p>
									<p class="title EN"><b>Plan C:</b></p>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Meninggal Dunia dan/atau Cacat Tetap Total akibat Kecelakaan Rp. 50.000.000</p></div>
										<div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									</div>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Biaya Pengobatan akibat Kecelakaan Rp. 5.000.000</p></div>
										<div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									</div>
									<div class="d-flex">
										<div><p>-&nbsp;</p></div>
										<div class="ID"><p>Premi Asuransi Rp. 100.000</p></div>
										<div class="EN"><p>Maximum insurance value is IDR 5 billion, if the insurance value is above IDR 5 billion, please contact GEGI.</p></div>
									</div>
								</div>
								<div class="button-unduh-wrapper">
									<!--a href="nyala/Documents/Brosur-Asuransi_Semua_Resiko_Industri_ID-OCBC-Microsite-updated.pdf" target="_blank" rel="noopener noreferrer">
										<button>
											<span class="ID"><img src="nyala/Resources/02-Detail/product-summary-icon.svg">Brosur Produk</span>
											<span class="EN"><img src="nyala/Resources/02-Detail/product-summary-icon.svg">Product Brochure</span>
										</button>
									</a-->
									<a href="nyala/Documents/Kecelakaan_Diri_(PA-Upgrade)_OCBC-GEGI_RIPLAY_Umum.pdf" target="_blank" rel="noopener noreferrer">
										<button>
											<span class="ID"><img src="nyala/Resources/02-Detail/product-summary-icon.svg">Unduh RIPLAY umum</span>
											<span class="EN"><img src="nyala/Resources/02-Detail/product-summary-icon.svg">Download general RIPLAY</span>
										</button>
									</a>
								</div>
								<div class="btn-beli-wrapper">
									<!--button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="modalContactCenter(2);"><div class="ID">Hubungi Kami</div><div class="EN">Contact Us</div></button-->
									<button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onclick="axredirect(1);"><div class="ID">Beli sekarang</div><div class="EN">Buy Now</div></button>
								</div>
							</div>
							<div class="col-md-12 col-lg-6 col-sm-12 modal-img-wrapper">
								<img class="modal-content-img" src="nyala/Resources/02-Detail/Asuransi_Kecelakaan_Diri.jpg" loading="lazy">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="modalDisclaimer" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-body">
					<div class="container" style="text-align: center; margin-bottom: 30px;">
						<div class="modalDisclaimer-logo">
							<img src="nyala/Resources/GE_Master_Logo_FC-HighRes.png" class="logo-header">
						</div>
						

						<div class="modalDisclaimer-txt">
							<p class="p1">Anda telah berada di halaman website PT. Great Eastern General Insurance Indonesia.</p>
							<p class="p2">Dengan melakukan transaksi pada website ini, Anda telah menyetujui semua syarat dan ketentuan yang berlaku dari PT. Great Eastern General Insurance Indonesia.</p>
						</div>
						
						<!--div class="modalDisclaimer-txt">
							<p class="p1"><i>You have left the OCBC Bank website page and are currently at PT. Great Eastern General Insurance Indonesia website page.</i></p>
							<p class="p2"><i>Everything on this website is not responsibility of OCBC Bank.</i></p>
						</div-->
						
						<div class="modalDisclaimer-txt">
							<p class="p2">Klik Setuju, untuk melanjutkan.</p>
							<!--p class="p1"><i>Click Agree, to continue.</i></p-->
						</div>

						<p></p>
						<button type="button" class="btn btn-danger btn-border" data-bs-dismiss="modal" aria-label="Close">Setuju<br><!--i>Agree</i--></button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="registrationModal" tabindex="-1" aria-labelledby="registrationModalLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title EN" id="registrationModalLabel">Policy Issuance Form</h5>
					<h5 class="modal-title ID" id="registrationModalLabel">Formulir Penerbitan Polis</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div id="loadingScreen" style="display: none;">
						<div class="text-center">
							<div class="spinner-border" role="status">
								<span class="visually-hidden EN">Loading...</span>
								<span class="visually-hidden ID">Memproses data...</span>
							</div>
							<p class="EN">Please wait...</p>
							<p class="ID">Mohon Tunggu...</p>
						</div>
					</div>
					<form id="registrationForm">
						<div class="form-group">
							<label for="fullName" class="EN">Full Name:</label>
							<label for="fullName" class="ID">Nama Lengkap:</label>
							<input type="text" class="form-control" id="fullName" maxlength="40" pattern="[a-zA-Z0-9\s]+" required>
						</div>
	
						<div class="form-group">
							<label for="dateOfBirth" class="EN">Date of Birth:</label>
							<label for="dateOfBirth" class="ID">Tanggal Lahir:</label>
							<input type="date" class="form-control" id="dateOfBirth" required>
						</div>
	
						<div class="form-group">
							<label for="nationalID" class="EN">National ID Number:</label>
							<label for="nationalID" class="ID">Nomor Kartu Identitas:</label>
							<input type="text" class="form-control" id="nationalID" maxlength="20" pattern="[0-9]+"
								required>
						</div>
	
						<div class="form-group">
							<label for="email" class="EN">Email Address:</label>
							<label for="email" class="ID">Alamat Surel:</label>
							<input type="email" class="form-control" id="email" required>
						</div>
	
						<div class="form-group">
							<label for="mobileNumber" class="EN">Mobile Phone Number:</label>
							<label for="mobileNumber" class="ID">Nomor Telepon Seluler:</label>
							<input type="tel" class="form-control" id="mobileNumber" maxlength="20" pattern="[0-9]+" required>
						</div>
	
						<div class="form-group">
							<label for="gender" class="EN">Gender:</label>
							<label for="gender" class="ID">Jenis Kelamin:</label>
							<select class="form-control" id="gender" required>
								<option value="" class="EN">Select a gender / Pilih jenis kelamin</option>
								<option value="M" class="EN">Male</option>
								<option value="M" class="ID">Pria</option>
								<option value="F" class="EN">Female</option>
								<option value="F" class="ID">Wanita</option>
							</select>
						</div>

						<div class="form-group">
							<label for="risk-age" class="EN">Age:</label>
							<label for="risk-age" class="ID">Umur:</label>
							<input type="text" class="form-control" id="risk-age" disabled>
						</div>
	
						<div class="form-group">
							<label for="transactionDate" class="EN">Transaction Date:</label>
							<label for="transactionDate" class="ID">Tanggal Transaksi:</label>
							<input type="date" class="form-control" id="transactionDate" disabled> 
						</div>

						<script>
						//  Get the modal element
						// 	const registrationModal = document.getElementById('registrationModal');

						// Add event listener for 'show.bs.modal' event
						// 	registrationModal.addEventListener('show.bs.modal', () => {
						// Clear the message container
						// 		const container = document.getElementById('messageContainer');
						// 		container.innerHTML = '';

						// Clear all form values
						// 		const form = registrationModal.querySelector('form'); // Get the form inside the modal
						// 		const formElements = form.elements; // Get all form elements

						// 		for (let i = 0; i < formElements.length; i++) {
						// 			const element = formElements[i];
						// 			if (element.id !== 'transactionDate') { // Exclude transaction date field
						// 				element.value = ''; // Clear other fields
						// 			}
						// 		}
						// 	});

						// 	document.getElementById('dateOfBirth').addEventListener('change', function() {
						// 		const dob = new Date(this.value);
						// 		const today = new Date();
						// 		let age = today.getFullYear() - dob.getFullYear();
						// 		const m = today.getMonth() - dob.getMonth();
						// 		if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {age--;}
						// 		document.getElementById('risk-age').value = age;
						// 	});

						//Get the current date
							
						// 	document.addEventListener('DOMContentLoaded', function () {
						// 	const today = new Date();
						// 	const year = today.getFullYear();
						// 	let month = today.getMonth() + 1; // JavaScript months are 0-indexed
						// 	let day = today.getDate();

						// Add leading zeros if needed
						// 	if (month < 10) month = '0' + month;
						// 	if (day < 10) day = '0' + day;

						// Format the date as YYYY-MM-DD
						// 	const formattedDate = year-month-day;

						// Set the value of the date input
						// 	document.getElementById('transactionDate').value = formattedDate;
						// });
						</script>
					</form>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary EN" data-bs-dismiss="modal">Close</button>
					<button type="button" class="btn btn-secondary ID" data-bs-dismiss="modal">Tutup</button>
					<button type="button" class="btn btn-primary EN" onclick="submitForm()" id="submitBtnEN">Submit</button>
					<button type="button" class="btn btn-primary ID" onclick="submitForm()" id="submitBtnID">Kirim</button>
				</div>
				<div id="messageContainer"></div>
			</div>
		</div>
	</div>
</html>`;

  return jqueryLoaded ? (
    <NyalaChildPage html={htmlContent}/>
  ) : (
	null
);
};

export default NyalaPage;
