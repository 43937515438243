import React from 'react';
import { Button, Col, Label, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const InfoFields = ({ respData, fields, fieldValue, onChangeList, handleAddList, handleRemoveList }) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }

    if (respData.infoFields.length) return (
        <div>
            {fields.field.map((f) => {
                return respData.infoFields.map((respField, i) => {
                    if (respField.key === f.key) {
                        return (<div key={i}>
                            <Col className="container-flex" xs="12" sm="6" md="6">
                            <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className={respField.isRequired ? "required" : ""}>
                                {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t("trnx.info."+f.key) ? t("trnx.info."+f.key) : f.key }
                                <br></br><i>
                                {respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t2("trnx.info."+f.key) ? t2("trnx.info."+f.key) : f.key }</i></Label></Col>
                            <Col xs="2" sm="7" md="7"><Button onClick={() => handleAddList("infoItems", f.key)}>+</Button></Col>
                            </Col>
                            <div className="error"><span id={"errMsg_info_"+f.key}/></div>
                            {
                                fieldValue["infoItems"][f.key].value.map((value, idx) => {
                                return (
                                    <Col key={idx}>
                                        <Col>
                                        <Row className="mt-3">
                                        <Col xs="10" sm="11" md="11"><Input name={f.key} maxLength={40} value={value} onChange={e => onChangeList(e, "infoItems", idx)} /></Col>
                                            {fieldValue["infoItems"][f.key].value.length !== 1 ? <Col xs="2" sm="1" md="1"><Button onClick={() => handleRemoveList("infoItems", f.key, idx)}>X</Button></Col> : ""}
                                        </Row>
                                        </Col>
                                    </Col>
                                );
                            })}
                        </div>)
                    }else return false;
                })
            })}
        </div>
    )
    else return "";
};

export default InfoFields;