import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; 

const Endorsement = (props) => {
  const { t } = useTranslation('riskitems');
  const [endorsementData, setEndorsementData] = useState([]);
  let sortedKeysArray='';
  
  useEffect (()=> {
    if (props.items.length !== 0) setEndorsementData(props.items);
  },[ props.items ]);

  const getRiskItems = () => {
    if (endorsementData.length !== 0) {
      return endorsementData.map( (endrstData) => {
        sortedKeysArray = Object.keys(endrstData.riskItems).sort();
        return sortedKeysArray.map((keyName, index) => {
          return [
            <tr className='riskitemsTable-tr' key={index}>
              <td className='riskitemsTable-td'>{ t(keyName) }</td>
              <td className='riskitemsTable-td'> : </td>
              <td className='riskitemsTable-td'>{ endrstData.riskItems[keyName] }</td>
            </tr>
          ]
        })
      })
    }
  }
  
  return (
    <table className='riskitemsTable'>
      <thead>
        <tr>
          <th>{t('endorsementDt')}</th>
          <th>{t('endorsementDesc')}</th>
        </tr>
      </thead>
      <tbody>
        {endorsementData.map( (endrstData, indx) => {
          return [  
            <tr key={indx}>
              <td>{endrstData.effectiveDate}</td>
              <td className='riskitemsTable-td'>
                <table className='riskitemsTable'>
                  <tbody>
                    <tr className='riskitemsTable-tr'>
                      <td className='riskitemsTable-td'> 
                        <table className='riskitemsTable'>
                          <tbody>
                            <tr className='riskitemsTable-tr'>
                              <td className='riskitemsTable-td'>{ t('endorsementTitle') }</td>
                              <td className='riskitemsTable-td'> : </td>
                              <td className='riskitemsTable-td'>{ endrstData.title }</td>
                            </tr>
                            {getRiskItems()}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table> 
              </td>
            </tr>
              ]
            })
          }    
      </tbody>
    </table>
  );
}

export default Endorsement;