import React, { Component } from 'react';
import { connect } from 'react-redux';
import DrawerToogleButton from './DrawerToogleButton';
import loginAction from '../actions/loginAction';
import './Toolbar.css';
import { format } from 'date-fns';
import * as Constant from './../constants/Constant';

class toolbar extends Component {
    logoutClickHandler = () => {
        this.props.handleLogout();
    }

    render() {
        return (
            <header className='toolbar'>
                <nav className="toolbar__navigation">
                    <div>
                        <DrawerToogleButton click={this.props.drawerClickHandler} />
                    </div>
                    <div className="toolbar__logo">Affinity eXchange</div>
                    <div className="toolbar__spacer" />
                    <div className="toolbar_navigation-items">
                        <ul>
                            {this.props.lastLoginDate? <li><small><i><span>Last login date: {format(new Date(this.props.lastLoginDate), Constant.dateFormat_C)}</span></i></small></li>: null}
                            <li><span style={{cursor:"context-menu"}} id="TooltipExample">Selamat Datang {this.props.username}</span></li>
                            {/* <li><a href="/logout" onClick={this.logoutClickHandler}>Keluar</a></li> */}
                            <li><a href="#" onClick={this.logoutClickHandler}>Keluar</a></li>
                        </ul>
                    </div>
                </nav>
            </header>
    )}
}

const mapStateToProps = (state) => {
	return {
		username: state.loginReducer.username,
        lastLoginDate: state.loginReducer.lastLoginDate
	}
}

const mapDispatchToState = (dispatch) => {
    return {
      handleLogout: () => {
          dispatch(loginAction.logoutHandler);
      }
    }
  }

export default connect(mapStateToProps, mapDispatchToState)(toolbar);