import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import loginAction from './../actions/loginAction';
import {useDispatch} from 'react-redux';
import IdleTimer from 'react-idle-timer'
import { useTranslation } from 'react-i18next';
import * as Constant from './../constants/Constant';
import { push } from 'connected-react-router';

const IdleSession = (props) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const withoutLogin = props.withoutLogin ? props.withoutLogin : null;

  const [remainingTime, setRemainingTime] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  
  const onIdle = () => {
    setModalIsOpen(true)
    setRemainingTime(Constant.idleLogOut);
    sessionTimeoutRef.current = setTimeout(forceLogOut, Constant.idleLogOut * Constant.milliseconds)
  }

  const stayActive = () => {
    setModalIsOpen(false)
    clearTimeout(sessionTimeoutRef.current)
  }

  const logOut = () => {
    setModalIsOpen(false)
    clearTimeout(sessionTimeoutRef.current)
    dispatch(loginAction.logoutHandler)
    if (withoutLogin) {
      dispatch(push({pathname: '/sessionTimeOut'}));
    }
  }

  const forceLogOut = () => {
    setModalIsOpen(false)
    clearTimeout(sessionTimeoutRef.current)
    dispatch(loginAction.logoutHandler)
    dispatch({ type: 'DISPLAY_ERROR', errorMsg: t('error.message.session.expired') })
    if (withoutLogin) {
      dispatch(push({pathname: '/sessionTimeOut'}));
    }
  }

  useEffect(() => {
    let interval = null;
    if (modalIsOpen) {
      interval = setInterval(() => {
        setRemainingTime(remainingTime => remainingTime - 1);
      }, Constant.milliseconds);
    } else if (!modalIsOpen && remainingTime !== null) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [modalIsOpen, remainingTime]);

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <ModalHeader className={'modal_header'}>
          {t('idle.modalheader.title')}
        </ModalHeader>
        <ModalBody>
          <p>{t('idle.modalbody.message1')}</p>
          <p>{t('idle.modalbody.message2', { 0: remainingTime })}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={logOut}>{t('idle.modalfooter.button.logout')}</Button>{' '}
          <Button color="secondary" onClick={stayActive}>{t('idle.modalfooter.button.signedin')}</Button>
        </ModalFooter>
      </Modal>
      <IdleTimer
        ref={idleTimerRef}
        onIdle={onIdle}
        timeout={Constant.idleWarning * Constant.milliseconds}
      />
  </div>
  )
}

export default IdleSession