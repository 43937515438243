import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from '../../../utils/Select';
import * as Constant from '../../../constants/Constant';
import DatePicker from '../../../components/DatePicker';
import * as Validate from '../../../utils/CommonValidate';
import InfoField from './InfoFields';
import RiskLength from './risk_length.json';

const ProposerItems = ({ respData, fields, fieldValue, onChangeInputSelect }) => {

    const { t } = useTranslation();
    const t2 = (label) => {
        return t('id_riskitems:'+label); 
    }
    const t3 = (label) => {
        return t('en_riskitems:'+label); 
    }

    if (respData.proposerFields) return (
        <div>
            <div className="container-flex" xs="12" sm="12" md="12">
                {fields.field.map((f, i) => {
                    return respData.proposerFields.map((respField, i) => {
                        if (respField.key === f.key) {
                            return (
                                <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                    <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                        <Label className={respField.isRequired ? "required" : ""}>
                                            {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key}
                                        <br></br>
                                        <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key}</i>
                                        </Label>
                                    </Col>
                                    <Col xs="2" sm="1" md="1">:</Col>
                                    <Col xs="12" sm="6" md="6">
                                        <div>
                                            {respField.type === "numeric" && !respField.isCurrency ? <Input name={f.key} maxLength={3} value={fieldValue["dynamicProposerFields"][f.key].value} onChange={(e) => onChangeInputSelect(e, "dynamicProposerFields", false)} onKeyPress={(event) => Validate.numeric(event)} readOnly={(fieldValue["proposerItems"]["dob"].value)}/>
                                                : respField.type === "freetext" ? <Input name={f.key} maxLength={40} value={fieldValue["dynamicProposerFields"][f.key].value} onChange={e => onChangeInputSelect(e, "dynamicProposerFields", false)} />
                                                    : ""
                                            }
                                            <div className="error"><span id={"errMsg_" + f.key} /></div>
                                        </div>
                                    </Col>
                                </Col>
                            )
                        } else return false;
                    })
                })}
            </div>
        </div>
    )
    else return "";
};

export default ProposerItems;