import { useState } from "react";
import { Button, Col, Row, Collapse, Label, CardBody, Card} from 'reactstrap';
import { useTranslation } from "react-i18next";

const ConfirmationQuestion = ({respData, fields, fieldValue}) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label);
    }

    const [panelOpen, setPanelOpen] = useState(true);

    const QuestionToggle = () => setPanelOpen(!panelOpen);

    if (respData.questionFields.length) return (
        <div>
            <Row className="mt-3"><Col><h5>{t('question')}</h5></Col></Row>
            <Row><Col><h6><i>{t2('question')}</i></h6></Col></Row>
            <Button className={"accordion-toggle mt-3 " + (panelOpen ? "collapse-icon" : "expand-icon")} 
                    onClick={e => QuestionToggle(e)}>{t('question')}<br></br><i>{t2('question')}</i>
            </Button>
            <Collapse isOpen={panelOpen}>
            <Card><CardBody><div className="container-flex">
                {fields.field.map(field => 
                    respData.questionFields.map(qField => 
                        field.key === qField.key ? 
                            <Col xs="12" sm="12" md="12" className="mt-3 container-flex">
                                <Col xs="10" sm="8" md="8"><Label>{qField.axLabel.axLabelId}<br></br><i>{qField.axLabel.axLabelEn}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="3" md="3">
                                    <Label>{fieldValue.questionItems[qField.key].desc}</Label>
                                </Col>
                            </Col>
                        :
                            ""
                        )
                )}
            </div></CardBody></Card>
            </Collapse>
        </div>
    )
    else return "";
}

export default ConfirmationQuestion;