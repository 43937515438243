import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import companyLogo from './../../images/gel-logo.png';
import * as actions from './../../actions/loginAction';
import { useTranslation } from 'react-i18next';

const SessionTimeOut = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: actions.UPDATE_TOKEN, token: '' });
    }, [dispatch])

    return (
        <div>
            <div className="auth-top claim"><img src={companyLogo} className="company-logo mx-auto d-block" alt="username" /></div>
            <Container className="auth-inner claim">
                <center>
                <Row className="mt-3" ><Col><h2>{t('error.message.title.sessionTimeOut')}</h2></Col></Row>
                <Row><Col><h4><i>{t('error.message.message.sessionTimeOut')}</i></h4></Col></Row>
                </center>
            </Container>
    </div>
    )
}

export default SessionTimeOut;