import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Label, Container, Button} from 'reactstrap';
import Select from '../../utils/Select';
import * as urlConstant from '../../constants/urlConstant';
import { Get } from '../../utils/AxFetch';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import CopyTooltipItem from './CopyToolTipItem';
import $ from 'jquery';

const CompanyProduct = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [drpdwnListComp,setDropdownList] = useState({activeCompanyList:[]});
    const [productListData,setProductListData] = useState({compProdListData:[], isErrorMessage:false});
    const [policyType, setPolicyType] = useState({policyTypeList: []});
    const [memberAgentListData, setMemberAgentListData] = useState({memberAgentCodeList:[]});
    const [message, setMessage] = useState("");
    
    const validateDt = () => {
        let message = '';

        if (!($('select[name="companyRegNo"]').val())) {
            message += t('error.message.select.blank', { 0: t('companyname') }) + '\n'
        }

        if (message) {
            setMessage(message);
            return false;
        }

        setMessage(message);
        return true;
    }

    useEffect(() => {

        const dropdownListComp = async dispatch => {
            let activeCompany = [];
            let activeCompanyResp = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_ACTIVECOMPANY, null, dispatch);    
    
            if(!activeCompanyResp.isError && activeCompanyResp.respData.data) {
                activeCompany = activeCompanyResp.respData.data.map(company => {
                    return {shortCode: company.regNo, value: company.companyName}
                });
            }
            setDropdownList({activeCompanyList: activeCompany});
        }
        dispatch(dropdownListComp);
        dispatch(getPolicyTypeList);
    }, [dispatch]);

    const getPolicyTypeList = async dispatch => {
        let polTypeList = [];
        let polTypeListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_COMMON_POLICY_TYPE_LIST, null, dispatch);

        if (!polTypeListResp.isError && polTypeListResp.respData.data) {
            polTypeList = polTypeListResp.respData.data.map(policyType => {
                return { shortCode: policyType.code, value: policyType.desc }
            });
        }
        setPolicyType({ policyTypeList: polTypeList });
    }

    const getMemberAgentCodeList = async (dispatch, companyName) => {
        let memAgentList = [];
        let memAgentListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_MEMBER_AGENT_CODE_LIST,
            {params:{companyName:companyName}}, dispatch);

        if(!memAgentListResp.isError && memAgentListResp.respData.data) {
            memAgentList = Object.entries(memAgentListResp.respData.data).map(([key, value]) => {
                return { shortCode: key, value: value };
            });
        }
        setMemberAgentListData({ memberAgentCodeList: memAgentList });
    }

    const handleCompanyChange = (e) => {
        const selectedOptionIndex = e.target.selectedIndex-1;
        const companyName = drpdwnListComp.activeCompanyList[selectedOptionIndex].value;   
        getMemberAgentCodeList(dispatch, companyName);
    }
    
    const handleSearchCriteria = async dispatch => {
        let rescompProductListData = [];
        let policyType = '';
        let companyRegNo = '';
        let memberAgentId ='';
        let isErrorMessage = false;
        if (validateDt()) {
            if ($('select[name="companyPolicyType"]').val() !== undefined) {
                policyType = $('select[name="companyPolicyType"]').val();
            }
            if ($('select[name="companyRegNo"]').val() !== undefined) {
                companyRegNo = $('select[name="companyRegNo"]').val();
            }
            if ($('select[name="memberAgentCode"]').val() !== undefined) {
                memberAgentId = $('select[name="memberAgentCode"]').val();
            }

            let compProductListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_COMPANY_PRODUCT_LIST + companyRegNo,
                {
                    params: {
                        policyType: policyType,
                        memberAgentId:memberAgentId
                    }
                }, dispatch);
            let dataResp = compProductListResp.respData;

            if (!compProductListResp.isError && compProductListResp.respData.data) {
                rescompProductListData = dataResp.data[companyRegNo];
            } else {
                isErrorMessage = compProductListResp.isError;
            }

            if (!isErrorMessage) {
                setProductListData({ compProdListData: rescompProductListData, isErrorMessage: isErrorMessage });
            }
        }
    }

    return (
        <div>
            <Container>
                {message ? <div className="error"><p>{message}</p></div> : null}
                <Row className="mt-3"><Col><h2>{t('productlisting')}</h2></Col></Row>
                
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label className='required'>{t('companyname')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select name='companyRegNo' optionList={drpdwnListComp.activeCompanyList} onChange={(e) => handleCompanyChange(e)}/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('policytype')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select name='companyPolicyType' optionList={policyType.policyTypeList}/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('memberAgentCode')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select name='memberAgentCode' optionList={memberAgentListData.memberAgentCodeList} /></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="12" sm="12" md="12">
                        <center>
                            <Button id="btnSearch" name="btnSearch" onClick={ () => {handleSearchCriteria();} } >{t('button.search')}</Button>
                        </center>
                    </Col>
                </Row>
                <Row className="mt-3"></Row>
                <Table bordered>
                    <colgroup>
                        <col width="5%"/>
                        <col width="10%"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="35%"/>
                        <col width="5"/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t('#')}</th>
                            <th>{t('productcode')}</th>
                            <th>{t('productname')}</th>
                            <th>{t('policytype')}</th>
                            <th>{t('productstartdate')}</th>
                            <th>{t('productenddate')}</th>
                            <th>{t('memberAgentCode')}</th>
                            <th>{t('url')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    {(productListData.compProdListData && productListData.compProdListData.length > 0) ?
                    <tbody>
                        {productListData.compProdListData.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{item.code}</td>
                                    <td>{item.desc}</td>
                                    <td>{item.policyType}</td>
                                    <td>{format(new Date(item.startDate), 'dd/MM/yyyy')}</td>
                                    <td>{format(new Date(item.endDate), 'dd/MM/yyyy')}</td>
                                    <td>{item.memberAgentCode}</td>
                                    { item.url?
                                        <td><div style={{wordBreak:"break-all"}}>{item.url}{' '}</div></td>
                                        :<td></td>
                                    }
                                    { item.url?
                                        <td><CopyTooltipItem id={i} url={item.url}/></td>
                                        :<td></td>
                                    }
                                </tr>
                            )}   
                        )}
                    </tbody>
                :   <tbody>
                        <tr><td className="emptyrecord" colSpan={7}>{t('norecordfound')}</td></tr>
                    </tbody>
                }
                </Table>
            </Container>
        </div>  
    );
}

export default CompanyProduct;