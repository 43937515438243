import { useSelector, useDispatch } from "react-redux";
import * as Constant from './../../constants/Constant';
import * as urlConstant from './../../constants/urlConstant';
import { Get } from './../../utils/AxFetch';
import React, { useEffect } from 'react';  
import {UPDATE_PRODUCT_VERSION} from './../../actions/loginAction' 

const Footer = () => {
    const dispatch = useDispatch();
    const productVersionSelector = useSelector(state => state.loginReducer.productVersion);
   
    useEffect(() => {
        const productVersionHandler = async() =>{
            let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_PRODUCT_VERSION);
            if (!resp.isError && resp.respData) {
                dispatch({ type: UPDATE_PRODUCT_VERSION, productVersion: resp.respData.data});
            }
        }

        if(productVersionSelector === undefined || productVersionSelector === "") {
            productVersionHandler();
        }
    }, [productVersionSelector, dispatch]);

    return (
        <div className="auth-bottom footer">
            <div className="btm-vision"></div>
            <div>{Constant.copyright}<br />{Constant.copyrightBtm}</div>
        </div>
    );
}

export default Footer;