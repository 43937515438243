import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import * as Constant from './../constants/Constant'

// let language;
// if (window.navigator.languages) {
//     language = window.navigator.languages[0];
// } else {
//     language = window.navigator.userLanguage || window.navigator.language;
// }

// if (language.match(/^(en)/)) {
//     language = Constant.defaultLanguageCode;
// }

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: Constant.defaultLanguageCode,
    backend: {
      /* translation file path */
      //loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
      // loadPath: '/assets/i18n/{{lng}}/{{ns}}.json'
      loadPath: '/assets/i18n/{{ns}}.json'
    },
    fallbackLng: Constant.defaultLanguageCode,
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    // ns: ['translations', 'errmsg', 'riskitems'],
    ns: ['id_translations', 'id_errmsg', 'id_riskitems','en_translations', 'en_errmsg', 'en_riskitems'],
    defaultNS: 'id_translations',
    nsSeparator: ':',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true,
      useSuspense: false
    }
  })

export default i18n