import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader ,ModalBody, ModalFooter, Button } from 'reactstrap';
import Checkbox from './Checkbox.jsx';

const Disclaimers = ({isOpen, toggle, disclaimerFields, handleUpload, setDisclaimerFields}) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const [errorMessage, setErrorMessage] = useState();

    const validateDisclaimer = () => {
        let err = false;

        if(!disclaimerFields) return;

        let message = "";
        disclaimerFields.map((disclaimer) => {
            if(disclaimer.isRequired) {
                if(!disclaimer.checked) {
                    err = true;
                    message += " Mandatory Check "+disclaimer.key;
                }
            }
        })
        
        if(err) {
            setErrorMessage(message);
            return;
        }

        handleUpload();
        toggle();  
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{t('disclaimer')}</ModalHeader>
            <ModalBody>
                {
                    disclaimerFields ?
                    disclaimerFields.map((disclaimer, i) => {
                        const changeCheck = () => {
                            setDisclaimerFields(disclaimerFields => {
                                disclaimerFields[i].checked = !disclaimer.checked;
                                return [...disclaimerFields];   
                            })
                        }

                        return (
                            <Checkbox key={disclaimer.key} data={disclaimer} changeCheck={changeCheck} errorMessage={errorMessage}  />
                        )
                    })
                    : ""
                }
            </ModalBody>
            <ModalFooter>
            <Button color="secondary" onClick={() => validateDisclaimer()}>
                Yes
            </Button>
            <Button color="secondary" onClick={() => toggle()}>
                No
            </Button>
            </ModalFooter>
        </Modal>
    )
}

export default Disclaimers;