import React, { useEffect, useRef, useState} from 'react';
import { Button, Label, Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import * as urlConstant from '../../constants/urlConstant';
import companyLogo from './../../images/gel-logo.png';

const PaymentSubmission = (props) => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const [respPaymentInfo] = useState(props.location.state.paymentResp);

  useEffect(() => {
    if (respPaymentInfo) {
      if (!respPaymentInfo.developmentMode) {
        formRef.current.submit();
      }
    }    
  }, [respPaymentInfo])

  return  (
      <div>
        <div className="auth-top claim">
          <img src={companyLogo} className="company-logo mx-auto d-block" alt="username" />
        </div>
        {respPaymentInfo ?
          <Container className="pt-5">
            <center>
              <Row className="mt-3"><Col><h4>{t('payment.submission.processing')}</h4></Col></Row>
              <Label><i className="error">{t('payment.submission.warnMessage')}</i></Label>
            </center>
            <form action={respPaymentInfo.url} method="POST" ref={formRef}>
              <input type="hidden" name="MALLID" value={respPaymentInfo.mallid} />
              <input type="hidden" name="CHAINMERCHANT" value={respPaymentInfo.chainmerchant} />
              <input type="hidden" name="AMOUNT" value={respPaymentInfo.amount} />
              <input type="hidden" name="PURCHASEAMOUNT" value={respPaymentInfo.purchaseamount} />
              <input type="hidden" name="TRANSIDMERCHANT" value={respPaymentInfo.transidmerchant} />
              <input type="hidden" name="PAYMENTCHANNEL" value={respPaymentInfo.paymentchannel} />
              <input type="hidden" name="WORDS" value={respPaymentInfo.words} />
              <input type="hidden" name="REQUESTDATETIME" value={respPaymentInfo.requestdatetime} />
              <input type="hidden" name="CURRENCY" value={respPaymentInfo.currency} />
              <input type="hidden" name="PURCHASECURRENCY" value={respPaymentInfo.purchasecurrency} />
              <input type="hidden" name="SESSIONID" value={respPaymentInfo.sessionid} />
              <input type="hidden" name="NAME" value={respPaymentInfo.name} />
              <input type="hidden" name="EMAIL" value={respPaymentInfo.email} />
              <input type="hidden" name="BASKET" value={respPaymentInfo.basket} />
              {respPaymentInfo.developmentMode ? 
                <center className="mt-3">
                  <Button type="submit" formAction={urlConstant.URL_BASED + urlConstant.URL_B2C_PAYMENT_MOCK_SUCCESS}>{t('button.success')}</Button>{' '}
                  <Button type="submit" formAction={urlConstant.URL_BASED + urlConstant.URL_B2C_PAYMENT_MOCK_FAILED}>{t('button.failed')}</Button>{' '}
                </center>
                : null
              }
            </form>
            <div className="pb-5"/>
          </Container>
          : <div>{t('payment.submission.denied')}</div>
        }
      </div>
  );
}

export default PaymentSubmission;