import React, { Component } from 'react';
import Toolbar from './components/Toolbar';
import SideDrawer from './components/SideDrawer';
import Backdrop from './components/Backdrop';
import { connect } from 'react-redux';
import LoginRoute from './containers/LoginRoute';
import AppRoute from './containers/AppRoute';
import ErrorMessage from './utils/ErrorMessage';
import InfoMessage from './utils/InfoMessage';
import Loading from './utils/Loading';
import IdleSession from './utils/IdleSession';
import Footer from "./containers/common/Footer";
import Logo from "./containers/common/Logo"

import './App.css';

const entries = performance.getEntriesByType("navigation").map( nav => nav.type );

class App extends Component {
	state = {
		sideDrawerOpen: false
	};
	
	drawerToogleClickHandler = () => {
		this.setState((prevState) => {
			return {sideDrawerOpen: !prevState.sideDrawerOpen};
		});
	};

	backdropClickHandler =() => {
		this.setState({sideDrawerOpen: false});
	};

	componentDidMount = () => {
		if (this.isPageRefreshed()) {
			this.props.clearError();
		}
	}

	isPageRefreshed() {
		return window.performance && entries.includes("reload");
	}
	
	render() {
		let backdrop;
		let mainPage;
		let mainClassNm = "";
		
		if(this.state.sideDrawerOpen) {
			backdrop = <Backdrop click={this.backdropClickHandler} />;
			mainClassNm = "push-content-container";
		} else {
			mainClassNm = "content-container";
		}

		if(this.props.loginstatus) {
			mainPage = (
				<div className={'Main ' + mainClassNm}>
					<IdleSession/>
					<Toolbar drawerClickHandler={this.drawerToogleClickHandler} />
					<SideDrawer show={this.state.sideDrawerOpen} />
					{backdrop}
					{this.props.errorMsg ? <ErrorMessage errorMsg={this.props.errorMsg} isVisible={true}/> :''}
					{this.props.infoMsg ? <InfoMessage infoMsg={this.props.infoMsg} isVisible={true}/> :''}
					<div className="Content" >
						<Logo/>			
						<div className="broswer-wrapper">
							<AppRoute />
						</div>
					</div>
					<Footer/>
				</div>
			);
		} else {
			mainPage = (	
				<div>
					{this.props.errorMsg ? <ErrorMessage errorMsg={this.props.errorMsg} isVisible={true}/> :''}
					{this.props.infoMsg? <InfoMessage infoMsg={this.props.infoMsg} isVisible={true}/> :''}
					<LoginRoute/>
					<Footer/>
				</div>
			);
		}
		return(
			<div className="App">
				{this.props.isLoading ? <Loading/> : ''}
				{mainPage}
			</div>
		)
	  }
}

const mapStateToProps = (state) => {
	return {
		loginstatus: state.loginReducer.loginstatus,
		id : state.loginReducer.id,
		username : state.loginReducer.username,
		isLoading: state.loadingReducer.isLoading,
		errorMsg: state.messageReducer.errorMsg,
		infoMsg: state.messageReducer.infoMsg,
		isVisible: state.messageReducer.isVisible
	}
}

const mapDispatchToState = (dispatch) => {
    return {
		clearError: () => {dispatch({type : 'CLEAR_ERROR'})}
    }
}

export default connect(mapStateToProps, mapDispatchToState)(App);
