import * as actions from '../../actions/loadingAction ';

const initState = {
    isLoading: false
}

const loadingReducer = (state = initState, action) => {
  switch(action.type) {
    case actions.LOADING_PROCESS: {
      return {
        ...state.loadingReducer,
        isLoading: true
      }
    }
    case actions.LOADING_DONE: {
      return {
        ...state.loadingReducer,
        isLoading: false
      }
    }
    default: {
      return state;
    }
  }
}

export default loadingReducer;