import React from 'react';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Select = (props) => {
    const { t } = useTranslation();
    let options = props.optionList?props.optionList:[];
    let attributes = {...props};
    let noBlankSelectionFlag = false;

    delete attributes.optionList;
    delete attributes.defaultVal;
    delete attributes.noBlankSelectionFlag;

    if(props.defaultVal) {
        attributes.defaultValue = props.defaultVal;
    }

    if(props.noBlankSelectionFlag) {
        noBlankSelectionFlag = true;
    }

    return (
        <Input value={attributes.value} type="select" {...attributes} >
            {!noBlankSelectionFlag ? <option value="">{t('placeholder.select')}</option> : null}
            {options.map(item => (
                        <option key={item.shortCode} value={item.shortCode}>{item.value}</option>
                    )
                )
            }
        </Input>
    )
}

export default Select;