import React, { useState } from 'react';
import {Row, Col, Card, CardBody} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../components/Checkbox';

const ConfirmationDisclaimer = ({disclaimerFields, setDisclaimerFields, errorMessage}) => {
    const { t } = useTranslation();
    const t2 = (label) => { return t('en_translations:'+label) };

    if(disclaimerFields.length !== 0) {
        return (
            <div className="mt-3">
                <Row><Col><h5>{t('disclaimer')}</h5></Col></Row>
                <Row><Col><h6>{t2('disclaimer')}</h6></Col></Row>
                
                <Card>
                    <CardBody>
                        {disclaimerFields.map((disclaimer, i) => {
                            const changeCheck = () => {
                                setDisclaimerFields(disclaimerFields => {
                                    disclaimerFields[i].checked = !disclaimer.checked;
                                    return [...disclaimerFields];   
                                })
                            }

                            return (
                                <Checkbox key={disclaimer.key} data={disclaimer} changeCheck={changeCheck} errorMessage={errorMessage} />
                            )
                        })}
                    </CardBody>
                </Card>
            </div>
        )
    }
    else return "";    
}

export default ConfirmationDisclaimer;