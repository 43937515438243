import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Table, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import $ from 'jquery';
import Select from '../../utils/Select';
import { Get } from '../../utils/AxFetch';
import * as urlConstant from './../../constants/urlConstant';

const TransactionCancel = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [message,setMessage] = useState("");
    const [cancelReasonList, setCancelReasonList] = useState([]);

    const validateForm = () => {
        let message = '';
        if (!$('Select[name="reason"]').val()) message += t('error.message.select.blank', { 0: t('transaction.cancel.reason') })+'\n'
        if (message) {
            setMessage(message);
            return false;
        }
        return true;
    }

    const doCancel = async dispatch => {
        let selectId = null; 
        if (props.params.transactionId) selectId = props.params.transactionId;
        let reasonCode = $('Select[name="reason"]').val();

        if (validateForm()) {
            let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_TRNX_CANCEL + selectId +'/reasonCode/' + reasonCode, dispatch);
            if(!resp.isError && resp.respData.data) {
                props.handleClose(true, t('message.save.success', { 0: t('certificateinsurance.result.certificate'), 1: t('updated') }));
            } else {
                props.handleClose(false, '');
            }
        }
    }

    const dropdownLists = useCallback(async() => {
        let reasonList = [];
        let cancelReasonResp = await Get(urlConstant.URL_BASED + urlConstant.URL_LOOKUP_CANCELREASON, null, dispatch);
        let dataResp = cancelReasonResp.respData;
        if(!cancelReasonResp.isError && dataResp.data) {
            reasonList = dataResp.data.map(reason => {
                return {shortCode: reason.shortCode, value: reason.value}
            });
        }
        setCancelReasonList(reasonList);
    }, [dispatch]);

    useEffect(() => {
        dropdownLists();
    }, [dropdownLists])

    return (
        <Container>
            { message? <div className="error"><p>{message}</p></div>: null}
            <Table>
            <tbody>
            <tr>
                <td><Label>{t('transaction.search.cinumber')}</Label></td>
                <td>:</td>
                <td><Label name="cinumber">{props.params.certNo}</Label></td>
            </tr>
            <tr>
                <td><Label>{t('transaction.cancel.reason')}</Label></td>
                <td>:</td>
                <td><Select name="reason" optionList={cancelReasonList} /></td>
            </tr>
            </tbody>
            </Table>
            <div style={{textAlign: 'center'}}>
            <Button className="mt-3" onClick={ ()=> dispatch(doCancel) } >{t('cancelCI')}</Button>
            </div>
        </Container>
    )

}

export default TransactionCancel;