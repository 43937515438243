import React, { useState, useEffect } from 'react';
import { Button, Label, Input, Table } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import * as urlConstant from './../../constants/urlConstant';
import { Get, Post, FGDownload } from '../../utils/AxFetch';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import * as Constant from './../../constants/Constant';
import * as Validate from './../../utils/CommonValidate';

const ClaimApproval = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [claimId] = useState(props.location.state? props.location.state.claimId : '');
    const [respData, setRespData] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [message,setMessage] = useState('');

    const validateForm = (action) => {
        let settleAmtError = "";
        let assessmentRemarksError = "";
        let paymentRemarksError = "";

        if (action === Constant.REVIEWDOC) {
            if (respData.documents) {
                let docRemark = [];
                respData.documents.map((doc) => {
                    let docCode = doc.documentCode;
                    if ($('#'+docCode).val()) {
                        docRemark.push({ 
                            documentCode : docCode, 
                            remarks : $('#'+docCode).val() })
                    }
                    return docRemark;
                })
                if (docRemark.length === 0) {
                    if (errorMsg) setErrorMsg('');
                    setMessage(t('error.message.docremark.empty'));
                    window.scrollTo(0, 0);
                    return false;
                } else return true;
            }
        } else if (action === Constant.APPROVED) {
            if (!$('input[name="settleAmt"]').val()) {
                settleAmtError = t('error.message.input.blank', {0: t('settleamt')});
            } else if (!($('input[name="settleAmt"]').val() > 0)) {
                settleAmtError = t('error.message.input.zero', {0: t('settleamt')});
            }

            if (!$('#assessmentRemarks').val()) assessmentRemarksError = t('error.message.input.blank', {0: t('assessmentremarks')});

            setErrorMsg({ settleAmtError: settleAmtError, assessmentRemarksError: assessmentRemarksError });
            if (message) setMessage('');
            if ( settleAmtError || assessmentRemarksError ) {
                return false;
            }
            return true;
        } else if (action === Constant.DECLINED) {
            if (!$('#assessmentRemarks').val()) assessmentRemarksError = t('error.message.input.blank', {0: t('assessmentremarks')});

            setErrorMsg({ assessmentRemarksError: assessmentRemarksError });
            if (message) setMessage('');
            if ( assessmentRemarksError ) {
                return false;
            }
            return true;
        } else if (action === Constant.NOTIFYPAYMENT) {
            if (!$('#paymentRemarks').val()) paymentRemarksError = t('error.message.input.blank', {0: t('paymentremarks')});

            setErrorMsg({ paymentRemarksError: paymentRemarksError });
            if (message) setMessage('');
            if ( paymentRemarksError ) {
                return false;
            }
            return true;
        }
    };

    const handleDocReviewed = async() => {
        if (validateForm(Constant.REVIEWDOC)) {
            let docRemark = [];
            if (respData.documents) {
                respData.documents.map((doc) => {
                    let docCode = doc.documentCode;
                        docRemark.push({ 
                            documentCode : docCode, 
                            remarks : $('#'+docCode).val() })
                    return docRemark;
                })
            }
            let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_CLAIM_APPROVAL_REVIEWDOC, 
                { "id" : respData.id,
                  "documents" : docRemark 
                }, 
                dispatch);
            if(!resp.isError && resp.respData.data) { 
                setRespData(resp.respData.data);
                $(':input').val('');
                setMessage(t('message.review.doc.success'));
                window.scrollTo(0, 0);
            }
        }
    }

    const handleNotifyPayment = async() => {
        if (validateForm(Constant.NOTIFYPAYMENT)) {
            let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_CLAIM_APPROVAL_NOTIFYPAYMENT, 
                { id : respData.id,
                  paymentRemark : $('#paymentRemarks').val()
                }, 
                dispatch);
            if(!resp.isError && resp.respData.data) { 
                setRespData(resp.respData.data);
                $(':input').val('');
                setMessage(t('message.notify.payment.success'));
                window.scrollTo(0, 0);
            }
        }
    }

    const handleApproval = async(action) => {
        if (validateForm(action)) {
            let settlementAmt = action === Constant.DECLINED && $('input[name="settleAmt"]').val().length === 0 ? 0 : $('input[name="settleAmt"]').val();
            let url = '';
            let postObj = {
                id: respData.id,
                settlementAmountStg: settlementAmt,
                assessmentRemark: $('#assessmentRemarks').val()
            };
            let message = '';

            if ( action === Constant.APPROVED ) {
                url = urlConstant.URL_CLAIM_APPROVAL_APPROVE;
                message = t('message.claim.approved.success');
            } else if ( action === Constant.DECLINED ) {
                url = urlConstant.URL_CLAIM_APPROVAL_DECLINED;
                message = t('message.claim.declined.success');
            } else return false; 

            let resp = await Post(urlConstant.URL_BASED + url, postObj, dispatch);

            if(!resp.isError && resp.respData.data) { 
                setRespData(resp.respData.data);
                setMessage(message);
                $(':input').val('');
                window.scrollTo(0, 0);
            }
        }
    }

    const handleDownload = async(documentCode, fileName) => {
        let ref = respData.claimRefNo+'^'+documentCode;

        let resp = await FGDownload(fileName, ref, dispatch);

        if (resp.isError) {
            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        const retrieveAccount = async dispatch => {
            let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_CLAIM_APPROVAL_RETRIEVE + claimId, null, dispatch);
            if(!resp.isError && resp.respData.data) { 
                setRespData(resp.respData.data);
                if (resp.respData.data.documents) {
                    resp.respData.data.documents.map((doc) => {
                        if (doc.remarks) {
                            return $('#'+doc.documentCode).val(doc.remarks);
                        } else return '';
                    })
                }
            }
        }
        dispatch(retrieveAccount);
        window.scrollTo(0, 0);
    }, [claimId, dispatch])

    return (
        <div>
            <Container>  
            { message? <div className="error"><p>{message}</p></div>: null}
                <Row className="mt-3"><Col><h2>{t('claimapproval')}</h2></Col></Row>
                <div></div>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('certificateinsuranceno')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5">{respData.policyNo}</Col> 
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('claimid')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5">{respData.claimRefNo}</Col>    
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('status')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5">{respData.statusDesc}</Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('mobileno')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5">{respData.mobileNo}</Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('emailaddress')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5">{respData.email}</Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('name')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5">{respData.proposerName}</Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('product')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5">{respData.productName}</Col>
                </Row>

                <div className="content-tables">
                    <Table id="basicBeneTable" bordered className="mt-3" style={{float:'left',width: '50%'}}> 
                        <thead className='text-center claim-table-header'>
                            <tr>
                                <th>{t('basicbenefit')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {respData.basicBenefits ?
                                respData.basicBenefits.map(basicBenefit => {
                                    return <tr key={basicBenefit}><td>{basicBenefit}</td></tr>
                                }) : <tr><td/></tr>
                            }
                        </tbody>
                    </Table>
                    <Table id="addBeneTable" bordered className="mt-3" style={{float:'left',width: '50%'}}> 
                        <thead className='text-center claim-table-header'>
                            <tr>
                                <th>{t('additionalbenefits')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {respData.additionalBenefits ?
                                respData.additionalBenefits.map(addBenefit => {
                                    return <tr key={addBenefit}><td>{addBenefit}</td></tr>
                                }) : <tr><td/></tr>
                            }
                        </tbody>
                    </Table>
                </div>

                <Row><Col><strong>{t('documentRequired')}</strong></Col></Row>
                    <Table bordered className="mt-3"> 
                        <thead className='text-center claim-table-header'>
                            <tr>
                                <th>{t('documenttype')}</th>
                                <th>{t('documentdesc')}</th>
                                <th>{t('documentrequired')}</th>
                                <th>{t('documentdate')}</th>
                                <th>{t('documentstatus')}</th>
                                <th>{t('documentuploaded')}</th>
                                <th>{t('documentreview')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {respData.documents ?
                                respData.documents.map((document, i) => {
                                    return <tr key={document.documentCode}>
                                    <td>{document.documentType}</td>
                                    <td>{document.documentDesc}</td>
                                    <td>{document.documenRequire ? Constant.documentRequiredTrue : Constant.documentRequiredFalse}</td>
                                    <td>{document.lastModifiedDatetime}</td>
                                    <td>{document.status}</td>
                                    <td><button className="link-button" onClick={()=> handleDownload(document.documentCode, document.fileName)}>{document.fileName}</button></td>
                                    <td>{
                                        respData.status === Constant.PEND_APPVL ?
                                            <Input id={document.documentCode} type='textarea' name={document.documentCode} maxLength='50'></Input>
                                            : document.remarks
                                    }</td>
                                </tr>
                            }) : <tr/>
                            }
                        </tbody>
                    </Table>
                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('dateofloss')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5">{respData.incidentDateStg}</Col> 
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('claimsamount')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5">{respData.claimAmountStg}</Col>    
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('claimsdescription')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5">{respData.claimRemark}</Col>
                </Row>

                <Table bordered className="mt-3 w-75"> 
                    <thead className='claim-table-header'>
                        <tr>
                            <th>{t('claimsassessment')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Row className="mt-3">
                                    <Col xs="10" sm="3" md="3"><Label className={respData.status === Constant.PEND_APPVL ? "required" : ""}>{t('settleamt')}</Label></Col>
                                    <Col xs="2" sm="1" md="1">:</Col>
                                    <Col xs="12" sm="8" md="8">
                                        {
                                            respData.status === Constant.PEND_APPVL ? 
                                            <Input name='settleAmt' onKeyPress={(event) => Validate.number(event)} maxLength='15'></Input> :
                                            respData.settlementAmountStg
                                        }
                                    </Col> 
                                </Row>
                                {errorMsg.settleAmtError ?
                                    <Row>
                                        <Col sm={{ size: 8, offset: 4 }}><div className="error">{errorMsg.settleAmtError}</div></Col>
                                    </Row>
                                    : ''
                                }
                                <Row className="mt-3">
                                    <Col xs="10" sm="3" md="3"><Label className={respData.status === Constant.PEND_APPVL ? "required" : ""}>{t('assessmentremarks')}</Label></Col>
                                    <Col xs="2" sm="1" md="1">:</Col>
                                    <Col xs="12" sm="8" md="8">
                                        {
                                            respData.status === Constant.PEND_APPVL ? 
                                            <Input type='textarea' id='assessmentRemarks' maxLength='140'></Input> :
                                            respData.assessmentRemark
                                        }
                                    </Col> 
                                </Row>
                                {errorMsg.assessmentRemarksError ?
                                    <Row>
                                        <Col sm={{ size: 8, offset: 4 }}><div className="error">{errorMsg.assessmentRemarksError}</div></Col>
                                    </Row>
                                    : ''
                                }
                                {respData.status === Constant.APPROVED || respData.status === Constant.PAID ? 
                                    <Row className="mt-3">
                                        <Col xs="10" sm="3" md="3"><Label className={respData.status === Constant.APPROVED ? "required" : ""}>{t('paymentremarks')}</Label></Col>
                                        <Col xs="2" sm="1" md="1">:</Col>
                                        <Col xs="12" sm="8" md="8">
                                            {
                                                respData.status === Constant.APPROVED ? 
                                                <Input type='textarea' id='paymentRemarks' maxLength='140'></Input> :
                                                respData.paymentRemark
                                            }
                                        </Col> 
                                    </Row> : ''
                                }
                                {errorMsg.paymentRemarksError ?
                                    <Row>
                                        <Col sm={{ size: 8, offset: 4 }}><div className="error">{errorMsg.paymentRemarksError}</div></Col>
                                    </Row>
                                    : ''
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <Row>
                    <Col xs="12" sm="12" md="12" className='text-center mt-3'>
                        {
                            respData.status === Constant.PEND_APPVL ? 
                            <>
                                <Button onClick={handleDocReviewed}>{t('button.docReviewed')}</Button>{' '}
                                <Button onClick={() => handleApproval('APPROVED')}>{t('button.approved') }</Button>{' '}
                                <Button onClick={() => handleApproval('DECLINED')}>{t('button.decline')}</Button>{' '}
                            </> :
                            respData.status === Constant.APPROVED ? 
                            <>
                                <Button id='notifyPayBtn' onClick={handleNotifyPayment}>{t('button.notifypayment')}</Button>{' '}
                            </> : ''
                        }
                        <Button onClick={() => dispatch(push(Constant.PATH_CLAIM_APPROVAL))}>{t('button.back')}</Button>
                    </Col>
                </Row>	

            </Container>
            
        </div>
    )
}

export default ClaimApproval;