
import axios from 'axios';
import * as action from '../actions/loadingAction ';
import * as loginAction from '../actions/loginAction';
import * as constant from '../constants/Constant';
import moment from 'moment';
import * as urlConstant from './../constants/urlConstant';
import * as Constant from './../constants/Constant';
import * as Crypto from './Crypto';
import * as mime from 'mime-types';

export const Get = async(url, postObject, dispatch) =>{
    let obj = {
        isError: false,
        errorMessage: null,
        respData: null,
        errorDetails: null
    }

    if (dispatch !== undefined) {
        dispatch({ type: action.LOADING_PROCESS });
    }
    
    await axios.get(url, postObject)
    .then(response => {
        if (response.data.errorCode) {
            obj.isError = true;
            obj.errorMessage = response.data.errorCode;
            if (response.data.details) {
                obj.errorDetails = response.data.details;
            }
            if (dispatch !== undefined) {
                dispatch({ type: loginAction.DISPLAY_ERROR, errorMsg: obj.errorMessage, errorDetails: obj.errorDetails });
            }
        }else{
            obj.isError = false;
            obj.errorMessage = '';
            if(dispatch !== undefined) {
                dispatch({type: loginAction.CLEAR_ERROR})
            }
        }
        obj.respData= response;
    })
    .catch(error => {
        obj.isError = true;               
        if (error.response !== undefined) {
            if (error.response.data.errorCode) {
                obj.errorMessage = error.response.data.errorCode;
                if (error.response.data.details) {
                    obj.errorDetails = error.response.data.details;
                }
            }else{
                obj.errorMessage = error.response.data.status;
            }
        } else {
            obj.errorMessage = constant.DEFAULT_ERR;
        }

        if (dispatch !== undefined) {
            dispatch({ type: loginAction.DISPLAY_ERROR, errorMsg: obj.errorMessage, errorDetails: obj.errorDetails });
        }
    })
    .finally(() => {
        if (dispatch !== undefined) {
            dispatch({ type: action.LOADING_DONE });
        }
    });
    return obj;
};

export const Post = async(url, postObject, dispatch) =>{
    let obj = {
            isError: false,
            errorMessage: null,
            respData: null,
            errorDetails: null
        }

        if (dispatch !== undefined) {
            dispatch({ type: action.LOADING_PROCESS });
        }

        await axios.post(url, postObject)
        .then(response => {
            if (response.data.errorCode) {
                obj.isError = true;
                obj.errorMessage = response.data.errorCode;
                if (response.data.details) {
                    obj.errorDetails = response.data.details;  
                }
                if (dispatch !== undefined) {
                    dispatch({ type: loginAction.DISPLAY_ERROR, errorMsg: obj.errorMessage, errorDetails: obj.errorDetails});
                }
            }else{
                if (dispatch !== undefined) {
                    dispatch({ type: loginAction.CLEAR_ERROR});
                }    
            }
            obj.respData= response;
        })
        .catch(error => {
            obj.isError = true; 
            if (error.response !== undefined) {
                // client received an error response.
                if (error.response.data.errorCode) {
                    obj.errorMessage = error.response.data.errorCode;
                    if (error.response.data.details) {
                        obj.errorDetails = error.response.data.details;
                    }
                }else{
                    obj.errorMessage = error.response.data.status;  
                }
            }else if (error.request) {
                // client never received a response, or request never left.
                obj.errorMessage = error.request.status;
            
            } else {
                // anything else.
                obj.errorMessage = constant.DEFAULT_ERR;  
            }

            if (dispatch !== undefined) {
                dispatch({ type: loginAction.DISPLAY_ERROR, errorMsg: obj.errorMessage, errorDetails: obj.errorDetails });
            }
        })
        .finally(() => {
            if (dispatch !== undefined) {
                dispatch({ type: action.LOADING_DONE });
            }
        });

        return obj;
};

export const FileGateway = async(transRef ,file, channel, dispatch, disclaimerFields) =>{
    let obj = {
            isError: false,
            errorMessage: null,
            respData: null
        }

        if (dispatch !== undefined) {
            dispatch({ type: action.LOADING_PROCESS });
        }

        let currentTms =  moment().format(constant.dateFormat);
        let hmacProp = Crypto.hmacFileGateway('POST', urlConstant.URL_FILEGATEWAY_DEFAULT+channel, constant.FG_SecretKey, transRef, currentTms);
        let formData = new FormData();
        formData.append('transRef', transRef);
        formData.append('file', file);
        formData.append('disclaimerFields', JSON.stringify(disclaimerFields));

        await axios.post(urlConstant.URL_FILEGATEWAY_HOST + urlConstant.URL_FILEGATEWAY_DEFAULT+channel, formData,
            {
              headers: {
                Timestamp: currentTms,
                CountryCode: constant.defaultCountryCode,
                ClientOrgCode: constant.FG_ClientOrgCode,
                Keyid: constant.FG_KeyId,
                HashProposal: hmacProp
              }
            })
        .then(response => {
            if (dispatch !== undefined) {
                dispatch({ type: loginAction.CLEAR_ERROR});
            }
            obj.respData= response;
        })
        .catch(error => {
            obj.isError = true;
            if (error.response !== undefined) {
                if (error.response.data.systemInformation) {
                    if (error.response.data.systemInformation[0].errorCode === constant.FG_SCAN_VIRUS_FAILED) {
                        obj.errorMessage = constant.FG_SCAN_VIRUS_FAILED;
                        obj.respData= constant.FG_SCAN_VIRUS_FAILED;
                    } else {
                        obj.errorMessage = constant.FG_COMMON_ERROR;
                    }
                }
            }else if (error.request) {
                obj.errorMessage = error.request.status;
            } else {
                obj.errorMessage = constant.DEFAULT_ERR;  
            }

            if (dispatch !== undefined) {
                dispatch({ type: loginAction.DISPLAY_ERROR, errorMsg: obj.errorMessage });
            }
        })
        .finally(() => {
            if (dispatch !== undefined) {
                dispatch({ type: action.LOADING_DONE });
            }
        });

        return obj;
};

export const FGDownload = async(outputfilename, transRef, dispatch) =>{
    let obj = {
        isError: false,
        errorMessage: null,
        respData: null
    }

    if (dispatch !== undefined) {
        dispatch({ type: action.LOADING_PROCESS });
    }

    let currentTms =  moment().format(constant.dateFormat);
    let hmacProp = Crypto.hmacFileGateway('GET', urlConstant.URL_FILEGATEWAY_DEFAULT + constant.FG_CH_fileDownload, constant.FG_SecretKey, transRef, currentTms);

    await axios({
        url: urlConstant.URL_FILEGATEWAY_HOST + urlConstant.URL_FILEGATEWAY_DEFAULT + constant.FG_CH_fileDownload,
        method: 'GET',
        headers: {
            Timestamp: currentTms,
            CountryCode: constant.defaultCountryCode,
            ClientOrgCode: constant.FG_ClientOrgCode,
            Keyid: constant.FG_KeyId,
            HashProposal: hmacProp
        },
        params: {
            transRef: transRef
        },
        responseType: 'blob',
      })
    .then(response => {
        if (dispatch !== undefined) {
            dispatch({ type: loginAction.CLEAR_ERROR});
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputfilename);
        document.body.appendChild(link);
        link.click();
        obj.respData= response;
    })
    .catch(error => {
        obj.isError = true;
        if (error.response !== undefined) {
            if (error.response.status !== constant.HTTP_200) {
                obj.errorMessage = constant.FG_DOWNLOAD_ERROR;
            }
        }else if (error.request) {
            obj.errorMessage = error.request.status;
        
        } else {
            obj.errorMessage = constant.DEFAULT_ERR;  
        }

        if (dispatch !== undefined) {
            dispatch({ type: loginAction.DISPLAY_ERROR, errorMsg: obj.errorMessage });
        }
    })
    .finally(() => {
        if (dispatch !== undefined) {
            dispatch({ type: action.LOADING_DONE });
        }
    });
    return obj;
};

export const Download = async(url, type, paramsOrData, outputfilename, dispatch) =>{
    let obj = {
        isError: false,
        errorMessage: null,
        respData: null
    }
    
    if (dispatch !== undefined) {
        dispatch({ type: action.LOADING_PROCESS });
    }

    await axios({
        url: url,
        method: type,
        params: type===Constant.downloadType.GET? paramsOrData: null,
        data: type===Constant.downloadType.POST? paramsOrData: null,
        responseType: "blob"
    })    
    .then((response) => {
        if (response.data.errorCode) {
            obj.isError = true;
            obj.errorMessage = response.data.errorCode;
            if (dispatch !== undefined) {
                dispatch({ type: loginAction.DISPLAY_ERROR, errorMsg: obj.errorMessage });
            }
        }else{
            obj.isError = false;
            obj.errorMessage = '';
            obj.respData= response;
        }
        
        let contentDisposition = response.headers["content-disposition"];
        let filename = "";
        let file = undefined;
        if(contentDisposition) {
            filename = contentDisposition.split("filename=")[1];
            let strMime = mime.contentType(filename);
            file = new Blob([response.data], { type: strMime});
        } else {
            file = new Blob([response.data]);
            filename = outputfilename? outputfilename: ("no-name." + mime.extension(file.type));
        }
        if (file) {
            let url = window.URL.createObjectURL(file);
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    })
    .catch(error => {
        obj.isError = true;   
        if (error.response !== undefined) {
            if (error.response.data.errorCode) {
                obj.errorMessage = error.response.data.errorCode;
            }else if(error.response.data.status){
                obj.errorMessage = error.response.data.status;
            }else{
                obj.errorMessage = Constant.FILE_DOWNLOAD_ERROR;
            }
        } else {
            obj.errorMessage = constant.DEFAULT_ERR;
        }

        if (dispatch !== undefined) {
            dispatch({ type: loginAction.DISPLAY_ERROR, errorMsg: obj.errorMessage });
        }
    })
    .finally(() => {
        if (dispatch !== undefined) {
            dispatch({ type: action.LOADING_DONE });
        }
    });
    return obj;
};