import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Table } from 'reactstrap';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import * as Constant from './../../constants/Constant';
import * as urlConstant from './../../constants/urlConstant';
import { Post } from '../../utils/AxFetch';
import { useDispatch, useSelector } from 'react-redux';
import PaginationBar from './../../components/PaginationBar';

const AccessControl = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAllowNew = useSelector(state => state.sideMenuReducer.functionItems.accessControlDetail ? state.sideMenuReducer.functionItems.accessControlDetail.includes('NEW') : '');
    const [list,setList] = useState({roleList:[], currentPage: 1, pageSize: 0, pagesCount: 0});
    
    const getRoleList = (page) => async dispatch => {
        let roleList = [];
        let currentPage = 1;
        let pageSize = 0;
        let pagesCount = 0;
        let postObject = {
            rowPerPage: Constant.rowPerPage,
            pageNum: page
        };
        let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_ROLE_LIST + page, postObject, dispatch);
        
        if(!resp.isError && resp.respData.data) {
            roleList = resp.respData.data.content;
            currentPage = page;
            pageSize = resp.respData.data.size;
            pagesCount = resp.respData.data.totalPages;
        }

        setList({roleList: roleList, currentPage: currentPage, pageSize: pageSize, pagesCount: pagesCount});
    }

    useEffect(() => {
        dispatch(getRoleList(1));
    }, [dispatch]);

    return (
        <Container>
            <Row className="mt-3"><Col><h2>{t('accesscontrolmaintenance')}</h2></Col></Row>
            <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('rolelist')}</h4></Col></Row>
            <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>
            <div>
                <Table className="mt-3" bordered>
                    <thead>
                        <tr>
                            <th style={{width: '10%'}}>{t('no.')}</th>
                            <th>{t('rolename')}</th>
                            <th>{t('lastupdatedon')}</th>
                            <th>{t('lastupdatedby')}</th>
                        </tr>
                    </thead>
                    {(list.roleList && list.roleList.length > 0) ?
                    <tbody>
                        {list.roleList.map((item, i) => {
                            return (
                            <tr key={i}>
                                <td style={{width: '10%'}}>{i+1}</td>
                                <td>
                                    <button className="link-button" 
                                        onClick={() => dispatch(push({
                                            pathname: Constant.PATH_ROLE_ACCESSCONTROLDET,
                                            state: { 
                                                action: 'EDIT',
                                                idSelected: item.id
                                            }
                                        }))}>{item.name}
                                    </button>
                                </td>
                                <td>{item.lastModifiedDatetime}</td>        
                                <td>{item.lastModifiedByName}</td>        
                            </tr>)}
                        )}
                    </tbody>
                    :   <tbody>
                            <tr><td className="emptyrecord" colSpan={4}>{t('norecordfound')}</td></tr>
                        </tbody>
                    }
                </Table>
                {isAllowNew ?
                    <div style={{textAlign: 'center'}}>
                        <Button className="mt-3" onClick={() => dispatch(push({
                            pathname: Constant.PATH_ROLE_ACCESSCONTROLDET,
                            state: { 
                                action: 'NEW',
                                idSelected: null
                            }
                        }))}>{t('button.add')}</Button>
                    </div> : <></>
                }
                <PaginationBar totalPages={list.pagesCount} currentPage={list.currentPage} handleClick={(page) => dispatch(getRoleList(page))}  />
            </div>
        </Container>
    )
}

export default AccessControl;
