import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import loginReducer from './modules/loginReducer';
import sideMenuReducer from './modules/sideMenuReducer';
import loadingReducer from './modules/loadingReducer';
import messageReducer from './modules/messageReducer'; 
import persistConfig from './persistConfig';
import './../utils/i18n';

export const history = createBrowserHistory();

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  loginReducer,
  sideMenuReducer,
  loadingReducer,
  messageReducer
})

const historyMiddleware = routerMiddleware(history);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export const store = createStore(connectRouter(history)(persistedReducer), composeEnhancers(applyMiddleware(historyMiddleware, thunk)));

export const persistor = persistStore(store);