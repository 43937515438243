import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Button, Label, Input, Table, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Select from '../../utils/Select';
import { Get, Post } from '../../utils/AxFetch';
import $ from 'jquery';
import * as urlConstant from './../../constants/urlConstant';
import * as Validate from './../../utils/CommonValidate';


const TransactionUpdatePayment = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [message,setMessage] = useState("");
    const [paymentTypeList, setPaymentTypeList] = useState({pymtTypeList:[]});
    const [paymentChannelList, setPaymentChannelList] = useState({pymtChannelList:[]});
    
    const validateForm = () => {
        let message = '';
        
        if (!$('input[name="apprcode"]').val()) message += t('error.message.input.blank', { 0: t('approvalcode') })+'\n'
        if (!$('select[name="paymenttype"]').val()) message += t('error.message.select.blank', { 0: t('paymenttype') })+'\n'
        if (!$('select[name="paymentchannel"]').val()) message += t('error.message.select.blank', { 0: t('paymentchannel') })+'\n'
        if (!$('input[name="paymentcode"]').val()) message += t('error.message.input.blank', { 0: t('paymentcode') })+'\n'
        if (!$('input[name="bankissuer"]').val()) message += t('error.message.input.blank', { 0: t('bankissuer') })+'\n'
        if (!$('input[name="cardbrand"]').val()) message += t('error.message.input.blank', { 0: t('cardbrand') })+'\n'
        if (!$('input[name="cardholdername"]').val()) message += t('error.message.input.blank', { 0: t('cardholdername') })+'\n'
        if (!$('input[name="cardnumber"]').val()) message += t('error.message.input.blank', { 0: t('cardnumber') })+'\n'

        if (message) {
            setMessage(message);
            //to do scroll up
            //$('#updatePayment').scrollTop(0,0);
            //$("body").scrollTop($("#updatePayment").offset().top);
            return false;
        }
        return true;
    }

    //to do - not working
    const populateField = useCallback((data) => {
        //console.log(data.refNo1);
        $('input[name="paymentrefno"]').val(data.paymentRefNo);
        //$("#paymentrefno").val("Test");
        //$('label[name="paymentrefno"]').val(data.refNo1);
        $('input[name="apprcode"]').val(data.approvedCode);
        $('select[name="paymenttype"]').val(data.paymentMethod);
        $('select[name="paymentchannel"]').val(data.paymentChannel);
        $('input[name="paymentcode"]').val(data.paymentCode);
        $('input[name="bankissuer"]').val(data.cardIssuer);
        $('input[name="cardbrand"]').val(data.cardType);
        $('input[name="cardholdername"]').val(data.cardHolderName);
        $('input[name="cardnumber"]').val(data.cardNum);
    }, []);

    useEffect(() => {
        // setTest([props.params.data[0].transaction]);  

        const dropdownPaymentType = async dispatch => {
            let payType = [];
            let payTypeResp = await Get(urlConstant.URL_BASED + urlConstant.URL_LOOKUP_PAYMENT_TYPE, null, dispatch);
            let dataResp = payTypeResp.respData;
            if(!payTypeResp.isError && dataResp.data) {
                payType = dataResp.data.map(reason => {
                    return {shortCode: reason.shortCode, value: reason.value}
                });
            }
            setPaymentTypeList({pymtTypeList:payType});
        }

        const dropdownPaymentChannel = async dispatch => {
            let payChannel = [];
            let payChannelResp = await Get(urlConstant.URL_BASED + urlConstant.URL_LOOKUP_PAYMENT_CHANNEL, null, dispatch);
            let dataResp = payChannelResp.respData;
            if(!payChannelResp.isError && dataResp.data) {
                payChannel = dataResp.data.map(reason => {
                    return {shortCode: reason.shortCode, value: reason.value}
                });
            }
            setPaymentChannelList({pymtChannelList:payChannel});
        }

        dispatch(dropdownPaymentType);
        dispatch(dropdownPaymentChannel);
        populateField(props.params.data[0]);

    }, [dispatch, populateField, props.params.data])

    //to do
    const doUpdate = async dispatch => {
        let postObj = {};
        let selectId = null; 
        if (props.transactionId) selectId = props.transactionId;
        
        if (validateForm()) {
            postObj = {
                approvedCode: $('input[name="apprcode"]').val(),
                paymentMethod: $('select[name="paymenttype"]').val(),
                paymentChannel: $('select[name="paymentchannel"]').val(),
                paymentCode: $('input[name="paymentcode"]').val(),
                cardIssuer: $('select[name="bankissuer"]').val(),
                cardType: $('select[name="cardbrand"]').val(),
                cardHolderName: $('input[name="cardholdername"]').val(),
                cardNum: $('input[name="cardnumber"]').val(),
                trnxId: selectId,
                id: props.params.data[0].id
            };

            let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_UPDATE_PAYMENT, postObj, dispatch);
            if(!resp.isError && resp.respData.data) {
                props.handleClose(true, t('message.save.success', { 0: t('transaction.search.cinumber'), 1: t('updated') }));
            } else {
                props.handleClose(false, '');
            }

        }
        //window.scrollTo(0, 0);
    }

    
    return (
            <Container>
                { message? <div className="error"><p>{message}</p></div>: null}
                <Table>
                <tbody >
                    <Fragment key="updateCIPayment">
                    <tr>
                        <td><Label>{t('transaction.search.cinumber')}</Label></td>
                        <td>:</td>
                        <td><Label>{props.params.data[0].policyNo}</Label></td>
                    </tr>
                    <tr>
                        <td><Label>{t('transaction.detail.periodofinsurance')}</Label></td>
                        <td>:</td>
                        <td><Label>{props.params.data[0].issuanceStartDate} To {props.params.data[0].issuanceEndDate}</Label></td>
                    </tr>
                    <tr>
                        <td><Label>{t('productname')}</Label></td>
                        <td>:</td>
                        <td><Label>{props.params.data[0].productDesc}</Label></td>
                    </tr>
                    <tr>
                        <td><Label>{t('transaction.detail.plan')}</Label></td>
                        <td>:</td>
                        <td><Label>{props.params.data[0].planName}</Label></td>
                    </tr>
                    <tr>
                        <td><Label>{t('suminsured')}</Label></td>
                        <td>:</td>
                        {props.params.data[0].sumInsured ?
                        <td><Label>Rp. {props.params.data[0].sumInsured}</Label></td>
                        : <td></td>    
                        }
                    </tr>
                    <tr>
                        <td><Label>{t('transaction.detail.premium')}</Label></td>
                        <td>:</td>
                        <td><Label>{props.params.data[0].totalPremium ? "Rp. "+props.params.data[0].totalPremium : ""}</Label></td>
                    </tr>
                    </Fragment>
                    <tr>
                        <td><Label>{t('paymentreferenceno')}</Label></td>
                        <td>:</td>
                        {/* <td><Input readOnly type="text" name="paymentrefno" maxLength={25} /></td> */}
                        <td><Label>{props.params.data[0].paymentRefNo}</Label></td>
                    </tr>
                    <tr>
                        <td><Label className="required">{t('approvalcode')}</Label></td>
                        <td>:</td>
                        <td><Input type="text" name="apprcode" maxLength={25} placeholder={t('placeholder.enter', { 0: t('approvalcode') })} /></td>
                    </tr>
                    <tr>
                        <td><Label className="required">{t('paymenttype')}</Label></td>
                        <td>:</td>
                        <td><Select name="paymenttype" optionList={paymentTypeList.pymtTypeList} /></td>
                    </tr>
                    <tr>
                        <td><Label className="required">{t('paymentchannel')}</Label></td>
                        <td>:</td>
                        <td><Select name="paymentchannel" optionList={paymentChannelList.pymtChannelList} /></td>
                    </tr>
                    <tr>
                        <td><Label className="required">{t('paymentcode')}</Label></td>
                        <td>:</td>
                        <td><Input type="text" name="paymentcode" maxLength={25} placeholder={t('placeholder.enter', { 0: t('paymentcode') })} /></td>
                    </tr>
                    <tr>
                        <td><Label className="required">{t('bankissuer')}</Label></td>
                        <td>:</td>
                        <td><Input type="text" name="bankissuer" maxLength={25} placeholder={t('placeholder.enter', { 0: t('bankissuer') })} /></td>
                    </tr>
                    <tr>
                        <td><Label className="required">{t('cardbrand')}</Label></td>
                        <td>:</td>
                        <td><Input type="text" name="cardbrand" maxLength={25} placeholder={t('placeholder.enter', { 0: t('cardbrand') })} /></td>
                    </tr>
                    <tr>
                        <td><Label className="required">{t('cardholdername')}</Label></td>
                        <td>:</td>
                        <td><Input type="text" name="cardholdername" maxLength={25} placeholder={t('placeholder.enter', { 0: t('cardholdername') })} /></td>
                    </tr>
                    <tr>
                        <td><Label className="required">{t('cardnumber')}</Label></td>
                        <td>:</td>
                        <td><Input type="text" name="cardnumber" maxLength={25} placeholder={t('placeholder.enter', { 0: t('cardnumber') })} onKeyPress={(event) => Validate.number(event)} /></td>
                    </tr>
                </tbody>
                </Table>
                <div style={{textAlign: 'center'}}>
                    <Button className="mt-3" onClick={ ()=> dispatch(doUpdate) } >{t('button.save')}</Button>
                </div>
            </Container>
    )
}

export default TransactionUpdatePayment;