import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import PaginationBar from '../../../components/PaginationBar';
import { Post } from '../../../utils/AxFetch';
import { useTranslation } from 'react-i18next';
import * as Constant from '../../../constants/Constant';
import * as urlConstant from '../../../constants/urlConstant';

const CertificateBatchUploadList = ({ searchCriteria, setDisclaimerFields }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [itemList, setItemList] = useState({data:[], currentPage:1, pageSize:0, pagesCount:0});
    const [pageOnClick,setPageOnClick] = useState(1);
    
    useEffect(() => {
        if(searchCriteria)
            setPageOnClick(1);
    }, [searchCriteria]);

    const onClick = page => setPageOnClick(page);

    useEffect(() => {
        const getItemList = async(page) => {                
            let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_TRNX_BULKCREATION_LIST + page,    
                {
                    rowPerPage: Constant.rowPerPage,
                    pageNum: page,
                    productCode: searchCriteria.product
                }, dispatch);
            let dataResp = resp.respData;

            if(!resp.isError && dataResp.data) {
                setItemList({data:dataResp.data.page.content, currentPage:page.page, pageSize:dataResp.data.page.size, pagesCount:dataResp.data.page.totalPages})
                setDisclaimerFields(dataResp.data.disclaimerFields);
            }
        }
        if (searchCriteria.product) getItemList(pageOnClick); 
        else setItemList({data:[], currentPage:1, pageSize:0, pagesCount:0});
}, [dispatch, searchCriteria, pageOnClick]);

    return (
        <div>
            <Table className="mt-3" bordered>
                <thead>
                    <tr>
                        <th style={{width: '10%'}}>{t('no.')}</th>
                        <th>{t('batch.certificateinsurance.product')}</th>
                        <th>{t('policytype')}</th>
                        <th>{t('batchFileCI')}</th>
                        <th>{t('documentdate')}</th>
                        <th>{t('documentby')}</th>
                        <th>{t('processStatus')}</th>
                        <th>{t('processDate')}</th>
                        <th>{t('remark')}</th>

                    </tr>
                </thead>
                {(itemList.data && itemList.data.length > 0) ?
                <tbody>
                    { itemList.data.map((item, i) =>                     
                        <tr key={i}>
                            <td style={{width: '10%'}}>{i+1}</td>
                            <td>{item.productCode}</td>
                            <td>{item.policyType}</td>
                            <td>{item.fileName}</td>
                            <td>{item.createdDatetime}</td>                           
                            <td>{item.createdByName}</td>
                            <td>{item.status}</td>
                            <td>{item.lastModifiedDatetime}</td>
                            <td>{item.remark}</td>
                        </tr>
                    )}
                </tbody>
                : <tbody>
                    <tr><td className="emptyrecord" colSpan={8}>{t('norecordfound')}</td></tr>
                </tbody>
            }
            </Table>
            <PaginationBar totalPages={itemList.pagesCount} currentPage={itemList.currentPage} handleClick={(page) => onClick(page)}  />
        </div>
    );
}

export default CertificateBatchUploadList;